export const QuincunxSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
      <path
        d="M21.2501 11L5 11V7L25 7H45V11H27.2857L45 42L41.527 43.9846L24.2679 13.7811L7 44L3.52703 42.0154L21.2501 11Z"
        fillRule="evenodd"
        clipRule="evenodd"
        stroke="currentColor"
        fill="currentColor"
      />
    </svg>
  );
}
