import { Chart as ChartType, Synastry } from '@danielpedroso/astrum-calc/dist/types';
import { SIGNS } from '@danielpedroso/astrum-calc/dist/constants';
import { Sign } from './sign';
import { House } from './house';
import { CelestialObject } from './object';
import { useMemo } from 'react';
import { Aspect } from './aspect';
import { ChartItemSelection } from './utils';
import { SquareRatio, Container, Svg } from './styled';
import { Degrees } from './degrees';

interface Props {
  synastry?: Synastry;
  itemSelection: ChartItemSelection;
  setItemSelection: (selection: ChartItemSelection) => void;
}

const mapObjects = (chart: ChartType) => {
  const objs = Object.values(chart.planets)
    .map(({ key, ...rest }) => ({ key: key as string, ...rest, depth: 1 }))
    .concat(({
      ...chart.lunarOrbit.meanAscendingNode,
      key: 'meanAscendingNode',
      depth: 1,
      isRetrograde: false,
    })).concat(({
      ...chart.lunarOrbit.meanDescendingNode,
      key: 'meanDescendingNode',
      depth: 1,
      isRetrograde: false,
    }));
  
  for (let i = 0; i < objs.length; i++) {
    const curr = objs[i];
    curr.depth = objs
      .slice(0, i)
      .filter(o => Math.abs(o.absolute - curr.absolute) < 10.0)
      .length;
  }

  return objs;
}


export const SynastryChart: React.FC<Props> = ({ synastry, itemSelection, setItemSelection }) => {
  const { externalPlanets, internalPlanets } = useMemo(() => {
    if (!synastry) return { externalPlanets: [], internalPlanets: [] };

    return { internalPlanets: mapObjects(synastry.chart1), externalPlanets: mapObjects(synastry.chart2) };
  }, [synastry]);

  const aspects = useMemo(() => {
    if (!itemSelection.item || !itemSelection.type) return synastry?.aspects;
    if (itemSelection.type === 'aspect') return synastry?.aspects;
    if (itemSelection.type === 'houseCusp' && !['1', '10'].includes(itemSelection.item as string)) return synastry?.aspects;

    return synastry?.aspects.filter((a) => {
      return a.object1.key === itemSelection.item || a.object2.key === itemSelection.item;
    });
  }, [synastry?.aspects, itemSelection.item, itemSelection.type])

  const rotation = useMemo(() => {
    if (!synastry?.chart1.houseCusps?.[0]) return 0;

    return synastry.chart1.houseCusps[0];
  }, [synastry?.chart1.houseCusps]);

  return (
    <SquareRatio>
      <Container>
        <Svg
          id="synastry-chart"
          viewBox="0 0 800 800"
          preserveAspectRatio="xMidYMid meet"
          style={{ transformBox: 'fill-box', transformOrigin: 'center', transform: rotation ? `rotate(${rotation}deg)` : undefined }}
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* External circle */}
          <circle r="48%" cx="50%" cy="50%" stroke="black" fill="transparent" />

          {/* externalChart sky zone */}
          {synastry?.chart2.houseCusps.map((cusp, i) => (
            <House
              key={i}
              externalRadiusMultiplier={.48}
              internalRadiusMultiplier={.33}
              extraInfoRadiusMultiplier={.48}
              namePos="external"
              cusp={cusp}
              name={`${i + 1}`}
              parentSize={800}
              rotation={rotation}
              fontSize={16}
            />
          ))}

          {externalPlanets?.map((planet) => (
            <CelestialObject
              key={planet.key}
              objKey={planet.key}
              object={planet}
              externalRadiusMultiplier={.33}
              inverted
              name={planet.key}
              selection={itemSelection}
              setSelection={setItemSelection}
              parentSize={800}
              pos={planet.absolute}
              depth={planet.depth}
              rotation={rotation}
              fontSize={32}
              hideDegrees
            />
          ))}

          <Degrees parentSize={800} fontSize={18} radiusMultiplier={.33} inverted />
          <circle r="33%" cx="50%" cy="50%" stroke="black" fill="transparent" />

          {/* Signs */}
          {SIGNS.map((name, i) => (
            <Sign
              key={name}
              externalRadiusMultiplier={.33}
              internalRadiusMultiplier={.30}
              cusp={i * 30}
              name={name}
              parentSize={800}
              rotation={rotation}
              fontSize={18}
            />
          ))}
          <Degrees parentSize={800} fontSize={18} radiusMultiplier={.30} />

          {/* End of zodiac sign zone */}


          {/* End of the externalChart sky zone */}
          <circle r="30%" cx="50%" cy="50%" stroke="black" fill="transparent" />
          {/* Houses */}
          {synastry?.chart1.houseCusps.map((cusp, i) => (
            <House
              key={i}
              externalRadiusMultiplier={.3}
              internalRadiusMultiplier={.15}
              extraInfoRadiusMultiplier={.3}
              cusp={cusp}
              name={`${i + 1}`}
              parentSize={800}
              rotation={rotation}
              fontSize={16}
            />
          ))}

          {/* Planets */}
          {internalPlanets?.map((planet) => (
            <CelestialObject
              key={planet.key}
              objKey={planet.key}
              object={planet}
              externalRadiusMultiplier={.3}
              name={planet.key}
              selection={itemSelection}
              setSelection={setItemSelection}
              parentSize={800}
              pos={planet.absolute}
              depth={planet.depth}
              rotation={rotation}
              fontSize={32}
              hideDegrees
            />
          ))}

          {/* Aspects zone */}
          <circle r="15%" cx="50%" cy="50%" stroke="black" fill="transparent" />
          {/* Aspects */}
          {aspects?.map((aspect, i) => (
            <Aspect
              id={i}
              externalRadiusMultiplier={0.15}
              object1={aspect.object1}
              object2={aspect.object2}
              type={aspect.aspectType}
              deg={aspect.degree}
              parentSize={800}
              key={`${aspect.object1.key}${aspect.aspectType}${aspect.object2.key}`}
              selection={itemSelection}
              setSelection={setItemSelection}
            />
          ))}
        </Svg>
      </Container>
    </SquareRatio>
  );
};
