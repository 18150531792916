import { useEffect, useState } from "react";

const breathPhases = ['inhale', 'hold', 'exhale', 'hold'];

interface BreathGuideProps {
  onNext: () => void;
}

export const BreathGuide: React.FC<BreathGuideProps> = ({ onNext }) => {
  const [currentPhase, setCurrentPhase] = useState(0);
  const [count, setCount] = useState(1);
  const [reps, setReps] = useState(1);

  useEffect(() => {
    const inc = () => {
      setCount((c) => {
        let nc = (c + 1) % 5;
        if (nc === 0) {
          nc = 1;
          setCurrentPhase(p => {
            let np = (p + 1) % breathPhases.length;
            if (np === 0) setReps(r => r + 1);
            return np;
          });
        }

        return nc;
      });
    };

    const interval = setInterval(inc, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <p>
      {breathPhases[currentPhase]}: {count}<br />{reps}
      {reps >= 0 && (
        <button onClick={onNext}>Next</button>
      )}
    </p>
  );
};
