"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.phases = exports.freqs = exports.ARCSECS_360_REVOLUTION = exports.COSZEN = exports.COSSUN = exports.DISFAC = exports.RADIUS_EARTH_AU = exports.SPEED_LIGHT_AU_DAY = exports.SPEED_LIGHT = exports.RADIUS_EARTH = exports.AU = exports.DIST_EARTH_FLAT = exports.TPI = exports.ARC_SEC_TO_RADIANS = exports.RADIANS_TO_ARC_SEC = exports.RADIANS_TO_DEG = exports.DEG_TO_RADIANS = exports.EARTH_MOON_RATIO = exports.RADIANS_TO_HRS = exports.JJAN_1900 = exports.BESSELIAN_1950 = exports.JAN_2000 = exports.OBJECT_SYMBOLS_MAP = exports.OBJECT_NAMES = exports.OBJECT_SYMBOLS = exports.SIGN_SYMBOLS_MAP = exports.SIGN_SYMBOLS = exports.SIGNS = exports.ASPECT_DASHARRAYS = exports.ASPECT_COLOURS = exports.ASPECTS = exports.MAIN_ASPECTS = void 0;
exports.MAIN_ASPECTS = [
    'conjunction',
    'sextile',
    'square',
    'trine',
    'opposition',
];
exports.ASPECTS = [
    'conjunction',
    'semisextile',
    'sextile',
    'square',
    'trine',
    'quincunx',
    'opposition',
];
exports.ASPECT_COLOURS = {
    'conjunction': '#FF3700', // Gold
    'semisextile': '#7FFF00', // Chartreuse
    'sextile': '#0055FF', // Aqua
    'square': '#FF0000', // Red
    'trine': '#008000', // Green
    'quincunx': '#FFA500', // Orange
    'opposition': '#800080' // Purple
};
exports.ASPECT_DASHARRAYS = {
    'conjunction': '10,0', // Solid Line
    'semisextile': '10,10', // Dashed Line
    'sextile': '10,0', // Solid Line
    'square': '10,0', // Solid Line
    'trine': '10,0', // Solid Line
    'quincunx': '10,10', // Dashed Line
    'opposition': '10,0' // Solid Line
};
exports.SIGNS = [
    'aries',
    'taurus',
    'gemini',
    'cancer',
    'leo',
    'virgo',
    'libra',
    'scorpio',
    'sagittarius',
    'capricorn',
    'aquarius',
    'pisces'
];
exports.SIGN_SYMBOLS = [
    '♈',
    '♉',
    '♊',
    '♋',
    '♌',
    '♍',
    '♎',
    '♏',
    '♐',
    '♑',
    '♒',
    '♓',
];
exports.SIGN_SYMBOLS_MAP = {
    'aries': '♈',
    'taurus': '♉',
    'gemini': '♊',
    'cancer': '♋',
    'leo': '♌',
    'virgo': '♍',
    'libra': '♎',
    'scorpio': '♏',
    'sagittarius': '♐',
    'capricorn': '♑',
    'aquarius': '♒',
    'pisces': '♓',
};
exports.OBJECT_SYMBOLS = [
    '☽',
    '☿',
    '♀',
    '☉',
    '♂',
    '♃',
    '♄',
    '♅',
    '♆',
    '♇',
    '☊',
    '☋',
];
exports.OBJECT_NAMES = [
    'moon',
    'mercury',
    'venus',
    'sun',
    'mars',
    'jupiter',
    'saturn',
    'uranus',
    'neptune',
    'pluto',
    'meanAscendingNode',
    'meanDescendingNode',
];
exports.OBJECT_SYMBOLS_MAP = {
    'moon': '☽',
    'mercury': '☿',
    'venus': '♀',
    'sun': '☉',
    'mars': '♂',
    'jupiter': '♃',
    'saturn': '♄',
    'uranus': '♅',
    'neptune': '♆',
    'pluto': '♇',
    'meanAscendingNode': '☊',
    'meanDescendingNode': '☋',
};
/* Standard epochs.  Note Julian epochs (J) are measured in
 * years of 365.25 days.
 */
exports.JAN_2000 = 2451545.0; /* 2000 January 1.5 */
exports.BESSELIAN_1950 = 2433282.423; /* 1950 January 0.923 Besselian epoch */
exports.JJAN_1900 = 2415020.0; /* 1900 January 0, 12h UT */
exports.RADIANS_TO_HRS = 12.0 / Math.PI; /* Radians to hours, minutes, seconds */
exports.EARTH_MOON_RATIO = 81.300585; /* Earth/Moon mass ratio.  */
/* Conversion factors between degrees and radians */
exports.DEG_TO_RADIANS = 1.7453292519943295769e-2;
exports.RADIANS_TO_DEG = 5.7295779513082320877e1;
exports.RADIANS_TO_ARC_SEC = 2.0626480624709635516e5; /* arc seconds per radian */
exports.ARC_SEC_TO_RADIANS = 4.8481368110953599359e-6; /* radians per arc second */
exports.TPI = 2.0 * Math.PI;
/* Distance from observer to center of earth, in earth radii
 */
exports.DIST_EARTH_FLAT = 298.257222;
exports.AU = 1.49597870691e8; /* Astronomical unit, in kilometers.  */
exports.RADIUS_EARTH = 6378137; /* Radius of the earth, in meters.  */
exports.SPEED_LIGHT = 2.99792458e5; /* Speed of light, km/sec  */
exports.SPEED_LIGHT_AU_DAY = 86400.0 * exports.SPEED_LIGHT / exports.AU; /* C in au/day  */
/* Radius of the earth in au
 Thanks to Min He <Min.He@businessobjects.com> for pointing out
 this needs to be initialized early.  */
exports.RADIUS_EARTH_AU = 0.001 * exports.RADIUS_EARTH / exports.AU; // calculated in kinit
/* Earth radii per au */
exports.DISFAC = 2.3454780e4;
/* cosine of 90 degrees 50 minutes: */
exports.COSSUN = -0.014543897651582657;
/* cosine of 90 degrees 34 minutes: */
exports.COSZEN = -9.8900378587411476e-3;
// Arcsecs in a full 360 revolution
exports.ARCSECS_360_REVOLUTION = 1.296e6;
/* From Simon et al (1994)  */
exports.freqs = [
    /* Arc sec per 10000 Julian years.  */
    53810162868.8982,
    21066413643.3548,
    12959774228.3429,
    6890507749.3988,
    1092566037.7991,
    439960985.5372,
    154248119.3933,
    78655032.0744,
    52272245.1795
];
exports.phases = [
    /* Arc sec.  */
    252.25090552 * 3600.,
    181.97980085 * 3600.,
    100.46645683 * 3600.,
    355.43299958 * 3600.,
    34.35151874 * 3600.,
    50.07744430 * 3600.,
    314.05500511 * 3600.,
    304.34866548 * 3600.,
    860492.1546
];
