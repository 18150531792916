import { useCallback, useMemo, useState } from 'react';
import { FullCross } from './full-cross';
import { GrowingAnimation } from './growing-animation';

const crossPhases = ['grow', 'ateh', 'malkuth', 've-geburah', 've-gedulah', 'cross', 'amen'];

interface BreathGuideProps {
  onNext: () => void;
}

const AtehGuide: React.FC = () => {
  return (
    <>
      <p>Touch your forehead with your index and middle fingers (other fingers folded into a fist) and vibrate the following word, feeling your whole body vibrate with it</p>
      <h1>ATEH</h1>
      <p>Visualize a pilar of light coming down from Kether and touching your forehead.</p>
    </>
  );
};

const MalkuthGuide: React.FC = () => {
  return (
    <>
      <p>Make a fist and place your thumb between your index and middle finger. Move your hand down (and visualise the pilar of light continue to come down with it) until it's in front of your crotch.<br />Vibrate the following word:</p>
      <h1>Malkuth</h1>
      <p>Visualise the pilar of light going down until the center of the Earth. That pillar now connects Kether to Malkuth, with you and your altar in the middle.</p>
    </>
  );
};

const VeGeburahGuide: React.FC = () => {
  return (
    <>
      <p>With your index and middle finger extended (other fingers folded), move your hand towards your right shoulder. As you do, visualise the right arm of the cross grow from the central pilar. Touch your right shoulder with your index and middle finger. Vibrate:</p>
      <h1>Ve-Geburah</h1>
      <p>The right arm of the cross should expand to your right, just past your right shoulder.</p>
    </>
  );
};

const VeGedulahGuide: React.FC = () => {
  return (
    <>
      <p>Moving your hand towards the left shoulder, visualise the left arm of the cross expanding with it. Touch your left shoulder with your index and middle finger. Vibrate:</p>
      <h1>Ve-Gedulah</h1>
      <p>The left arm of the cross should mirror the right one, just past your shoulder. The cross is now complete, with you and your altar right in the middle of it.</p>
    </>
  );
};

const RoseCrossGuide: React.FC = () => {
  return (
    <>
      <p>Visualise the full cross and draw your personal sigil in the center (in one continuous trace, starting from the circle)</p>
      <FullCross />
      <p>Then open your arms so that you ARE the cross. Take a deep breath and feel light of Kether around you.</p>
    </>
  );
};

const AmenGuide: React.FC = () => {
  return (
    <>
      <p>Join your palms in front of your heart (in a "praying" position). If using a dagger, place it in between your palms pointing up. Vibrate:</p>
      <h1>LeOlam Amen</h1>
      <p>The Cabalistic Cross is complete. Time for the pentagram.</p>
    </>
  );
};

export const CrossGuide: React.FC<BreathGuideProps> = ({ onNext }) => {
  const [stage, setStage] = useState(0);

  const Elem: React.FC | undefined = useMemo(() => {
    switch (stage) {
      case 0: return GrowingAnimation;
      case 1: return AtehGuide;
      case 2: return MalkuthGuide;
      case 3: return VeGeburahGuide;
      case 4: return VeGedulahGuide;
      case 5: return RoseCrossGuide;
      case 6: return AmenGuide;
    }
  }, [stage]);

  const handleBackClick = useCallback(() => {
    setStage((s) => Math.max(0, s - 1));
  }, []);

  const handleForwardClick = useCallback(() => {
    if (stage === crossPhases.length - 1) {
      return onNext();
    }

    return setStage((s) => {
      return Math.max(0, (s + 1) % crossPhases.length)
    });
  }, [stage, onNext]);

  return (
    <>
      {stage > 0 && <button onClick={handleBackClick}>&lt;-</button>}
      {Elem && <Elem />}
      <button onClick={handleForwardClick}>-&gt;</button>
    </>
  );
};
