export const ConjunctionSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
      <path
        d="M30.5686 21.6637L45 5.30811L41.2508 2L26.4577 18.7655C24.5019 17.8312 22.312 17.3081 20 17.3081C11.7157 17.3081 5 24.0238 5 32.3081C5 40.5924 11.7157 47.3081 20 47.3081C28.2843 47.3081 35 40.5924 35 32.3081C35 28.1467 33.3054 24.3811 30.5686 21.6637ZM30 32.3081C30 37.831 25.5228 42.3081 20 42.3081C14.4772 42.3081 10 37.831 10 32.3081C10 26.7853 14.4772 22.3081 20 22.3081C25.5228 22.3081 30 26.7853 30 32.3081Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}