import React, { useMemo } from 'react';
import { getLineCoordinates } from '../utils'

interface SignProps {
  parentSize: number;
  radiusMultiplier: number;
  fontSize: number;
  show30s?: boolean;
  inverted?: boolean;
}

export const Degrees: React.FC<SignProps> = ({
  parentSize,
  radiusMultiplier,
  fontSize,
  show30s = false,
  inverted = false,
}) => {
  const coords = useMemo(() => {
    const width = parentSize;
    const screenRadius = width / 2;
    const radius = width * radiusMultiplier;

    const markings = [];
    for (let i = 1; i <= 360; i++) {
      const mod5 = i % 5 === 0;
      const mod30 = i % 30 === 0;

      const correct = (n: number) => inverted ? n * -1 : n;
      const mod30Size = show30s ? (fontSize / 1.5) : fontSize / 2;
      const size = mod30 ? mod30Size : (mod5 ? (fontSize / 2) : (fontSize / 4));
      markings.push({
        key: i,
        ...getLineCoordinates(i, radius, radius - correct(size), screenRadius),
      });
    }

    return {
      markings,
    };
  }, [parentSize, radiusMultiplier, show30s, fontSize, inverted]);

  return (
    <g>
      {coords.markings.map(marking => (
        <line {...marking} stroke="black" />
      ))}
    </g>
  );
};
