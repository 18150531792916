import moment from 'moment-timezone';
import { atom, SetStateAction } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { Aspect, Synastry } from '../../lib/types';
import { ChartItemSelection } from '@danielpedroso/chart/dist/utils';
import { getPlanetaryHours, getSolarInfo, PlanetaryHours, SolarLunarInfo } from '@danielpedroso/astrum-calc/dist/planetary-magick';
import { locationsAtom } from '../../core/location/state';

interface ChartState {
  chart1: {
    isoDate: string;
    locationId: string;
  },
  chart2: {
    isoDate: string;
    locationId: string;
  }
}

const initialState: ChartState = {
  chart1: {
    isoDate: new Date().toISOString(),
    locationId: 'BNE',
  },
  chart2: {
    isoDate: new Date().toISOString(),
    locationId: 'BNE',
  },
};
const initialStr = JSON.stringify(initialState);
const STORAGE_KEY = 'ASTRUM_SYNASTRY';

const strStateAtom = atom(localStorage.getItem(STORAGE_KEY) ?? initialStr);

export const chartStateAtom = atom(
  (get) => JSON.parse(get(strStateAtom)) as ChartState,
  (get, set, newState: SetStateAction<ChartState>) => {
    let newV: ChartState;
    if (typeof newState === 'function') {
      newV = newState(get(chartStateAtom));
    } else {
      newV = newState;
    }

    const newVal = JSON.stringify(newV ?? initialState);
    set(strStateAtom, newVal);
    localStorage.setItem(STORAGE_KEY, newVal);
  }
);

export const chart1LocationAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const locations = get(locationsAtom);
  const locationId = chartState.chart1.locationId ?? 'BNE';
  return locations.find((l) => l.id === locationId) ?? locations[0];
});

export const chart2LocationAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const locations = get(locationsAtom);
  const locationId = chartState.chart2.locationId ?? 'BNE';
  return locations.find((l) => l.id === locationId) ?? locations[0];
});

export const chartAtom = atom<Synastry | undefined>(undefined);

export const chartItemSelectionAtom = atom<ChartItemSelection>({ type: 'object', item: '' });

export const chartAspectsAtom = focusAtom(chartAtom, (optic) => optic.optional().prop('aspects'));

export const planetaryInfoAtom = atom<{ curr: SolarLunarInfo, next: SolarLunarInfo, planetaryHours: PlanetaryHours }>(
  (get) => {
    const chartState = get(chartStateAtom);
    const chart2Location = get(chart2LocationAtom);
    const curr = getSolarInfo(moment.tz(chartState.chart2.isoDate, chart2Location.tz), chart2Location.lat, chart2Location.lon);
    const next = getSolarInfo(moment.tz(chartState.chart2.isoDate, chart2Location.tz).add(1, 'day'), chart2Location.lat, chart2Location.lon);
    const planetaryHours = getPlanetaryHours(curr, next);

    return { curr, next, planetaryHours };
  },
);

interface FilteredAspect extends Aspect {
  ogIndex: number;
}

export const filteredAspectsAtom = atom(
  (get) => {
    const selection = get(chartItemSelectionAtom);
    const aspects = get(chartAspectsAtom);
    const mapped = aspects?.map((a, i): FilteredAspect => ({ ...a, ogIndex: i }));

    if (!selection.type) return mapped;
    if (!selection.item) return mapped;
    if (selection.type === 'aspect') return mapped;
    if (selection.type === 'houseCusp' && !['1', '10'].includes(selection.item as string)) return mapped;

    const filtered = mapped?.filter((a: FilteredAspect) =>
      a.object1.key === selection.item || a.object2.key === selection.item);

    return filtered;
  }
);
