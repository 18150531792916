export const Tsaddi: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_121_10)">
        <path d="M36.6953 37.9219V42H12.1094V36.3281H27.8594L10.2344 12.9141H17.8281L26.7109 24.9609C27.7422 24.4609 28.4844 23.7109 28.9375 22.7109C29.4062 21.7109 29.7344 20.25 29.9219 18.3281L30.4375 12.9141H36.9297L36.4375 18.375C36.2344 20.5312 35.8906 22.2188 35.4062 23.4375C34.9219 24.625 34.2266 25.6328 33.3203 26.4609C32.4141 27.2891 31.1719 28.0234 29.5938 28.6641L36.6953 37.9219Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_121_10">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
