export const Nun: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_4)">
        <path
          d="M31.8516 24.375V42H16.6875V36.3281H25.5234V25.5938C25.5234 23.4844 25.3828 21.9766 25.1016 21.0703C24.8203 20.1797 24.3516 19.5469 23.6953 19.1719C23.0391 18.7812 22.0781 18.5859 20.8125 18.5859H18.0234V12.9141H22.0078C24.6953 12.9141 26.7031 13.2734 28.0312 13.9922C29.3594 14.7109 30.3281 15.8906 30.9375 17.5312C31.5469 19.1719 31.8516 21.4531 31.8516 24.375Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_120_4">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
