import React, { useCallback } from "react";
import { useAtom } from 'jotai';
import { IconButton, Icon } from "@mui/material";
import { chartItemSelectionAtom } from '../state';
import { objectSymbols } from '@danielpedroso/celestial-objects';
import { Toolbar } from './index';
import { celestialBodies } from "@danielpedroso/chart/dist/utils";
import { useTranslation } from "react-i18next";

export const PlanetsToolbar: React.FC = () => {
  const { t } = useTranslation();
  const [, setItemSelection] = useAtom(chartItemSelectionAtom);

  const handleClick = useCallback((item: string): React.MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      setItemSelection({
        type: 'object',
        item: item,
      })
    },
    [setItemSelection]
  );

  return (
    <Toolbar title={t('chartPage.planets')} bottomBorder>
      {celestialBodies.map((key) => {
        const S1 = objectSymbols[key];

        return (
          <IconButton
            key={key}
            size="small"
            onClick={handleClick(key)}
          >
            <Icon>
              <S1 stroke="black" width={18} height={18} />
            </Icon>
          </IconButton>
        )
      })}
    </Toolbar>
  );
}
