export const Lamed: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_119_2)">
        <path
          d="M15.5234 36.5859C19.3359 35.3359 22.4141 33.7578 24.7578 31.8516C26.3828 30.5547 27.625 29.1406 28.4844 27.6094C29.3438 26.0625 29.7734 24.4453 29.7734 22.7578C29.7734 19.9766 28.0312 18.5859 24.5469 18.5859H12.6406V2.97656H19.1094V12.9141H25.6016C32.6328 12.9141 36.1484 15.9844 36.1484 22.125C36.1484 25.0625 35.4531 27.8125 34.0625 30.375C32.6875 32.9219 30.7188 35.1953 28.1562 37.1953C24.7812 39.8203 20.5703 41.8828 15.5234 43.3828V36.5859Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_119_2">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
