import { Table, TableCell, TableBody, TableRow, SvgIconProps, Box, TableHead } from "@mui/material";
import { objectSymbols } from "@danielpedroso/celestial-objects";
import { ChartItemSelection } from "@danielpedroso/chart/dist/utils";
import { zodiacSignSymbols } from "@danielpedroso/zodiac-signs";
import { Aspect, Synastry } from "../../../lib/types";
import { useTranslation } from "react-i18next";
import { aspectSymbols } from "@danielpedroso/aspects";
import { useMemo } from "react";

interface Props {
  chart?: Synastry;
  itemSelection?: ChartItemSelection;
}

interface SignProps extends SvgIconProps {
  sign: string;
}

const RenderSign: React.FC<SignProps> = ({ sign, ...props }) => {
  const Sign = zodiacSignSymbols[sign];
  return <Sign {...props} />;
};

interface RowProps extends Props {
  aspect: Aspect;
}

const Row: React.FC<RowProps> = ({ aspect }) => {
  if (!aspect) return null;

  const Obj1 = objectSymbols[aspect.object1.key];
  const Obj2 = objectSymbols[aspect.object2.key];
  const Aspect = aspectSymbols[aspect.aspectType];

  return (
    <TableRow>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Obj1 width={18} height={18} stroke="black" />
          <RenderSign sign={aspect.object1.sign} width={18} height={18} stroke="black" />
          <Aspect stroke="black" width={18} height={18} />
          <Obj2 width={18} height={18} stroke="black" />
          <RenderSign sign={aspect.object2.sign} width={18} height={18} stroke="black" />
        </Box>
      </TableCell>
      <TableCell>
        {aspect.degree.toFixed(2)}º
      </TableCell>
    </TableRow>
  );
};

export const AspectsTable: React.FC<Props> = ({ chart, itemSelection }) => {
  const { t } = useTranslation();
  const aspects = useMemo(() => {
    const all = (itemSelection && (itemSelection.item === 0 || itemSelection.item))
      ? (chart?.aspects.filter(a => a.object1.key === itemSelection.item || a.object2.key === itemSelection.item))
      : (chart?.aspects);
      
    return (all ?? []).sort((a, b) => a.degree - b.degree);
  }, [itemSelection, chart?.aspects]);

  if (!chart) return null;

  return (
    <Box 
      display="flex"
      flex-direction="row"
      justifyContent="space-around"
      marginTop="24px"
      sx={{
        sm: {
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }
      }}
    >
      <Table sx={{ width: { md: '25%', sm: '100%' }, maxWidth: '400px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.aspect')}</TableCell>
            <TableCell>{t('tables.degree')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aspects?.map((aspect, i) => <Row aspect={aspect} key={i} />) ?? null}
        </TableBody>
      </Table>
    </Box>
  );
};