export const Tav: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_114_12)">
        <path d="M19.5547 37.3594C18.9297 38.9688 17.9688 40.1484 16.6719 40.8984C15.375 41.6328 13.4922 42 11.0234 42H9.42969V36.3281H9.82812C10.9062 36.3281 11.7656 36.125 12.4062 35.7188C13.0312 35.3125 13.4766 34.6953 13.7422 33.8672C14.0078 33.0234 14.1406 31.75 14.1406 30.0469V18.5859H9.6875V12.9141H26C30.5 12.9141 33.7266 13.8672 35.6797 15.7734C37.6484 17.6641 38.6328 20.8047 38.6328 25.1953V42H32.2812V25.2891C32.2812 22.7891 31.7734 21.0469 30.7578 20.0625C29.7422 19.0781 28.0312 18.5859 25.625 18.5859H20.4688V30.8672C20.4688 33.5703 20.1641 35.7344 19.5547 37.3594Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_114_12">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
