import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ChartIcon } from '@danielpedroso/icons/dist/chart-icon';
import { TransitsIcon } from '@danielpedroso/icons/dist/transits-icon';
import { RevolutionIcon } from '@danielpedroso/icons/dist/revolution-icon';

export const AppBar: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const appBarHeight = useAppBarHeight();

  const handleLanguageClick = useCallback(() => {
    i18n.changeLanguage(i18n.language === 'en' ? 'pt' : 'en');
  }, [i18n]);

  return (
    <MuiAppBar position="fixed">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setMenuOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Astrum
        </Typography>
        <Button color="inherit" onClick={handleLanguageClick}>{i18n.language === 'en' ? 'PT' : 'EN'}</Button>
      </Toolbar>
      <Drawer
        open={menuOpen}
        PaperProps={{ sx: { marginTop: `${appBarHeight}px` } }}
        onClose={() => setMenuOpen(false)}
      >
        <Box sx={{ width: 250 }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/chart" onClick={() => setMenuOpen(false)}>
                <ListItemIcon>
                  <ChartIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.chart')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/transits" onClick={() => setMenuOpen(false)}>
                <ListItemIcon>
                  <TransitsIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.transits')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/revolution" onClick={() => setMenuOpen(false)}>
                <ListItemIcon>
                  <RevolutionIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.revolution')} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </MuiAppBar>
  );
}

type MinHeight = {
  minHeight: number,
};

export const useAppBarHeight = (): number => {
  const {
    mixins: { toolbar },
    breakpoints,
  } = useTheme();
  const toolbarDesktopQuery = breakpoints.up('sm');
  const toolbarLandscapeQuery = `${breakpoints.up('xs')} and (orientation: landscape)`;
  const isDesktop = useMediaQuery(toolbarDesktopQuery);
  const isLandscape = useMediaQuery(toolbarLandscapeQuery);
  let currentToolbarMinHeight;
  if (isDesktop) {
    currentToolbarMinHeight = toolbar[toolbarDesktopQuery];
  } else if (isLandscape) {
    currentToolbarMinHeight = toolbar[toolbarLandscapeQuery];
  } else {
    currentToolbarMinHeight = toolbar;
  }
  return (currentToolbarMinHeight as MinHeight).minHeight;
}