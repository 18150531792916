import axios from 'axios';
import { Modal, Typography, Box, TextField, Autocomplete, Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import countryCodes from './countryCodes.json';
import { useTranslation } from 'react-i18next';

export interface Location {
  id: string;
  name: string;
  country: string;
  lat: number;
  lon: number;
  height: number;
  tz: string;
}

interface Country {
  code: string;
  label: string;
}

interface Geoname {
  geonameId: number;
  name: string;
  adminName1: string;
  countryCode: string;
  lat: string;
  lng: string;
  astergdem: number;
  timezone: {
    gmtOffset: number;
    timeZoneId: string;
    dstOffset: number;
  },
}

interface Props {
  open: boolean;
  onClose: (chosen?: Location) => void;
}

export const LocationFinderModal: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState<Country | null>(null);
  const [cityQuery, setCityQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Geoname[]>([]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCountryChanged = useCallback((_: any, value: Country | null) => {
    setCountry(value);
  }, []);

  const handleCityChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setCityQuery(e.target.value);
  }, [])

  const handleSearchClicked = useCallback(async () => {
    if (!country || !cityQuery || (cityQuery?.length < 3)) return;

    try {
      setLoading(true);
      const res = await axios.get(
        `https://bbpf32q7cl.execute-api.us-east-1.amazonaws.com/prod/location/search?country=${country.code}&query=${encodeURIComponent(cityQuery)}`
      );
      setResults((res.data?.geonames ?? []) as Geoname[]);
    }
    finally {
      setLoading(false);
    }
  }, [cityQuery, country]);

  const handleCityClicked = useCallback((city: Geoname) => async () => {
    if (!city) return;

    const lat = Number.parseFloat(city.lat);
    const lon = Number.parseFloat(city.lng);
    const height = city.astergdem || 0;
    let tz = city.timezone.timeZoneId ?? '';

    try {
      setLoading(true);
      if (!tz) {
        const tzQuery = await axios.get(
          `https://bbpf32q7cl.execute-api.us-east-1.amazonaws.com/prod/location/tz?lat=${city.lat}&lon=${city.lng}`
        );
        tz = tzQuery?.data?.tz as string;
      }

      onClose({
        id: city.geonameId.toString(),
        country: city.countryCode,
        name: `${city.name}, ${city.adminName1}`,
        lat,
        lon,
        height,
        tz,
      });
    }
    finally {
      setLoading(false);
    }
  }, [onClose]);

  const searchDisabled = useMemo(() => {
    return loading || !(country && cityQuery) || cityQuery?.length < 3;
  }, [loading, country, cityQuery]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width={400}
        bgcolor="background.paper"
        border="1px solid"
        borderColor="InactiveBorder"
        maxHeight="80vh"
        overflow="hidden"
        borderRadius={1}
        p={2}
        gap={2}
        display="flex"
        flexDirection="column"
        sx={{ transform: 'translate(-50%, -50%)', overflowY: 'auto' }}
      >
        <Typography variant="h6" gutterBottom>{t('chartPage.location.findLocation')}</Typography>
        <Autocomplete
          options={countryCodes.countries as unknown as Country[]}
          renderInput={(params) => <TextField label={t('chartPage.location.country')} {...params} />}
          value={country}
          onChange={handleCountryChanged}
        />
        <TextField label={t('chartPage.location.city')} disabled={!country?.code} onChange={handleCityChanged} />
        <Button variant="contained" disabled={searchDisabled} onClick={handleSearchClicked}>{t('chartPage.location.search')}</Button>
        {results.map(r => (
          <Button key={r.geonameId} onClick={handleCityClicked(r)}>{r.name}, {r.adminName1} - {r.countryCode}</Button>
        ))}
      </Box>
    </Modal>
  );
};
