import React, { useCallback, useState } from 'react';
import { ClickAwayListener, Tooltip, Typography } from '@mui/material';
import { hebrewLettersToNames } from '@danielpedroso/hebrew';
import { SephirahDesc } from '../utils';

interface Props {
  from: SephirahDesc;
  to: SephirahDesc;
  name: string;
  namePos: number; // number between 0 and 1
}

export const Path: React.FC<Props> = ({ name, from, to, namePos }) => {
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  const handleMouseClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setHovering(true);
  }, []);

  const handleMouseClickAway = useCallback(() => {
    setHovering(false);
  }, []);

  const diffX = to.x - from.x;
  const diffY = to.y - from.y;
  const x = from.x + (diffX * namePos);
  const y = from.y + (diffY * namePos);

  return (
    <g>
      <ClickAwayListener onClickAway={handleMouseClickAway}>
        <Tooltip
          open={hovering}
          title={
            <>
              <Typography variant="h6" display="flex" flexDirection="row" gap={1} alignItems="center" justifyContent="space-between">
                {name}
                <span>({hebrewLettersToNames[name]})</span>
              </Typography>
              <Typography variant="subtitle2" display="flex" flexDirection="row" gap={1} alignItems="center">
                {from.englishName} - {to.englishName}
              </Typography>
            </>
          }
          followCursor
        >
          <g
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleMouseClick}
          >
            <path d={`M ${from.x} ${from.y} L ${to.x} ${to.y}`} stroke="black" strokeWidth="30" />
            <path d={`M ${from.x} ${from.y} L ${to.x} ${to.y}`} stroke={hovering ? '#eaeaea' : 'white'} strokeWidth="26" />
            <text x={x} y={y + 5} fontSize="24" textAnchor="middle">
              {name}
            </text>
          </g>
        </Tooltip>
      </ClickAwayListener>
    </g>
   );
};
