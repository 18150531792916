import { atom } from 'jotai';

export interface Location {
  id: string;
  country: string;
  name: string;
  lat: number;
  lon: number;
  height: number;
  tz: string;
}

const initialState: Location[] = [{
  id: 'BNE',
  country: 'AU',
  name: 'Brisbane, QLD',
  lat: -27.470125,
  lon: 153.021072,
  height: 24,
  tz: 'Australia/Brisbane',
}];

const initialStr = JSON.stringify(initialState);
const STATE_KEY = 'ASTRUM_LOCATIONS';

const strStateAtom = atom(localStorage.getItem(STATE_KEY) ?? initialStr);

export const locationsAtom = atom(
  (get) => JSON.parse(get(strStateAtom)) as Location[],
  (get, set, newState: Location[]) => {
    const newVal = JSON.stringify(newState ?? initialState);
    set(strStateAtom, newVal);
    localStorage.setItem(STATE_KEY, newVal);
  }
);
