export const Peh: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_107_7)">
        <path
          d="M31.2656 26.0156C31.2656 23.5156 30.6953 21.6562 29.5547 20.4375C28.4141 19.2031 26.6172 18.5859 24.1641 18.5859H18.75V24.8672H24.4453V30.5391H12.3516V12.9141H24.2109C28.8047 12.9141 32.1953 13.9844 34.3828 16.125C36.5703 18.2656 37.6641 21.5938 37.6641 26.1094V28.0312C37.6641 37.3438 32.875 42 23.2969 42H12.6094V36.3281H23.1562C25.8594 36.3281 27.8828 35.6328 29.2266 34.2422C30.5859 32.8516 31.2656 30.7656 31.2656 27.9844V26.0156Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_7">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
