import { useTranslation } from 'react-i18next';
import { aspectSymbols } from "@danielpedroso/aspects";
import { Aspect } from "@danielpedroso/astrum-calc/dist/types";
import { objectSymbols } from "@danielpedroso/celestial-objects";
import { zodiacSignSymbols } from "@danielpedroso/zodiac-signs";
import { Box, Typography } from "@mui/material";

interface Props {
  aspect: Aspect;
}

export const AspectTooltip: React.FC<Props> = ({ aspect }) => {
  const { t } = useTranslation();

  const P1S = objectSymbols[aspect.object1.key];
  const P2S = objectSymbols[aspect.object2.key];
  const P1ZS = zodiacSignSymbols[aspect.object1.sign];
  const P2ZS = zodiacSignSymbols[aspect.object2.sign];
  const AS = aspectSymbols[aspect.aspectType];


  return (
    <Box sx={{ pointerEvents: 'none' }}>
      <Typography variant="h6" display="flex" flexDirection="row" gap={1} alignItems="center">
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <P1S stroke="currentColor" fill="currentColor" strokeWidth="1px" width="24px" height="24px" />
          <P1ZS stroke="currentColor" fill="currentColor" strokeWidth="1px" width="12px" height="12px" fontSize="12px" />
        </span>
        <AS stroke="currentColor" fill="currentColor" strokeWidth="1px" width="24px" height="24px" fontSize="24px" />
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <P2S stroke="currentColor" fill="currentColor" strokeWidth="1px" width="24px" height="24px" />
          <P2ZS stroke="currentColor" fill="currentColor" strokeWidth="1px" width="12px" height="12px" fontSize="12px" />
        </span>
      </Typography>
      <Typography variant="caption">
        {`${t(`celestialBodies.${aspect.object1.key}`)} - `}
        {`${t(`celestialBodies.${aspect.object2.key}`)}: `}
        {t(`aspects.${aspect.aspectType}`)}, {aspect.degree.toFixed(2)}º 
      </Typography>
    </Box>
  );
};