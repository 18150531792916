"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateMeanDescendingNode = exports.calculateMeanAscendingNode = exports.calculateLunarOrbit = exports.getMeanPerigee = exports.calcMoonPosition = void 0;
const constants_1 = require("../constants");
const gplan_1 = require("./gplan");
const aberration_1 = require("./aberration");
const precess_1 = require("./precess");
const nutation_1 = require("./nutation");
const lonlat_1 = require("./lonlat");
const math_1 = require("../math");
const calcMoonPosition = (body, earthBody, observer) => {
    body.ra = 0.0; /* Right Ascension */
    body.dec = 0.0; /* Declination */
    body = calculateLunarBody(body, earthBody, observer);
    return body;
};
exports.calcMoonPosition = calcMoonPosition;
const getShapeString = ({ illuminatedFraction = 0.00 } = {}) => {
    if (illuminatedFraction >= 0 && illuminatedFraction < 0.5)
        return "Crescent";
    if (illuminatedFraction >= 0.5 && illuminatedFraction < 1)
        return "Gibbous";
};
const getShapeDirectionString = ({ phaseDecimal = 0.00 } = {}) => {
    if (phaseDecimal >= 0 && phaseDecimal < 0.5)
        return "Waxing";
    if (phaseDecimal >= 0.5 && phaseDecimal < 1)
        return "Waning";
};
const calculateLunarBody = (body, earthBody, observer) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!earthBody.position || !earthBody.position.rect)
        throw new Error('Earth position not calculated');
    var i; // int
    var ra0, dec0; // double
    var x, y, z /*, lon0*/; // double
    let pp = [0, 0, 0];
    let qq = [0, 0, 0];
    let pe = [0, 0, 0, {}, {}];
    let re = [0, 0, 0];
    let moonpp = [0, 0, 0];
    let moonpol = [0, 0, 0];
    // double
    body.position = {
        polar: [0, 0, 0],
        rect: [0, 0, 0],
    };
    /* Geometric equatorial coordinates of the earth.  */
    for (i = 0; i < 3; i++) {
        re[i] = earthBody.position.rect[i];
    }
    /* Run the orbit calculation twice, at two different times,
      * in order to find the rate of change of R.A. and Dec.
      */
    /* Calculate for 0.001 day ago
      */
    calcll(observer.date.julian - 0.001, moonpp, moonpol, body, earthBody, observer); // TDT - 0.001
    body.ra = (_a = body.ra) !== null && _a !== void 0 ? _a : 0.0;
    body.dec = (_b = body.ra) !== null && _b !== void 0 ? _b : 0.0;
    ra0 = body.ra;
    dec0 = body.dec;
    // lon0 = moonpol[0];
    /* Calculate for present instant.
      */
    body.position.nutation = calcll(observer.date.julian, moonpp, moonpol, body, earthBody, observer).nutation;
    body.position.geometric = {
        longitude: constants_1.RADIANS_TO_DEG * body.position.polar[0],
        latitude: constants_1.RADIANS_TO_DEG * body.position.polar[1],
        distance: constants_1.RADIANS_TO_DEG * body.position.polar[2]
    };
    /**
     * The rates of change.  These are used by altaz () to
     * correct the time of rising, transit, and setting.
     */
    body.locals.dradt = body.ra - ra0;
    if (body.locals.dradt >= Math.PI)
        body.locals.dradt = body.locals.dradt - 2.0 * Math.PI;
    if (body.locals.dradt <= -Math.PI)
        body.locals.dradt = body.locals.dradt + 2.0 * Math.PI;
    body.locals.dradt = 1000.0 * body.locals.dradt;
    body.locals.ddecdt = 1000.0 * (body.dec - dec0);
    /* Rate of change in longitude, degrees per day
      * used for phase of the moon
      */
    // lon0 = 1000.0*RADIANS_TO_DEG*(moonpol[0] - lon0);
    /* Get apparent coordinates for the earth.  */
    z = re[0] * re[0] + re[1] * re[1] + re[2] * re[2];
    z = Math.sqrt(z);
    for (i = 0; i < 3; i++) {
        re[i] /= z;
    }
    /* aberration of light. */
    body.position.annualAberration = (0, aberration_1.calcAberration)(re, earthBody, observer, body);
    /* pe[0] -= STR * (20.496/(RTS*pe[2])); */
    re = (0, precess_1.calcPrecess)(re, observer.date.julian, -1);
    (0, nutation_1.calcNutation)(observer.date, re); // NOTE mutates re
    for (i = 0; i < 3; i++) {
        re[i] *= z;
    }
    pe = (0, lonlat_1.calcLonLat)(re, observer.date, pe, 0);
    /* Find sun-moon-earth angles */
    for (i = 0; i < 3; i++) {
        qq[i] = re[i] + moonpp[i];
    }
    body = (0, math_1.angles)(moonpp, qq, re, body);
    /* Display answers
      */
    if (!body.position)
        throw new Error('Typescript is stupid');
    body.position.apparentGeocentric = {
        longitude: moonpol[0],
        dLongitude: constants_1.RADIANS_TO_DEG * moonpol[0],
        latitude: moonpol[1],
        dLatitude: constants_1.RADIANS_TO_DEG * moonpol[1],
        distance: moonpol[2] / constants_1.RADIUS_EARTH_AU,
    };
    body.position.apparentLongitude = body.position.apparentGeocentric.dLongitude;
    const dmsLongitude = (0, math_1.dms)(body.position.apparentGeocentric.longitude);
    body.position.apparentLongitudeString =
        dmsLongitude.degree + '\u00B0' +
            dmsLongitude.minutes + '\'' +
            Math.floor(dmsLongitude.seconds) + '"';
    body.position.apparentLongitude30String =
        (dmsLongitude.degree % 30) + '\u00B0' +
            dmsLongitude.minutes + '\'' +
            Math.floor(dmsLongitude.seconds) + '"';
    body.position.geocentricDistance = moonpol[2] / constants_1.RADIUS_EARTH_AU;
    x = constants_1.RADIUS_EARTH_AU / moonpol[2];
    body.position.dHorizontalParallax = Math.asin(x);
    body.position.horizontalParallax = (0, math_1.dms)(Math.asin(x));
    x = 0.272453 * x + 0.0799 / constants_1.RADIANS_TO_ARC_SEC; /* AA page L6 */
    body.position.dSemidiameter = x;
    body.position.Semidiameter = (0, math_1.dms)(x);
    x = constants_1.RADIANS_TO_DEG * Math.acos(-body.locals.ep);
    /*	x = 180.0 - RTD * arcdot (re, pp); */
    body.position.sunElongation = x;
    x = 0.5 * (1.0 + body.locals.pq);
    body.position.illuminatedFraction = x;
    /* Find phase of the Moon by comparing Moon's longitude
      * with Earth's longitude.
      *
      * The number of days before or past indicated phase is
      * estimated by assuming the true longitudes change linearly
      * with time.  These rates are estimated for the date, but
      * do not stay body.  The error can exceed 0.15 day in 4 days.
      */
    x = moonpol[0] - pe[0];
    x = (x % constants_1.TPI) * constants_1.RADIANS_TO_DEG; /* difference in longitude */
    i = Math.floor(x / 90); /* number of quarters */
    // phaseDecimal - 0.0 - 1.0 float representation of phase.
    body.position.phaseDecimal = ((x / 360) + 0.5) % 1; // 0-0.24 = New, 0.25 - 0.49 = First Quarter, 0.5 - 0.74 = Full, 0.75 - 0.99 = Last Quarter
    x = (x - i * 90.0); /* phase angle mod 90 degrees */
    /* days per degree of phase angle */
    z = moonpol[2] / (12.3685 * 0.00257357);
    if (x > 45.0) {
        y = -(x - 90.0) * z;
        body.position.phaseDaysBefore = y;
    }
    else {
        y = x * z;
        body.position.phaseDaysPast = y;
    }
    body.position.phaseDaysDistance = y;
    i = (i + 2) % 4;
    body.position.phaseQuarter = i;
    body.position.shapeString = getShapeString({ illuminatedFraction: body.position.illuminatedFraction });
    body.position.shapeDirectionString = getShapeDirectionString({ phaseDecimal: body.position.phaseDecimal });
    body.position.apparent = {
        dRA: (_c = body.ra) !== null && _c !== void 0 ? _c : 0.0,
        dDec: (_d = body.dec) !== null && _d !== void 0 ? _d : 0.0,
        ra: (0, math_1.hms)((_e = body.ra) !== null && _e !== void 0 ? _e : 0.0),
        dec: (0, math_1.dms)((_f = body.dec) !== null && _f !== void 0 ? _f : 0.0)
    };
    /* Compute and display topocentric position (altaz.c)
      */
    pp[0] = (_g = body.ra) !== null && _g !== void 0 ? _g : 0.0;
    pp[1] = (_h = body.dec) !== null && _h !== void 0 ? _h : 0.0;
    pp[2] = moonpol[2];
    return body;
};
const getMeanAscendingNode = (julianDate) => {
    // * t float = julianDate
    // returns => longitude of node in decimal degrees
    //////////
    // A.K.A - North Node / Right Ascension Ascending Node
    // Source: Astronomical Algorithims by Jean Meeus
    // pg 313 example 45.7
    const t = (0, math_1.timeInJulianCenturies)(julianDate);
    const result = 125.0445550 -
        1934.1361849 * t +
        0.0020762 * Math.pow(t, 2) +
        Math.pow(t, 3) / 467410 -
        Math.pow(t, 4) / 60616000;
    return (0, math_1.mod)(result, 360);
};
const getMeanPerigee = (julianDate) => {
    // * t float = julianDate
    // returns => longitude of perigee in decimal degrees
    //////////
    // Source: Astronomical Algorithims by Jean Meeus
    // pg 313 example 45.7
    const t = (0, math_1.timeInJulianCenturies)(julianDate);
    const result = 83.3532430 +
        4069.0137111 * t -
        0.0103238 * Math.pow(t, 2) -
        Math.pow(t, 3) / 80053 +
        Math.pow(t, 4) / 18999000;
    return (0, math_1.mod)(result, 360);
};
exports.getMeanPerigee = getMeanPerigee;
const calcll = (julianDate, rect, pol, body, earthBody, observer, result) => {
    var cosB, sinB, cosL, sinL, y, z; // double
    var qq = [0, 0, 0], pp = [0, 0, 0]; // double
    var i; // int
    result = result || { nutation: {} };
    /* Compute obliquity of the ecliptic, coseps, and sineps.  */
    const epsilonObject = (0, math_1.calcEpsilon)(julianDate);
    /* Get geometric coordinates of the Moon.  */
    const lp_equinox = (0, gplan_1.get_lp_equinox)(julianDate);
    rect = (0, gplan_1.gPlanMoon)(julianDate, rect, pol, lp_equinox);
    /* Post the geometric ecliptic longitude and latitude, in radians,
      * and the radius in au.
      */
    if (!body.position)
        throw new Error('Position not calculated');
    body.position.polar[0] = pol[0];
    body.position.polar[1] = pol[1];
    body.position.polar[2] = pol[2];
    /* Light time correction to longitude,
      * about 0.7".
      */
    pol[0] -= 0.0118 * constants_1.DEG_TO_RADIANS * constants_1.RADIUS_EARTH_AU / pol[2];
    /* convert to equatorial system of date */
    cosB = Math.cos(pol[1]);
    sinB = Math.sin(pol[1]);
    cosL = Math.cos(pol[0]);
    sinL = Math.sin(pol[0]);
    rect[0] = cosB * cosL;
    rect[1] = epsilonObject.coseps * cosB * sinL - epsilonObject.sineps * sinB;
    rect[2] = epsilonObject.sineps * cosB * sinL + epsilonObject.coseps * sinB;
    /* Rotate to J2000. */
    rect = (0, precess_1.calcPrecess)(rect, observer.date.julian, 1); // TDT
    if (!earthBody.position)
        throw new Error('Earth\'s position not calculated');
    /* Find Euclidean vectors and angles between earth, object, and the sun
      */
    for (i = 0; i < 3; i++) {
        pp[i] = rect[i] * pol[2];
        qq[i] = earthBody.position.rect[i] + pp[i];
    }
    body = (0, math_1.angles)(pp, qq, earthBody.position.rect, body);
    /* Make rect a unit vector.  */
    /* for (i = 0; i < 3; i++) */
    /*  rect[i] /= EO; */
    /* Correct position for light deflection.
      (Ignore.)  */
    /* relativity( rect, qq, rearth ); */
    /* Aberration of light.
      The Astronomical Almanac (Section D, Daily Polynomial Coefficients)
      seems to omit this, even though the reference ephemeris is inertial.  */
    /* annuab (rect); */
    /* Precess to date.  */
    rect = (0, precess_1.calcPrecess)(rect, observer.date.julian, -1); // TDT
    /* Correct for nutation at date TDT.
      */
    const nutationObject = (0, nutation_1.getObject)(observer.date);
    result.nutation = (0, nutation_1.calcNutation)(observer.date, rect); // TDT
    /* Apparent geocentric right ascension and declination.  */
    body.ra = (0, math_1.zatan2)(rect[0], rect[1]);
    body.dec = Math.asin(rect[2]);
    /* For apparent ecliptic coordinates, rotate from the true
      equator into the ecliptic of date.  */
    cosL = Math.cos(epsilonObject.eps + nutationObject.nuto);
    sinL = Math.sin(epsilonObject.eps + nutationObject.nuto);
    y = cosL * rect[1] + sinL * rect[2];
    z = -sinL * rect[1] + cosL * rect[2];
    pol[0] = (0, math_1.zatan2)(rect[0], y);
    pol[1] = Math.asin(z);
    /* Restore earth-moon distance.  */
    for (i = 0; i < 3; i++) {
        rect[i] *= body.locals.EO;
    }
    return result;
};
const calculateLunarOrbit = (julianDate) => {
    const meanAscendingNode = (0, math_1.attachApparentLongitudes)({}, getMeanAscendingNode(julianDate));
    const meanDescendingNode = (0, math_1.attachApparentLongitudes)({}, (0, math_1.mod)((meanAscendingNode === null || meanAscendingNode === void 0 ? void 0 : meanAscendingNode.apparentLongitude) - 180, 360));
    const meanPerigee = (0, math_1.attachApparentLongitudes)({}, (0, exports.getMeanPerigee)(julianDate));
    const meanApogee = (0, math_1.attachApparentLongitudes)({}, (0, math_1.mod)((meanPerigee === null || meanPerigee === void 0 ? void 0 : meanPerigee.apparentLongitude) - 180, 360));
    return {
        meanAscendingNode,
        meanDescendingNode,
        meanPerigee,
        meanApogee,
    };
};
exports.calculateLunarOrbit = calculateLunarOrbit;
const calculateMeanAscendingNode = (julianDate) => {
    const apparentLongitude = getMeanAscendingNode(julianDate);
    const apparentLongitudeString = (0, math_1.decimalDegreesToDMSString)(apparentLongitude);
    const apparentLongitude30String = (0, math_1.decimalDegreesToDMSString)((0, math_1.mod)(apparentLongitude, 30));
    return { apparentLongitude, apparentLongitudeString, apparentLongitude30String };
};
exports.calculateMeanAscendingNode = calculateMeanAscendingNode;
const calculateMeanDescendingNode = (ascendingNode) => {
    const apparentLongitude = (0, math_1.mod)(ascendingNode - 180, 360);
    const apparentLongitudeString = (0, math_1.decimalDegreesToDMSString)(apparentLongitude);
    const apparentLongitude30String = (0, math_1.decimalDegreesToDMSString)((0, math_1.mod)(apparentLongitude, 30));
    return { apparentLongitude, apparentLongitudeString, apparentLongitude30String };
};
exports.calculateMeanDescendingNode = calculateMeanDescendingNode;
