import { useTranslation } from 'react-i18next';
import { objectSymbols } from "@danielpedroso/celestial-objects";
import { IconButton, Typography } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { PlanetPos } from "@danielpedroso/astrum-calc/dist/types";
import { zodiacSignSymbols } from '@danielpedroso/zodiac-signs';
import { degToDMS, getDecan, getNameOfGod, getTarotCard, keys } from "../utils";

interface Props {
  objKey: string;
  collapsed: boolean;
  object: Omit<PlanetPos, 'key'>;
  colour: string;
  highPrecision?: boolean;
  onCollapseClick: (arg0: React.MouseEvent) => void;
}

export const ObjectTooltip: React.FC<Props> = ({ objKey, collapsed, object, colour = 'blue', highPrecision = false, onCollapseClick }) => {
  const { t } = useTranslation();

  const Symbol = objectSymbols[objKey];
  if (!Symbol) throw new Error(`Unknown object: ${objKey}`);

  const decan = getDecan(object.absolute);
  const DecanSymbol = objectSymbols[decan];
  if (!DecanSymbol) throw new Error(`Unknown object: ${decan}`);

  const ZodiacSymbol = zodiacSignSymbols[object.sign];
  if (!ZodiacSymbol) throw new Error(`Unknown zodiac sign: ${object.sign}`);

  const tarotCard = getTarotCard(object.absolute);
  const musicalKey = keys[Math.trunc(object.absolute / 30) % 12];
  const deg = degToDMS(object.pos, highPrecision);
  const nameOfGod = getNameOfGod(object.absolute);

  return (
    <>
      <Typography variant="h6" display="flex" flexDirection="row" gap={1} alignItems="center" justifyContent="space-between">
        <span>
          <Symbol stroke="currentColor" fill="currentColor" strokeWidth="1px" width="24px" height="24px" />
          {!collapsed && (
            <>
              {` ${t(`celestialBodies.${objKey}`)}`}
              {object.isRetrograde ? ` ${t('celestialBodies.retrograde')}` : ''}
            </>
          )}
        </span>
        <IconButton sx={{ color: (theme) => theme.palette.common.white }} onClick={onCollapseClick}>
          {collapsed ? <ArrowDropDown /> : <ArrowDropUp />}
        </IconButton>
      </Typography>
      {!collapsed && (
        <>
          <Typography variant="subtitle2" display="flex" flexDirection="row" gap={1} alignItems="center">
            <span>{deg}</span>
            <ZodiacSymbol stroke="currentColor" fill="currentColor" strokeWidth="1px" width="12px" height="12px" />
            <span>({t(`signs.${object.sign}`)})</span>
            <span>{object.intermediary ? t('signs.intermediary') : ''}</span>
            <span>{t('houses.house')} {object.house}</span>
          </Typography>
          <Typography variant="body2" display="flex" flexDirection="row" gap={0.5} alignItems="center">
            {`${t('decans.capital')}:`}
            <DecanSymbol stroke="currentColor" fill="currentColor" strokeWidth="1px" width="12px" height="12px" />
            <span>({t(`celestialBodies.${decan}`)})</span>
          </Typography>
          <Typography variant="body2">{t('other.musicalKey')}: {musicalKey}</Typography>
          <Typography variant="body2">Tarot: {tarotCard} - {t(`tarot.${tarotCard}`)}</Typography>
          <Typography variant="body2">{nameOfGod.name} - {nameOfGod.englishName} ({nameOfGod.number})</Typography>
          <Typography variant="body2">{t(`biblical.verses.${nameOfGod.bible.book}.${nameOfGod.bible.number}.${nameOfGod.bible.verse}`)}</Typography>
          <Typography variant="body2">({t(`biblical.${nameOfGod.bible.book}`)} {nameOfGod.bible.number}:{nameOfGod.bible.verse})</Typography>
          <Typography
            variant="h5"
            fontFamily='"Times New Roman", Times, serif' 
          >
            {nameOfGod.bible.text}
          </Typography>
        </>
      )}
    </>
  );
};