"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlanetaryHours = exports.weeklyOrder = exports.chaldeanOrder = void 0;
const sun_times_1 = require("./sun-times");
__exportStar(require("./sun-times"), exports);
exports.chaldeanOrder = [
    'saturn',
    'jupiter',
    'mars',
    'sun',
    'venus',
    'mercury',
    'moon'
];
exports.weeklyOrder = {
    0: 3,
    1: 6,
    2: 2,
    3: 5,
    4: 1,
    5: 4,
    6: 0,
};
const getPlanetaryHours = (curr, next) => {
    const weekDay = curr.date.day();
    const ruler = exports.weeklyOrder[weekDay];
    const [hStart, mStart] = curr.sun.rise.split(':').map(s => Number.parseInt(s));
    const [hEnd, mEnd] = curr.sun.set.split(':').map(s => Number.parseInt(s));
    const [hNextSunrise, mNextSunrise] = next.sun.rise.split(':').map(s => Number.parseInt(s));
    const minStart = hStart * 60 + mStart;
    const minEnd = hEnd * 60 + mEnd;
    const nextSunrise = hNextSunrise * 60 + mNextSunrise;
    const perHr = (minEnd - minStart) / 12;
    const dayHrs = [];
    for (let i = 0; i < 12; i++) {
        dayHrs.push({
            start: (0, sun_times_1.mToHM)(minStart + (perHr * i)),
            end: (0, sun_times_1.mToHM)(minStart + (perHr * (i + 1))),
            planet: exports.chaldeanOrder[(ruler + i) % exports.chaldeanOrder.length],
        });
    }
    const nightStart = minEnd;
    const nightEnd = nextSunrise;
    const nightLength = 1440 - nightStart + nightEnd;
    const nightPerHr = nightLength / 12;
    const nightHrs = [];
    for (let i = 0; i < 12; i++) {
        nightHrs.push({
            start: (0, sun_times_1.mToHM)((nightStart + (nightPerHr * i)) % 1440),
            end: (0, sun_times_1.mToHM)((nightStart + (nightPerHr * (i + 1))) % 1440),
            planet: exports.chaldeanOrder[(ruler + 12 + i) % exports.chaldeanOrder.length],
        });
    }
    return { ruler: exports.chaldeanOrder[ruler], day: dayHrs, night: nightHrs };
};
exports.getPlanetaryHours = getPlanetaryHours;
