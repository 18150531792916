import React, { useCallback, useState } from 'react';
import { objectSymbols } from "@danielpedroso/celestial-objects";
import { aspectSymbols } from "@danielpedroso/aspects";
import { ASPECT_COLOURS } from '@danielpedroso/astrum-calc/dist/constants';
import { Aspect } from '@danielpedroso/astrum-calc/dist/types';
import { SquareRatio, Container, Svg } from './styled';
import { Tooltip } from '@mui/material';
import { AspectTooltip } from './aspect/tooltip';
import { labels as houseLabels } from './house/labels';

interface Props {
  aspects: Aspect[];
}

interface AspectProps extends React.ComponentProps<typeof Svg> {
  aspect?: Aspect;
  i: number;
  j: number;
}

const RenderAspect: React.FC<AspectProps> = ({ aspect, i, j, ...props }) => {
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  if (!aspect) {
    return (
      <g key={`${i}_${j}`} transform={`translate(${CELL_SIZE * (j + 1) + 10}, ${CELL_SIZE * (i) + 10})`}>
        <rect width={CELL_SIZE} height={CELL_SIZE} fill="none" stroke="rgba(224, 224, 224, 1)" />
      </g>
    )
  }

  const As = aspectSymbols[aspect.aspectType];
  const colour = ASPECT_COLOURS[aspect.aspectType];

  if (!As) return <text>{aspect.aspectType}</text>;

  return (
    <Tooltip
      open={hovering}
      title={
        <AspectTooltip aspect={aspect} />
      }
      followCursor={false}
    >
      <g
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        pointerEvents="all"
        transform={`translate(${CELL_SIZE * (j + 1) + 10}, ${CELL_SIZE * (i) + 10})`}
      >
        <rect width={CELL_SIZE} height={CELL_SIZE} fill="none" stroke="rgba(224, 224, 224, 1)" />
        {aspect && (
          <As {...props} stroke={colour} color={colour} />
        )}
      </g>
    </Tooltip>
  );
};

interface ObjectProps extends React.ComponentProps<typeof Svg> {
  obj: string;
}

const RenderObject: React.FC<ObjectProps> = ({ obj, ...props }) => {
  let Object = objectSymbols[obj];
  if (!Object) {
    Object = houseLabels[obj];
  }
  if (!Object) {
    return <text>{obj}</text>;
  }

  return <Object stroke="black" color="black" {...props} />;
};

const list = [
  'moon',
  'mercury',
  'venus',
  'sun',
  'mars',
  'jupiter',
  'saturn',
  'uranus',
  'neptune',
  'pluto',
  'meanAscendingNode',
  'meanDescendingNode',
  '1',
];

const SVG_WIDTH = 800; // You can adjust this based on your requirements
const CELL_SIZE = SVG_WIDTH / (list.length + 2);

export const AspectsTable: React.FC<Props> = ({ aspects }) => {
  if (!aspects?.length) return null;

  const findAspect = (obj1: string, obj2: string) => {
    const aspect = aspects.find((asp) => {
      if (asp.object1.key === obj2 && asp.object2.key === obj1) return true;
      return false;
    });
    return aspect;
  };

  const renderAspect = (obj1: string, obj2: string, i: number, j: number) => {
    const aspect = findAspect(obj1, obj2);
    return <RenderAspect key={`${obj1}-${obj2}-${i}-${j}`} aspect={aspect} i={i} j={j} x={CELL_SIZE / 2 - 9} y={CELL_SIZE / 2 - 9} width={18} height={18} />
  };

  return (
    <SquareRatio>
      <Container>
        <Svg
          id="aspects-table"
          viewBox="0 0 820 820"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform={`translate(${CELL_SIZE + 10}, ${10})`}>
            <rect width={CELL_SIZE} height={CELL_SIZE} fill="none" stroke="rgba(224, 224, 224, 1)" />
            <RenderObject obj="moon" x={CELL_SIZE / 2 - 9} y={CELL_SIZE / 2 - 9} width={18} height={18} />
          </g>
          {list.map((obj1, i) => (
            <React.Fragment key={`${obj1}-${i}`}>
              <g transform={`translate(10, ${CELL_SIZE * (i + 1) + 10})`}>
                <rect width={CELL_SIZE} height={CELL_SIZE} fill="none" stroke="rgba(224, 224, 224, 1)" />
                <RenderObject obj={obj1} x={CELL_SIZE / 2 - 9} y={CELL_SIZE / 2 - 9} width={18} height={18} />
              </g>
              {list.slice(0, i + 1).map((obj2, j) => renderAspect(obj1, obj2, i + 1, j))}
              {i + 1 < list.length && (
                <g transform={`translate(${CELL_SIZE * (i + 2) + 10}, ${CELL_SIZE * (i + 1) + 10})`}>
                  <rect width={CELL_SIZE} height={CELL_SIZE} fill="none" stroke="rgba(224, 224, 224, 1)" />
                  <RenderObject obj={list[i + 1]} x={CELL_SIZE / 2 - 9} y={CELL_SIZE / 2 - 9} width={18} height={18} />
                </g>
              )}
            </React.Fragment>
          ))}
        </Svg>
      </Container>
    </SquareRatio>
  );
}
