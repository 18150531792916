import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { calculateSynastry } from '@danielpedroso/astrum-calc';
import { SynastryChart } from '@danielpedroso/chart';
import { DateTimeInput } from '../../core/datetime';
import { Grid } from '@mui/material';
import { SelectLocation } from '../../core/location/select';
import { chart1LocationAtom, chart2LocationAtom, chartAtom, chartItemSelectionAtom, chartStateAtom, planetaryInfoAtom } from './state';
import { useTranslation } from 'react-i18next';
import { locationsAtom, Location as LocationType } from '../../core/location/state';
import { switchTimezones } from '../../core/datetime/utils';
import { AspectsTable } from './aspects-table';

///* Dan
//const bd = moment('1990-06-08T18:00:00.000-03:00');
//const lat = -15.2735;
//const lon = -49.64455;
//*/

// Mai
// const bd = moment('1990-07-21 16:55:00.000-03:00');
// const lat = -27.216093;
// const lon = -49.643502;
// const maiChart = buildChart({ date: bd, lat, lon });

export const TransitsPage: React.FC = () => {
  const { t } = useTranslation();
  const [chartState, setChartState] = useAtom(chartStateAtom);
  const [chart, setChart] = useAtom(chartAtom);
  const [locations] = useAtom(locationsAtom);
  const [location1] = useAtom(chart1LocationAtom);
  const [location2] = useAtom(chart2LocationAtom);
  const [planetary] = useAtom(planetaryInfoAtom);
  const [itemSelection, setItemSelection] = useAtom(chartItemSelectionAtom);

  const date1 = useMemo(() => {
    return moment.tz(chartState?.chart1.isoDate, location1.tz);
  }, [chartState?.chart1.isoDate, location1.tz]);

  const date2 = useMemo(() => {
    return moment.tz(chartState?.chart2.isoDate, location2.tz);
  }, [chartState?.chart2.isoDate, location2.tz]);

  const handleChangeDate = useCallback((which: 'chart1' | 'chart2') => (e: string) => {
    if (!e) return;

    setChartState({
      ...chartState,
      [which]: {
        ...chartState[which],
        isoDate: e,
      },
    });
  }, [chartState, setChartState]);

  const handleLocationChanged = useCallback((which: 'chart1' | 'chart2') => (locationId: string | undefined, loc?: LocationType) => {
    if (!locationId || !loc) return;

    setChartState((s) => {
      const state = s[which];
      const oldLocation = locations.find(l => l.id === state.locationId);
      if (!oldLocation) return s;

      const updated = switchTimezones(state.isoDate, oldLocation.tz, loc.tz);

      return {
        ...s,
        [which]: {
          ...state,
          locationId,
          isoDate: updated,
        },
      };
    });
  }, [setChartState, locations]);

  useEffect(() => {
    if (!date1 || !date1.isValid() || !date2 || !date2.isValid()) return;

    calculateSynastry({
      date: date1.toISOString(),
      lat: location1.lat,
      lon: location1.lon,
    }, {
      date: date2.toISOString(),
      lat: location2.lat,
      lon: location2.lon,
    }).then((chart) => {
      setChart(chart);
    });
  }, [date1, date2, location1.lat, location1.lon, location2.lat, location2.lon, setChart]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Grid container>
            <Grid item md={6} sm={12} sx={{ pr: { md: 1 } }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('transitsPage.birth')}</Typography>
                <Box display="flex" flexDirection="row">
                  <DateTimeInput tz={location1.tz} value={chartState.chart1.isoDate} onChange={handleChangeDate('chart1')} />
                </Box>
                <Box display="flex" flexDirection="row">
                  <SelectLocation label={t('transitsPage.location')} value={location1.id} onChange={handleLocationChanged('chart1')} />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} sx={{ pl: { md: 1 } }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('transitsPage.transit')}</Typography>
                <Box display="flex" flexDirection="row">
                  <DateTimeInput tz={location2.tz} value={chartState.chart2.isoDate} onChange={handleChangeDate('chart2')} />
                </Box>
                <Box display="flex" flexDirection="row">
                  <SelectLocation label={t('transitsPage.location')} value={location2.id} onChange={handleLocationChanged('chart2')} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }}} maxWidth="100vw">
          <Grid item md={8} lg={9} pt={2} display="flex" flexDirection="column" maxWidth="100vw">
            <SynastryChart
              synastry={chart}
              itemSelection={itemSelection}
              setItemSelection={setItemSelection}
            />
            <AspectsTable chart={chart} itemSelection={itemSelection} />
          </Grid>
          <Grid item md={4} lg={3} maxWidth="100vw">
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom>{t('chartPage.planetaryMagick.moon')}</Typography>
              <Box pb={2}>
                <Typography>{t('chartPage.planetaryMagick.quarter')}: {t(`chartPage.planetaryMagick.quarters.${chart?.chart2.planets.moon.phase?.quarter}`)}</Typography>
              </Box>
              <Typography variant="h5" gutterBottom>{t('chartPage.planetaryMagick.title')}</Typography>
              <Box pb={1}>
                <Typography>{t('chartPage.planetaryMagick.sunrise')}: {planetary.curr.sun.rise}</Typography>
                <Typography>{t('chartPage.planetaryMagick.noon')}: {planetary?.curr.sun.noon}</Typography>
                <Typography>{t('chartPage.planetaryMagick.sunset')}: {planetary.curr.sun.set}</Typography>
                <Typography>{t('chartPage.planetaryMagick.nightEnd')}: {planetary.next.sun.rise}</Typography>
                <Typography>{t('chartPage.planetaryMagick.ruler')}: {t(`celestialBodies.${planetary.planetaryHours.ruler}`)}</Typography>
              </Box>
              <Box pb={1}>
                <Typography variant="h6" gutterBottom>{t('chartPage.planetaryMagick.day')}</Typography>
                {planetary.planetaryHours.day.map((h, i) => (
                  <Typography key={i}>{t('chartPage.planetaryMagick.timeslot', { from: h.start, to: h.end })} - {t(`celestialBodies.${h.planet}`)}</Typography>
                ))}
              </Box>
              <Box pb={1}>
                <Typography variant="h6" gutterBottom>{t('chartPage.planetaryMagick.night')}</Typography>
                {planetary.planetaryHours.night.map((h, i) => (
                  <Typography key={`n${i}`}>{t('chartPage.planetaryMagick.timeslot', { from: h.start, to: h.end })} - {t(`celestialBodies.${h.planet}`)}</Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
