import anime from 'animejs';
import { useEffect, useState } from 'react';

export const PentagramAnimation: React.FC = () => {
  const [message, setMessage] = useState('');
  const [timeline, setTimeline] = useState<anime.AnimeTimelineInstance | undefined>(undefined);

  useEffect(() => {
    const targets = '#Pentagram path';
    const t1 = anime.timeline({
      easing: 'easeInOutSine',
      duration: 1500,
      strokeDashoffset: [anime.setDashoffset, 0],
      autoplay: false,
      loop: false,
    });
    t1.add({
      targets: '#Pentagram1',
      strokeDashoffset: [anime.setDashoffset, 0],
      begin: () => setMessage('Inhale'),
    });
    t1.add({
      targets: '#Pentagram2',
      strokeDashoffset: [anime.setDashoffset, 0],
      begin: () => setMessage('Exhale'),
    });
    t1.add({
      targets: '#Pentagram3',
      strokeDashoffset: [anime.setDashoffset, 0],
      begin: () => setMessage('Inhale'),
    });
    t1.add({
      targets: '#Pentagram4',
      strokeDashoffset: [anime.setDashoffset, 0],
      begin: () => setMessage('Hold'),
    });
    t1.add({
      targets: '#Pentagram5',
      strokeDashoffset: [anime.setDashoffset, 0],
      begin: () => setMessage('Exhale'),
    });
    t1.add({
      targets: '#Sigil',
      strokeDashoffset: [anime.setDashoffset, 0],
      begin: () => setMessage('Sigil'),
    });

    setTimeline(t1);
    t1.play();
    return () => {
      anime.remove(targets);
    }
  }, []);

  return (
    <>
      <svg id="Pentagram" width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="300" height="300" fill="white"/>
        <path id="Pentagram1" d="M91 203L150 22" stroke="black" strokeWidth={3} />
        <path id="Pentagram2" d="M150 22L209 203" stroke="black" strokeWidth={3} />
        <path id="Pentagram3" d="M209 203L55 91" stroke="black" strokeWidth={3} />
        <path id="Pentagram4" d="M55 91H245" stroke="black" strokeWidth={3} />
        <path id="Pentagram5" d="M245 91L91 203" stroke="black" strokeWidth={3} />
        <path id="Sigil" d="M148.5 136.5V107.5L163.5 118H135L158.5 127" stroke="black" strokeWidth={3} />
        <text fontSize={48} x="50%" y={261.1} stroke="black" fill="black" dominantBaseline="midle" textAnchor="middle">{message}</text>
      </svg>
      <button onClick={() => timeline?.play()}>Play</button>
    </>
  );
}
