import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SigilOnly } from '@danielpedroso/chart/dist/sigil-only';
import { Grid, MenuItem, TextField } from '@mui/material';
import { chartStateAtom, hebrewNameAtom } from './state';
import { useTranslation } from 'react-i18next';
import { hebrewOnly } from '@danielpedroso/hebrew';

export const SigilPage: React.FC = () => {
  const { t } = useTranslation();
  const [chartState, setChartState] = useAtom(chartStateAtom);
  const [hebrewName] = useAtom(hebrewNameAtom);
  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);
  const [exportedImage, setExportedImage] = useState<string | null>(null);

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChartState((s) => ({
      ...s,
      name: hebrewOnly(e?.target?.value ?? ''),
    }));
  }, [setChartState]);

  const handleChangeMode = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChartState((s) => ({
      ...s,
      mode: e?.target?.value === 'edit' ? 'edit' : 'view',
    }));
  }, [setChartState]);

  const handleChangeDoubleLettersEnd = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChartState((s) => ({
      ...s,
      doubleLettersEnd: e?.target?.value === 'kaf' ? 'kaf' : 'tav',
    }));
  }, [setChartState]);

  const handleExportButtonClick = useCallback(async () => {
    debugger;
    if (!svgRef) return;
    const svgText = svgRef.outerHTML;
    const blob = new Blob([svgText], {
      type: 'image/svg+xml;charset=utf-8',
    });

    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const png = await new Promise<string>((resolve) => {
      img.onload = () => {
        ctx?.drawImage(img, 0, 0);
        (window.URL || window.webkitURL).revokeObjectURL(url);
        resolve(canvas.toDataURL());
      }
      img.src = url;
    });
    setExportedImage(png);
  }, [svgRef]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }}} maxWidth="100vw">
          <Grid item md={8} lg={9} pt={2} display="flex" flexDirection="column" maxWidth="100vw">
            <SigilOnly name={hebrewName} mode={chartState.mode} svgRef={setSvgRef} doubleLettersEndInKaf={chartState.doubleLettersEnd === 'kaf'} />
          </Grid>
          <Grid item md={4} lg={3} maxWidth="100vw">
            <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('chartPage.location.name')}</Typography>
                <TextField value={chartState.name} onChange={handleChangeName} />
               <Typography variant="h5">{t('sigilPage.doubleLettersEnd.title')}</Typography>
                <TextField value={chartState.doubleLettersEnd} onChange={handleChangeDoubleLettersEnd} select>
                  <MenuItem key="kaf" value="kaf">{t('sigilPage.doubleLettersEnd.kaf')}</MenuItem>
                  <MenuItem key="tav" value="tav">{t('sigilPage.doubleLettersEnd.tav')}</MenuItem>
                </TextField>
                <Typography variant="h5">{t('sigilPage.mode.title')}</Typography>
                <TextField value={chartState.mode} onChange={handleChangeMode} select>
                  <MenuItem key="view" value="view">{t('sigilPage.mode.view')}</MenuItem>
                  <MenuItem key="edit" value="edit">{t('sigilPage.mode.edit')}</MenuItem>
                </TextField>
                <Button disabled={chartState.mode === 'edit'} onClick={handleExportButtonClick}>{t('sigilPage.export')}</Button>
              </Box>
              <Dialog open={!!exportedImage} onClose={() => setExportedImage(null)}>
                <DialogTitle>
                  <Typography variant="h5">{t('sigilPage.exportedDialog.title')}</Typography>
                  <Typography variant="subtitle1">{t('sigilPage.exportedDialog.subtitle')}</Typography>
                </DialogTitle>
                <DialogContent>
                  {exportedImage && <img alt="Sigil" src={exportedImage} style={{ maxWidth: '100%' }} />}
                </DialogContent>
              </Dialog>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
