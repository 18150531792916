import { useAtom } from 'jotai';
import { chartItemSelectionAtom, filteredAspectsAtom } from '../state';
import { Button, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { objectSymbols } from '@danielpedroso/celestial-objects';
import { aspectSymbols } from '@danielpedroso/aspects';
import { Toolbar } from './index';
import { useTranslation } from 'react-i18next';

export const AspectsToolbar: React.FC = () => {
  const { t } = useTranslation();
  const [aspects] = useAtom(filteredAspectsAtom);
  const [, setItemSelection] = useAtom(chartItemSelectionAtom);

  const selectItem = useCallback((item: number) => {
    setItemSelection({
      type: 'aspect',
      item: item,
    })
  }, [setItemSelection]);

  const handleTouch = useCallback((item: number) => (e: React.TouchEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    selectItem(item);
  }, [selectItem]);

  const handleClick = useCallback((item: number): React.MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      selectItem(item);
    },
    [selectItem]
  );

  return (
    <Toolbar title={t('chartPage.aspects')} topBorder>
      {!aspects?.length && (
        <Typography variant="subtitle1">{t('chartPage.noAspects')}</Typography>
      )}
      {aspects?.map((aspect) => {
        const S1 = objectSymbols[aspect.object1.key];
        const S2 = objectSymbols[aspect.object2.key];
        const A = aspectSymbols[aspect.aspectType];

        return (
          <Button
            key={aspect.ogIndex}
            size="small"
            onClick={handleClick(aspect.ogIndex)}
            onTouchStart={handleTouch(aspect.ogIndex)}
            onTouchEnd={(e) => { e.stopPropagation(); e.preventDefault() }}
          >
            <S1 stroke="black" color="black" width={18} height={18} />
            <A stroke="black" color="black" width={12} height={12} />
            <S2 stroke="black" color="black" width={18} height={18} />
          </Button>
        )
      })}
    </Toolbar>
  );
}