"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcLonLat = void 0;
const math_1 = require("../math");
const precess_1 = require("./precess");
const calcLonLat = (pp, date, polar, ofdate, result) => {
    // NOTE mutates polar
    // returns => polar as result
    let s = [0, 0, 0];
    let x;
    let y;
    let z;
    let yy;
    let zz;
    let r = 0.0;
    let i; // int
    result = result || [0, 0, 0, { degree: 0, minutes: 0, seconds: 0 }, { degree: 0, minutes: 0, seconds: 0 }];
    /* Make local copy of position vector
     * and calculate radius.
     */
    for (i = 0; i < 3; i++) {
        x = pp[i];
        s[i] = x;
        r += x * x;
    }
    r = Math.sqrt(r);
    /* Precess to equinox of date J
     */
    if (ofdate) {
        s = (0, precess_1.calcPrecess)(s, date.julian, -1);
    }
    /* Convert from equatorial to ecliptic coordinates
     */
    let epsilonObject = (0, math_1.calcEpsilon)(date.julian);
    yy = s[1];
    zz = s[2];
    x = s[0];
    y = epsilonObject.coseps * yy + epsilonObject.sineps * zz;
    z = -epsilonObject.sineps * yy + epsilonObject.coseps * zz;
    yy = (0, math_1.zatan2)(x, y);
    zz = Math.asin(z / r);
    // longitude and latitude in decimal
    polar[0] = yy;
    polar[1] = zz;
    polar[2] = r;
    // longitude and latitude in h,m,s
    const lon = (0, math_1.dms)(polar[0]);
    const lat = (0, math_1.dms)(polar[1]);
    result[0] = polar[0];
    result[1] = polar[1];
    result[2] = polar[2];
    result[3] = lon;
    result[4] = lat;
    return result;
};
exports.calcLonLat = calcLonLat;
