import React from 'react';
import { Aleph } from './aleph';
import { Ayin } from './ayin';
import { Beit } from './beit';
import { Chet } from './chet';
import { Dalet } from './dalet';
import { Gimel } from './gimel';
import { He } from './he';
import { Kaf } from './kaf';
import { Lamed } from './lamed';
import { Mem } from './mem';
import { Nun } from './nun';
import { Peh } from './peh';
import { Qof } from './qof';
import { Resh } from './resh';
import { Samekh } from './samekh';
import { Shin } from './shin';
import { Tav } from './tav';
import { Tet } from './tet';
import { Tsaddi } from './tsaddi';
import { Vav } from './vav';
import { Yod } from './yod';
import { Zayin } from './zayin';

export const letterIcons: Record<string, React.FC<any>> = {
  'א': Aleph,
  'מ': Mem,
  'ש': Shin,
  'פ': Peh,
  'ר': Resh,
  'ב': Beit,
  'ד': Dalet,
  'ג': Gimel,
  'ת': Tav,
  'כ': Kaf,
  'ה': He,
  'ו': Vav,
  'ז': Zayin,
  'ח': Chet,
  'ט': Tet,
  'י': Yod,
  'ל': Lamed,
  'נ': Nun,
  'ס': Samekh,
  'ע': Ayin,
  'צ': Tsaddi,
  'ק': Qof,
};