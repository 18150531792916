import { Coord } from "../types";

interface PointProps extends Coord {
  colour?: string;
}

export const Point: React.FC<PointProps> = (props) => {
  return (
    <circle
      r={3}
      cx={props.x}
      cy={props.y}
      stroke={props.colour}
      strokeWidth={2}
      fill="white"
    />
  );
};
