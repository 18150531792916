export const Aleph: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_106_2)">
        <path
          d="M18.5625 33.5156L17.1094 42H10.5469L12.5156 32.2969C13.0781 29.6094 13.5938 27.6953 14.0625 26.5547C14.5312 25.3984 15.1094 24.4844 15.7969 23.8125C16.5 23.125 17.4688 22.5156 18.7031 21.9844L11.9297 12.9141H19.6172L29.1094 26.0625C30.0938 25.1719 30.8438 24.125 31.3594 22.9219C31.875 21.7188 32.2422 20.0938 32.4609 18.0469L32.9531 12.9141H39.4219L38.8594 18.4688C38.6875 20.0469 38.375 21.4453 37.9219 22.6641C37.6094 23.4922 37.2266 24.2734 36.7734 25.0078C36.3203 25.7266 35.7266 26.4922 34.9922 27.3047C34.2734 28.1016 33.3203 29.0312 32.1328 30.0938L40.875 42H33.2344L21.5391 25.7812C20.7109 26.2656 20.1172 26.9688 19.7578 27.8906C19.5859 28.3594 19.3984 29.0625 19.1953 30C18.9922 30.9375 18.7812 32.1094 18.5625 33.5156Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_106_2">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
