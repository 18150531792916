import React, { useCallback, useState } from 'react';
import { ClickAwayListener, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NameOfGod } from '../utils';

interface Props {
  x: number;
  y: number;
  r?: number;
  sephiraName: string;
  sephiraEnglishName: string;
  nameOfGod: NameOfGod;
  colour: string;
  opacity?: number;
}

export const Sephirah: React.FC<Props> = ({
  x,
  y,
  r = 70,
  sephiraName,
  sephiraEnglishName,
  nameOfGod,
  colour,
  opacity = 1,
}) => {
  const { t } = useTranslation();
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  const handleMouseClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setHovering(true);
  }, []);

  const handleMouseClickAway = useCallback(() => {
    setHovering(false);
  }, []);

  return (
    <g>
      <ClickAwayListener onClickAway={handleMouseClickAway}>
        <Tooltip
          open={hovering}
          title={
            <>
              <Typography variant="h6" display="flex" flexDirection="row" gap={1} alignItems="center" justifyContent="space-between">
                {sephiraName}
                <span>({sephiraEnglishName})</span>
              </Typography>
              <Typography variant="subtitle2" display="flex" flexDirection="row" gap={1} alignItems="center">
                {nameOfGod.name} - {nameOfGod.englishName} ({nameOfGod.number})
              </Typography>
              <Typography variant="body2">{t(`biblical.verses.${nameOfGod.bible.book}.${nameOfGod.bible.number}.${nameOfGod.bible.verse}`)}</Typography>
              <Typography variant="body2">({t(`biblical.${nameOfGod.bible.book}`)} {nameOfGod.bible.number}:{nameOfGod.bible.verse})</Typography>
              <Typography
                variant="h5"
                fontFamily='"Times New Roman", Times, serif' 
              >
                {nameOfGod.bible.text}
              </Typography>
            </>
          }
          followCursor
        >
          <g
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleMouseClick}
            opacity={hovering ? 1 : opacity}
          >
            <circle cx={x} cy={y} r={r} fill={colour} stroke={hovering ? 'black' : '#696969'} strokeWidth="2" />
            <text
              fill="white"
              stroke="black"
              x={x}
              y={y + 16}
              fontSize="60"
              textAnchor="middle"
              fontWeight="bold"
              // Weirdness here - Safari somehow shows the text backwards, so we reverse it here and set direction to ltr
              unicodeBidi="bidi-override"
              direction="ltr"
            >
              {nameOfGod.name.split('').reverse().join('')}
            </text>
          </g>
        </Tooltip>
      </ClickAwayListener>
    </g>
   );
};
