export const He: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_114_14)">
        <path
          d="M37.6172 25.1484V42H31.2656V25.1016C31.2656 22.7109 30.7578 21.0312 29.7422 20.0625C28.7266 19.0781 26.9609 18.5859 24.4453 18.5859H12.5625V12.9141H24.8438C27.9219 12.9141 30.3906 13.3281 32.25 14.1562C34.125 14.9844 35.4844 16.2969 36.3281 18.0938C37.1875 19.875 37.6172 22.2266 37.6172 25.1484ZM19.3594 24.3281V42H13.0312V24.3281H19.3594Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_114_14">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
