import { Provider } from 'jotai';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Routes, Route, BrowserRouter, Navigate, Outlet } from 'react-router-dom';
import 'moment-timezone';
import { Layout } from './core/layout';
import { ChartPage } from './pages/chart';
import { createTheme, ThemeProvider } from '@mui/material';
import { TransitsPage } from './pages/transits';
import { RevolutionPage } from './pages/solar-revolution';
import { SigilPage } from './pages/sigil';
import { LBRPGuide } from './pages/ritual-guide/lbrp';
import { SephirotPage } from './pages/sephirot';

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={createTheme()}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout><Outlet /></Layout>}>
                <Route path="/" element={<Navigate to="/chart" />} />
                <Route path="/chart" element={<ChartPage />} />
                <Route path="/sephirot" element={<SephirotPage />} />
                <Route path="/transits" element={<TransitsPage />} />
                <Route path="/revolution" element={<RevolutionPage />} />
                <Route path="/sigil" element={<SigilPage />} />
                <Route path="/lbrp" element={<LBRPGuide />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
