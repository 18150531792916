import React, { useMemo } from 'react';
import { getCoordinates, getLineCoordinates } from '../utils'
import { zodiacSignSymbols } from '@danielpedroso/zodiac-signs';
import { signColourMap } from '../utils';

interface SignProps {
  parentSize: number;
  externalRadiusMultiplier: number;
  internalRadiusMultiplier: number;
  cusp: number;
  name: string;
  rotation: number;
  colour?: string;
  fontSize?: number;
  noSeparator?: boolean;
}

export const Sign: React.FC<SignProps> = ({
  parentSize,
  cusp,
  name,
  externalRadiusMultiplier,
  internalRadiusMultiplier,
  rotation,
  colour = '',
  fontSize = 24,
  noSeparator = false,
}) => {
  const coords = useMemo(() => {
    const width = parentSize;
    const r = width / 2;
    const externalRadius = width * externalRadiusMultiplier;
    const internalRadius = width * internalRadiusMultiplier;
    const deg = (cusp + 180) % 360;

    const start = getLineCoordinates(deg, internalRadius, externalRadius, r);
    const middleOfOutterRing = (externalRadius - internalRadius) / 2;
    const svg = getCoordinates(deg + 15, internalRadius + middleOfOutterRing, r);

    return {
      start,
      name: {
        svg: { x: svg.x - fontSize / 2, y: svg.y - fontSize / 2 },
      }
    };
  }, [cusp, externalRadiusMultiplier, internalRadiusMultiplier, parentSize, fontSize]);

  const Symbol = zodiacSignSymbols[name];
  const c = colour || signColourMap[name];
  if (!Symbol)
    throw new Error(`Unknown zodiac sign: ${name}`);

  return (
    <g>
      {!noSeparator && <line {...coords.start} stroke="black" />}
      <g style={{ transformBox: 'fill-box', transformOrigin: 'center' }} transform={`rotate(-${rotation})`}>
        <Symbol
          color={c ?? 'black'}
          stroke={c ?? 'black'}
          {...coords.name.svg}
          width={fontSize}
          height={fontSize}
        />
      </g>
    </g>
  );
}