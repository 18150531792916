import React, { useCallback, useMemo, useState } from 'react';
import { ClickAwayListener, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { AspectType, ChartObjectPos } from '@danielpedroso/astrum-calc/dist/types';
import { ASPECT_COLOURS, ASPECT_DASHARRAYS } from '@danielpedroso/astrum-calc/dist/constants';
import { ChartItemSelection, getCoordinates } from '../utils'
import { AspectTooltip } from './tooltip';

interface Props {
  id: number;
  parentSize: number;
  externalRadiusMultiplier: number;
  object1: ChartObjectPos;
  object2: ChartObjectPos;
  type: AspectType;
  deg: number;
  selection: ChartItemSelection;
  setSelection: (item: ChartItemSelection) => void;
}

export const Aspect: React.FC<Props> = ({
  id,
  parentSize,
  externalRadiusMultiplier,
  object1,
  object2,
  type,
  deg,
  selection,
  setSelection,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (!isMobile) setHovering(true);
  }, [isMobile]);

  const handleMouseLeave = useCallback(() => {
    if (!isMobile) setHovering(false);
  }, [isMobile]);

  const handleMouseClick = useCallback(() => {
    if (isMobile) {
      setSelection({ type: 'aspect', item: id });
    }
  }, [isMobile, id, setSelection]);

  const selected = useMemo(() => {
    return isMobile
      ? selection?.item === id && selection?.type === 'aspect'
      : hovering;
  }, [isMobile, selection?.item, selection?.type, id, hovering]);

  const handleMouseClickAway = useCallback(() => {
    if (!isMobile) return;

    if (selected) {
      setSelection({ type: 'aspect', item: '' });
    }
  }, [isMobile, selected, setSelection]);

  const coords = useMemo(() => {
    const width = parentSize;
    const r = width / 2;
    const externalRadius = (width * externalRadiusMultiplier);
    const p1Pos = object1.absolute;
    const p2Pos = object2.absolute;
    const deg1 = (p1Pos + 180) % 360;
    const deg2 = (p2Pos + 180) % 360;

    const pt1 = getCoordinates(deg1, externalRadius, r);
    const pt2 = getCoordinates(deg2, externalRadius, r);

    return {
      line: {
        x1: pt1.x,
        y1: pt1.y,
        x2: pt2.x,
        y2: pt2.y,
      },
    };
  }, [parentSize, externalRadiusMultiplier, object1.absolute, object2.absolute]);

  return (
    <ClickAwayListener onClickAway={handleMouseClickAway}>
      <Tooltip
        open={selected}
        title={
          <AspectTooltip aspect={{
            aspectType: type,
            degree: deg,
            object1,
            object2,
          }} />
        }
        followCursor={!isMobile}
      >
        <g
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleMouseClick}
            pointerEvents="all"
        >
          <line {...coords.line} stroke={selected ? 'grey' : ASPECT_COLOURS[type]} strokeWidth={selected ? 4 : 2} strokeDasharray={ASPECT_DASHARRAYS[type]} />
        </g>

      </Tooltip>
    </ClickAwayListener>
  );
}