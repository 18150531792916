import { SvgIcon, SvgIconProps } from "@mui/material";

export const TransitsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 50 50" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 30.2667C11.2245 30.2667 5.73334 24.7755 5.73334 18C5.73334 11.2245 11.2245 5.73334 18 5.73334C24.7755 5.73334 30.2667 11.2245 30.2667 18C30.2667 24.7755 24.7755 30.2667 18 30.2667ZM3.60001 18C3.60001 25.9537 10.0463 32.4 18 32.4C25.9537 32.4 32.4 25.9537 32.4 18C32.4 10.0463 25.9537 3.60001 18 3.60001C10.0463 3.60001 3.60001 10.0463 3.60001 18ZM14.2708 18C14.2708 15.9407 15.9407 14.2708 18 14.2708C20.0593 14.2708 21.7292 15.9407 21.7292 18C21.7292 20.0593 20.0593 21.7292 18 21.7292C15.9407 21.7292 14.2708 20.0593 14.2708 18ZM18 16.9375C17.4135 16.9375 16.9375 17.4135 16.9375 18C16.9375 18.5865 17.4135 19.0625 18 19.0625C18.5865 19.0625 19.0625 18.5865 19.0625 18C19.0625 17.4135 18.5865 16.9375 18 16.9375ZM44.6667 17.3333C36.5657 17.3333 30 23.899 30 32C30 40.101 36.5657 46.6667 44.6667 46.6667C45.2701 46.6667 45.7983 46.2614 45.9545 45.6786C46.1108 45.0958 45.8561 44.4806 45.3336 44.1788C40.9852 41.6668 38.3021 37.0233 38.3021 32C38.3021 26.9767 40.9852 22.3332 45.3336 19.8212C45.8561 19.5194 46.1108 18.9043 45.9545 18.3214C45.7983 17.7386 45.2701 17.3333 44.6667 17.3333ZM32.6667 32C32.6667 27.1443 35.5495 22.963 39.6973 21.0738C37.1171 24.0664 35.6354 27.928 35.6354 32C35.6354 36.0721 37.1171 39.9336 39.6973 42.9262C35.5495 41.0371 32.6667 36.8557 32.6667 32Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
};
