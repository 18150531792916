import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { buildChart } from '@danielpedroso/astrum-calc';
import { Checkbox, Grid, FormControlLabel } from '@mui/material';
import { PlanetsToolbar } from './toolbar/planets-toolbar';
import { chartAtom, chartItemSelectionAtom, chartStateAtom, locationAtom, planetaryInfoAtom } from './state';
import { useTranslation } from 'react-i18next';
import { SelectLocation } from '../../core/location/select';
import { AspectsToolbar } from './toolbar/aspects-toolbar';
import { DateTimeInput } from '../../core/datetime';
import { switchTimezones } from '../../core/datetime/utils';
import { Location as LocationType, locationsAtom } from '../../core/location/state';
import { SummaryTable } from './table';
import { PositionFinderDialog } from './position-finder';
import axios, { AxiosError } from 'axios';
import { BirthChart } from '@danielpedroso/chart';

///* Dan
//const bd = moment('1990-06-08T18:00:00.000-03:00');
//const lat = -15.2735;
//const lon = -49.64455;
//*/

/* Mai
const bd = moment('1990-07-21 16:55:00.000-03:00');
const lat = -27.216093;
const lon = -49.643502;
*/

export const ChartPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [chartState, setChartState] = useAtom(chartStateAtom);
  const [chart, setChart] = useAtom(chartAtom);
  const [highPrecision, setHighPrecision] = useState(false);
  const [planetary] = useAtom(planetaryInfoAtom);
  const [itemSelection, setItemSelection] = useAtom(chartItemSelectionAtom);
  const [location] = useAtom(locationAtom);
  const [locations] = useAtom(locationsAtom);

  const handleSubtract = useCallback((dur: moment.unitOfTime.DurationConstructor) => () => {
    setChartState((s) => ({
      ...s,
      isoDate: moment(s.isoDate).subtract(1, dur).toISOString(),
    }));
  }, [setChartState]);

  const handleAdd = useCallback((dur: moment.unitOfTime.DurationConstructor) => () => {
    setChartState((s) => ({
      ...s,
      isoDate: moment(s.isoDate).add(1, dur).toISOString(),
    }));
  }, [setChartState]);

  const handleChangeDate = useCallback((d: string) => {
    if (!d) return;

    setChartState((s) => ({
      ...s,
      isoDate: d,
    }));
  }, [setChartState]);

  const handleLocationChanged = useCallback((locationId: string | undefined, loc?: LocationType) => {
    if (!locationId || !loc) return;

    setChartState((s) => {
      const oldLocation = locations.find(l => l.id === s.locationId);
      if (!oldLocation) return s;

      const updated = switchTimezones(s.isoDate, oldLocation.tz, loc.tz);

      return {
        ...s,
        locationId,
        isoDate: updated,
      };
    });
  }, [locations, setChartState]);

  const handleHighPrecisionClick = useCallback(() => setHighPrecision(p => !p), []);

  const findByPosition = useCallback((obj: string, pos: number) => {
    if (!location) return;
    if (!chartState?.isoDate) return;

    (async () => {
      setLoading(true);
      try {
        await buildChart({
          date: chartState.isoDate,
          lat: location.lat,
          lon: location.lon,
          height: location.height,
          highPrecision: true,
          find: { obj, pos },
        }).then((chart) => {
          setHighPrecision(true);
          setChartState(c => ({ ...c, isoDate: chart.date ?? c.isoDate }));
          setChart(chart);
        });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          alert((err as AxiosError<any, any>).response?.data?.message ?? err.response?.statusText ?? `Error calculating chart: ${err.message}`);
        } else {
          alert((err as Error)?.message || err);
        }
      } finally {
        setLoading(false);
      }
    })();

  }, [chartState, location, setChart, setChartState]);

  useEffect(() => {
    if (!location) return;
    if (!chartState?.isoDate) return;

    (async () => {
      setLoading(true);
      try {
        await buildChart({
          date: chartState.isoDate,
          lat: location.lat,
          lon: location.lon,
          height: location.height,
          highPrecision,
        }).then((chart) => {
          setChart(chart);
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [chartState, location, setChart, highPrecision]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h5">{t('chartPage.dates.title')}</Typography>
            <Box display="flex" flexDirection="row">
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('hour')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; h</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('day')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; d</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('month')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; m</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('year')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; y</Button>
              <DateTimeInput tz={location?.tz ?? moment.tz.guess()} value={chartState.isoDate} onChange={handleChangeDate} />
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('year')} sx={{ display: { xs: 'none', md: 'block' } }}>y &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('month')} sx={{ display: { xs: 'none', md: 'block' } }}>m &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('day')} sx={{ display: { xs: 'none', md: 'block' } }}>d &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('hour')} sx={{ display: { xs: 'none', md: 'block' } }}>h &gt;</Button>
            </Box>
          </Box>
        </Paper>
        <PositionFinderDialog onFind={findByPosition} />
        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }}} maxWidth="100vw">
          <Grid
            item
            md={8}
            lg={9}
            pt={2}
            display="flex"
            flexDirection="column"
            maxWidth="100vw"
            sx={{
              opacity: loading ? 0.5 : 1,
              position: 'relative',
            }}
          >
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <FormControlLabel
                control={<Checkbox checked={highPrecision} onClick={handleHighPrecisionClick} />}
                label={t('chartPage.highPrecision')}
              />
            </Box>
            <PlanetsToolbar />
            {loading && <Typography variant="h3" width="100%" textAlign="center" sx={{ position: 'absolute', top: '300px' }}>{t('chartPage.loading')}</Typography>}
            <BirthChart chart={chart} itemSelection={itemSelection} setItemSelection={setItemSelection} />
            <AspectsToolbar />
            <SummaryTable chart={chart} />
          </Grid>
          <Grid item md={4} lg={3} maxWidth="100vw">
            <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('chartPage.location.title')}</Typography>
                <SelectLocation label={t('chartPage.location.title')} value={chartState.locationId} onChange={handleLocationChanged} />
              </Box>
            </Paper>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom>{t('chartPage.planetaryMagick.moon')}</Typography>
              <Box pb={2}>
                <Typography>{t('chartPage.planetaryMagick.quarter')}: {t(`chartPage.planetaryMagick.quarters.${chart?.planets.moon.phase?.quarter}`)}</Typography>
              </Box>
              <Typography variant="h5" gutterBottom>{t('chartPage.planetaryMagick.title')}</Typography>
              <Box pb={1}>
                <Typography>{t('chartPage.planetaryMagick.sunrise')}: {planetary?.curr.sun.rise}</Typography>
                <Typography>{t('chartPage.planetaryMagick.noon')}: {planetary?.curr.sun.noon}</Typography>
                <Typography>{t('chartPage.planetaryMagick.sunset')}: {planetary?.curr.sun.set}</Typography>
                <Typography>{t('chartPage.planetaryMagick.nightEnd')}: {planetary?.next.sun.rise}</Typography>
                <Typography>{t('chartPage.planetaryMagick.ruler')}: {t(`celestialBodies.${planetary?.planetaryHours.ruler}`)}</Typography>
              </Box>
              <Box pb={1}>
                <Typography variant="h6" gutterBottom>{t('chartPage.planetaryMagick.day')}</Typography>
                {planetary?.planetaryHours.day.map((h, i) => (
                  <Typography key={i}>{t('chartPage.planetaryMagick.timeslot', { from: h.start, to: h.end })} - {t(`celestialBodies.${h.planet}`)}</Typography>
                ))}
              </Box>
              <Box pb={1}>
                <Typography variant="h6" gutterBottom>{t('chartPage.planetaryMagick.night')}</Typography>
                {planetary?.planetaryHours.night.map((h, i) => (
                  <Typography key={`n${i}`}>{t('chartPage.planetaryMagick.timeslot', { from: h.start, to: h.end })} - {t(`celestialBodies.${h.planet}`)}</Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
