export const ACSymbol: React.FC<any> = (props) => {
  return (
    <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.29048 21H3.65412L7.67116 9.36364H10.8416L14.853 21H12.2166L9.30185 12.0227H9.21094L6.29048 21ZM6.12571 16.4261H12.353V18.3466H6.12571V16.4261ZM25.9737 13.4375H23.4851C23.4396 13.1155 23.3468 12.8295 23.2067 12.5795C23.0665 12.3258 22.8866 12.1098 22.6669 11.9318C22.4472 11.7538 22.1934 11.6174 21.9055 11.5227C21.6214 11.428 21.3127 11.3807 20.9794 11.3807C20.3771 11.3807 19.8525 11.5303 19.4055 11.8295C18.9586 12.125 18.612 12.5568 18.3658 13.125C18.1196 13.6894 17.9964 14.375 17.9964 15.1818C17.9964 16.0114 18.1196 16.7083 18.3658 17.2727C18.6158 17.8371 18.9643 18.2633 19.4112 18.5511C19.8582 18.839 20.3752 18.983 20.9624 18.983C21.2919 18.983 21.5968 18.9394 21.8771 18.8523C22.1612 18.7652 22.4131 18.6383 22.6328 18.4716C22.8525 18.3011 23.0343 18.0947 23.1783 17.8523C23.326 17.6098 23.4283 17.3333 23.4851 17.0227L25.9737 17.0341C25.9093 17.5682 25.7483 18.0833 25.4908 18.5795C25.237 19.072 24.8942 19.5133 24.4624 19.9034C24.0343 20.2898 23.523 20.5966 22.9283 20.8239C22.3374 21.0473 21.6688 21.1591 20.9226 21.1591C19.8847 21.1591 18.9567 20.9242 18.1385 20.4545C17.3241 19.9848 16.6802 19.3049 16.2067 18.4148C15.737 17.5246 15.5021 16.447 15.5021 15.1818C15.5021 13.9129 15.7408 12.8333 16.218 11.9432C16.6953 11.053 17.343 10.375 18.1612 9.90909C18.9794 9.43939 19.8999 9.20455 20.9226 9.20455C21.5968 9.20455 22.2218 9.29924 22.7976 9.48864C23.3771 9.67803 23.8904 9.95455 24.3374 10.3182C24.7843 10.678 25.148 11.1193 25.4283 11.642C25.7124 12.1648 25.8942 12.7633 25.9737 13.4375Z"
        style={{
          fill: 'currentColor',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
};
