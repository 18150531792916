"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pluto = void 0;
exports.pluto = {
    key: 'pluto',
    type: 'heliocentric',
    epoch: 2446640.5,
    inclination: 17.1346,
    node: 110.204,
    perihelion: 114.21,
    semiAxis: 39.4633,
    dailyMotion: 0.00397570,
    eccentricity: 0.248662,
    anomaly: 355.0554,
    equinox: 2446640.,
    magnitude: -1.0,
    semiDiameter: 2.07,
    distance: 0.0, // computed
    longitude: 0.0, // computed
    perturbation: 0.0, // computed
    ptable: {
        maxargs: 9,
        max_harmonic: [0, 0, 0, 0, 2, 2, 9, 13, 13],
        max_power_of_t: 7,
        distance: 3.9539999999999999e+01,
        timescale: 3652500.0,
        trunclvl: 1.0,
        lon_tbl: [
            74986469.33577, 100898343.73690, 48199471.54076, 9520076.03177,
            690431.67340, -427355.12716, 52266623.77862, 860190.70714,
            -21.08511, -143.39295,
            -126.71124, 48.18528,
            -88.74508, 40.50942,
            51.29367, -10.24075,
            0.63094, 32.03258,
            -410.00781, 399.90234, -116.54319, 51.50329,
            116.84565, 687.76781, -13.38605, 182.70107,
            -1668.12226, -5162.22648, -585.68297, -2247.56041,
            -20.95875, -193.13703,
            -57.12097, -10.35058,
            -1778.01442, -6463.73779, -657.86093, -2713.44436,
            -41.32560, -211.82042,
            -107.16513, -36.58628,
            97929588.08231, -33582903.63417, 143382679.31770, -47411568.92345,
            79428827.73581, -24658834.87499, 19861233.17488, -5755585.62084,
            1995060.95931, -693507.08147, 135176.31467, 109360.38672,
            -8188.00598, -1680.95072, 71020.19608, -70785.39049,
            -24.56034, -20.34919,
            1618.45976, -2079.48538, 635.62954, -850.87068,
            44.95257, -64.04459,
            -18.61475, -1.77734,
            9.38079, 5.19958,
            17422.08783, -4872.53852, 10985.76629, -1137.68569,
            1771.28893, 288.93745, 40.22664, 37.90027,
            6.81361, -32.65868,
            16.97268, 11.76152,
            29.33024, -12.92289,
            -55.13187, -14.73791,
            7.52474, -102.05060,
            182.53144, -20.18960,
            -490237997.49400, 486646248.63360, -781277018.26430, 602300460.57290,
            -463787999.46420, 249529525.96100, -123964746.86420, 31353019.97807,
            -13353800.92159, -3463382.63269, -35469.17654, -1035343.45385,
            65076.64025, -38181.61312, -16473.33813, 3928.44674,
            188.60263, 1000.42530,
            -208376.39376, -700566.62363, 114839.84613, -342407.71113,
            39467.04812, -14553.84849,
            581895.26100, 1012499.16715, 406317.22416, 310804.78515,
            43625.07033, -4157.26545,
            -5930.13795, -2070.62413,
            3348.17582, -10871.23729,
            144609.18550, 60383.63650, 27912.02226, 15254.61228,
            -98561.37758, -67442.28158, -15573.63338, -19931.99773,
            24323.06905, -37473.32558, 2840.64042, -8911.23694,
            -19636.31898, 71725.21946, -12280.54554, 12251.00101,
            88626.52260, 5513.68450, 18506.41546, -6513.87434,
            -83350.14621, 44300.00743, -22075.37353, 3731.57531,
            -29167.76020, -21642.67384,
            56602666.72177, -22225578.01823, 50576897.80669, -50319847.79086,
            5689259.25622, -29585299.79697, -4249711.27661, -4490830.29568,
            -727678.08724, 366050.85631, 19183.62792, 55647.98226,
            1897.78091, -1091.03988, 432.38158, -138.62556,
            101.38743, 25.67379,
            320.20735, 362.16615, 85.06067, 54.02616,
            2.39460, 18.70004,
            -8.43353, 2.72100,
            -3.11205, -3.06201,
            136.31503, -28.33620, 48.68781, -18.45285,
            1.15302, -1.52360,
            -0.13706, -0.37489,
            0.08750, -0.14579,
            -0.07051, -0.06518,
            0.30237, -0.00448,
            4.83172, 6.83684,
            1752447.78043, -945086.75857, 2340978.12819, -1963675.42559,
            1254147.25257, -1274861.91191, 279459.60237, -263954.01378,
            11835.62290, -16344.44434, 9119.98960, -2390.44218,
            -23.67909, 86.73916, -642.78635, -1290.12208,
            -0.43345, -1.85348,
            0.03094, -0.01655,
            0.12380, 0.31834,
            5.54756, -1.63109, 1.10598, -0.17578,
            2.66994, -2.17573, 0.97360, -0.92226,
            -0.18533, -0.39747,
            0.45809, -0.65286,
            0.26129, 0.91922,
            0.81068, 0.11183,
            6.32182, 14.16786, 0.20872, 3.28489,
            -1.47458, -2.11724,
            1.70020, -1.99889,
            3.13003, 1.90638,
            -4483669.52795, -446454.90158, -6586256.67478, -671890.16779,
            -3620444.55554, -499672.41074, -855998.32655, -191073.94266,
            -73186.69110, -22649.38582, -2414.81729, -1294.40542,
            436.80907, 125.48109, -81.16877, 458.86508,
            -11.57414, -26.39114, -4.00801, -5.01054,
            -18.17569, 20.86879, -4.80965, 3.10535,
            -4.71122, 1.18169,
            74.75544, 649.21464, -26.55060, 272.35592,
            -8.06982, 16.86110,
            -26.54868, 26.75711,
            -35.82910, 38.51063,
            22.22814, 19.38336,
            -6.30462, 0.90602,
            0.62856, -0.34981,
            -0.10232, -0.00939,
            0.04439, -0.18875,
            0.16025, 0.11306,
            -0.06803, 0.06004,
            -91305.66728, 262370.61704, -194633.44577, 304838.17733,
            -124505.90904, 94111.75602, -22317.18255, 1575.23438,
            748.66316, -349.78711, 166.64450, -89.05045,
            120.76207, -100.26715,
            3.13806, 3.71747,
            -1.44731, -0.35235,
            -0.51660, -1.50621,
            2.81310, -3.93573, 1.20292, -0.36412,
            -0.03340, -0.00561,
            -5.29764, 26.02941, 1.91382, 3.30686,
            -3.35265, -3.20868,
            0.05807, -0.11885,
            -0.78588, 0.34807, -0.19038, 0.11295,
            -0.03642, -0.03794,
            0.00251, 0.03449,
            -0.08426, -0.00310,
            0.05297, -0.09278,
            0.10941, 0.00099,
            -228688.56632, 312567.73069, -331458.31119, 328200.19460,
            -143760.57524, 104182.01134, -17313.30132, 12591.15513,
            -440.32735, -105.67674, 104.35854, -852.84590,
            0.95527, 0.30212, -54.63983, 4.06948,
            0.07545, -0.13429,
            16.21005, 29.24658, 9.23410, 50.48867,
            30.55641, 12.76809, 0.11781, 0.70929,
            -0.04100, 13.60859,
            0.04976, -0.02083,
            0.36279, 0.30130, -0.02129, 0.09363,
            -0.07812, 0.01570,
            -0.06217, -0.37181,
            -29348.55031, 43889.87672, -35765.41577, 33855.90070,
            -10128.69894, 4535.32148, 281.75353, -218.49194,
            -7.55224, 134.28640, 2.11319, -2.13109,
            15.71244, 11.07183,
            -0.05406, -0.23337,
            -1.28949, 1.34281,
            0.04212, -0.02080,
            0.08109, 0.14820,
            -6010.46564, 3639.41780, -5973.16000, 1381.66999,
            -1177.36865, -501.06937, 166.14792, -103.36431,
            14.92766, 4.12877, -2.20893, -6.32033,
            -0.29038, -0.43172,
            -0.59341, 0.20477, -0.13143, -0.03150,
            0.10992, 0.01976,
            -0.00254, 0.02028,
            -0.30044, -0.44658, -0.03409, -0.10758,
            0.08349, 0.06153,
            -0.06055, 0.18249,
            -1.15341, -8.68699, -0.11348, -3.30688,
            1.08604, 1.04018,
            -0.46892, -0.69765, 0.21504, 0.01968,
            -0.00455, -0.01678,
            3.95643, -3.17191, 3.95220, -2.12670,
            0.99305, -0.16651,
            0.34839, -0.49162,
            0.85744, 0.20173, -0.00975, 0.20225,
            -0.02627, -0.02281,
            -0.18002, -0.01803,
            -0.06144, -0.21510,
            0.15935, -0.01251,
            -0.21378, 0.44806, -0.01174, 0.05779,
            0.07646, -0.19656, -0.04044, -0.02521,
            0.02996, 0.06169,
            0.16698, -0.04710, -0.06506, -0.02114,
            0.05500, 0.00276,
            0.08433, 0.03160,
            0.08193, 0.35773, 0.05454, 0.10718,
            -0.02823, -0.00839,
            0.54078, 0.49347, 0.09609, 0.11825,
            -0.16092, -0.11897,
            0.09059, 0.08254,
            0.16712, 0.05860,
            -0.09547, -0.03206,
            0.03876, 0.04719,
            -0.02345, 0.02240,
            -0.00609, -0.00649,
            0.03859, 0.00077,
            0.47819, 0.26196, 0.09780, 0.08104,
            -0.16919, 0.05042,
            -0.42652, 0.30810,
            -0.03409, -0.51452,
            -0.23120, -0.01380,
            -0.01157, -0.00143,
            -0.00512, -0.01628,
            -0.00189, 0.00183,
            -0.01427, -0.02861,
            0.00618, -0.00015,
            0.13087, 0.13870,
            0.15158, -0.21056,
            -3.94829, -1.06028, -1.36602, 0.77954,
            0.08709, -0.03118,
            -44.74949, 91.17393, 8.78173, 45.84010,
            1.97560, -15.02849, -0.10755, -0.02884,
            3.38670, 0.30615,
            130.92778, -24.33209, 43.01636, -40.81327,
            -19.43900, 22.18162, -0.12691, 0.33795,
            -6.44790, -6.23145,
            0.00319, 0.01141,
            -0.03252, 0.03872,
            0.04467, 0.01614,
            -0.00382, -0.00019,
            0.05955, 0.01533,
            16.11371, 41.37565, 61.44963, 6.90615,
            1.41326, -0.73920, -0.03871, 24.81978,
            -0.10229, -0.32775, -0.05188, -0.05628,
            -2.33618, 2.39053,
            -0.00584, 0.00436,
            0.20903, 0.02220,
            -0.01738, -0.02765,
            -0.00217, 0.00613,
            -0.01772, 0.01903,
            0.07075, -0.00530,
            0.15234, -0.37760, -0.11641, -0.20102,
            -0.63675, 0.20525, -0.15783, 0.58945,
            -0.06243, 0.04306
        ],
        lat_tbl: [
            -35042727.30412, -49049197.81293, -25374963.60995, -5761406.03035,
            -467370.57540, 14040.11453, 2329.15763, -13978.69390,
            45.43441, 29.70305,
            32.33772, -38.34012,
            26.43575, -28.76136,
            -18.59040, 12.64837,
            5.56569, -12.51581,
            248.37350, -64.44466, 54.02618, 4.39466,
            -269.35114, -290.63134, -48.03841, -52.83576,
            1508.94995, 1682.78967, 554.02336, 715.65819,
            34.37602, 58.44397,
            16.63685, 16.10176,
            -1069.51609, 2300.89166, -437.16796, 927.89245,
            -33.17679, 68.74495,
            18.72022, 32.97640,
            -34004958.12619, -17758805.77098, -48416073.75788, -24973405.03542,
            -25374996.23732, -13351084.97340, -5738294.54942, -3082092.63350,
            -519989.39256, -206440.89101, 44186.23548, -87639.22630,
            2506.47602, 2327.01164, -53878.47903, -19670.13471,
            2.66934, -3.86086,
            106.32427, 576.47944, 46.56388, 218.28339,
            4.35402, 15.04642,
            2.68717, -2.86835,
            0.81728, -2.34417,
            -1604.85823, -1999.24986, -631.47343, -1382.19156,
            -15.74075, -256.97077, 6.99648, -4.54257,
            2.63478, 1.88838,
            0.17628, -2.11518,
            -2.46735, -1.48743,
            1.83456, 4.68487,
            -7.10919, 3.57046,
            -5.36342, -7.70367,
            28395956.20816, -37176795.74372, 48969952.83034, -48145798.96248,
            31155823.23557, -21163596.14822, 9057634.38260, -3167688.51696,
            1167488.70078, 219103.97591, -19017.97335, 107849.61195,
            -3814.43474, 4405.92120, 5800.13959, 12619.88708,
            22.18168, -89.47801,
            52202.81929, 55119.44083, 5082.58907, 37955.06062,
            -3165.24355, 3316.67588,
            -113906.43970, -69279.41495, -57358.07767, -10176.17329,
            -4179.79867, 2495.99374,
            787.87180, -154.35591,
            -1148.62509, 1034.58199,
            -22194.95235, 3341.97949, -4578.53994, 108.30832,
            7444.39789, 16646.40725, 509.75430, 3808.92686,
            -179.85869, 7408.76716, 340.65366, 1504.64227,
            -3783.09873, -13505.60867, 875.74489, -3181.27898,
            -16220.93983, 8041.37347, -2631.07448, 2899.50781,
            18894.92095, -20072.81471, 5925.05701, -1947.91902,
            -6731.56601, 8014.52403,
            -987793.49463, 6491762.34471, -279205.73643, 6117135.96868,
            -140925.91402, 2259422.06929, 114028.61646, 605600.90358,
            91858.00186, 56506.65187, 8949.15777, -9782.67413,
            -394.66541, -105.19208, -76.54752, -32.59411,
            -19.28741, 10.40013,
            -107.64003, -7.36229, -22.25126, 4.05952,
            -3.74402, -2.79308,
            1.03337, -2.13968,
            1.53794, -0.02617,
            35.70756, 12.97733, 14.46213, 6.20518,
            1.79381, 1.65422,
            -0.31216, 0.29053,
            -0.03538, -0.01584,
            -0.08934, 0.00079,
            0.05539, -0.21591,
            2.86929, -2.24724,
            320797.07455, 93342.16556, -20903.39115, 79523.22083,
            -226588.37473, -121017.23944, -48472.25935, -74195.36778,
            -7962.48081, -4607.76339, -4597.33274, -7983.12541,
            -20.34500, 56.82999, -1038.19507, 619.69624,
            1.08907, -0.91278,
            -0.13391, 0.34956,
            -0.19982, -0.18296,
            -0.97688, 2.36806, -0.30127, 0.50980,
            0.96103, 1.96432, 0.43338, 0.87317,
            0.36997, -0.01583,
            -0.44692, -0.25159,
            -0.53525, 0.01154,
            -0.13231, 0.35562,
            3.88928, -4.02882, 1.06967, -0.56305,
            -0.45204, 0.77213,
            -0.82873, -0.25854,
            0.21136, -1.06696,
            458529.05491, 616790.47568, 698431.01349, 1124501.41713,
            300226.10339, 766533.33698, 26896.22954, 207880.75720,
            1116.29607, 21793.26153, -850.64044, 3528.95568,
            29.61278, -120.13367, 376.95131, 66.45758,
            -3.64868, 2.76062, -0.85352, 0.95115,
            5.35056, 2.52803, 0.90026, 0.76403,
            0.43191, 0.83605,
            125.81792, -39.65364, 50.14425, -5.75891,
            2.78555, 2.05055,
            -4.27266, -4.92428,
            6.78868, 5.73537,
            3.35229, -3.70143,
            0.08488, 1.07465,
            0.10227, 0.06074,
            0.00291, 0.01522,
            -0.02274, 0.00297,
            0.01095, -0.01856,
            -0.02862, 0.00178,
            143640.07486, 707.21331, 177163.08586, 53386.52697,
            56856.89297, 48268.74645, 1764.52814, 7711.76224,
            352.34159, -968.03169, -45.16568, -81.60481,
            -76.35993, -98.06932,
            -1.42185, 1.81425,
            -0.23427, 0.59023,
            0.57127, -0.36335,
            1.89975, 0.66890, 0.28797, 0.43592,
            -0.03769, 0.03273,
            -6.06571, -2.68515, -0.55315, 0.86977,
            1.53840, -0.59422,
            -0.05453, 0.02447,
            -0.12658, 0.22814, -0.01715, 0.08497,
            -0.01288, -0.00606,
            0.01547, -0.00692,
            0.01157, 0.02407,
            -0.03883, 0.00835,
            -0.01542, -0.04761,
            174386.39024, 158048.26273, 159192.81681, 220154.55148,
            33716.11953, 87537.86597, -116.90381, 7535.83928,
            -962.06994, -132.28837, -644.90482, -110.52332,
            3.42499, 3.74660, -0.94008, 41.55548,
            -0.03824, -0.05607,
            28.74787, -37.31399, 30.87853, -26.11940,
            10.79742, -5.97905, 1.01237, -0.04429,
            0.54402, 0.41905,
            -0.02440, -0.03991,
            -0.00347, -0.04362, -0.00347, -0.00469,
            -0.02707, 0.02761,
            -0.17773, -0.11789,
            26475.02580, 35363.04345, 19877.11475, 41430.35940,
            2948.09998, 12983.41406, 281.93744, 570.70054,
            147.83157, 16.00090, -1.62814, -8.30846,
            9.29131, -10.16496,
            -0.15799, 0.03843,
            1.44716, 0.46953,
            -0.02150, -0.02502,
            0.08861, -0.06690,
            2237.41551, 3739.08722, 753.74867, 3460.41553,
            -298.69226, 520.47031, -33.62615, -138.12767,
            3.61843, -8.29860, -4.56656, 0.79553,
            0.20041, -0.25771,
            -0.35233, -0.27913, -0.02799, -0.08328,
            -0.06889, -0.16853,
            0.01701, -0.00964,
            -0.37737, 0.18030, -0.08525, 0.01906,
            0.05236, -0.05155,
            0.11320, 0.05991,
            -5.66926, -0.54402, -2.08508, -0.39407,
            0.82155, -0.55975,
            0.39168, -0.25551, 0.00623, 0.16162,
            -0.02519, 0.02420,
            -1.23293, -3.19649, -0.60519, -2.79729,
            0.05362, -0.61569,
            -0.25638, -0.27033,
            -0.03987, 0.46623, -0.12070, 0.00643,
            0.00849, -0.00768,
            -0.03687, 0.10445,
            -0.13544, -0.00592,
            0.02078, 0.09172,
            0.15824, 0.15815, 0.02020, 0.00747,
            0.10919, 0.09553, 0.01953, -0.00135,
            0.04266, -0.00218,
            0.02182, -0.13742, -0.01249, 0.01724,
            -0.02200, 0.02975,
            -0.01401, 0.03416,
            -0.28873, 0.04235, -0.08137, 0.04223,
            -0.00326, 0.02144,
            -0.40423, 0.14281, -0.08256, 0.02142,
            0.08116, -0.03680,
            -0.02324, 0.07260,
            -0.06746, 0.11645,
            0.03233, -0.05997,
            -0.03101, 0.02197,
            -0.00896, -0.00491,
            0.00574, 0.00855,
            0.00052, 0.01209,
            -0.31828, 0.29955, -0.08133, 0.04318,
            0.06787, -0.08865,
            -0.13228, -0.06507,
            0.34008, 0.06417,
            -0.00177, -0.15116,
            -0.00553, -0.01950,
            0.01144, -0.00309,
            -0.00115, -0.00153,
            0.02063, -0.00791,
            -0.00314, 0.00493,
            -0.10614, 0.08338,
            0.08845, 0.20168,
            1.38955, -2.52285, -0.30475, -1.05787,
            0.00580, 0.06623,
            -44.33263, -47.70073, -29.80583, -8.77838,
            7.02948, 2.77221, 0.05248, -0.13702,
            -0.78176, 1.77489,
            -16.32831, 46.68457, 2.54516, 21.78145,
            -5.09080, -8.42611, -0.24419, -0.03315,
            2.80629, -1.12755,
            -0.00402, 0.00053,
            0.00024, -0.00043,
            0.00403, -0.00210,
            0.00603, 0.00411,
            -0.00260, 0.00416,
            2.29235, 3.05992, 2.36465, -0.58750,
            0.14030, 0.13523, 0.89998, 0.70156,
            -0.02188, 0.02003, -0.00533, 0.00447,
            2.96411, 1.30183,
            0.01422, 0.00624,
            -0.10737, -0.38316,
            -0.05968, 0.04379,
            0.01171, 0.01180,
            -0.00989, -0.01375,
            -0.00845, 0.03782,
            0.09484, 0.09909, 0.07640, -0.00898,
            -0.01076, 0.02760, 0.01630, 0.02198,
            0.05985, 0.04130
        ],
        rad_tbl: [
            17990649.12487, 24806479.30874, 12690953.00645, 2892671.69562,
            249947.71316, -5138.71425, 1142.68629, 6075.25751,
            -34.76785, -19.72399,
            -15.81516, 30.47718,
            -11.73638, 21.87955,
            9.42107, -10.40957,
            -5.59670, 6.85778,
            -167.06735, -2.31999, -32.42575, -13.72714,
            130.16635, 117.97555, 31.33915, 39.64331,
            -1378.54934, -395.83244, -562.79856, -167.74359,
            -45.12476, -17.08986,
            -4.20576, -16.56724,
            1762.12089, -1148.86987, 736.55320, -423.09108,
            56.13621, -26.26674,
            9.77810, -38.05151,
            4702224.98754, 27254904.94363, 5306232.25993, 39518429.29982,
            1725110.05669, 21833263.27069, 46010.62605, 5425411.66252,
            17238.09865, 536771.62156, -61263.36051, 66270.70142,
            2084.66296, -1936.71208, 35898.49503, 34885.28549,
            1.93276, 10.66292,
            -665.11445, 3.70467, -265.68478, 16.16272,
            -19.45954, 2.32738,
            3.04237, 3.97339,
            -2.64312, 0.66308,
            -3207.68754, 3418.03720, -2342.62310, 1729.15030,
            -450.84643, 179.00943, -13.20367, -1.86087,
            -4.95659, 7.22347,
            -5.08890, -1.28891,
            -6.21713, 5.10551,
            13.97276, 0.44529,
            3.25177, 25.02775,
            -45.56672, 11.58470,
            124443355.55450, -100018293.41775, 190506421.77863, -118262753.40162,
            108199328.45091, -45247957.63323, 27272084.41143, -4125106.01144,
            2583469.66051, 1024678.12935, -22702.55109, 199269.51481,
            -15783.14789, 5564.52481, -427.22231, -6330.86079,
            -97.50757, -204.32241,
            -9060.54822, 156661.77631, -47791.83678, 59725.58975,
            -8807.74881, -92.38886,
            -28886.11572, -244419.59744, -53336.36915, -92232.16479,
            -8724.89354, -2446.76739,
            889.71335, 936.51108,
            494.80305, 2252.83602,
            -18326.60823, -25443.13554, -3130.86382, -5426.29135,
            23494.08846, 91.28882, 4664.14726, 1552.06143,
            -8090.43357, 2843.48366, -1445.73506, 1023.11482,
            11664.20863, -7020.08612, 3100.21504, -64.16577,
            -9724.97938, -12261.47155, -3008.08276, -1523.06301,
            6788.74046, 10708.27853, 343.09434, 1701.52760,
            14743.99857, -4781.96586,
            -15922236.41469, 1825172.51825, -14006084.36972, 10363332.64447,
            -979550.91360, 6542446.18797, 1160614.26915, 570804.88172,
            89912.68112, -171247.08757, -13899.52899, -6182.25841,
            -240.64725, 412.42581, -66.24510, 71.30726,
            -15.81125, -15.76899,
            -21.85515, -102.12717, -10.18287, -19.38527,
            1.43749, -3.87533,
            1.97109, 0.20138,
            0.32012, 1.02928,
            -40.22077, 20.80684, -15.69766, 9.63663,
            -1.26010, 0.56197,
            0.08592, 0.18540,
            -0.07303, 0.03897,
            0.01438, -0.08809,
            0.15479, 0.10354,
            0.19052, 2.08790,
            405480.24475, -607986.83623, 582811.58843, -915111.10396,
            258696.21023, -493391.09443, 23403.62628, -119503.67282,
            -4036.86957, -9766.17805, -663.93268, 2544.07799,
            40.36638, 76.26390, 246.67716, -13.93440,
            0.12403, 0.25378,
            0.14004, -0.08501,
            0.07904, 0.12731,
            1.02117, -1.34663, 0.25142, -0.26903,
            0.18135, -0.57683, -0.30092, -0.36121,
            -0.09623, 0.05873,
            -0.05803, 0.02869,
            -0.01194, 0.04983,
            0.04250, 0.04894,
            1.34245, 0.70137, 0.24217, 0.25912,
            -0.32759, -0.03575,
            0.06780, -0.41277,
            0.43865, 0.17857,
            -763933.02226, 465658.17048, -1082753.91241, 593319.68634,
            -553911.89340, 274748.95145, -122250.71547, 56608.95768,
            -9914.17300, 2988.43709, 707.94605, -765.01470,
            52.73260, -34.22263, -43.58300, -38.43647,
            -4.95939, -1.97173, -1.04406, -0.13072,
            -0.34281, 4.75202, -0.35513, 0.93597,
            -0.54380, 0.70536,
            84.83116, 102.93003, 26.34884, 48.57746,
            0.02853, 2.91676,
            -8.07116, 1.66613,
            -2.07908, 11.62592,
            6.64704, 0.98291,
            -1.19192, 0.93791,
            0.18822, 0.00900,
            -0.03181, -0.02000,
            0.02755, -0.01398,
            -0.03971, -0.03756,
            0.13454, -0.04193,
            -18672.98484, 28230.75834, -28371.58823, 26448.45214,
            -13352.09393, 7461.71279, -2609.33578, 726.50321,
            -309.72942, -86.71982, 12.48589, -9.69726,
            1.82185, 14.92220,
            -0.04748, 0.42510,
            -0.20047, 0.00154,
            0.00176, -0.26262,
            0.78218, -0.73243, 0.23694, -0.03132,
            -0.00290, -0.03678,
            14.03094, 4.25948, 0.79368, -0.78489,
            -2.30962, 2.31946,
            0.00158, -0.04125,
            -0.01387, 0.28503, 0.00892, 0.05154,
            0.00184, -0.01727,
            -0.00889, 0.03526,
            -0.00521, -0.02093,
            0.00200, 0.04872,
            -0.02163, 0.00578,
            20699.27413, -2175.57827, 31177.33085, 4572.02063,
            15486.28190, 8747.74091, 2455.51737, 3839.83609,
            51.31433, 507.91086, 15.90082, 44.75942,
            -0.98374, -2.64477, 2.52336, -3.09203,
            -0.08897, -0.00083,
            -15.91892, 0.72597, 14.04523, -3.16525,
            4.33379, -30.82980, 0.40462, -0.75845,
            13.14831, -0.02721,
            -0.01779, 0.00481,
            0.42365, -0.09048, 0.08653, 0.04391,
            0.00846, 0.01082,
            -0.04736, 0.02308,
            6282.21778, -4952.70286, 7886.57505, -5328.36122,
            3113.76826, -1696.84590, 330.70011, -155.51989,
            -18.31559, -3.90798, -3.11242, 1.87818,
            -1.05578, 0.11198,
            0.05077, -0.01571,
            2.41291, 2.40568,
            -0.01136, -0.00076,
            -0.00392, -0.02774,
            634.85065, -352.21937, 674.31665, -260.73473,
            199.16422, -28.44198, 6.54187, 6.44960,
            -1.55155, 0.29755, 0.16977, 0.17540,
            -0.02652, 0.03726,
            -0.00623, 0.11777, -0.00933, 0.02602,
            -0.13943, -0.24818,
            0.02876, -0.01463,
            -0.07166, 0.06747, -0.01578, 0.01628,
            0.00233, -0.00686,
            0.00431, -0.00276,
            0.21774, 0.09735, 0.07894, 0.07279,
            -0.01300, -0.00268,
            0.10824, 0.09435, 0.00720, 0.02111,
            -0.01960, 0.06154,
            0.56867, -0.07544, 0.18210, 0.06343,
            -0.00906, 0.01942,
            -0.00850, -0.00351,
            -0.06988, 0.01713, -0.01110, -0.00663,
            0.00196, -0.02064,
            -0.00008, 0.00043,
            0.00375, 0.00084,
            -0.00279, 0.00100,
            0.00271, -0.02017, -0.00074, -0.00357,
            0.03793, -0.10108, -0.01083, -0.03952,
            0.00030, 0.00012,
            0.01576, 0.01142, 0.00351, 0.00277,
            0.01409, -0.00774,
            -0.00065, 0.01895,
            0.07350, -0.02519, 0.01528, -0.01057,
            -0.00099, -0.00295,
            0.21347, -0.17458, 0.04940, -0.02757,
            -0.06243, 0.05203,
            0.01055, -0.00109,
            0.00003, -0.04201,
            -0.00263, 0.02387,
            0.00886, -0.01168,
            0.00479, 0.00204,
            -0.00239, 0.00022,
            -0.00223, -0.02029,
            -0.14130, -0.15237, -0.01827, -0.04877,
            0.12104, 0.06796,
            0.16379, 0.31892,
            -0.15605, 0.07048,
            -0.00700, 0.07481,
            -0.00370, -0.00142,
            -0.00446, 0.00329,
            -0.00018, 0.00117,
            -0.00910, 0.00510,
            -0.00055, -0.00114,
            0.04131, -0.04013,
            -0.13238, 0.02680,
            -0.10369, 1.38709, 0.35515, 0.41437,
            -0.01327, -0.02692,
            38.02603, 13.38166, 15.33389, -7.40145,
            -8.55293, -0.13185, -0.03316, 0.13016,
            0.04428, -1.60953,
            -12.87829, -76.97922, -23.96039, -22.45636,
            14.83309, 14.09854, 0.24252, 0.13850,
            -4.16582, 4.08846,
            0.00751, -0.00051,
            0.03456, 0.02900,
            0.01625, -0.04660,
            0.01390, -0.00530,
            0.01665, -0.04571,
            40.90768, -14.11641, 7.46071, -58.07356,
            -0.27859, -1.33816, 23.76074, -0.03124,
            -0.27860, 0.13654, -0.04800, 0.05375,
            4.38091, 4.39337,
            0.02233, 0.00514,
            -0.25616, -0.54439,
            -0.05155, 0.11553,
            0.02944, -0.00818,
            0.00570, 0.00119,
            -0.00733, -0.02700,
            -0.23759, -0.08712, -0.12433, 0.07397,
            0.20629, 0.60251, 0.56512, 0.14790,
            0.07778, 0.11614
        ],
        arg_tbl: [
            0, 7,
            2, 3, 7, -9, 9, 0,
            2, 4, 7, -12, 9, 0,
            2, 4, 7, -8, 8, 0,
            3, -4, 7, 5, 8, 4, 9, 0,
            3, 3, 7, -5, 8, -1, 9, 0,
            2, 1, 6, -8, 9, 1,
            2, 3, 8, -5, 9, 1,
            2, 1, 6, -9, 9, 2,
            3, 6, 7, -6, 8, -8, 9, 0,
            3, 4, 7, -10, 8, 4, 9, 2,
            2, 3, 7, -8, 9, 0,
            1, 1, 9, 7,
            2, 3, 7, -10, 9, 0,
            3, 4, 7, -10, 8, 2, 9, 2,
            3, 5, 7, -12, 8, 2, 9, 0,
            2, 1, 6, -7, 9, 0,
            1, 1, 8, 3,
            2, 1, 6, -10, 9, 0,
            3, 6, 7, -12, 8, 2, 9, 0,
            3, 5, 7, -10, 8, 2, 9, 0,
            2, 5, 7, -13, 9, 0,
            2, 4, 7, -10, 9, 0,
            2, 3, 7, -7, 9, 0,
            1, 2, 9, 7,
            2, 3, 7, -11, 9, 0,
            3, 4, 7, -9, 8, 4, 9, 2,
            3, 3, 7, -5, 8, 1, 9, 2,
            2, 1, 6, -6, 9, 0,
            2, 7, 8, -13, 9, 0,
            2, 3, 8, -2, 9, 1,
            3, 1, 7, -5, 8, 2, 9, 1,
            3, 6, 7, -12, 8, 3, 9, 1,
            2, 5, 7, -12, 9, 1,
            2, 4, 7, -9, 9, 1,
            2, 2, 7, -3, 9, 1,
            1, 1, 7, 0,
            1, 3, 9, 5,
            2, 3, 7, -12, 9, 1,
            3, 5, 7, -9, 8, 2, 9, 0,
            3, 4, 7, -7, 8, 2, 9, 1,
            3, 3, 7, -5, 8, 2, 9, 0,
            3, 2, 7, -5, 8, 5, 9, 0,
            2, 1, 6, -5, 9, 0,
            2, 3, 8, -1, 9, 2,
            2, 1, 6, -12, 9, 0,
            3, 2, 7, -7, 8, 1, 9, 0,
            2, 5, 7, -11, 9, 0,
            2, 4, 7, -8, 9, 0,
            2, 2, 7, -2, 9, 0,
            1, 4, 9, 7,
            3, 2, 7, -8, 8, 2, 9, 0,
            3, 5, 7, -9, 8, 3, 9, 0,
            3, 4, 7, -9, 8, 6, 9, 0,
            3, 3, 7, -5, 8, 3, 9, 1,
            2, 2, 7, -1, 8, 1,
            2, 3, 8, -9, 9, 0,
            2, 9, 8, -9, 9, 0,
            2, 1, 6, -13, 9, 0,
            3, 2, 7, -5, 8, -3, 9, 0,
            2, 6, 7, -13, 9, 1,
            2, 5, 7, -10, 9, 0,
            2, 4, 7, -7, 9, 0,
            2, 3, 7, -4, 9, 0,
            1, 5, 9, 7,
            3, 6, 7, -9, 8, 1, 9, 1,
            3, 4, 7, -5, 8, 1, 9, 1,
            3, 3, 7, -3, 8, 1, 9, 0,
            2, 1, 6, -3, 9, 2,
            2, 3, 8, -10, 9, 0,
            2, 1, 8, 4, 9, 0,
            2, 5, 8, -2, 9, 0,
            2, 11, 8, -11, 9, 0,
            3, 1, 7, -9, 8, 5, 9, 0,
            2, 6, 7, -12, 9, 0,
            2, 5, 7, -9, 9, 0,
            2, 4, 7, -6, 9, 0,
            2, 3, 7, -3, 9, 0,
            1, 6, 9, 6,
            2, 2, 7, -12, 9, 0,
            3, 6, 7, -9, 8, 2, 9, 0,
            3, 3, 7, -12, 8, 3, 9, 0,
            3, 4, 7, -10, 8, -3, 9, 1,
            3, 3, 7, -3, 8, 2, 9, 0,
            2, 1, 6, -2, 9, 2,
            2, 1, 8, 5, 9, 0,
            2, 13, 8, -13, 9, 1,
            3, 2, 7, -9, 8, 1, 9, 0,
            2, 6, 7, -11, 9, 0,
            2, 5, 7, -8, 9, 0,
            2, 4, 7, -5, 9, 0,
            2, 3, 7, -2, 9, 0,
            1, 7, 9, 7,
            3, 6, 7, -9, 8, 3, 9, 0,
            2, 1, 6, -1, 9, 4,
            2, 3, 8, 3, 9, 0,
            2, 7, 7, -13, 9, 1,
            2, 3, 7, -1, 9, 0,
            2, 2, 7, 2, 9, 0,
            1, 8, 9, 6,
            3, 7, 7, -9, 8, 1, 9, 0,
            1, 1, 6, 0,
            1, 3, 7, 0,
            2, 2, 7, 3, 9, 0,
            1, 9, 9, 5,
            3, 1, 7, -10, 8, 3, 9, 0,
            3, 2, 7, -12, 8, 3, 9, 1,
            2, 1, 6, 1, 9, 0,
            3, 1, 7, -1, 8, 8, 9, 0,
            2, 3, 7, 1, 9, 1,
            2, 2, 7, 4, 9, 0,
            2, 1, 7, 7, 9, 0,
            2, 4, 8, 4, 9, 1,
            2, 12, 8, -8, 9, 0,
            3, 1, 7, -10, 8, 2, 9, 1,
            2, 1, 6, 2, 9, 0,
            1, 11, 9, 2,
            2, 12, 8, -7, 9, 0,
            3, 1, 7, -10, 8, 1, 9, 1,
            1, 4, 7, 0,
            1, 12, 9, 0,
            2, 6, 8, 3, 9, 0,
            3, 1, 7, -2, 8, -12, 9, 0,
            3, 7, 7, -7, 8, 2, 9, 1,
            2, 2, 6, -4, 9, 1,
            1, 13, 9, 0,
            2, 10, 8, -2, 9, 1,
            2, 4, 7, 2, 9, 0,
            2, 2, 6, -3, 9, 0,
            2, 2, 7, 8, 9, 1,
            2, 8, 8, 2, 9, 0,
            1, 5, 7, 1,
            2, 4, 7, 3, 9, 0,
            2, 3, 7, 6, 9, 0,
            2, 1, 5, -6, 9, 0,
            3, 2, 7, 8, 8, -3, 9, 0,
            3, 1, 7, 6, 8, 3, 9, 0,
            2, 6, 8, 6, 9, 0,
            3, 8, 7, -7, 8, 2, 9, 0,
            2, 9, 7, -11, 9, 0,
            2, 5, 7, 1, 9, 1,
            2, 4, 7, 4, 9, 0,
            2, 2, 6, -1, 9, 0,
            3, 2, 6, -1, 7, 2, 9, 0,
            2, 2, 7, 10, 9, 0,
            2, 1, 7, 13, 9, 0,
            2, 8, 7, -7, 9, 0,
            2, 7, 7, -4, 9, 0,
            2, 6, 7, -1, 9, 0,
            2, 5, 7, 3, 9, 0,
            2, 4, 7, 5, 9, 0,
            1, 2, 6, 0,
            2, 1, 5, -4, 9, 1,
            3, 1, 6, 9, 8, -5, 9, 0,
            2, 1, 5, -3, 9, 4,
            2, 1, 5, -2, 9, 4,
            3, 9, 7, -9, 8, 6, 9, 0,
            2, 8, 7, -4, 9, 0,
            2, 7, 7, -1, 9, 0,
            2, 1, 6, 3, 9, 0,
            2, 2, 6, 3, 9, 0,
            2, 1, 5, -1, 9, 3,
            3, 6, 7, -3, 8, 7, 9, 1,
            1, 1, 5, 0,
            2, 2, 6, 5, 9, 0,
            2, 1, 5, 1, 9, 0,
            2, 1, 5, 2, 9, 0,
            2, 1, 5, 3, 9, 0,
            2, 2, 5, -4, 9, 0,
            2, 2, 5, -3, 9, 0,
            2, 2, 5, -2, 9, 1,
            2, 2, 5, -1, 9, 1,
            1, 2, 5, 0,
            -1
        ],
    },
};
