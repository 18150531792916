"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createObserver = void 0;
const constants_1 = require("../constants");
const date_1 = require("../date");
const createObserver = (latitude, longitude, height, year, month, day, hours, minutes, seconds) => {
    let a, b, fl, co, si, u; // double
    const glat = latitude;
    const tlong = longitude;
    u = glat * constants_1.DEG_TO_RADIANS;
    co = Math.cos(u);
    si = Math.sin(u);
    fl = 1.0 - 1.0 / constants_1.DIST_EARTH_FLAT;
    fl = fl * fl;
    si = si * si;
    u = 1.0 / Math.sqrt(co * co + fl * si);
    a = constants_1.RADIUS_EARTH * u + height;
    b = constants_1.RADIUS_EARTH * fl * u + height;
    let trho = Math.sqrt(a * a * co * co + b * b * si);
    let tlat = constants_1.RADIANS_TO_DEG * Math.acos(a * co / trho);
    if (glat < 0.0) {
        tlat = -tlat;
    }
    trho /= constants_1.RADIUS_EARTH;
    return {
        atpress: 1010.0,
        attemp: 12.0,
        glat,
        height,
        latitude,
        longitude,
        tlat,
        tlong,
        trho,
        year,
        month,
        day,
        hours,
        minutes,
        seconds,
        date: (0, date_1.createDateDelta)(year, month, day, hours, minutes, seconds),
    };
};
exports.createObserver = createObserver;
