import styled from '@emotion/styled';

export const SquareRatio = styled.div(() => ({
  width: '100%',
  paddingBottom: 'min(800px, 100%)',
  position: 'relative',
}));

export const RectangularRatio = styled.div(() => ({
  width: '100%',
  paddingBottom: 'min(800px, 200%)',
  position: 'relative',
}));

export const Container = styled.div<{ overrideHeight?: number }>(({ overrideHeight }) => ({
  position: 'absolute',
  inset: 0,
  padding: 0,
  width: '100%',
  height: overrideHeight ? `min(${overrideHeight}px, 200%)` : '100%',
  maxHeight: '800px',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'flex-start',
  overflow: 'hidden',
}));

export const Svg = styled.svg(() => ({
  width: '100%',
  maxWidth: '800px',
  height: '100%',
  maxHeight: '800px',
  overflow: 'visible',
}));