import { useMediaQuery, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { findNearestPoint } from '../service';
import { chartSizeAtom, pointsMemoAtom, selectedPointAtom } from '../state';

interface Props {
  scrollableEl: HTMLDivElement | undefined;
  children: React.ReactNode;
}

export const MouseTracker: React.FC<Props> = ({ scrollableEl, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [points] = useAtom(pointsMemoAtom);
  const [chartSize] = useAtom(chartSizeAtom);
  const [, setSelectedPoint] = useAtom(selectedPointAtom);

  const handleMouseMove: React.MouseEventHandler<SVGElement> = useCallback((e) => {
    const offsetTop = e.currentTarget.parentElement?.getBoundingClientRect()?.y ?? 0;

    const point = { 
      x: scrollPos.x + e.clientX,
      y: scrollPos.y + e.clientY - offsetTop,
    };
    setMousePos(point);
  }, [scrollPos.x, scrollPos.y]);

  const handleTouchEnd: React.TouchEventHandler<SVGElement> = useCallback((e) => {
    if (!isMobile) return;

    const offsetTop = e.currentTarget.parentElement?.getBoundingClientRect()?.y ?? 0;
    const touch = e.changedTouches[e.changedTouches.length - 1];
    if (!touch) return;

    const point = { 
      x: scrollPos.x + touch.clientX,
      y: scrollPos.y + touch.clientY - offsetTop,
    };
    setMousePos(point);
  }, [isMobile, scrollPos.x, scrollPos.y]);

  const selectedPoint = useMemo(() => {
    const point = findNearestPoint(mousePos, points);
    setSelectedPoint(point);
    return point;
  }, [mousePos, points, setSelectedPoint]);

  useEffect(() => {
    const updateScrollPos = () => {
      if (!scrollableEl) return setScrollPos({ x: 0, y: 0 });

      return setScrollPos({
        x: scrollableEl.scrollLeft,
        y: scrollableEl.scrollTop,
      });
    };

    updateScrollPos();
    scrollableEl?.addEventListener('scroll', updateScrollPos);
    return () => scrollableEl?.removeEventListener('scroll', updateScrollPos);
  }, [scrollableEl]);

  return (
    <g>
      <rect
        x={0}
        y={0}
        width={chartSize.width + chartSize.margin.left + chartSize.margin.right}
        height={chartSize.height + chartSize.margin.top + chartSize.margin.bottom}
        stroke="transparent"
        fill="transparent"
        onMouseMove={!isMobile ? handleMouseMove : undefined}
        onClick={isMobile ? handleMouseMove : undefined}
        onTouchEnd={isMobile ? handleTouchEnd : undefined}
        onTouchMove={(e) => e.preventDefault()}
        pointerEvents="bounding-box"
      />
      {selectedPoint && (
        <>
          <line
            x1={selectedPoint?.coord.x}
            y1={selectedPoint?.coord.y}
            x2={chartSize.margin.left}
            y2={selectedPoint?.coord.y}
            stroke="black"
            strokeDasharray={4}
          />
          <line
            x1={selectedPoint?.coord.x}
            y1={selectedPoint?.coord.y}
            x2={selectedPoint?.coord.x}
            y2={chartSize.height - chartSize.margin.bottom}
            stroke="black"
            strokeDasharray={4}
          />
        </>
      )}
      {children}
    </g>
  );
};