export const SunSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        d="M 6.898438 6 C 6.898438 5.503906 6.496094 5.101562 6 5.101562 C 5.503906 5.101562 5.101562 5.503906 5.101562 6 C 5.101562 6.496094 5.503906 6.898438 6 6.898438 C 6.496094 6.898438 6.898438 6.496094 6.898438 6 "
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 110 60 C 110 87.617188 87.617188 110 60 110 C 32.382812 110 10 87.617188 10 60 C 10 32.382812 32.382812 10 60 10 C 87.617188 10 110 32.382812 110 60 Z M 110 60 "
        strokeWidth="8"
        transform="matrix(0.1,0,0,-0.1,0,12)"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
}