import React, { useState, useCallback } from 'react';
import { useAtom } from 'jotai';
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { LocationFinderModal } from '../finder';
import countryCodes from '../finder/countryCodes.json';
import { locationsAtom, Location } from '../state';
import { InputLabel, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface SelectLocationProps extends Omit<SelectProps<string>, 'onChange'> {
  onChange: (locationId: string | undefined, location?: Location) => void;
}

export const SelectLocation: React.FC<SelectLocationProps> = ({ label, id, onChange, ...props }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [locations, setLocations] = useAtom(locationsAtom);
  const { t } = useTranslation();

  const handleModalClose = useCallback((chosen: Location | undefined) => {
    if (chosen) {
      setLocations(locations.filter(lo => lo.id !== chosen.id).concat(chosen));
      onChange(chosen.id, chosen);
    }
    setModalOpen(false);
  }, [locations, onChange, setLocations]);

  const handleChange = useCallback((e: SelectChangeEvent<string>, child: React.ReactNode) => {
    if (e.target.value === 'create-new') {
      return setModalOpen(true);
    }
    const chosen = locations.find(lo => lo.id === e.target.value);
    if (chosen && onChange) {
      return onChange(e.target.value, chosen);
    }
  }, [locations, onChange]);

  return (
    <FormControl fullWidth>
      {label ? (
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
      ) : null}
      <Select {...props} id={id} label={label} labelId={label ? `${id}-label` : undefined} onChange={handleChange}>
        {locations.map(l => (
          <MenuItem key={l.id} value={l.id}>
            {l.name} - {countryCodes.countries.find(c => c.code === l.country)?.label ?? l.country}
          </MenuItem>
        ))}
        <MenuItem key="create-new" value="create-new">{t('location.addNew')}</MenuItem>
      </Select>
      <LocationFinderModal open={modalOpen} onClose={handleModalClose} />
    </FormControl>
  );
};