export const AscNodeSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        d="m 8.828427,8.3466714 c 1.560607,-1.5606068 1.560607,-4.0962473 2e-7,-5.6568541 -1.5606067,-1.5606067 -4.0962475,-1.5606067 -5.6568542,0 -1.5606067,1.5606068 -1.5606067,4.0962475 0,5.6568543"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="m 3.171573,8.3466716 c -0.4888979,-0.4888979 -1.278869,-0.488898 -1.767767,0 -0.48889805,0.4888981 -0.48889783,1.2788691 0,1.7677674 0.4888979,0.488897 1.2788689,0.488898 1.767767,0 0.4888979,-0.4888984 0.4888979,-1.2788696 0,-1.7677674 z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="m 10.596194,8.3466714 c -0.488898,-0.4888979 -1.278869,-0.488898 -1.767767,0 -0.4888981,0.488898 -0.4888978,1.278869 0,1.7677666 0.4888979,0.488898 1.278869,0.488898 1.767767,0 0.488898,-0.4888976 0.488898,-1.2788688 0,-1.7677666 z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
}