export const Gimel: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_114_8)">
        <path
          d="M20.7812 12.9141C22.1719 12.9141 23.3281 13.0234 24.25 13.2422C25.1875 13.4609 25.9688 13.8438 26.5938 14.3906C27.2344 14.9219 27.7891 15.6562 28.2578 16.5938C28.6172 17.2969 28.9922 18.2812 29.3828 19.5469C29.7734 20.7969 30.1875 22.3281 30.625 24.1406L34.75 42H28.4453L26.1016 31.5469C24.9609 31.8438 24.1562 32.2422 23.6875 32.7422C23.2188 33.2422 22.8203 34.0312 22.4922 35.1094C22.4297 35.3281 22.3281 35.7109 22.1875 36.2578C22.0625 36.7891 21.9062 37.4766 21.7188 38.3203L20.9219 42H14.4766L15.4375 37.7812C15.7188 36.4844 16 35.3672 16.2812 34.4297C16.5625 33.4766 16.8516 32.6953 17.1484 32.0859C17.7266 30.8672 18.4688 29.8984 19.375 29.1797C20.2969 28.4453 21.5625 27.8359 23.1719 27.3516L25.0469 26.7891C24.8281 25.7422 24.6094 24.8047 24.3906 23.9766C24.1719 23.1484 23.9688 22.4375 23.7812 21.8438C23.3906 20.6406 22.9375 19.7969 22.4219 19.3125C21.9062 18.8281 21.0703 18.5859 19.9141 18.5859H16.75V12.9141H20.7812Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_114_8">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
