import { SetStateAction } from 'react';
import { atom } from 'jotai';
import { Chart } from '../../lib/types';
import { locationsAtom } from '../../core/location/state';
import { PathType } from '@danielpedroso/chart/dist/utils';
import { getZmanim, Zmanim } from '@danielpedroso/astrum-calc/dist/judaism';
import moment from 'moment-timezone';

interface ChartState {
  isoDate: string;
  locationId: string;
  treeType: PathType;
}

const initialState: ChartState = {
  isoDate: new Date().toISOString(),
  locationId: 'BNE',
  treeType: 'lurianic',
};
const initialStr = JSON.stringify(initialState);

const STORAGE_KEY = 'ASTRUM_SEPHIROTIC_CHART';

const strStateAtom = atom(localStorage.getItem(STORAGE_KEY) ?? initialStr);

export const chartStateAtom = atom(
  (get) => {
    const str = get(strStateAtom);

    try {
      return JSON.parse(str)
    } catch {
      return JSON.parse(initialStr);
    }
  },
  (get, set, newState: SetStateAction<ChartState>) => {
    let newV: ChartState;
    if (typeof newState === 'function') {
      newV = newState(get(chartStateAtom));
    } else {
      newV = newState;
    }

    const newVal = JSON.stringify(newV ?? initialState);
    set(strStateAtom, newVal);
    localStorage.setItem(STORAGE_KEY, newVal);
  }
);

export const locationAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const locations = get(locationsAtom);
  return locations.find(l => l.id === chartState.locationId ?? 'BNE') ?? locations[0];
});

export const zmanimAtom = atom<Zmanim | undefined>(
  (get) => {
    const chartState = get(chartStateAtom);
    const location = get(locationAtom);
    if (!location) return undefined;

    const zmanim = getZmanim(moment.tz(chartState.isoDate, location.tz), location.lat, location.lon, location.height);
    return zmanim;
  },
);

export const chartAtom = atom<Chart | undefined>(undefined);
