export const Chet: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_115_20)">
        <path
          d="M31.4141 25.9219C31.4141 23.2969 30.9141 21.4219 29.9141 20.2969C28.9141 19.1562 27.1641 18.5859 24.6641 18.5859H18.4062V42H12.0781V12.9141H24.6641C29.2891 12.9141 32.625 13.875 34.6719 15.7969C36.7344 17.7031 37.7656 20.8203 37.7656 25.1484V42H31.4141V25.9219Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_115_20">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
