export const Beit: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_113_4)">
        <path
          d="M39.0391 36.3281V42H11.9219V36.3281H29.5938V25.9688C29.5938 23.2344 29.0781 21.3203 28.0469 20.2266C27.0312 19.1328 25.2891 18.5859 22.8203 18.5859H13.0234V12.9141H22.9141C27.4766 12.9141 30.7812 13.8672 32.8281 15.7734C34.8906 17.6797 35.9219 20.8047 35.9219 25.1484V36.3281H39.0391Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_113_4">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
