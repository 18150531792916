export const CompletePentagrams: React.FC = () => {
  return (
    <>
      <p>You'll now have 4 Pentagrams around you (one in front, one behind and one on each side) connected by a circle.</p>
      <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="300" height="300" fill="white"/>
        <circle cx="150" cy="150" r="121.5" stroke="black"/>
        <path d="M134.474 53L140.424 33.8364L125 22.0608H144.081L150 3L155.919 22.0608H175L159.576 33.8364L165.526 53L150 41.1467L134.474 53Z" fill="white"/>
        <path d="M134.474 53L150 3L165.526 53L125 22.0608H175L134.474 53Z" stroke="black"/>
        <path d="M134.474 297L140.424 277.836L125 266.061H144.081L150 247L155.919 266.061H175L159.576 277.836L165.526 297L150 285.147L134.474 297Z" fill="white"/>
        <path d="M134.474 297L150 247L165.526 297L125 266.061H175L134.474 297Z" stroke="black"/>
        <path d="M256.474 175L262.424 155.836L247 144.061H266.081L272 125L277.919 144.061H297L281.576 155.836L287.526 175L272 163.147L256.474 175Z" fill="white"/>
        <path d="M256.474 175L272 125L287.526 175L247 144.061H297L256.474 175Z" stroke="black"/>
        <path d="M12.4737 175L18.4245 155.836L3 144.061H22.0811L28 125L33.9189 144.061H53L37.5755 155.836L43.5263 175L28 163.147L12.4737 175Z" fill="white"/>
        <path d="M12.4737 175L28 125L43.5263 175L3 144.061H53L12.4737 175Z" stroke="black"/>
        <path d="M149.655 36V26L154.828 29.6207H145L153.103 32.7241" stroke="black"/>
        <path d="M271.655 158V148L276.828 151.621H267L275.103 154.724" stroke="black"/>
        <path d="M149.655 280V270L154.828 273.621H145L153.103 276.724" stroke="black"/>
        <path d="M27.6552 158V148L32.8276 151.621H23L31.1034 154.724" stroke="black"/>
      </svg>
      <p>Still facing East, stand up tall and open your arms (forming a cross).</p>
    </>
  );
};