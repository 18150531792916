export const signColours = [
  '#c92838',
  '#f76438',
  '#f99838',
  '#f8b938',
  '#e8e83a',
  '#c9d648',
  '#089859',
  '#07696c',
  '#0878a8',
  '#482a68',
  '#682859',
  '#b82a66', 
];

export const signColourMap: Record<string, string> = {
  'aries': '#c92838',
  'taurus': '#f76438',
  'gemini': '#f99838',
  'cancer': '#f8b938',
  'leo': '#e8e83a',
  'virgo': '#c9d648',
  'libra': '#089859',
  'scorpio': '#07696c',
  'sagittarius':'#0878a8',
  'capricorn': '#482a68',
  'aquarius': '#682859',
  'pisces': '#b82a66', 
};