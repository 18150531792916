"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBodyInstance = void 0;
const sun_1 = require("./sun");
const moon_1 = require("./moon");
const mercury_1 = require("./mercury");
const venus_1 = require("./venus");
const earth_1 = require("./earth");
const mars_1 = require("./mars");
const jupiter_1 = require("./jupiter");
const saturn_1 = require("./saturn");
const uranus_1 = require("./uranus");
const neptune_1 = require("./neptune");
const pluto_1 = require("./pluto");
const mapped = {
    'sun': sun_1.sun,
    'moon': moon_1.moon,
    'mercury': mercury_1.mercury,
    'venus': venus_1.venus,
    'earth': earth_1.earth,
    'mars': mars_1.mars,
    'jupiter': jupiter_1.jupiter,
    'saturn': saturn_1.saturn,
    'uranus': uranus_1.uranus,
    'neptune': neptune_1.neptune,
    'pluto': pluto_1.pluto,
};
const createBodyInstance = (key) => {
    return {
        ...mapped[key],
        semiAxis: 0.0,
        magnitude: 0,
        locals: {
            EO: 0,
            SE: 0,
            SO: 0,
            pq: 0,
            ep: 0,
            qe: 0,
            ddecdt: 0,
            dp: [0, 0, 0],
            dradt: 0,
        },
        position: {
            rect: [0, 0, 0],
            polar: [0, 0, 0],
            apparentLongitude: 0,
            apparentLongitudeString: '',
            apparentLongitude30String: '',
        },
    };
};
exports.createBodyInstance = createBodyInstance;
