export const PlutoSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        d="M 6,11 V 4.75"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="m 3.5,7.25 h 5"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 2.25,1 C 2.25,3.0703125 3.9296875,4.75 6,4.75 8.0703125,4.75 9.75,3.0703125 9.75,1"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 7.25,2.25 C 7.25,1.558594 6.6914062,1 6,1 5.3085938,1 4.75,1.558594 4.75,2.25 4.75,2.9414062 5.3085938,3.5 6,3.5 6.6914062,3.5 7.25,2.9414062 7.25,2.25 Z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
}