export const Zayin: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_115_18)">
        <path d="M27.3672 27.9844V42H21.0391V27.9375C21.0391 26.25 21.3594 24.8359 22 23.6953C22.6562 22.5391 23.7422 21.6328 25.2578 20.9766L17.1953 18.2812V12.1406L32.8516 17.4141V23.5312L30.2969 22.7109C29.2188 23.2578 28.4766 23.8828 28.0703 24.5859C27.6016 25.3359 27.3672 26.4688 27.3672 27.9844Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_115_18">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
