import moment from "moment";
import { AspectData } from "../../state";
import { ChartConfig, Coord, PointCalcParams, PointsMemo, TransitPoint } from "../types";

export const calcPointCoord = (params: PointCalcParams): Coord => {
  const xSize = params.xRange / (params.xElements - 1);
  const ySize = params.yRange / (params.yElements);

  return {
    x: params.margin.left + (xSize * params.x),
    y: params.margin.top + params.yRange - (ySize * params.y),
  };
};

const SECS_IN_DAY = 24 * 60 * 60;

export const addZeroPointIfNeeded = (raw: AspectData, index: number, arr: TransitPoint[], chartConfig: ChartConfig) => {
  const deg = raw.data[index].aspect?.degree ?? 0.0;

  if (deg < 5.0 && index > 0) {
    const movedSincePrevDay = Math.abs(raw.data[index - 1].position.absolute - raw.data[index].position.absolute);
    const movedPerSecond = movedSincePrevDay / SECS_IN_DAY;
    const secondsToMoveBack = (5 - deg) / movedPerSecond;
    const inFractionOfDay = secondsToMoveBack / 60 / 60 / 24;

    arr.push({
      data: {
        ...raw.data[index],
        date: moment(raw.data[index].date).subtract(secondsToMoveBack, 'seconds'),
      },
      index: index,
      virtual: true,
      coord: calcPointCoord({
        ...chartConfig,
        x: index - inFractionOfDay,
        y: 0,
      }),
    });
  } else {
    arr.push({
      data: raw.data[index],
      index: index,
      coord: calcPointCoord({
        ...chartConfig,
        x: index,
        y: 0,
      }),
    });
  }
};

export const addLastZeroIfNeeded = (raw: AspectData, index: number, arr: TransitPoint[], chartConfig: ChartConfig) => {
  const lastPoint = raw.data[index];
  const deg = raw.data[index].aspect?.degree ?? 0.0;
  if (deg < 5.0 && index < (raw.data.length - 1)) {
    const movedSinceLastDay = Math.abs(raw.data[index + 1].position.absolute - lastPoint.position.absolute);
    const movedPerSecond = movedSinceLastDay / SECS_IN_DAY;
    const secondsToMoveForward = (5 - deg) / movedPerSecond;
    const inFractionOfDay = secondsToMoveForward / 60 / 60 / 24;

    arr.push({
      data: {
        ...raw.data[index],
        date: moment(raw.data[index].date).add(secondsToMoveForward, 'seconds'),
      },
      index,
      virtual: true,
      coord: calcPointCoord({
        ...chartConfig,
        x: index + inFractionOfDay,
        y: 0,
      }),
    });
  } else {
    arr.push({
      data: raw.data[index],
      index,
      coord: calcPointCoord({
        ...chartConfig,
        x: index,
        y: 0,
      }),
    });
  }
};

export const distance = (p1: Coord, p2: Coord) => Math.sqrt(
  // Calc the hypothenuse connecting p1 and p2 in a right angled triangle
  // horizontal side (x axis)
  Math.pow(p1.x - p2.x, 2) +
  // vertical side (y axis)
  Math.pow(p1.y - p2.y, 2)
);


export const findNearestPoint = (to: Coord, memo: PointsMemo): TransitPoint | undefined => {
  if (!memo.keys.length) return undefined;

  const includeVirtual = to.y > 400;

  // Narrow it down to a window of 3 x coordinates closest to the mouse pointer
  // Binary search so it runs in O(log n)
  const findNearestXs = (lo: number = 0, hi: number = memo.keys.length): number[] => {
    if (to.x < memo.keys[lo]) return [memo.keys[0]];
    if (to.x > memo.keys[hi]) return [memo.keys[hi]];

    const mid = Math.floor((hi + lo) / 2);
    if (hi - lo < 2) {
      if (!includeVirtual && memo.memo[memo.keys[lo]]?.every(elem => elem.virtual)) {
        lo = Math.max(0, lo - 1);
      }
      if (!includeVirtual && memo.memo[memo.keys[hi]]?.every(elem => elem.virtual)) {
        hi = Math.min(hi + 1, memo.keys.length);
      }

      // return
      if (to.x - memo.keys[lo] < memo.keys[hi] - to.x) {
        return memo.keys.slice(lo - 1, lo + 2);
      } else {
        return memo.keys.slice(hi - 1, hi + 2);
      }
    }
    if (to.x < memo.keys[mid]) {
      return findNearestXs(lo, mid);
    } else if (to.x > memo.keys[mid]) {
      return findNearestXs(mid, hi);
    } else {
      return memo.keys.slice(mid - 1, mid + 2);
    }
  };

  const findNearestY = (nearestXs: number[]): (TransitPoint | undefined) => {
    const pointsArr = nearestXs.map(nearestX => memo.memo[nearestX]);

    // Find the nearest Y point in each of the 3 X coordinates found previously
    // Worst case is O(n)
    const nearestPoints = pointsArr.map(points => points.find((v, i) => {
      if (v.coord.y > to.y) return true;

      if (v.coord.y <= to.y && (points[i + 1]?.coord.y ?? Number.MAX_SAFE_INTEGER) > to.y) {
        if (i < points.length - 1) {
          if (Math.abs(v.coord.y - to.y) < Math.abs(points[i + 1]?.coord.y - to.y)) {
            return true;
          }
        } else {
          return true;
        }
      }

      return false;
    })).filter(p => !!p) as TransitPoint[];

    // Now with 3 points in different X coordinates, find the one closest to the mouse pointer
    // O(3)
    return nearestPoints.reduce((prev, curr) => {
      if (!prev) return curr;
      if (!curr) return prev;

      const prevDist = distance(prev.coord, to);
      const currDist = distance(curr.coord, to);
      
      return prevDist < currDist ? prev : curr;
    }, nearestPoints[0]);
  };

  const pointsNearX = findNearestXs();
  if (pointsNearX === undefined) return undefined;

  return findNearestY(pointsNearX);
};
