export const Tet: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_115_22)">
        <path d="M24.4062 42.4688C20.0938 42.4688 16.9219 41.4688 14.8906 39.4688C12.8594 37.4531 11.8438 34.3594 11.8438 30.1875V12.9141H18.1719V30.0469C18.1719 32.375 18.6719 34.0781 19.6719 35.1562C20.6719 36.2344 22.2656 36.7734 24.4531 36.7734C26.0469 36.7734 27.3984 36.4688 28.5078 35.8594C29.6172 35.2344 30.4609 34.1562 31.0391 32.625C31.6172 31.0781 31.9062 28.9375 31.9062 26.2031C31.9062 23.5 31.6016 21.4844 30.9922 20.1562C30.3828 18.8125 29.375 18.1406 27.9688 18.1406C26.6094 18.1406 25.1875 18.5859 23.7031 19.4766V13.7344C25.1719 12.8594 26.9453 12.4219 29.0234 12.4219C32.3672 12.4219 34.7656 13.5156 36.2188 15.7031C37.6875 17.875 38.4219 21.3594 38.4219 26.1562C38.4219 31.8125 37.2969 35.9453 35.0469 38.5547C32.8125 41.1641 29.2656 42.4688 24.4062 42.4688Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_115_22">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
