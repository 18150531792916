import { Chart as ChartType } from '@danielpedroso/astrum-calc/dist/types';
import { SIGNS } from '@danielpedroso/astrum-calc/dist/constants';
import { Sign } from './sign';
import { House } from './house';
import { CelestialObject } from './object';
import { useMemo } from 'react';
import { Aspect } from './aspect';
import { ChartItemSelection } from './utils';
import { SquareRatio, Container, Svg } from './styled';
import { Degrees } from './degrees';

interface Props {
  chart?: ChartType;
  itemSelection: ChartItemSelection;
  setItemSelection: (selection: ChartItemSelection) => void;
}

const nav = (typeof window !== "undefined" ? window.navigator : undefined) ?? undefined;

export const BirthChart: React.FC<Props> = ({ chart, itemSelection, setItemSelection }) => {
  const planets = useMemo(() => {
    if (!chart) return [];

    const objs = Object.values(chart.planets)
      .map(({ key, ...rest }) => ({ key: key as string, ...rest, depth: 1 }))
      .concat(({
        ...chart.lunarOrbit.meanAscendingNode,
        key: 'meanAscendingNode',
        depth: 1,
        isRetrograde: false,
      })).concat(({
        ...chart.lunarOrbit.meanDescendingNode,
        key: 'meanDescendingNode',
        depth: 1,
        isRetrograde: false,
      }));
    
    for (let i = 0; i < objs.length; i++) {
      const curr = objs[i];
      curr.depth = objs
        .slice(0, i)
        .filter(o => Math.abs(o.absolute - curr.absolute) < 10.0)
        .length;
    }

    return objs;
  }, [chart]);

  const aspects = useMemo(() => {
    if (!itemSelection.item || !itemSelection.type) return chart?.aspects;
    if (itemSelection.type === 'aspect') return chart?.aspects;
    if (itemSelection.type === 'houseCusp' && !['1', '10'].includes(itemSelection.item as string)) return chart?.aspects;

    return chart?.aspects.filter((a) => {
      return a.object1.key === itemSelection.item || a.object2.key === itemSelection.item;
    });
  }, [chart?.aspects, itemSelection.item, itemSelection.type])

  const rotation = useMemo(() => {
    if (!chart?.houseCusps?.[0]) return 0;

    return chart.houseCusps[0];
  }, [chart]);

  const isSafari = !nav ? false : /^((?!chrome|android).)*safari/i.test(nav?.userAgent ?? '');

  return (
    <SquareRatio>
      <Container>
        <Svg
          id="birth-chart"
          viewBox="0 0 820 820"
          preserveAspectRatio="xMidYMid meet"
          transform={chart && !isSafari ? `rotate(${rotation})` : undefined}
          style={{ transformBox: 'fill-box', transformOrigin: 'center', transform: isSafari && chart ? `rotate(${rotation}deg)` : undefined }}
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* External circle */}
          <circle r="46%" cx="50%" cy="50%" stroke="black" fill="transparent" />

          {/* Internal circle */}
          <circle r="40%" cx="50%" cy="50%" stroke="black" fill="transparent" />

          {/* Center */}
          <circle r="20%" cx="50%" cy="50%" stroke="black" fill="transparent" />

          {/* Houses */}
          {chart?.houseCusps.map((cusp, i) => (
            <House
              key={i}
              externalRadiusMultiplier={.4}
              internalRadiusMultiplier={.2}
              extraInfoRadiusMultiplier={.46}
              cusp={cusp}
              name={`${i + 1}`}
              parentSize={820}
              rotation={rotation}
              fontSize={16}
              highPrecision={chart?.highPrecision}
            />
          ))}

          {/* Planets */}
          {planets?.map((planet) => (
            <CelestialObject
              key={planet.key}
              objKey={planet.key}
              object={planet}
              externalRadiusMultiplier={.4}
              name={planet.key}
              selection={itemSelection}
              setSelection={setItemSelection}
              parentSize={820}
              pos={planet.absolute}
              depth={planet.depth}
              rotation={rotation}
              fontSize={32}
              highPrecision={chart?.highPrecision}
            />
          ))}

          {/* Aspects */}
          {aspects?.map((aspect, i) => (
            <Aspect
              id={i}
              externalRadiusMultiplier={0.2}
              object1={aspect.object1}
              object2={aspect.object2}
              type={aspect.aspectType}
              deg={aspect.degree}
              parentSize={820}
              key={`${aspect.object1.key}${aspect.aspectType}${aspect.object2.key}`}
              selection={itemSelection}
              setSelection={setItemSelection}
            />
          ))}

          {/* Signs */}
          {SIGNS.map((name, i) => (
            <Sign
              key={name}
              externalRadiusMultiplier={.46}
              internalRadiusMultiplier={.4}
              cusp={i * 30}
              name={name}
              parentSize={820}
              rotation={rotation}
              fontSize={32}
            />
          ))}
          <Degrees parentSize={820} fontSize={18} radiusMultiplier={.4} />
        </Svg>
      </Container>
    </SquareRatio>
  );
};
