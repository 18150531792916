import React from "react";
import { Eight } from "./eight";
import { Eleven } from "./eleven";
import { Five } from "./five";
import { Four } from "./four";
import { Nine } from "./nine";
import { One } from "./one";
import { Seven } from "./seven";
import { Six } from "./six";
import { Ten } from "./ten";
import { Three } from "./three";
import { Twelve } from "./twelve";
import { Two } from "./two";

export const numbers: Record<string, React.FC<any>> = {
  '1': One,
  '2': Two,
  '3': Three,
  '4': Four,
  '5': Five,
  '6': Six,
  '7': Seven,
  '8': Eight,
  '9': Nine,
  '10': Ten,
  '11': Eleven,
  '12': Twelve,
};