import { signColourMap } from "@danielpedroso/zodiac-signs";

export type MotherLetters = 'א' | 'מ' | 'ש';
export type DoubleLetters = 'פ' | 'ר' | 'ב' | 'ד' | 'ג' | 'כ' | 'ת';
export type SimpleLetters = 'ה' | 'ו' | 'ז' | 'ח' | 'ט' | 'י' | 'ל' | 'נ' | 'ס' | 'ע' | 'צ' | 'ק';

export const motherLetters = ['א' ,'מ' ,'ש'];
export const doubleLetters = ['פ' ,'ר' ,'ב' ,'ד' ,'ג' ,'כ' ,'ת'];
export const simpleLetters = ['ה' ,'ו' ,'ז' ,'ח' ,'ט' ,'י' ,'ל' ,'נ' ,'ס' ,'ע' ,'צ' ,'ק'];
export const hebrewLetters = motherLetters.concat(doubleLetters).concat(simpleLetters);

export const letterColours: Record<MotherLetters | DoubleLetters | SimpleLetters, string> = {
  'א': signColourMap['leo'],
  'ב': signColourMap['leo'],
  'ג': signColourMap['sagittarius'],
  'ד': signColourMap['libra'],
  'ה': signColourMap['aries'],
  'ו': signColourMap['taurus'],
  'ז': signColourMap['gemini'],
  'ח': signColourMap['cancer'],
  'ט': signColourMap['leo'],
  'י': signColourMap['virgo'],
  'כ': signColourMap['aquarius'],
  'ל': signColourMap['libra'],
  'מ': signColourMap['sagittarius'],
  'נ': signColourMap['scorpio'],
  'ס': signColourMap['sagittarius'],
  'ע': signColourMap['capricorn'],
  'פ': signColourMap['aries'],
  'צ': signColourMap['aquarius'],
  'ק': signColourMap['pisces'],
  'ר': signColourMap['gemini'],
  'ש': signColourMap['aries'],
  'ת': signColourMap['capricorn'],
};

export interface Letter {
  name: string;
  hebrew: MotherLetters | DoubleLetters | SimpleLetters;
  latin: string[];
}

export interface Letters {
  areaAngle: number;
  startAngle: number;
  letters: Letter[];
}

export interface AllLetters {
  mother: Letters;
  double: Letters;
  simple: Letters;
}

const finalFormToNormal: Record<string, string> = {
  'ך': 'כ', // kaf
  'ם': 'מ', // mem
  'ן': 'נ', // nun
  'ף': 'פ', // pe
  'ץ': 'צ', // tsaddi
};

export const getNormalFormLetter = (hebrewLetter: string) => finalFormToNormal[hebrewLetter] ?? hebrewLetter;

const baseLetters: AllLetters = {
  mother: {
    areaAngle: 120,
    startAngle: 30,
    letters: [{
      name: 'Aleph',
      hebrew: 'א' as MotherLetters,
      latin: ['a'],
    }, {
      name: 'Mem',
      hebrew: 'מ' as MotherLetters,
      latin: ['m'],
    }, {
      name: 'Shin',
      hebrew: 'ש' as MotherLetters,
      latin: ['sh'],
    }],
  },
  double: {
    areaAngle: 360 / 7,
    startAngle: 90,
    letters: [{
      name: 'Peh',
      hebrew: 'פ' as DoubleLetters,
      latin: ['ph', 'f', 'p'],
    }, {
      name: 'Resh',
      hebrew: 'ר' as DoubleLetters,
      latin: ['r'],
    },
    {
      name: 'Beit',
      hebrew: 'ב' as DoubleLetters,
      latin: ['bh', 'b'],
    },
    {
      name: 'Dalet',
      hebrew: 'ד' as DoubleLetters,
      latin: ['dh', 'd'],
    },
    {
      name: 'Gimel',
      hebrew: 'ג' as DoubleLetters,
      latin: ['gh', 'g'],
    },
    {
      name: 'Tav',
      hebrew: 'ת' as DoubleLetters,
      latin: ['th'],
    }, {
      name: 'Kaf',
      hebrew: 'כ' as DoubleLetters,
      latin: ['kh', 'k', 'c'],
    }],
  },
  simple: {
    areaAngle: 360 / 12,
    startAngle: 90,
    letters: [{
      name: 'He',
      hebrew: 'ה' as SimpleLetters,
      latin: ['e', 'h'],
    },
    { 
      name: 'Vav',
      hebrew: 'ו' as SimpleLetters,
      latin: ['u', 'v', 'w'],
    },
    {
      name: 'Zayin',
      hebrew: 'ז' as SimpleLetters,
      latin: ['dz', 'z'],
    },
    {
      name: 'Chet',
      hebrew: 'ח' as SimpleLetters,
      latin: ['ch'],
    },
    {
      name: 'Tet',
      hebrew: 'ט' as SimpleLetters,
      latin: ['t'],
    },
    {
      name: 'Yod',
      hebrew: 'י' as SimpleLetters,
      latin: ['i', 'y'],
    },
    {
      name: 'Lamed',
      hebrew: 'ל' as SimpleLetters,
      latin: ['l'],
    },
    {
      name: 'Nun',
      hebrew: 'נ' as SimpleLetters,
      latin: ['n'],
    },
    {
      name: 'Samekh',
      hebrew: 'ס' as SimpleLetters,
      latin: ['s'],
    },
    {
      name: 'Ayin',
      hebrew: 'ע' as SimpleLetters,
      latin: ['o', 'aa'],
    },
    {
      name: 'Tsaddi',
      hebrew: 'צ' as SimpleLetters,
      latin: ['ts', 'tz', 'j'],
    },
    {
      name: 'Qof',
      hebrew: 'ק' as SimpleLetters,
      latin: ['q', 'qh'],
    }],
  },
};

const lettersWithAlternateDoubleEnd: AllLetters = {
  ...baseLetters,
  double: {
    ...baseLetters.double,
    letters: [{
      name: 'Peh',
      hebrew: 'פ' as DoubleLetters,
      latin: ['ph', 'f', 'p'],
    }, {
      name: 'Resh',
      hebrew: 'ר' as DoubleLetters,
      latin: ['r'],
    },
    {
      name: 'Beit',
      hebrew: 'ב' as DoubleLetters,
      latin: ['bh', 'b'],
    },
    {
      name: 'Dalet',
      hebrew: 'ד' as DoubleLetters,
      latin: ['dh', 'd'],
    },
    {
      name: 'Gimel',
      hebrew: 'ג' as DoubleLetters,
      latin: ['gh', 'g'],
    }, {
      name: 'Kaf',
      hebrew: 'כ' as DoubleLetters,
      latin: ['kh', 'k', 'c'],
    },
    {
      name: 'Tav',
      hebrew: 'ת' as DoubleLetters,
      latin: ['th'],
    }]
  }
};

export const getHebrewLetters = (doubleEndsInKaf: boolean = true): AllLetters => {
  if (doubleEndsInKaf) return baseLetters;

  return lettersWithAlternateDoubleEnd;
};

export const hebrewLettersToNames = [baseLetters.mother, baseLetters.double, baseLetters.simple].reduce((acc, curr) => {
   const currentMap = curr.letters.reduce((map, letter) => {
    return {
      ...map,
      [letter.hebrew]: letter.name,
    };
  }, {} as Record<string, string>);
  return {
    ...acc,
    ...currentMap,
  };
}, {} as Record<string, string>);

export const getHebrewLetterName = (hebrewLetter: string): string => {
  return hebrewLettersToNames[hebrewLetter];
};

export const hebrewToLatinMap = [baseLetters.mother, baseLetters.double, baseLetters.simple].reduce((acc, curr) => {
  const currentMap = curr.letters.reduce((map, letter) => {
    return {
      ...map,
      [letter.hebrew]: letter.latin,
    };
  }, {} as Record<string, string[]>);
  return {
    ...acc,
    ...currentMap,
  };
}, {} as Record<string, string[]>);

const hebrewToLatinArray = Object.entries(hebrewToLatinMap);

const maxL = Object.values(hebrewToLatinMap).reduce((acc, curr) => Math.max(curr.length, acc), 0);

const hebrewLetterFinalVersions: Record<string, string> = {
  'כ': 'ך', // kaf
  'מ': 'ם', // mem
  'נ': 'ן', // nun
  'פ': 'ף', // pe
  'צ': 'ץ', // tsaddi
}

export const hebrewOnly = (text: string): string => text.replace(/[^\u0590-\u05FF]+/gi, '');

export const transliterateToHebrew = (latin: string): string => {
  let res = latin.replace(/[^a-zA-Z]+/gi, '').toLowerCase();
  for (let i = 0; i < maxL; i++) {
    res = hebrewToLatinArray.reduce((acc, [hebrew, latin]) => {
      const currentLetter = latin[i];
      if (!currentLetter) return acc;

      const regex = new RegExp(currentLetter, 'gi');
      return acc.replace(regex, hebrew);
    }, res);
  }

  const last = res[res.length - 1];
  const asFinal = hebrewLetterFinalVersions[last] || last;
  const withFinalLetter = `${res.substring(0, res.length - 1)}${asFinal}`;
  return withFinalLetter;
};
