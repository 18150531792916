export const UranusSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        d="M 7.25,9.75 C 7.25,9.0585938 6.6914062,8.5 6,8.5 5.3085938,8.5 4.75,9.0585938 4.75,9.75 4.75,10.441406 5.3085938,11 6,11 6.6914062,11 7.25,10.441406 7.25,9.75 Z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 6,8.5 V 2.25"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 3.5,1 V 6"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 8.5,1 V 6"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="m 3.5,3.5 h 5"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />

    </svg>
  );
}