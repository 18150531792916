export const SextileSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
      <path
        d="M27 21.3431V5H23V20.3431L10.8284 8.17157L8 11L20 23H3V27H21.3431L8.17157 40.1716L11 43L23 31V46H27V30L39.8198 42.8198L42.6482 39.9914L29.6569 27H48V23H31L42.8198 11.1802L39.9914 8.35177L27 21.3431Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}
