export const Yod: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_115_24)">
        <path d="M28.4062 12.9141V28.3594H22.0781V12.9141H28.4062Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_115_24">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
