import { Box } from "@mui/material";
import { Chart } from "../../../lib/types";
import { AspectsTable, PlanetsTable } from "@danielpedroso/chart";

interface Props {
  chart?: Chart;
}

export const SummaryTable: React.FC<Props> = ({ chart }) => {
  if (!chart) return null;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ flexDirection: { xs: 'column', md: 'row' }} }
      maxWidth="100vw"
    >
      <Box sx={{ width: { xs: '100%', md: '33.8709677419355%' }} }>
        <PlanetsTable chart={chart} />
      </Box>
      <Box sx={{ width: { xs: '100%', md: '66.1290322580645%' }} }>
        <AspectsTable aspects={chart?.aspects ?? []} />
      </Box>
    </Box>
  );
};