export const Mem: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_107_3)">
        <path
          d="M28.5938 12.7734C32.5781 12.7734 35.4766 13.9453 37.2891 16.2891C39.1016 18.6172 40.0078 22.2578 40.0078 27.2109V42H24.8906V36.3281H33.6328V27.7031C33.6328 24.4062 33.2344 22.0469 32.4375 20.625C31.6562 19.1875 30.2734 18.4688 28.2891 18.4688C26.4609 18.4688 24.9219 19.3828 23.6719 21.2109C22.4219 23.0859 21.2578 26.2109 20.1797 30.5859L17.3438 42H10.5938L13.7578 29.8594C14.1484 28.3906 14.5234 27.0781 14.8828 25.9219C15.2578 24.7656 15.6172 23.7734 15.9609 22.9453L10.1484 13.2422H17.1562L19.3594 17.3672C20.2969 15.9297 21.5781 14.8047 23.2031 13.9922C24.8438 13.1797 26.6406 12.7734 28.5938 12.7734Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_3">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
