import { Fragment, useCallback, useMemo } from 'react';

interface AxisProps {
  margin: { top: number; bottom: number; left: number; right: number };
  range: number;
  direction: 'x' | 'y';
  elements: number;
  elementLabel: (element: number, direction: 'x' | 'y') => string;
}

export const Axis: React.FC<AxisProps> = ({
  margin,
  range,
  direction,
  elements,
  elementLabel,
}) => {
  const finalRange = useMemo(() => {
    return direction === 'x' ? (range - margin.left - margin.right) : (range - margin.top - margin.bottom);
  }, [direction, margin.bottom, margin.left, margin.right, margin.top, range]);

  const itemSize = useMemo(() => {
    return finalRange / (elements - 1);
  }, [elements, finalRange]);

  const tickSize = 5;

  const fixX = (x: number) => x + margin.left;
  const fixY = (y: number) => {
    if (direction === 'x') {
      return margin.top - y;
    }
    return finalRange + margin.top - y;
  };

  const getLineCoordinates = (element: number) => {
    if (direction === 'x') {
      return {
        x1: fixX(element * itemSize),
        y1: fixY(0),
        x2: fixX(element * itemSize),
        y2: fixY(-tickSize),
      };
    }

    return {
      x1: fixX(-tickSize),
      y1: fixY(element * itemSize),
      x2: fixX(0),
      y2: fixY(element * itemSize),
    };
  };

  const getTextCoordinates = (element: number) => {
    if (direction === 'x') {
      const coord = {
        x: fixX(element * itemSize - 10),
        y: fixY(-tickSize - 23),
      };
      return {
        x: coord.x,
        y: coord.y,
        transform: `rotate(-45 ${coord.x} ${coord.y})`,
      };
    }
    return {
      x: fixX(-tickSize - 24),
      y: fixY(element * itemSize),
    };
  };

  const getLabel = useCallback((element: number) => {
    if (direction === 'x') {
      return elementLabel(element, direction);
    }

    return element.toFixed(2);
  }, [elementLabel, direction]);
  
  return (
    <g>
      <line
        x1={margin.left}
        y1={margin.top}
        x2={direction === 'x' ? finalRange + margin.left : margin.left}
        y2={direction === 'x' ? margin.top : finalRange + margin.top}
        stroke="black"
      />
      {[...new Array(elements)].map((_, i) => (
        <Fragment key={i}>
          <line
            {...getLineCoordinates(i)}
            key={i}
            stroke="black"
          />
          <text {...getTextCoordinates(i)} fontWeight="200" stroke="black" strokeWidth={1} fontSize={8}>{getLabel(i)}</text>
        </Fragment>
      ))}
    </g>
  )
};