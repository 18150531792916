import { SvgIcon, SvgIconProps } from "@mui/material";

export const RevolutionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 50 50" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.13333 25.4C6.13333 32.1755 11.6245 37.6667 18.4 37.6667C19.9183 37.6667 21.372 37.3909 22.714 36.8868C20.1646 34.9562 18.2781 32.1963 17.4557 29.0085C15.8535 28.5903 14.6708 27.1331 14.6708 25.4C14.6708 23.6669 15.8535 22.2097 17.4557 21.7915C18.2781 18.6037 20.1646 15.8438 22.714 13.9132C21.372 13.4091 19.9183 13.1333 18.4 13.1333C11.6245 13.1333 6.13333 18.6245 6.13333 25.4ZM24.9 12.5467C22.9474 11.5574 20.7389 11 18.4 11C10.4463 11 4 17.4463 4 25.4C4 33.3537 10.4463 39.8 18.4 39.8C20.7389 39.8 22.9474 39.2426 24.9 38.2533C26.8526 39.2426 29.0611 39.8 31.4 39.8C39.3537 39.8 45.8 33.3537 45.8 25.4C45.8 17.4463 39.3537 11 31.4 11C29.0611 11 26.8526 11.5574 24.9 12.5467ZM24.9 14.9947C22.4011 16.5589 20.5063 18.9982 19.6446 21.8836C21.0921 22.3961 22.1292 23.7771 22.1292 25.4C22.1292 27.0229 21.0921 28.4039 19.6446 28.9164C20.5063 31.8018 22.4011 34.2411 24.9 35.8053C27.3989 34.2411 29.2937 31.8018 30.1554 28.9164C28.7079 28.4039 27.6708 27.0229 27.6708 25.4C27.6708 23.7771 28.7079 22.3961 30.1554 21.8837C29.2937 18.9982 27.3989 16.5589 24.9 14.9947ZM27.086 36.8868C29.6354 34.9562 31.5219 32.1963 32.3443 29.0085C33.9465 28.5903 35.1292 27.1331 35.1292 25.4C35.1292 23.6669 33.9465 22.2097 32.3443 21.7915C31.5219 18.6037 29.6354 15.8438 27.086 13.9132C28.428 13.4091 29.8817 13.1333 31.4 13.1333C38.1755 13.1333 43.6667 18.6245 43.6667 25.4C43.6667 32.1755 38.1755 37.6667 31.4 37.6667C29.8817 37.6667 28.428 37.3909 27.086 36.8868ZM19.1557 26.1467C19.3454 25.9548 19.4625 25.691 19.4625 25.4C19.4625 25.109 19.3454 24.8452 19.1557 24.6533C19.1409 24.9003 19.1333 25.1493 19.1333 25.4C19.1333 25.6507 19.1409 25.8997 19.1557 26.1467ZM30.6667 25.4C30.6667 25.1493 30.6591 24.9003 30.6443 24.6533C30.4546 24.8452 30.3375 25.109 30.3375 25.4C30.3375 25.691 30.4546 25.9548 30.6443 26.1467C30.6591 25.8997 30.6667 25.6507 30.6667 25.4Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
};
