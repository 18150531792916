import React, { useCallback, useMemo, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PlanetPos } from '@danielpedroso/astrum-calc/dist/types';
import { objectSymbols } from '@danielpedroso/celestial-objects';
import { zodiacSignSymbols } from '@danielpedroso/zodiac-signs';
import { getCoordinates, getLineCoordinates, getNameOfGod, getTarotCard, getDecan, keys, degToDMS } from '../utils'
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChartItemSelection } from '../utils';
import { ObjectTooltip } from './tooltip';

interface Props {
  objKey: string;
  object: Omit<PlanetPos, 'key'>;
  parentSize: number;
  externalRadiusMultiplier: number;
  pos: number;
  name: string;
  selection?: ChartItemSelection;
  setSelection?: (item: ChartItemSelection) => void;
  rotation: number;
  depth: number;
  colour?: string;
  inverted?: boolean;
  fontSize?: number;
  hideDegrees?: boolean;
  condensed?: boolean;
  highPrecision?: boolean;
}

const flipLeftHandSide = (deg: number, rotation: number): number => {
  if (deg <= (rotation / 2)) {
    return (deg + 180) % 360;
  }

  return deg;
}

export const CelestialObject: React.FC<Props> = ({
  objKey,
  object,
  parentSize,
  selection,
  setSelection,
  externalRadiusMultiplier,
  depth,
  rotation,
  fontSize = 24,
  colour = 'blue',
  inverted = false,
  hideDegrees = false,
  condensed = false,
  highPrecision = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [hovering, setHovering] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (!isMobile) setHovering(true);
  }, [isMobile]);

  const handleMouseLeave = useCallback(() => {
    if (!isMobile) setHovering(false);
  }, [isMobile]);

  const handleMouseClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSelection && setSelection({ type: 'object', item: objKey });
  }, [objKey, setSelection]);

  const handleCollapseClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setCollapsed(c => !c);
  }, [])

  const selected = useMemo(() => {
    return (selection?.item === objKey && selection?.type === 'object') || (!isMobile && hovering);
  }, [isMobile, selection?.item, selection?.type, objKey, hovering]);

  const handleMouseClickAway = useCallback(() => {
    if (selected) {
      setSelection && setSelection({ type: 'object', item: '' });
      setCollapsed(false);
    }
  }, [selected, setSelection]);

  const coords = useMemo(() => {
    const width = parentSize;
    const r = width / 2;
    const externalRadius = width * externalRadiusMultiplier;
    const deg = (object.absolute + 180) % 360;
    const correct = (n: number): number => inverted ? n * -1 : n;

    const text = getCoordinates(deg, externalRadius - correct((depth + 1) * fontSize), r);
    const degText = getCoordinates(deg, externalRadius - correct((depth + 1.8) * fontSize), r);
    const marking = getLineCoordinates(deg, externalRadius, externalRadius - correct(15), r);

    return {
      marking,
      text: {
        x: text.x - fontSize / 2,
        y: text.y - fontSize / 2,
      },
      deg: {
        x: degText.x,
        y: degText.y,
      },
    };
  }, [parentSize, externalRadiusMultiplier, object.absolute, depth, fontSize, inverted]);

  const Symbol = objectSymbols[objKey];
  if (!Symbol) throw new Error(`Unknown object: ${objKey}`);

  const decan = getDecan(object.absolute);
  const DecanSymbol = objectSymbols[decan];
  if (!DecanSymbol) throw new Error(`Unknown object: ${decan}`);

  const ZodiacSymbol = zodiacSignSymbols[object.sign];
  if (!ZodiacSymbol) throw new Error(`Unknown zodiac sign: ${object.sign}`);

  const baseColor = object.isRetrograde ? 'red' : colour;
  const tarotCard = getTarotCard(object.absolute);
  const musicalKey = keys[Math.trunc(object.absolute / 30) % 12];
  const deg = degToDMS(object.pos, highPrecision);
  const nameOfGod = getNameOfGod(object.absolute);

  return (
    <g>
      {!condensed && <line {...coords.marking} stroke={selected ? 'grey' : baseColor} strokeWidth={selected ? 4 : 2} />}
      <ClickAwayListener onClickAway={handleMouseClickAway}>
        <Tooltip
          open={selected}
          title={
            <ObjectTooltip
              collapsed={collapsed}
              colour={baseColor}
              objKey={objKey}
              object={object}
              onCollapseClick={handleCollapseClick}
              highPrecision={highPrecision}
              key={objKey}
            />
          }
          followCursor={!isMobile && !selection}
        >
          <g
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleMouseClick}
            transform={`rotate(-${rotation} ${coords.text.x + fontSize / 2} ${coords.text.y + fontSize / 2})`}
            pointerEvents="bounding-box"
          >
            <Symbol
              stroke={selected ? 'grey' : baseColor}
              fill={selected ? 'grey' : baseColor}
              strokeWidth="1px"
              {...coords.text}
              width={fontSize}
              height={fontSize}
            />
          </g>
        </Tooltip>
      </ClickAwayListener>
      {!hideDegrees && (
        <g transform={`rotate(-${flipLeftHandSide(object.absolute, rotation)} ${coords.deg.x} ${coords.deg.y})`} width={fontSize} height={fontSize}>
          <text
            {...coords.deg}
            textAnchor="middle"
            alignmentBaseline="central"
            stroke={selected ? 'grey' : baseColor}
            fontSize={12}
            opacity={selected ? 1 : 0.5}
          >
            {Math.trunc(object.pos)}º
          </text>
        </g>
      )}
    </g>
  );
}
