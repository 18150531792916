import { RectangularRatio, Container, Svg } from "./styled";
import { SvgIconProps, Typography, Tooltip } from "@mui/material";
import { objectSymbols } from "@danielpedroso/celestial-objects";
import { zodiacSignSymbols } from "@danielpedroso/zodiac-signs";
import { translateCoordinate } from '@danielpedroso/astrum-calc';
import { useTranslation } from "react-i18next";
import { BodyKey, Chart, LunarNodeType, PlanetPos, Pos } from "@danielpedroso/astrum-calc/dist/types";
import { useCallback, useMemo, useState } from "react";
import { ObjectTooltip } from "./object/tooltip";
import { labels as houseLabels } from './house/labels';
import { degToDMS } from "./utils";
import { HouseTooltip } from "./house/tooltip";
import { EXTRA_INFO_CUSP_LABEL } from "./house/utils";

interface Props {
  chart?: Chart;
}

interface SignProps extends SvgIconProps {
  sign: string;
}

const RenderSign: React.FC<SignProps> = ({ sign, ...props }) => {
  const Sign = zodiacSignSymbols[sign];
  if (!Sign) return null;
  return <Sign {...props} />;
};

interface ObjectProps extends SvgIconProps {
  obj: string;
}

const RenderObject: React.FC<ObjectProps> = ({ obj, ...props }) => {
  let Object = objectSymbols[obj];
  if (!Object) Object = houseLabels[obj];

  if (!Object) return <Typography variant="subtitle2">{obj}</Typography>;
  return <Object {...props} />;
};

interface RowProps extends Props {
  objKey: string;
  object: Omit<PlanetPos, 'key' | 'isRetrograde'>;
  index: number;
  highPrecision?: boolean;
  isPlanet?: boolean;
}

const objects = [
  'moon',
  'mercury',
  'venus',
  'sun',
  'mars',
  'jupiter',
  'saturn',
  'uranus',
  'neptune',
  'pluto',
  'meanAscendingNode',
  'meanDescendingNode',
  '1',
];

const TABLE_WIDTH = 400;
const TABLE_HEIGHT = 800;
const MARGIN = 10;
const CELL_WIDTH = TABLE_WIDTH / 3;
const CELL_HEIGHT = TABLE_HEIGHT / (objects.length + 2);

const Row: React.FC<RowProps> = ({ objKey, object, highPrecision, isPlanet, index }) => {
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  const calcCelPos = (cell: number) => {
    const x = cell * CELL_WIDTH;
    const y = CELL_HEIGHT / 2;
    return { x, y };
  };

  const POS_MARGIN = highPrecision ? MARGIN : CELL_WIDTH / 2;

  return (
    <Tooltip
      open={hovering}
      title={
        isPlanet ? (
          <ObjectTooltip
            collapsed={false}
            colour="black"
            objKey={objKey}
            object={{ ...object, isRetrograde: false }}
            onCollapseClick={() => undefined}
            highPrecision={highPrecision}
          />
        ) : (
          <HouseTooltip
            coord={object as Pos}
            cusp={object.absolute}
            label={EXTRA_INFO_CUSP_LABEL[objKey]}
            highPrecision={highPrecision}
          />
        )
      }
      followCursor={false}
    >
      <g
        transform={`translate(${MARGIN}, ${CELL_HEIGHT * index + MARGIN})`}
        pointerEvents="all"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <rect
          width={TABLE_WIDTH}
          height={CELL_HEIGHT}
          fill="none"
          stroke="rgba(224, 224, 224, 1)"
        />
        <g transform={`translate(${calcCelPos(0).x + CELL_WIDTH / 2}, ${calcCelPos(0).y - 9})`}>
          <RenderObject obj={objKey} width={18} height={18} stroke="black" />
        </g>
        <g transform={`translate(${calcCelPos(1).x + POS_MARGIN}, ${calcCelPos(1).y - 9})`}>
          <RenderSign sign={object.sign} width={18} height={18} />
          <text x={20} y={15} textAnchor="start" fontSize={16}>{degToDMS(object.pos, highPrecision)}</text>
        </g>
        {isPlanet && (
          <g transform={`translate(${calcCelPos(2).x + CELL_WIDTH / 2}, ${calcCelPos(2).y - 9})`}>
            <text x={0} y={15} textAnchor="middle" fontSize={16}>{object.house}</text>
          </g>
        )}
      </g>
    </Tooltip>
  );
};

export const PlanetsTable: React.FC<Props> = ({ chart }) => {
  const { t } = useTranslation();

  if (!chart) return null;

  const objs = useMemo(() => {
    return objects.map((obj) => {
      let object: Omit<PlanetPos, 'key' | 'isRetrograde'> = chart.planets[obj as BodyKey];
      
      if (!object) object = chart.lunarOrbit[obj as LunarNodeType];
      if (!object) object = translateCoordinate(chart.houseCusps[parseInt(obj, 10) - 1], []);

      return { objKey: obj, object }
    });
  }, [chart]);

  return (
    <RectangularRatio>
      <Container>
        <Svg
          id="planets-table"
          viewBox="0 0 420 820"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
        >
          {objs.map((obj, index) => (
            <Row
              key={obj.objKey}
              highPrecision={chart.highPrecision}
              object={obj.object}
              objKey={obj.objKey}
              isPlanet={index < 12}
              index={index + 1}
            />
          ))}
        </Svg>
      </Container>
    </RectangularRatio>
  );
}