import { SetStateAction } from 'react';
import moment from 'moment-timezone';
import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { Aspect, Chart } from '../../lib/types';
import { ChartItemSelection } from '@danielpedroso/chart/dist/utils';
import { getPlanetaryHours, getSolarInfo, PlanetaryHours, SolarLunarInfo } from '@danielpedroso/astrum-calc/dist/planetary-magick';
import { locationsAtom } from '../../core/location/state';

interface ChartState {
  isoDate: string;
  locationId: string;
}

const initialState: ChartState = {
  isoDate: new Date().toISOString(),
  locationId: 'BNE',
};
const initialStr = JSON.stringify(initialState);

const STORAGE_KEY = 'ASTRUM_CHART';

const strStateAtom = atom(localStorage.getItem(STORAGE_KEY) ?? initialStr);

export const chartStateAtom = atom(
  (get) => {
    const str = get(strStateAtom);

    try {
      return JSON.parse(str)
    } catch {
      return JSON.parse(initialStr);
    }
  },
  (get, set, newState: SetStateAction<ChartState>) => {
    let newV: ChartState;
    if (typeof newState === 'function') {
      newV = newState(get(chartStateAtom));
    } else {
      newV = newState;
    }

    const newVal = JSON.stringify(newV ?? initialState);
    set(strStateAtom, newVal);
    localStorage.setItem(STORAGE_KEY, newVal);
  }
);

export const locationAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const locations = get(locationsAtom);
  return locations.find(l => l.id === chartState.locationId ?? 'BNE') ?? locations[0];
});

export const chartAtom = atom<Chart | undefined>(undefined);

export const chartItemSelectionAtom = atom<ChartItemSelection>({ type: 'object', item: '' });

export const chartAspectsAtom = focusAtom(chartAtom, (optic) => optic.optional().prop('aspects'));

interface PlanetaryInfo {
  curr: SolarLunarInfo;
  next: SolarLunarInfo;
  planetaryHours: PlanetaryHours;
}

export const planetaryInfoAtom = atom<PlanetaryInfo | undefined>(
  (get) => {
    const chartState = get(chartStateAtom);
    const location = get(locationAtom);
    if (!location) return undefined;

    const curr = getSolarInfo(moment.tz(chartState.isoDate, location.tz), location.lat, location.lon);
    const next = getSolarInfo(moment.tz(chartState.isoDate, location.tz).add(1, 'day'), location.lat, location.lon);
    const planetaryHours = getPlanetaryHours(curr, next);

    return { curr, next, planetaryHours };
  },
);

interface FilteredAspect extends Aspect {
  ogIndex: number;
}

export const filteredAspectsAtom = atom(
  (get) => {
    const selection = get(chartItemSelectionAtom);
    const aspects = get(chartAspectsAtom);
    const mapped = aspects?.map((a, i): FilteredAspect => ({ ...a, ogIndex: i }));

    if (!selection.type) return mapped;
    if (!selection.item) return mapped;
    if (selection.type === 'aspect') return mapped;
    if (selection.type === 'houseCusp' && !['1', '10'].includes(selection.item as string)) return mapped;

    const filtered = mapped?.filter((a: FilteredAspect) =>
      a.object1.key === selection.item || a.object2.key === selection.item);

    return filtered;
  }
);
