export const OppositionSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
      <path
        d="M34 21C36.7614 21 39 18.7614 39 16C39 13.2386 36.7614 11 34 11C31.2386 11 29 13.2386 29 16C29 18.7614 31.2386 21 34 21ZM34 25C38.9706 25 43 20.9706 43 16C43 11.0294 38.9706 7 34 7C29.0294 7 25 11.0294 25 16C25 17.7277 25.4868 19.3417 26.3308 20.7123L20.7123 26.3308C19.3417 25.4868 17.7277 25 16 25C11.0294 25 7 29.0294 7 34C7 38.9706 11.0294 43 16 43C20.9706 43 25 38.9706 25 34C25 32.2056 24.4749 30.5338 23.5699 29.1301L29.1301 23.5699C30.5338 24.4749 32.2056 25 34 25ZM16 39C18.7614 39 21 36.7614 21 34C21 31.2386 18.7614 29 16 29C13.2386 29 11 31.2386 11 34C11 36.7614 13.2386 39 16 39Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}
