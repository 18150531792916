export const Resh: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_113_2)">
        <path d="M34.8125 25.1484V42H28.4609V25.1016C28.4609 22.6797 27.9375 20.9922 26.8906 20.0391C25.8594 19.0703 24.1172 18.5859 21.6641 18.5859H11.9141V12.9141H22.0391C25.1172 12.9141 27.5859 13.3281 29.4453 14.1562C31.3203 14.9688 32.6797 16.2734 33.5234 18.0703C34.3828 19.8516 34.8125 22.2109 34.8125 25.1484Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_113_2">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
