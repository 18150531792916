import { useCallback, useState } from 'react';
import { CompletePentagrams } from './completed';
import { PentagramAnimation } from './drawing';

interface Props {
  onNext: () => void;
}

const stages = [{
  top: 'Facing East, trace the first pentagram. The pentagram should be from the top of your head down to your waist.',
  bottom: 'Place your hand in the center of the pentagram and vibrate:',
  name: 'YHVH',
}, {
  top: 'Start drawing a circle by holding your hand in front of you and turning 90 degrees clockwise to face South. Trace the second pentagram.',
  bottom: 'Place your hand in the center of the pentagram and vibrate:',
  name: 'ADONAI',
}, {
  top: 'Continue the circle by turning 90 degrees clockwise to face West. Trace the third pentagram.',
  bottom: 'Place your hand in the center of the pentagram and vibrate:',
  name: 'EHEIEH',
}, {
  top: 'Continue the circle with another 90 degrees clockwise motion to face West. Trace the third pentagram.',
  bottom: 'Place your hand in the center of the pentagram and vibrate:',
  name: 'AGLA',
}];

const Pentagram: React.FC<{ stage: number }> = ({ stage }) => {
  if (stage >= stages.length) return <CompletePentagrams />;

  return (
    <>
      <p>{stages[stage].top}</p>
      <PentagramAnimation />
      <p>{stages[stage].bottom}</p>
      <h1>{stages[stage].name}</h1>
    </>
  );
}

export const PentagramGuide: React.FC<Props> = ({ onNext }) => {
  const [stage, setStage] = useState(0);
  const handleNextClick = useCallback(() => {
    setStage(s => {
      const ns = s + 1;
      if (ns > stages.length) {
        onNext();
      }

      return ns % (stages.length + 1);
    });
  }, [onNext]);

  return (
    <>
      {stage > 0 && <button onClick={() => setStage(s => s - 1)}>&lt;-</button>}
      <Pentagram key={stage} stage={stage} />
      <button onClick={handleNextClick}>-&gt;</button>
    </>
  )
};