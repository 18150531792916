import { useAtom } from "jotai";
import { aspectsAtom } from "./state";
import { TransitChart } from "./transit-chart";

export const Chart: React.FC = () => {
  const [aspects] = useAtom(aspectsAtom);

  return (
    <TransitChart data={aspects} />
  );
};
