export const Samekh: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6)">
        <path d="M11.1172 27.3047C11.1172 26.2734 11.2188 25.2031 11.4219 24.0938C11.6406 22.9688 11.9375 21.9219 12.3125 20.9531C12.6875 19.9688 13.125 19.1797 13.625 18.5859H9.19531V12.9141H25.4609C30.1641 12.9141 33.5703 13.9062 35.6797 15.8906C37.8047 17.875 38.8672 21.0703 38.8672 25.4766V27.3516C38.8672 32.3359 37.6641 36.1016 35.2578 38.6484C32.8516 41.1953 29.3047 42.4688 24.6172 42.4688C20.0234 42.4688 16.625 41.2734 14.4219 38.8828C12.2188 36.4766 11.1172 32.8594 11.1172 28.0312V27.3047ZM19.1328 18.5859C18.1797 20.2422 17.7031 23.0234 17.7031 26.9297V27.9844C17.7031 30.9219 18.2891 33.1328 19.4609 34.6172C20.6328 36.0859 22.3984 36.8203 24.7578 36.8203C27.3828 36.8203 29.2812 36.0625 30.4531 34.5469C31.6406 33.0312 32.2344 30.5703 32.2344 27.1641V25.4297C32.2344 22.9922 31.6641 21.2422 30.5234 20.1797C29.3984 19.1172 27.5547 18.5859 24.9922 18.5859H19.1328Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_120_6">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
