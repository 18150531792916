export const TrineSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
      <path
        d="M9.47853 38.75L25.5 11L41.5215 38.75H9.47853Z"
        stroke="currentColor"
        strokeWidth="4"
      />
    </svg>
  );
}
