import { Box, CssBaseline } from '@mui/material';
import { AppBar, useAppBarHeight } from '../app-bar';

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => {
  const appBarHeight = useAppBarHeight();

  return (
    <Box display="flex" width="100vw" height="100vh" flexDirection="column" overflow="hidden">
      <CssBaseline />
      <AppBar />
      <Box flexGrow={1} sx={{ overflowX: 'hidden', overflowY: 'auto', marginTop: `${appBarHeight}px` }}>
        {children}
      </Box>
    </Box>
  );
};