import { useMemo } from 'react';
import { AspectTooltip } from '../aspect-tooltip';
import { AspectData } from "../../state";
import { Point } from "../point";
import { Aspect } from '../../../../lib/types';
import { TransitPoint } from '../types';
import { selectedPointAtom } from '../state';
import { useAtom } from 'jotai';

interface TransitProps {
  id: string;
  raw: AspectData;
  transit: TransitPoint[];
  colour: string;
  margin: { top: number; bottom: number; left: number; right: number };
  width: number;
  height: number;
  xElements: number;
  yElements: number;
}

interface Coord {
  x: number;
  y: number;
}

const line = (p1: Coord, p2: Coord) => {
  const lengthX = p2.x - p1.x;
  const lengthY = p2.y - p1.y;

  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPoint = (current: Coord, previous?: Coord, next?: Coord, reverse?: boolean, smoothing: number = 0.2): Coord => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current;
  // Properties of the opposed-line
  const o = line(p, n);
  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;
  // The control point position is relative to the current point
  const x = current.x + Math.cos(angle) * length;
  const y = current.y + Math.sin(angle) * length;
  return { x, y };
};

const bezier = (point: Coord, i: number, a: { coord: Coord }[]) => {
  // start control point
  const { x: cpsX, y: cpsY } = controlPoint(a[i - 1]?.coord, a[i - 2]?.coord, point);
  // end control point
  const { x: cpeX, y: cpeY } = controlPoint(point, a[i - 1]?.coord, a[i + 1]?.coord, true);
  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point.x},${point.y}`;
}

export const Transit: React.FC<TransitProps> = ({
  id,
  transit,
  colour,
}) => {
  const [selectedPoint] = useAtom(selectedPointAtom);

  const line = useMemo(() => {
    return transit.reduce((acc, curr, i, a) => {
      if (i === 0) return `M ${curr.coord.x},${curr.coord.y}`;
      if (i < 3) return `${acc} L ${curr.coord.x} ${curr.coord.y}`;
      if (i > a.length - 3) return `${acc} L ${curr.coord.x} ${curr.coord.y}`;
      return `${acc} ${bezier(curr.coord, i, a)}`; /*bezier(curr, i, a)*/
    }, '');
  }, [transit]);

  return (
    <g>
      <path stroke={colour} d={line} fill={colour} fillOpacity={0.2} pointerEvents="none" />
      {transit.map((t, i) => (
        <AspectTooltip
          open={selectedPoint?.coord.x === t.coord.x && selectedPoint?.coord.y === t.coord.y}
          key={`${id}-${i}`}
          date={t.data.date}
          aspect={t.data.aspect as Aspect}
        >
          <Point
            colour={colour}
            x={t.coord.x}
            y={t.coord.y}
          />
        </AspectTooltip>
      ))}

    </g>
  );
};