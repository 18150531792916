"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcEpsilon = exports.attachApparentLongitudes = exports.decimalDegreesToDMSString = exports.timeInJulianCenturies = exports.mod = exports.angles = exports.deltap = exports.showrd = exports.showcor = exports.dms = exports.hms = exports.tanh = exports.cosh = exports.sinh = exports.zatan2 = void 0;
const constants_1 = require("../constants");
const zatan2 = (x, y) => {
    var z, w; // double
    var code; // short
    code = 0;
    if (x < 0.0) {
        code = 2;
    }
    if (y < 0.0) {
        code |= 1;
    }
    if (x === 0.0) {
        if (code & 1) {
            return 1.5 * Math.PI;
        }
        if (y === 0.0) {
            return 0.0;
        }
        return 0.5 * Math.PI;
    }
    if (y === 0.0) {
        if (code & 2) {
            return Math.PI;
        }
        return 0.0;
    }
    switch (code) {
        default:
        case 0:
            w = 0.0;
            break;
        case 1:
            w = 2.0 * Math.PI;
            break;
        case 2:
        case 3:
            w = Math.PI;
            break;
    }
    z = Math.atan(y / x);
    return w + z;
};
exports.zatan2 = zatan2;
// Hyperbolic sine
const sinh = (x) => {
    return (Math.exp(x) - Math.exp(-x)) / 2;
};
exports.sinh = sinh;
// Hyperbolic cosine
const cosh = (x) => {
    return (Math.exp(x) + Math.exp(-x)) / 2;
};
exports.cosh = cosh;
// Hyperbolic tangent
const tanh = (x) => {
    return (Math.exp(x) - Math.exp(-x)) / (Math.exp(x) + Math.exp(-x));
};
exports.tanh = tanh;
// Convert radians to hours-minutes-seconds-milliseconds
const hms = (x) => {
    var h, m; // int
    var sint, sfrac; // long
    var s; // double
    var result;
    s = x * constants_1.RADIANS_TO_HRS;
    if (s < 0.0) {
        s += 24.0;
    }
    h = Math.floor(s);
    s -= h;
    s *= 60;
    m = Math.floor(s);
    s -= m;
    s *= 60;
    /* Handle shillings and pence roundoff. */
    sfrac = Math.floor(1000.0 * s + 0.5);
    if (sfrac >= 60000) {
        sfrac -= 60000;
        m += 1;
        if (m >= 60) {
            m -= 60;
            h += 1;
        }
    }
    sint = Math.floor(sfrac / 1000);
    sfrac -= Math.floor(sint * 1000);
    result = {
        hours: h,
        minutes: m,
        seconds: sint,
        milliseconds: sfrac,
    };
    return result;
};
exports.hms = hms;
/*
 * Get degree, minutes and second from radians
 */
const dms = (x) => {
    var s; // double
    var d, m; // int
    s = x * constants_1.RADIANS_TO_DEG;
    if (s < 0.0) {
        s = -s;
    }
    d = Math.floor(s);
    s -= d;
    s *= 60;
    m = Math.floor(s);
    s -= m;
    s *= 60;
    return {
        degree: d,
        minutes: m,
        seconds: s,
    };
};
exports.dms = dms;
/* Display magnitude of correction vector
 * in arc seconds
 */
const showcor = (p, dp, result) => {
    var p1 = [0, 0, 0]; // dr, dd; // double
    var i; // int
    var d;
    for (i = 0; i < 3; i++) {
        p1[i] = p[i] + dp[i];
    }
    d = (0, exports.deltap)(p, p1);
    result = result || { dRA: 0, dDec: 0 };
    result.dRA = constants_1.RADIANS_TO_ARC_SEC * d.dr / 15.0;
    result.dDec = constants_1.RADIANS_TO_ARC_SEC * d.dd;
    return result;
};
exports.showcor = showcor;
/* Display Right Ascension and Declination
 * from input equatorial rectangular unit vector.
 * Output vector pol[] contains R.A., Dec., and radius.
 */
const showrd = (p, pol, result) => {
    var x, y, r; // double
    var i; // int
    r = 0.0;
    for (i = 0; i < 3; i++) {
        x = p[i];
        r += x * x;
    }
    r = Math.sqrt(r);
    x = (0, exports.zatan2)(p[0], p[1]);
    pol[0] = x;
    y = Math.asin(p[2] / r);
    pol[1] = y;
    pol[2] = r;
    result = { ...(result !== null && result !== void 0 ? result : {}), ...{
            dRA: x,
            dDec: y,
            ra: (0, exports.hms)(x),
            dec: (0, exports.dms)(y)
        } };
    return result;
};
exports.showrd = showrd;
/*
 * Convert change in rectangular coordinatates to change
 * in right ascension and declination.
 * For changes greater than about 0.1 degree, the
 * coordinates are converted directly to R.A. and Dec.
 * and the results subtracted.  For small changes,
 * the change is calculated to first order by differentiating
 *   tan(R.A.) = y/x
 * to obtain
 *    dR.A./cos**2(R.A.) = dy/x  -  y dx/x**2
 * where
 *    cos**2(R.A.)  =  1/(1 + (y/x)**2).
 *
 * The change in declination arcsin(z/R) is
 *   d asin(u) = du/sqrt(1-u**2)
 *   where u = z/R.
 *
 * p0 is the initial object - earth vector and
 * p1 is the vector after motion or aberration.
 *
 */
const deltap = (p0, p1, d) => {
    var dp = [], A, B, P, Q, x, y, z; // double
    var i; // int
    d = d || { dr: 0, dd: 0 };
    P = 0.0;
    Q = 0.0;
    z = 0.0;
    for (i = 0; i < 3; i++) {
        x = p0[i];
        y = p1[i];
        P += x * x;
        Q += y * y;
        y = y - x;
        dp[i] = y;
        z += y * y;
    }
    A = Math.sqrt(P);
    B = Math.sqrt(Q);
    if ((A < 1.e-7) || (B < 1.e-7) || (z / (P + Q)) > 5.e-7) {
        P = (0, exports.zatan2)(p0[0], p0[1]);
        Q = (0, exports.zatan2)(p1[0], p1[1]);
        Q = Q - P;
        while (Q < -Math.PI) {
            Q += 2.0 * Math.PI;
        }
        while (Q > Math.PI) {
            Q -= 2.0 * Math.PI;
        }
        d.dr = Q;
        P = Math.asin(p0[2] / A);
        Q = Math.asin(p1[2] / B);
        d.dd = Q - P;
        return d;
    }
    x = p0[0];
    y = p0[1];
    if (x === 0.0) {
        d.dr = 1.0e38;
    }
    else {
        Q = y / x;
        Q = (dp[1] - dp[0] * y / x) / (x * (1.0 + Q * Q));
        d.dr = Q;
    }
    x = p0[2] / A;
    P = Math.sqrt(1.0 - x * x);
    d.dd = (p1[2] / B - x) / P;
    return d;
};
exports.deltap = deltap;
/* Sun - object - earth angles and distances.
 * q (object), e (earth), and p (q minus e) are input vectors.
 * The answers are posted in the following global locations:
 */
const angles = (p, q, e, body) => {
    // NOTE mutates body
    var a, b, s; // double
    var i; // int
    body.locals.EO = 0.0;
    body.locals.SE = 0.0;
    body.locals.SO = 0.0;
    body.locals.pq = 0.0;
    body.locals.ep = 0.0;
    body.locals.qe = 0.0;
    for (i = 0; i < 3; i++) {
        a = e[i];
        b = q[i];
        s = p[i];
        body.locals.EO += s * s;
        body.locals.SE += a * a;
        body.locals.SO += b * b;
        body.locals.pq += s * b;
        body.locals.ep += a * s;
        body.locals.qe += b * a;
    }
    body.locals.EO = Math.sqrt(body.locals.EO); /* Distance between Earth and object */
    body.locals.SO = Math.sqrt(body.locals.SO); /* Sun - object */
    body.locals.SE = Math.sqrt(body.locals.SE); /* Sun - earth */
    /* Avoid fatality: if object equals sun, SO is zero.  */
    if (body.locals.SO > 1.0e-12) {
        body.locals.pq /= body.locals.EO * body.locals.SO; /* cosine of sun-object-earth */
        body.locals.qe /= body.locals.SO * body.locals.SE; /* cosine of earth-sun-object */
    }
    body.locals.ep /= body.locals.SE * body.locals.EO; /* -cosine of sun-earth-object */
    return body;
};
exports.angles = angles;
// Mods even negative numbers
const mod = (x, mod) => (x % mod + mod) % mod;
exports.mod = mod;
const timeInJulianCenturies = (julianDate) => {
    return (julianDate - constants_1.JAN_2000) / 36525.0;
};
exports.timeInJulianCenturies = timeInJulianCenturies;
const decimalDegreesToDMSString = (decimalDegrees) => {
    // converts decimal degrees to degrees / minutes / seconds
    // https://stackoverflow.com/a/5786627/6826976
    // * float decimalDegrees
    // => returns { degrees, minutes, seconds }
    let degrees = Math.floor(decimalDegrees);
    let minfloat = (decimalDegrees - degrees) * 60;
    let minutes = Math.floor(minfloat);
    let secfloat = (minfloat - minutes) * 60;
    let seconds = Math.round(secfloat);
    // After rounding, the seconds might become 60. These two
    // if-tests are not necessary if no rounding is done.
    if (seconds === 60) {
        minutes++;
        seconds = 0;
    }
    if (minutes === 60) {
        degrees++;
        minutes = 0;
    }
    return degrees + '\u00B0' +
        minutes + '\'' +
        Math.floor(seconds) + '"';
};
exports.decimalDegreesToDMSString = decimalDegreesToDMSString;
const attachApparentLongitudes = (object, decimalDegrees) => {
    object.apparentLongitude = decimalDegrees;
    object.apparentLongitudeString = (0, exports.decimalDegreesToDMSString)(object.apparentLongitude);
    object.apparentLongitude30String = (0, exports.decimalDegreesToDMSString)((0, exports.mod)(object.apparentLongitude, 30));
    return object;
};
exports.attachApparentLongitudes = attachApparentLongitudes;
const calcEpsilon = (julianDate) => {
    let T;
    T = (0, exports.timeInJulianCenturies)(julianDate);
    /* DE403 values. */
    T /= 10.0;
    let eps = (((((((((2.45e-10 * T + 5.79e-9) * T + 2.787e-7) * T
        + 7.12e-7) * T - 3.905e-5) * T - 2.4967e-3) * T
        - 5.138e-3) * T + 1.9989) * T - 0.0175) * T - 468.33960) * T
        + 84381.406173;
    eps *= constants_1.ARC_SEC_TO_RADIANS;
    const coseps = Math.cos(eps);
    const sineps = Math.sin(eps);
    const jdeps = julianDate;
    return {
        jdeps: jdeps,
        eps: eps,
        coseps: coseps,
        sineps: sineps,
    };
};
exports.calcEpsilon = calcEpsilon;
