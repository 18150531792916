import React, { useCallback, useMemo, useState } from "react";
import { getHebrewLetters, doubleLetters, getNormalFormLetter, motherLetters, simpleLetters } from "@danielpedroso/hebrew";
import { getCoordinates, getLineCoordinates } from "../utils";
import { Aleph } from "./letters/aleph";
import { letterIcons } from './letters';

interface Props {
  parentSize: number;
  internalRadiusMultiplier: number;
  name: string;
  rotation?: number;
  mode: 'edit' | 'view';
  doubleLettersEndInKaf?: boolean;
  layerSize?: number;
}

interface Coord {
  x: number;
  y: number;
}

export const Rose: React.FC<Props> = ({
  parentSize,
  internalRadiusMultiplier,
  layerSize = .1,
  name,
  rotation = 0,
  doubleLettersEndInKaf = true,
  mode,
}) => {
  const [sigilPoints, setSigilPoints] = useState<Coord[]>([]);
  const [movingPoint, setMovingPoint] = useState<number | undefined>(undefined);
  const allLetters = useMemo(() => getHebrewLetters(doubleLettersEndInKaf), [doubleLettersEndInKaf]);

  const radii = useMemo(() => {
    const width = parentSize;
    const r = width / 2;
    const layerHeight = width * layerSize;

    const mother = {
      internal: width * internalRadiusMultiplier,
      external: width * (internalRadiusMultiplier + layerSize),
    };
    const double = {
      internal: mother.external,
      external: mother.external + layerHeight,
    };
    const simple = {
      internal: double.external,
      external: double.external + layerHeight,
    };
    return { mother, double, simple, parent: { width, r } };
  }, [parentSize, internalRadiusMultiplier, layerSize]);

  const asLetters = useMemo(() => {
    const res = name.split('').map(getNormalFormLetter);
    return res;
  }, [name]);

  const letterCount = useMemo(() => {
    return asLetters.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: (acc[curr] ?? 0) + 1,
      };
    }, {} as Record<string, number>);
  }, [asLetters]);

  const calculatedPoints = useMemo(() => {
    const memo: Record<string, number> = {};
    const points = asLetters.map(l => {
      let type: 'mother' | 'double' | 'simple';
      if (motherLetters.includes(l)) {
        type = 'mother';
      } else if (doubleLetters.includes(l)) {
        type = 'double';
      } else if (simpleLetters.includes(l)) {
        type = 'simple';
      } else {
        throw new Error(`Unknown letter: ${l}`);
      }

      const seen = memo[l] ?? 0;
      const offset = (allLetters[type].areaAngle / ((letterCount[l] ?? 0) + 1)) * (seen + 1);
      const rotation = allLetters[type].areaAngle * ((allLetters[type].letters.findIndex(le => le.hebrew === l) ?? 0));
      const pointRadius = radii[type].external - (layerSize * parentSize / 2);
      memo[l] = seen + 1;
      return {
        ...getCoordinates(allLetters[type].startAngle + rotation + offset, pointRadius, radii.parent.r),
        letterType: type,
        letter: l,
        seen,
        offset,
        rotation,
        pointRadius,
      };
    });

    const regeneratePoint = (point: typeof points[0], count: number) => {
      if (count > 1) {
        let newRadius = radii[point.letterType].external - 10;
        let newAngle = allLetters[point.letterType].startAngle + point.rotation + point.offset;
        if (movedHorizontally.has(point.letter)) {
          newRadius = radii[point.letterType].external - (layerSize * parentSize) + 10;
          newAngle += 10;
        }
        movedHorizontally.add(point.letter)

        return {
          ...point,
          ...getCoordinates(newAngle, newRadius, radii.parent.r),
        };
      } else {
        const newAngle = allLetters[point.letterType].startAngle + point.rotation + point.offset - (allLetters[point.letterType].areaAngle / 8);
        return {
          ...point,
          ...getCoordinates(newAngle, point.pointRadius, radii.parent.r),
        };
      }
    };

    // Ensure no two lines connected by the points will overlap
    const movedHorizontally = new Set<string>();
    for (let i = 0; i < points.length - 2; i++) {
      const slope1 = (points[i + 1].y - points[i].y) / (points[i + 1].x - points[i].x);
      const slope2 = (points[i + 2].y - points[i + 1].y) / (points[i + 2].x - points[i + 1].x);
      if (Math.abs(Math.abs(slope1) - Math.abs(slope2)) < 0.1) {
        // Recalculate the points i, i + 1 and i + 2 so they move and avoid the overlap - ensure the point stays within the area of the letter
        const [p1, p2, p3] = [points[i], points[i + 1], points[i + 2]];
        const [t1, t2, t3] = [letterCount[p1.letter], letterCount[p2.letter], letterCount[p3.letter]];

        points[i] = regeneratePoint(p1, t1);
        points[i + 1] = regeneratePoint(p2, t2);
        points[i + 2] = regeneratePoint(p3, t3);
      }
    }

    setSigilPoints(points);
    return points;
  }, [asLetters, allLetters, letterCount, radii, layerSize, parentSize]);

  const sigil = useMemo(() => {
    const pts = sigilPoints.reduce((acc, curr, i) => {
      if (i === 0) return `M ${curr.x},${curr.y}`;
      return `${acc} L ${curr.x} ${curr.y}`;
    }, '');

    const [prev, last] = sigilPoints.slice(sigilPoints.length - 2, sigilPoints.length);
    if (prev && last) {
      const slope = Math.atan2(last.y - prev.y, last.x - prev.x);
      const dist = 20;

      const perpendicular = `M ${Math.sin(slope) * dist + last.x},${-Math.cos(slope) * dist + last.y} L ${-Math.sin(slope) * dist + last.x} ${Math.cos(slope) * dist + last.y}`;

      return `${pts} ${perpendicular}`;
    }

    return pts;
  }, [sigilPoints]);

  const handleMouseDown = useCallback((index: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setMovingPoint(index);
  }, []);

  const handleMouseUp = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setMovingPoint(undefined);
  }, []);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    if (movingPoint === undefined) return;
    e.stopPropagation();
    e.preventDefault();

    setSigilPoints(p => {
      const n = { ...p[movingPoint], x: p[movingPoint].x + e.movementX, y: p[movingPoint].y + e.movementY };
      return [
        ...p.slice(0, movingPoint),
        n,
        ...p.slice(movingPoint + 1),
      ];
    });
  }, [movingPoint]);

  const roseColour = mode === 'view' ? 'transparent' : 'black';
  const pointColour = mode === 'view' ? 'transparent' : '#eaeaea';

  const drawLetter = (i: number, letter: string, startAngle: number, areaAngle: number, externalRadius: number, internalRadius: number) => {
    const angle = startAngle + (areaAngle * i);
    const letterHeight = (externalRadius - internalRadius) / 2;
    const letterPos = getCoordinates(angle + (areaAngle / 2), internalRadius + letterHeight, radii.parent.r);
    const Icon = letterIcons[letter] ?? Aleph;
    return (
      <g key={`${i}-${letter}`}>
        <line
          {...getLineCoordinates(
            angle,
            externalRadius,
            internalRadius,
            radii.parent.r
          )}
          stroke={roseColour}
        />
        <Icon x={letterPos.x - 25} y={letterPos.y - 25} opacity={0.5} color={roseColour} />
      </g>
    );
  };

  return (
    <g
      onMouseUp={movingPoint !== undefined ? handleMouseUp : undefined}
      style={{ transformBox: 'fill-box', transformOrigin: 'center center' }}
      transform={rotation ? `rotate(${rotation})` : undefined}
    >
      <circle r={radii.simple.external} cx="50%" cy="50%" stroke={roseColour} fill="transparent" />
      {allLetters.simple.letters.map((l, i) => drawLetter(i, l.hebrew, allLetters.simple.startAngle, allLetters.simple.areaAngle, radii.simple.external, radii.simple.internal))}
      <circle r={radii.double.external} cx="50%" cy="50%" stroke={roseColour} fill="transparent" />
      {allLetters.double.letters.map((l, i) => drawLetter(i, l.hebrew, allLetters.double.startAngle, allLetters.double.areaAngle, radii.double.external, radii.double.internal))}
      <circle r={radii.mother.external} cx="50%" cy="50%" stroke={roseColour} fill="transparent" />
      {allLetters.mother.letters.map((l, i) => drawLetter(i, l.hebrew, allLetters.mother.startAngle, allLetters.mother.areaAngle, radii.mother.external, radii.mother.internal))}
      <circle r={radii.mother.internal} cx="50%" cy="50%" stroke={roseColour} fill="transparent" />
      <path stroke="black" d={sigil} fill="none" strokeWidth={15} strokeLinejoin="bevel" pointerEvents="none" />
      {mode === 'view' && sigilPoints[0] && <circle r={10} cx={sigilPoints[0].x} cy={sigilPoints[0].y} stroke="black" strokeWidth={10} fill="white" />}
      {mode === 'edit' && sigilPoints.map((p, i) => (
        <g
          onMouseDown={handleMouseDown(i)}
          onMouseUp={movingPoint !== undefined ? handleMouseUp : undefined}
          onMouseMove={movingPoint !== undefined ? handleMouseMove : undefined}
          pointerEvents="bounding-box"
        >
          <circle r={10} cx={p.x} cy={p.y} stroke="white" strokeWidth={1} fill={pointColour} />
        </g>
      ))}
    </g>
  );
};
