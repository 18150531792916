export const MercurySymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        d="M 8,5 C 8,3.8945312 7.1054688,3 6,3 4.8945312,3 4,3.8945312 4,5 4,6.1054688 4.8945312,7 6,7 7.1054688,7 8,6.1054688 8,5 Z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 4,1 C 4,2.1054688 4.8945312,3 6,3 7.1054688,3 8,2.1054688 8,1"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 6,11 V 7"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 4,9 H 8"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
}