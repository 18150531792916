import { atom, SetStateAction } from 'jotai';
import { throttle } from 'lodash';

const STORAGE_KEY = 'ASTRUM_RECENT_DATES';

const strStateAtom = atom(localStorage.getItem(STORAGE_KEY) ?? '[]');
const throttledSave = throttle((key: string, val: string) => localStorage.setItem(key, val), 3000, { leading: false });

export const recentDatesAtom = atom(
  (get) => {
    const str = get(strStateAtom);

    try {
      return JSON.parse(str)
    } catch {
      return [];
    }
  },
  (get, set, newState: SetStateAction<string[]>) => {
    let newV: string[];
    if (typeof newState === 'function') {
      newV = newState(get(recentDatesAtom));
    } else {
      newV = newState;
    }

    const newVal = JSON.stringify(newV ?? []);
    set(strStateAtom, newVal);
    throttledSave(STORAGE_KEY, newVal);
  }
);
