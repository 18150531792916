export const Kaf: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_114_10)">
        <path
          d="M35.9062 28.0312C35.9062 37.3438 31.1562 42 21.6562 42H13.875V36.3281H21.3047C24.1016 36.3281 26.1719 35.625 27.5156 34.2188C28.8594 32.8125 29.5312 30.6719 29.5312 27.7969V27.1641C29.5312 24.2422 28.8594 22.0859 27.5156 20.6953C26.1719 19.2891 24.1328 18.5859 21.3984 18.5859H13.875V12.9141H21.9844C31.2656 12.9141 35.9062 17.5547 35.9062 26.8359V28.0312Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_114_10">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
