import { useAtom } from 'jotai';
import { Suspense, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { chartStateAtom, chartLocationAtom, transitLocationAtom, transitTargetAtom } from './state';
import { useTranslation } from 'react-i18next';
import { BodyKey } from '../../lib/types';
import { DateTimeInput } from '../../core/datetime';
import { SelectLocation } from '../../core/location/select';
import { locationsAtom, Location } from '../../core/location/state';
import { switchTimezones } from '../../core/datetime/utils';
import { Chart } from './chart';

const transitsWith: BodyKey[] = ['sun', 'mercury', 'venus', 'mars', 'jupiter', 'saturn', 'uranus', 'neptune', 'pluto'];
interface OptionType {
  id: BodyKey;
  label: string;
}

export const RevolutionPage: React.FC = () => {
  const { t } = useTranslation();
  const [chartState, setChartState] = useAtom(chartStateAtom);
  const [locations] = useAtom(locationsAtom);
  const [location] = useAtom(chartLocationAtom);
  const [transitLocation] = useAtom(transitLocationAtom);
  const [transitTarget, setTransitTarget] = useAtom(transitTargetAtom);

  const handleChangeDate = useCallback((e: string) => {
    if (!e) return;

    setChartState((s) => ({
      ...s,
      chartData: {
        ...s.chartData,
        isoDate: e,
      },
    }));
  }, [setChartState]);

  const handleChangeYear: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const { value } = e.currentTarget;
    const nbr = Number.parseInt(value);
    if (!Number.isNaN(nbr)) {
      setChartState((s) => ({
        ...s,
        year: nbr,
      }));
    }
  }, [setChartState]);

  const handleChartLocationChanged = useCallback((locationId: string | undefined, loc?: Location) => {
    if (!locationId || !loc) return;

    setChartState((s) => {
      const oldLocation = locations.find(l => l.id === s.chartData.locationId);
      if (!oldLocation) return s;

      const updated = switchTimezones(s.chartData.isoDate, oldLocation.tz, loc.tz);

      return {
        ...s,
        chartData: {
          ...s.chartData,
          locationId,
          isoDate: updated,
        }
      };
    });
  }, [setChartState, locations]);

  const handleTransitLocationChanged = useCallback((locationId: string | undefined) => {
    if (locationId) {
      setChartState((s) => ({
        ...s,
        locationId,
      }));
    }
  }, [setChartState]);

  const handleTransitTargetChange = useCallback((_: any, tgt: OptionType | null) => setTransitTarget(tgt?.id || 'sun'), [setTransitTarget]);

  const options = useMemo(() => {
    return transitsWith.map(key => ({ label: t(`celestialBodies.${key}`), id: key }));
  }, [t]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Grid container>
            <Grid item md={6} sm={12} sx={{ pr: { md: 1 } }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('revolutionPage.birth')}</Typography>
                <Box display="flex" flexDirection="row">
                  <DateTimeInput tz={location.tz} value={chartState.chartData.isoDate} onChange={handleChangeDate} />
                </Box>
                <Box display="flex" flexDirection="row">
                  <SelectLocation label={t('revolutionPage.location')} value={location.id} onChange={handleChartLocationChanged} />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} sx={{ pl: { md: 1 } }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('revolutionPage.year')}</Typography>
                <Box display="flex" flexDirection="row">
                  <TextField
                    type="number"
                    label={t('revolutionPage.year')}
                    value={chartState.year}
                    onChange={handleChangeYear}
                  />
                </Box>
                <Box display="flex" flexDirection="row">
                  <SelectLocation label={t('revolutionPage.location')} value={transitLocation.id} onChange={handleTransitLocationChanged} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Box p={2}>
          <Autocomplete
            disablePortal
            options={options}
            renderInput={(params) => <TextField label={t('revolutionPage.aspectsWith')} sx={{ flexGrow: 1, minWidth: '300px' }} {...params} />}
            onChange={handleTransitTargetChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={{ id: transitTarget, label: t(`celestialBodies.${transitTarget}`) }}
          />
        </Box>
        <Suspense fallback="Loading...">
          <Chart />
        </Suspense>
      </Box>
    </Box>
  );
};
