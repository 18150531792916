import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import { posToSignDMS, signDMSToPos, signNames } from "@danielpedroso/chart/dist/utils";
import { useTranslation } from "react-i18next";

interface PositionFinderDialogProps {
  onFind: (obj: string, pos: number) => void;
}

export const PositionFinderDialog: React.FC<PositionFinderDialogProps> = ({ onFind }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({
    obj: 'sun',
    pos: 0,
  });

  const changeValue: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const { name, value } = e.target;
    return setValues(v => ({ ...v, [name]: value }));
  }, []);

  const signDMS = useMemo(() => {
    return posToSignDMS(values.pos);
  }, [values.pos]);

  const changePosition: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const dms = { ...signDMS, [e.target.name]: e.target.valueAsNumber ?? e.target.value };
    setValues(v => ({ ...v, pos: signDMSToPos(dms.sign, dms.d || 0, dms.m || 0, dms.s || 0) }));
  }, [signDMS]);

  const cancel = useCallback(() => setShow(false), []);

  const find = useCallback(() => {
    onFind(values.obj, values.pos);
    setShow(false);
  }, [values, onFind]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'g') {
        setShow(true);
        e.stopImmediatePropagation();
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <Dialog open={show} onClose={() => setShow(false)}>
      <DialogTitle>{t('chartPage.positionFinder.title')}</DialogTitle>
      <DialogContent>
        <Box pt={2} width="400px" display="flex" flexDirection="row">
          <TextField
            name="obj"
            value={values.obj}
            onChange={changeValue}
            label={t('chartPage.positionFinder.object')}
            sx={{ flexGrow: 1 }}
            select
          >
            <MenuItem value="sun">{t('celestialBodies.sun')}</MenuItem>
            <MenuItem value="moon">{t('celestialBodies.moon')}</MenuItem>
          </TextField>
          <TextField
            name="sign"
            value={signDMS.sign}
            onChange={changePosition}
            label={t('chartPage.positionFinder.sign')}
            sx={{ flexGrow: 1 }}
            select
          >
            {signNames.map(n => (
              <MenuItem key={n} value={n}>{t(`signs.${n}`)}</MenuItem>
            ))}
          </TextField>
          <TextField
            name="d"
            type="number"
            inputProps={{
              min: 0,
              max: 30,
            }}
            value={signDMS.d}
            onChange={changePosition}
            label={t('chartPage.positionFinder.deg')}
          />
          <TextField
            name="m"
            type="number"
            inputProps={{
              min: 0,
              max: 59,
            }}
            value={signDMS.m}
            onChange={changeValue}
            label={t('chartPage.positionFinder.min')}
          />
          <TextField
            name="s"
            type="number"
            inputProps={{
              min: 0,
              max: 59,
            }}
            value={signDMS.s}
            onChange={changePosition}
            label={t('chartPage.positionFinder.sec')}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{t('chartPage.positionFinder.cancel')}</Button>
        <Button onClick={find}>{t('chartPage.positionFinder.find')}</Button>
      </DialogActions>
    </Dialog>
  );
};