export const SemisextileSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
      <path
        d="M23.7499 33H40V37H20H0V33H17.7143L0 2L3.47297 0.0154442L20.7321 30.2189L38 0L41.473 1.98456L23.7499 33Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}