import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { Box } from '@mui/material';
import { Axis } from './axis';
import { AspectData } from '../state';
import { Transit } from './transit';
import { MouseTracker } from './mouse-tracker';
import { chartSizeAtom, aspectTransitsAtom, xElementsAtom, chartDataAtom } from './state';

interface TransitChartProps {
  margin?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  },
  widthMultiplier?: number;
  height?: number;
  data: AspectData[];
}

const defaultMargin = {
  top: 10,
  bottom: 50,
  left: 50,
  right: 50,
};

const getWidth = (widthMultiplier: number) => {
  return Math.max(8000, window.innerWidth * widthMultiplier);
};

export const TransitChart: React.FC<TransitChartProps> = ({
  widthMultiplier = 10,
  height = 500,
  margin = defaultMargin,
  data,
}) => {
  const scrollableEl = useRef<HTMLDivElement>();
  const [width, setWidth] = useState(getWidth(widthMultiplier));
  const [, setChartState] = useAtom(chartDataAtom);
  const [xElements] = useAtom(xElementsAtom);
  const [aspectTransits] = useAtom(aspectTransitsAtom);
  const [, setSize] = useAtom(chartSizeAtom);

  const getLabel = useCallback((element: number, axis: 'x' | 'y') => {
    if (axis === 'x') {
      return data[0]?.data[element]?.date?.format('DD/MM') ?? '?';
    }

    return element.toFixed(2);
  }, [data]);

  useEffect(() => {
    const updateWidth = () => setWidth(getWidth(widthMultiplier));

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [widthMultiplier]);

  useEffect(() => {
    setSize((val) => ({
      ...val,
      width,
      margin,
    }));
  }, [width, margin, setSize]);

  useEffect(() => {
    setChartState(data);
  }, [data, setChartState])

  return (
    <Box width="100%" sx={{ overflowX: 'scroll', overflowY: 'hidden' }} ref={scrollableEl}>
      <Box width={`${width}px`} height={`${height}px`}>
        <svg width={`${width}px`} height={`${height}px`}>
          {/* Y axis */}
          <Axis direction="y" range={height} margin={margin} elements={6} elementLabel={getLabel} />

          {/* X axis */}
          <Axis direction="x" range={width} margin={{ ...margin, top: height - margin.bottom }} elements={xElements} elementLabel={getLabel} />

          <MouseTracker scrollableEl={scrollableEl.current}>
            {aspectTransits.flatMap(a => a.transits.flatMap((t, i) => (
              <Transit
                id={`${a.key}${i}`}
                key={`${a.key}${i}`}
                colour={a.colour}
                transit={t}
                raw={data.find(d => d.key === a.key) as AspectData}
                width={width}
                height={height}
                margin={margin}
                xElements={xElements}
                yElements={5}
              />
            )))}
          </MouseTracker>
        </svg>
      </Box>
    </Box>
  );
};
