import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useAtom } from 'jotai';
import moment from 'moment-timezone';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import { IconButton, MenuItem, Popover, Tooltip } from '@mui/material';
import { AccessTime as TodayIcon, History as HistoryIcon, DeleteForever } from '@mui/icons-material';
import { recentDatesAtom } from './state';

export interface DateTimeInputProps {
  value: string;
  tz: string;
  onChange: (arg0: string) => void;
}

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

export const DateTimeInput: React.FC<DateTimeInputProps> = ({ value, tz, onChange }) => {
  const [text, setText] = useState(value);
  const [errorText, setErrorText] = useState('');
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLButtonElement | null>(null);
  const [recentDates, setRecentDates] = useAtom(recentDatesAtom);
  const previous = useRef(value);

  const onChangeDebounced = useMemo(() => {
    return debounce(onChange, 500, { leading: false });
  }, [onChange]);

  const handleNowClick = useCallback(() => {
    const now = moment().tz(tz);
    setText(now.format(dateFormat));
    onChangeDebounced(now.toISOString());
  }, [onChangeDebounced, tz]);

  const handleRecentDateClick = useCallback((date: string) => () => {
    const dt = moment.tz(date, tz);
    setText(dt.format(dateFormat));
    onChangeDebounced(dt.toISOString());
    setDropdownAnchor(null);
  }, [onChangeDebounced, tz]);

  const handleDeleteRecentDateClick = useCallback((date: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setRecentDates(d => d.filter(v => v !== date));
  }, [setRecentDates]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    setText(v);
    if (v) {
      const d = moment.tz(v, dateFormat, tz);
      if (d.isValid()) {
        onChangeDebounced(d.toISOString());
        setErrorText('');
      } else {
        setErrorText('Invalid date');
      }
    }
  }, [tz, onChangeDebounced]);

  useEffect(() => {
    if (value !== previous.current) {
      setRecentDates((d) => {
        return [value].concat(...d.filter(v => v !== value).slice(0, 10));
      });
    }
    previous.current = value;
    setText(moment.tz(value, tz).format(dateFormat));
  }, [value, tz, setRecentDates]);

  return (
    <InputMask mask="99/99/9999 99:99:99" value={text} onChange={handleChange}>
      <TextField
        type="text"
        error={!!errorText}
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              <Tooltip title="Now">
                <IconButton onClick={handleNowClick}>
                  <TodayIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Previous">
                <IconButton onClick={(e) => setDropdownAnchor(e.currentTarget)}>
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
              <Popover
                open={!!dropdownAnchor}
                anchorEl={dropdownAnchor}
                onClose={() => setDropdownAnchor(null)}
              >
                {recentDates.map((d: string) => (
                  <MenuItem
                    key={d}
                    onClick={handleRecentDateClick(d)}
                  >
                    {moment.tz(d, tz).format(dateFormat)}
                    <IconButton onClick={handleDeleteRecentDateClick(d)}>
                      <DeleteForever />
                    </IconButton>
                  </MenuItem>
                ))}
              </Popover>
            </>
          ),
        }}
      />
    </InputMask>
  );
};
