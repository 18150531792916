import React from "react";
import { AC } from "./ascendant";
import { DC } from "./descendant";
import { IC } from "./imum-coeli";
import { MC } from "./midheaven";

export const labels: Record<string, React.FC<any>> = {
  '1': AC,
  '7': DC,
  '10': MC,
  '4': IC,
};