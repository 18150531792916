"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcVelocityEarth = exports.calcHeliocentricPosition = exports.emBarycenterToEarthSunDistance = void 0;
const constants_1 = require("../constants");
const math_1 = require("../math");
const gplan_1 = require("./gplan");
const precess_1 = require("./precess");
/**
 * Calculate Earth-sun distance from Earth-Moon Barycenter position
 * julianDate: day in the julian calendar
 * emb: equatorial rectangular coordinates of Earh-Moon Barycenter
 * returns: Earth-sun distance
 * */
const emBarycenterToEarthSunDistance = (julianDate, emb) => {
    let ea = [...emb];
    let pm = [0, 0, 0];
    let polm = [0, 0, 0]; // double
    let a;
    let b; // double
    let i; // int
    /* Compute the vector Moon - Earth.  */
    const lp_equinox = (0, gplan_1.get_lp_equinox)(julianDate);
    pm = (0, gplan_1.gPlanMoon)(julianDate, pm, polm, lp_equinox); // TODO - investigate how this mutates the data.
    /* Precess the lunar position
     * to ecliptic and equinox of J2000.0
     */
    pm = (0, precess_1.calcPrecess)(pm, julianDate, 1);
    /* Adjust the coordinates of the Earth
     */
    a = 1.0 / (constants_1.EARTH_MOON_RATIO + 1.0);
    b = 0.0;
    for (i = 0; i < 3; i++) {
        ea[i] = ea[i] - a * pm[i];
        b = b + ea[i] * ea[i];
    }
    /* Sun-Earth distance.  */
    return Math.sqrt(b);
};
exports.emBarycenterToEarthSunDistance = emBarycenterToEarthSunDistance;
const calcHeliocentricPosition = (julianDate, body, rect, polar) => {
    const definePosition = !(rect && polar);
    var alat, E, M, W, temp; // double
    var epoch, inclination, ascnode, argperih; // double
    var meandistance, dailymotion, eccent, meananomaly; // double
    var r, coso, sino, cosa; // double
    rect = rect || [0, 0, 0];
    polar = polar || [0, 0, 0];
    const heliocentricBody = body;
    /* Call program to compute position, if one is supplied.  */
    if (heliocentricBody.ptable) {
        if (body.key === 'earth') {
            polar = (0, gplan_1.gPlanCalc3)(julianDate, body.ptable, polar, 3);
        }
        else {
            polar = (0, gplan_1.gPlanCalc)(julianDate, heliocentricBody.ptable, polar);
        }
        E = polar[0]; /* longitude */
        heliocentricBody.longitude = E;
        W = polar[1]; /* latitude */
        r = polar[2]; /* radius */
        heliocentricBody.distance = r;
        heliocentricBody.epoch = julianDate;
        heliocentricBody.equinox = constants_1.JAN_2000;
        // goto kepdon;
    }
    else {
        /* Decant the parameters from the data structure
         */
        epoch = heliocentricBody.epoch;
        inclination = heliocentricBody.inclination;
        ascnode = heliocentricBody.node * constants_1.DEG_TO_RADIANS;
        argperih = heliocentricBody.perihelion;
        meandistance = heliocentricBody.semiAxis; /* semimajor axis */
        dailymotion = heliocentricBody.dailyMotion;
        eccent = heliocentricBody.eccentricity;
        meananomaly = heliocentricBody.anomaly;
        /* Check for parabolic orbit. */
        if (eccent === 1.0) {
            /* meandistance = perihelion distance, q
             * epoch = perihelion passage date
             */
            temp = meandistance * Math.sqrt(meandistance);
            W = (julianDate - epoch) * 0.0364911624 / temp;
            /* The constant above is 3 k / sqrt(2),
             * k = Gaussian gravitational constant = 0.01720209895 . */
            E = 0.0;
            M = 1.0;
            while (Math.abs(M) > 1.0e-11) {
                temp = E * E;
                temp = (2.0 * E * temp + W) / (3.0 * (1.0 + temp));
                M = temp - E;
                if (temp !== 0.0) {
                    M /= temp;
                }
                E = temp;
            }
            r = meandistance * (1.0 + E * E);
            M = Math.atan(E);
            M = 2.0 * M;
            alat = M + constants_1.DEG_TO_RADIANS * argperih;
            // goto parabcon;
        }
        else {
            if (eccent > 1.0) {
                /* The equation of the hyperbola in polar coordinates r, theta
                 * is r = a(e^2 - 1)/(1 + e cos(theta))
                 * so the perihelion distance q = a(e-1),
                 * the "mean distance"  a = q/(e-1).
                 */
                meandistance = meandistance / (eccent - 1.0);
                temp = meandistance * Math.sqrt(meandistance);
                W = (julianDate - epoch) * 0.01720209895 / temp;
                /* solve M = -E + e sinh E */
                E = W / (eccent - 1.0);
                M = 1.0;
                while (Math.abs(M) > 1.0e-11) {
                    M = -E + eccent * (0, math_1.sinh)(E) - W;
                    E += M / (1.0 - eccent * (0, math_1.cosh)(E));
                }
                r = meandistance * (-1.0 + eccent * (0, math_1.cosh)(E));
                temp = (eccent + 1.0) / (eccent - 1.0);
                M = Math.sqrt(temp) * (0, math_1.tanh)(0.5 * E);
                M = 2.0 * Math.atan(M);
                alat = M + constants_1.DEG_TO_RADIANS * argperih;
                // goto parabcon;
            }
            else {
                /* Calculate the daily motion, if it is not given.
                 */
                if (dailymotion === 0.0) {
                    /* The constant is 180 k / pi, k = Gaussian gravitational body.
                     * Assumes object in heliocentric orbit is massless.
                     */
                    dailymotion = 0.9856076686 / (heliocentricBody.semiAxis * Math.sqrt(heliocentricBody.semiAxis));
                }
                dailymotion *= julianDate - epoch;
                /* M is proportional to the area swept out by the radius
                 * vector of a circular orbit during the time between
                 * perihelion passage and Julian date J.
                 * It is the mean anomaly at time J.
                 */
                M = constants_1.DEG_TO_RADIANS * (meananomaly + dailymotion);
                M = M % constants_1.TPI;
                /* If mean longitude was calculated, adjust it also
                 * for motion since epoch of elements.
                 */
                if (heliocentricBody.longitude) {
                    heliocentricBody.longitude += dailymotion;
                    heliocentricBody.longitude = (heliocentricBody.longitude % 360);
                }
                /* By Kepler's second law, M must be equal to
                 * the area swept out in the same time by an
                 * elliptical orbit of same total area.
                 * Integrate the ellipse expressed in polar coordinates
                 *     r = a(1-e^2)/(1 + e cosW)
                 * with respect to the angle W to get an expression for the
                 * area swept out by the radius vector.  The area is given
                 * by the mean anomaly; the angle is solved numerically.
                 *
                 * The answer is obtained in two steps.  We first solve
                 * Kepler's equation
                 *    M = E - eccent*sin(E)
                 * for the eccentric anomaly E.  Then there is a
                 * closed form solution for W in terms of E.
                 */
                E = M; /* Initial guess is same as circular orbit. */
                temp = 1.0;
                do {
                    /* The approximate area swept out in the ellipse */
                    temp = E - eccent * Math.sin(E)
                        /* ...minus the area swept out in the circle */
                        - M;
                    /* ...should be zero.  Use the derivative of the error
                     * to converge to solution by Newton's method.
                     */
                    E -= temp / (1.0 - eccent * Math.cos(E));
                } while (Math.abs(temp) > 1.0e-11);
                /* The exact formula for the area in the ellipse is
                 *    2.0*atan(c2*tan(0.5*W)) - c1*eccent*sin(W)/(1+e*cos(W))
                 * where
                 *    c1 = sqrt( 1.0 - eccent*eccent )
                 *    c2 = sqrt( (1.0-eccent)/(1.0+eccent) ).
                 * Substituting the following value of W
                 * yields the exact solution.
                 */
                temp = Math.sqrt((1.0 + eccent) / (1.0 - eccent));
                W = 2.0 * Math.atan(temp * Math.tan(0.5 * E));
                /* The true anomaly.
                 */
                W = W % constants_1.TPI;
                meananomaly *= constants_1.DEG_TO_RADIANS;
                /* Orbital longitude measured from node
                 * (argument of latitude)
                 */
                if (heliocentricBody.longitude) {
                    alat = (heliocentricBody.longitude) * constants_1.DEG_TO_RADIANS + W - meananomaly - ascnode;
                }
                else {
                    alat = W + constants_1.DEG_TO_RADIANS * argperih; /* mean longitude not given */
                }
                /* From the equation of the ellipse, get the
                 * radius from central focus to the object.
                 */
                r = meandistance * (1.0 - eccent * eccent) / (1.0 + eccent * Math.cos(W));
            }
        }
        // parabcon:
        /* The heliocentric ecliptic longitude of the object
         * is given by
         *   tan( longitude - ascnode )  =  cos( inclination ) * tan( alat ).
         */
        coso = Math.cos(alat);
        sino = Math.sin(alat);
        inclination *= constants_1.DEG_TO_RADIANS;
        W = sino * Math.cos(inclination);
        E = (0, math_1.zatan2)(coso, W) + ascnode;
        /* The ecliptic latitude of the object
         */
        W = sino * Math.sin(inclination);
        W = Math.asin(W);
    }
    // kepdon:
    /* Convert to rectangular coordinates,
     * using the perturbed latitude.
     */
    rect[2] = r * Math.sin(W);
    cosa = Math.cos(W);
    rect[1] = r * cosa * Math.sin(E);
    rect[0] = r * cosa * Math.cos(E);
    /* Convert from heliocentric ecliptic rectangular
     * to heliocentric equatorial rectangular coordinates
     * by rotating eps radians about the x axis.
     */
    let epsilonObject = (0, math_1.calcEpsilon)(heliocentricBody.equinox);
    W = epsilonObject.coseps * rect[1] - epsilonObject.sineps * rect[2];
    M = epsilonObject.sineps * rect[1] + epsilonObject.coseps * rect[2];
    rect[1] = W;
    rect[2] = M;
    /* Precess the position
     * to ecliptic and equinox of J2000.0
     * if not already there.
     */
    rect = (0, precess_1.calcPrecess)(rect, heliocentricBody.equinox, 1);
    /* If earth, adjust from earth-moon barycenter to earth
     * by AA page E2.
     */
    if (body.key === 'earth') {
        r = (0, exports.emBarycenterToEarthSunDistance)(julianDate, rect); /* see below */
    }
    /* Rotate back into the ecliptic.  */
    epsilonObject = (0, math_1.calcEpsilon)(constants_1.JAN_2000);
    W = epsilonObject.coseps * rect[1] + epsilonObject.sineps * rect[2];
    M = -epsilonObject.sineps * rect[1] + epsilonObject.coseps * rect[2];
    /* Convert to polar coordinates */
    E = (0, math_1.zatan2)(rect[0], W);
    W = Math.asin(M / r);
    /* Output the polar cooordinates
     */
    polar[0] = E; /* longitude */
    polar[1] = W; /* latitude */
    polar[2] = r; /* radius */
    // fill the body.position only if rect and polar are
    // not defined
    if (definePosition) {
        body.position = {
            rect: rect,
            polar: polar,
        };
    }
    return body;
};
exports.calcHeliocentricPosition = calcHeliocentricPosition;
const calcVelocityEarth = (julianDate, earthBody) => {
    var _a, _b, _c;
    let e = [0, 0, 0];
    let p = [0, 0, 0];
    const t = 0.005;
    /* calculate heliocentric position of the earth
     * as of a short time ago.
     */
    const coords = [0, 0, 0];
    const keplerEarthBody = (0, exports.calcHeliocentricPosition)(julianDate - t, { ...earthBody }, e, p);
    // Return velocity as rectangular coordinates
    for (let i = 0; i < 3; i++) {
        coords[i] = (((_c = (_b = (_a = keplerEarthBody === null || keplerEarthBody === void 0 ? void 0 : keplerEarthBody.position) === null || _a === void 0 ? void 0 : _a.rect) === null || _b === void 0 ? void 0 : _b[i]) !== null && _c !== void 0 ? _c : 0) - e[i]) / t;
    }
    return coords;
};
exports.calcVelocityEarth = calcVelocityEarth;
