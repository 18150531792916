export const MarsSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        d="M 9,7 C 9,4.7929688 7.2070312,3 5,3 2.7929688,3 1,4.7929688 1,7 1,9.2070312 2.7929688,11 5,11 7.2070312,11 9,9.2070312 9,7 Z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 7.828125,4.171875 11,1"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="M 9.2304688,1 H 11 v 1.7695312"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
}