import { translateCoordinate } from "@danielpedroso/astrum-calc"
import { DEG_TO_RADIANS, SIGNS } from "@danielpedroso/astrum-calc/dist/constants";
import { BodyKey, Chart } from "@danielpedroso/astrum-calc/dist/types";

export interface ChartItemSelection {
  type: 'object' | 'aspect' | 'houseCusp';
  item: string | number;
  chartIndex?: 1 | 2;
}

interface Coord {
  x: number;
  y: number;
}

interface LineCoord {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export const center2TopLeft = (x: number, y: number, r: number): Coord => {
  return {
    x: x + r,
    y: Math.abs(y - r),
  };
}

export const getCoordinates = (angle: number, radius: number, screenRadius: number): Coord => {
  const ang = angle * DEG_TO_RADIANS;
  return center2TopLeft(radius * Math.cos(ang), radius * Math.sin(ang), screenRadius);
};

export const getLineCoordinates = (angle: number, startRadius: number, endRadius: number, screenRadius: number): LineCoord => {
  const { x: x1, y: y1 } = getCoordinates(angle, startRadius, screenRadius);
  const { x: x2, y: y2 } = getCoordinates(angle, endRadius, screenRadius);

  return { x1, y1, x2, y2 };
};

export const getNameOfGodIdx = (deg: number): number => {
  const nbr = Math.trunc(deg / 5);
  return Math.min(Math.max(0, nbr), namesOfGod.length);
};

export const getNameOfGod = (deg: number): NameOfGod => {
  const idx = getNameOfGodIdx(deg);
  return namesOfGod[idx];
};

export const getAngelColourLinearGradient = (deg: number): [string, string, string, string] => {
  const sign = Math.trunc(deg / 30);
  const pos = deg % 30;
  const signIdx = Math.trunc(sign % SIGNS.length);
  const prevIdx = signIdx <= 0 ? SIGNS.length - 1 : signIdx - 1;
  const nextIdx = (signIdx + 1) % SIGNS.length;
  const angelNbrWithinSign = Math.trunc(pos / 5) % 6;

  if (angelNbrWithinSign === 0) {
    return [signColours[prevIdx], signColours[prevIdx], signColours[signIdx], signColours[signIdx]];
  }
  if (angelNbrWithinSign === 1) {
    return [signColours[prevIdx], signColours[signIdx], signColours[signIdx], signColours[signIdx]];
  }
  if (angelNbrWithinSign === 4) {
    return [signColours[signIdx], signColours[signIdx], signColours[signIdx], signColours[nextIdx]];
  }
  if (angelNbrWithinSign === 5) {
    return [signColours[signIdx], signColours[signIdx], signColours[nextIdx], signColours[nextIdx]];
  }

  return [signColours[signIdx], signColours[signIdx], signColours[signIdx], signColours[signIdx]];
};

export const getTarotCard = (deg: number) => {
  const cardNbr = Math.trunc(deg / 5);
  return `${tarotCards[Math.min(Math.max(0, cardNbr), tarotCards.length)]}`;
};

export const getDecan = (deg: number): string => {
  const decanNbr = Math.trunc(deg/10) % decans.length;
  return `${decans[Math.min(Math.max(0, decanNbr), decans.length - 1)]}`;
};

export const degToDMSVals = (deg: number) => {
  const d = Math.trunc(deg);
  let rest = deg - d;
  const m = Math.trunc((deg - d) * 60);
  const s = Math.trunc((rest - m/60) * 3600);

  return { d, m, s };
};

export const degToDMS = (deg: number, highPrecision: boolean = false): string => {
  if (!highPrecision) {
    const d = Math.trunc(deg);
    return `${d}º`;
  }

  const { d, m, s } = degToDMSVals(deg);
  return `${d}º ${m}' ${s}"`;
};

export const signColours = [
  '#c92838',
  '#f76438',
  '#f99838',
  '#f8b938',
  '#e8e83a',
  '#c9d648',
  '#089859',
  '#07696c',
  '#0878a8',
  '#482a68',
  '#682859',
  '#b82a66', 
];

export const signColourMap: Record<string, string> = {
  'aries': '#c92838',
  'taurus': '#f76438',
  'gemini': '#f99838',
  'cancer': '#f8b938',
  'leo': '#e8e83a',
  'virgo': '#c9d648',
  'libra': '#089859',
  'scorpio': '#07696c',
  'sagittarius':'#0878a8',
  'capricorn': '#482a68',
  'aquarius': '#682859',
  'pisces': '#b82a66', 
};

export interface NameOfGod {
  // 3 letters of the angel name, in Hebrew
  name: string;
  // The name of God in English
  englishName: string;
  // Angel number (index + 1)
  number: number;
  // Psalm (number, verse) in the Tanakh
  bible: { book: string; number: number; verse: number, text: string };
}

export interface SephirahDesc {
  number: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  name: string;
  englishName: string;
  getNameOfGod: (chart: Chart) => NameOfGod;
  getSephirahColour: (chart: Chart) => string[];
  x: number;
  y: number;
  opacity?: number;
}

export const sephiroth: SephirahDesc[] = [
  {
    number: 1,
    name: "כתר",
    englishName: 'Kether',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.neptune.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.neptune.absolute),
    x: 500,
    y: 80,
  },
  {
    number: 2,
    name: "חכמה",
    englishName: 'Chokhmah',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.uranus.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.uranus.absolute),
    x: 750,
    y: 200,
  },
  {
    number: 3,
    name: "בינה",
    englishName: 'Binah',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.saturn.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.saturn.absolute),
    x: 250,
    y: 200,
  },
  {
    number: 4,
    name: "חסד",
    englishName: 'Chesed',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.jupiter.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.jupiter.absolute),
    x: 750,
    y: 400,
  },
  {
    number: 5,
    name: "גבורה",
    englishName: 'Gevurah',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.mars.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.mars.absolute),
    x: 250,
    y: 400,
  },
  {
    number: 6,
    name: "תפארת",
    englishName: 'Tiphareth',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.sun.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.sun.absolute),
    x: 500,
    y: 500,
  },
  {
    number: 7,
    name: "נצח",
    englishName: 'Netzach',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.venus.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.venus.absolute),
    x: 750,
    y: 600,
  },
  {
    number: 8,
    name: "הוד",
    englishName: 'Hod',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.mercury.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.mercury.absolute),
    x: 250,
    y: 600,
  },
  {
    number: 9,
    name: "יסוד",
    englishName: 'Yesod',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.moon.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.moon.absolute),
    x: 500,
    y: 700,
  },
  {
    number: 10,
    name: "מלכות",
    englishName: 'Malkhut',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.houseCusps[0])],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.houseCusps[0]),
    x: 500,
    y: 900,
  }, {
    number: 11,
    name: "דעת",
    englishName: 'Daath',
    getNameOfGod: (chart) => namesOfGod[getNameOfGodIdx(chart.planets.pluto.absolute)],
    getSephirahColour: (chart) => getAngelColourLinearGradient(chart.planets.pluto.absolute),
    x: 500,
    y: 300,
    opacity: 0.8,
  },
];

export interface PathDesc {
  from: SephirahDesc;
  to: SephirahDesc;
  name: string;
  namePos: number;
}

export const lurianicPaths: PathDesc[] = [
  { from: sephiroth[0], to: sephiroth[1], name: "ה", namePos: 0.5 },
  { from: sephiroth[1], to: sephiroth[3], name: "ב", namePos: 0.5 },
  { from: sephiroth[3], to: sephiroth[6], name: "כ", namePos: 0.5 },
  { from: sephiroth[0], to: sephiroth[2], name: "ו", namePos: 0.5 },
  { from: sephiroth[2], to: sephiroth[4], name: "ג", namePos: 0.5 },
  { from: sephiroth[4], to: sephiroth[7], name: "פ", namePos: 0.5 },
  { from: sephiroth[1], to: sephiroth[5], name: "ט", namePos: 0.3 },
  { from: sephiroth[2], to: sephiroth[3], name: "ק", namePos: 0.23 },
  { from: sephiroth[2], to: sephiroth[5], name: "ע", namePos: 0.3 },
  { from: sephiroth[1], to: sephiroth[4], name: "ז", namePos: 0.23 },
  { from: sephiroth[3], to: sephiroth[5], name: "ח", namePos: 0.5 },
  { from: sephiroth[4], to: sephiroth[5], name: "צ", namePos: 0.5 },
  { from: sephiroth[6], to: sephiroth[5], name: "י", namePos: 0.5 },
  { from: sephiroth[6], to: sephiroth[8], name: "נ", namePos: 0.5 },
  { from: sephiroth[7], to: sephiroth[5], name: "ס", namePos: 0.5 },
  { from: sephiroth[7], to: sephiroth[8], name: "ל", namePos: 0.5 },
  { from: sephiroth[0], to: sephiroth[5], name: "ד", namePos: 0.21 },
  { from: sephiroth[5], to: sephiroth[8], name: "ר", namePos: 0.4 },
  { from: sephiroth[8], to: sephiroth[9], name: "ת", namePos: 0.5 },
  { from: sephiroth[1], to: sephiroth[2], name: "ש", namePos: 0.5 },
  { from: sephiroth[3], to: sephiroth[4], name: "א", namePos: 0.5 },
  { from: sephiroth[6], to: sephiroth[7], name: "מ", namePos: 0.5 },
];

export const goldenDawnPaths: PathDesc[] = [
  // Right pilar
  { from: sephiroth[0], to: sephiroth[1], name: "א", namePos: 0.5 },
  { from: sephiroth[1], to: sephiroth[3], name: "ו", namePos: 0.5 },
  { from: sephiroth[3], to: sephiroth[6], name: "כ", namePos: 0.5 },
  { from: sephiroth[6], to: sephiroth[9], name: "ק", namePos: 0.5 },

  // Left pilar
  { from: sephiroth[0], to: sephiroth[2], name: "ב", namePos: 0.5 },
  { from: sephiroth[2], to: sephiroth[4], name: "ח", namePos: 0.5 },
  { from: sephiroth[4], to: sephiroth[7], name: "מ", namePos: 0.5 },
  { from: sephiroth[7], to: sephiroth[9], name: "ש", namePos: 0.5 },

  // Others
  { from: sephiroth[1], to: sephiroth[5], name: "ה", namePos: 0.4 },
  { from: sephiroth[2], to: sephiroth[5], name: "ז", namePos: 0.4 },
  { from: sephiroth[3], to: sephiroth[5], name: "י", namePos: 0.5 },
  { from: sephiroth[4], to: sephiroth[5], name: "ל", namePos: 0.5 },
  { from: sephiroth[6], to: sephiroth[5], name: "נ", namePos: 0.5 },
  { from: sephiroth[6], to: sephiroth[8], name: "צ", namePos: 0.5 },
  { from: sephiroth[7], to: sephiroth[5], name: "ע", namePos: 0.5 },
  { from: sephiroth[7], to: sephiroth[8], name: "ר", namePos: 0.5 },

  // Middle pilar
  { from: sephiroth[0], to: sephiroth[5], name: "ג", namePos: 0.21 },
  { from: sephiroth[5], to: sephiroth[8], name: "ס", namePos: 0.4 },
  { from: sephiroth[8], to: sephiroth[9], name: "ת", namePos: 0.5 },

  // Horizontal paths
  { from: sephiroth[1], to: sephiroth[2], name: "ד", namePos: 0.5 },
  { from: sephiroth[3], to: sephiroth[4], name: "ט", namePos: 0.5 },
  { from: sephiroth[6], to: sephiroth[7], name: "פ", namePos: 0.5 },

];

export type PathType = 'lurianic' | 'golden-dawn';

export const getPaths = (type: PathType) => {
  if (type === 'lurianic') return lurianicPaths;
  return goldenDawnPaths;
};

export const namesOfGod: NameOfGod[] = [
  { name: 'והו', englishName: 'Vehuiah', number: 1, bible: { book: 'psalms', number: 3, verse: 4, text: 'וְאַתָּ֣ה יְ֖הֹוָה מָגֵ֣ן בַּֽעֲדִ֑י כְּ֜בוֹדִ֗י וּמֵרִ֥ים רֹאשִֽׁי' } },
  { name: 'ילי', englishName: 'Jeliel', number: 2, bible: { book: 'psalms', number: 22, verse: 20, text: 'וְאַתָּ֣ה יְ֖הֹוָה אַל־תִּרְחָ֑ק אֱ֜יָֽלוּתִ֗י לְעֶזְרָ֥תִי חֽוּשָׁה' } },
  { name: 'סיט', englishName: 'Sitael', number: 3, bible: { book: 'psalms', number: 91, verse: 2, text: 'אֹמַ֗ר לַֽ֖יהֹוָה מַחְסִּ֣י וּמְצֽוּדָתִ֑י אֱ֜לֹהַ֗י אֶבְטַח־בּֽוֹ' } },
  { name: 'עלם', englishName: 'Elemiah', number: 4, bible: { book: 'psalms', number: 6, verse: 5, text: 'שׁוּבָ֣ה יְ֖הֹוָה חַלְּצָ֣ה נַפְשִׁ֑י הֽ֜וֹשִׁיעֵ֗נִי לְמַ֣עַן חַסְדֶּֽךָ' } },
  { name: 'מהש', englishName: 'Mahasiah', number: 5, bible: { book: 'psalms', number: 34, verse: 5, text: 'דָּרַ֣שְׁתִּי אֶת־יְהֹוָ֣ה וְעָנָ֑נִי וּמִכָּל־מְ֜גֽוּרוֹתַ֗י הִצִּילָֽנִי' } },
  { name: 'ללה', englishName: 'Lelahel', number: 6, bible: { book: 'psalms', number: 9, verse: 12, text: 'זַמְּר֗וּ לַ֖יהֹוָה יֹשֵׁ֣ב צִיּ֑וֹן הַגִּ֥ידוּ בָ֜עַמִּ֗ים עֲלִֽילוֹתָֽיו' } },
  { name: 'אכא', englishName: 'Achaiah', number: 7, bible: { book: 'psalms', number: 103, verse: 8, text: 'רַח֣וּם וְחַנּ֣וּן יְהֹוָ֑ה אֶ֖רֶךְ אַפַּ֣יִם וְרַב־חָֽסֶד' } },
  { name: 'כהת', englishName: 'Cahetel', number: 8, bible: { book: 'psalms', number: 95, verse: 6, text: 'בֹּאוּ נִשְׁתַּֽחֲוֶ֣ה וְנִכְרָ֑עָה נִ֜בְרְכָ֗ה לִֽפְנֵי־יְהֹוָ֥ה עֹשֵֽׂנוּ' } },
  { name: 'הזי', englishName: 'Haziel', number: 9, bible: { book: 'psalms', number: 25, verse: 6, text: 'זְכֹר־רַֽחֲמֶ֣יךָ יְ֖הֹוָה וַֽחֲסָדֶ֑יךָ כִּ֖י מֵֽעוֹלָ֣ם הֵֽמָּה' } },
  { name: 'אלד', englishName: 'Aladiah', number: 10, bible: { book: 'psalms', number: 33, verse: 22, text: 'יְהִי־חַסְדְּךָ֣ יְהֹוָ֣ה עָלֵ֑ינוּ כַּֽ֜אֲשֶׁ֗ר יִחַ֥לְנוּ לָֽךְ' } },
  { name: 'לאו', englishName: 'Lauviah', number: 11, bible: { book: 'psalms', number: 18, verse: 47, text: 'חַי־יְ֖הֹוָה וּבָר֣וּךְ צוּרִ֑י וְ֜יָר֗וּם אֱל֘וֹהֵ֥י יִשְׁעִֽי' } },
  { name: 'ההע', englishName: 'Hahaiah', number: 12, bible: { book: 'psalms', number: 10, verse: 1, text: 'לָמָ֣ה יְ֖הֹוָה תַּֽעֲמֹ֣ד בְּרָח֑וֹק תַּ֜עְלִ֗ים לְעִתּ֥וֹת בַּצָּֽרָה' } },
  { name: 'יזל', englishName: 'Iezalel', number: 13, bible: { book: 'psalms', number: 98, verse: 4, text: 'הָרִ֣יעוּ לַֽ֖יהֹוָה כָּל־הָאָ֑רֶץ פִּצְח֖וּ וְרַנְּנ֣וּ וְזַמֵּֽרוּ' } },
  { name: 'מבה', englishName: 'Mebahel', number: 14, bible: { book: 'psalms', number: 9, verse: 10, text: 'וִ֘יהִ֣י יְהֹוָ֣ה מִשְׂגָּ֣ב לַדָּ֑ךְ מִ֜שְׂגָּ֗ב לְעִתּ֥וֹת בַּצָּרָֽה' } },
  { name: 'הרי', englishName: 'Hariel', number: 15, bible: { book: 'psalms', number: 94, verse: 22, text: 'וַיְהִ֣י יְהֹוָ֣ה לִ֣י לְמִשְׂגָּ֑ב וֵֽ֜אלֹהַ֗י לְצ֣וּר מַחְסִֽי' } },
  { name: 'הקם', englishName: 'Hekamiah', number: 16, bible: { book: 'psalms', number: 88, verse: 2, text: 'יְהֹוָה אֱלֹהֵ֣י יְשֽׁוּעָתִ֑י י֚וֹם צָעַ֖קְתִּי בַלַּ֣יְלָה נֶגְדֶּֽךָ' } },
  { name: 'לאו', englishName: 'Lauviah', number: 17, bible: { book: 'psalms', number: 8, verse: 2, text: 'יְהֹוָ֚ה אֲדֹנֵ֗ינוּ מָֽה־אַדִּ֣יר שִׁ֖מְךָ בְּכָל־הָאָ֑רֶץ אֲשֶׁר־תְּנָ֥ה הֽ֜וֹדְךָ֗ עַל־הַשָּׁמָֽיִם' } },
  { name: 'כלי', englishName: 'Caliel', number: 18, bible: { book: 'psalms', number: 35, verse: 24, text: 'שָׁפְטֵ֣נִי כְ֖צִדְקְךָ יְהֹוָ֥ה אֱ֜לֹהָ֗י וְאַל־יִשְׂמְחוּ־לִֽי' } },
  { name: 'לוו', englishName: 'Leuviah', number: 19, bible: { book: 'psalms', number: 40, verse: 2, text: 'קַוֹּ֣ה קִוִּ֣יתִי יְהֹוָ֑ה וַיֵּ֥ט אֵ֜לַ֗י וַיִּשְׁמַ֥ע שַׁוְעָתִֽי' } },
  { name: 'פהל', englishName: 'Pahaliah', number: 20, bible: { book: 'psalms', number: 116, verse: 4, text: 'וּבְשֵֽׁם־יְהֹוָ֥ה אֶקְרָ֑א אָֽנָּ֥ה יְ֜הֹוָ֗ה מַלְּטָ֥ה נַפְשִֽׁי' } },
  { name: 'נלך', englishName: 'Nelkhael', number: 21, bible: { book: 'psalms', number: 31, verse: 15, text: 'וַֽאֲנִ֚י | עָלֶ֣יךָ בָטַ֣חְתִּי יְהֹוָ֑ה אָ֜מַ֗רְתִּי אֱלֹהַ֥י אָֽתָּה' } },
  { name: 'ייי', englishName: 'Yeiayel', number: 22, bible: { book: 'psalms', number: 121, verse: 5, text: 'יְהֹוָ֥ה שֹֽׁמְרֶ֑ךָ יְהֹוָ֥ה צִ֜לְּךָ֗ עַל־יַ֥ד יְמִינֶֽךָ' } },
  { name: 'מלה', englishName: 'Melahel', number: 23, bible: { book: 'psalms', number: 121, verse: 8, text: 'יְֽהֹוָ֗ה יִשְׁמָר־צֵֽאתְךָ֥ וּבוֹאֶ֑ךָ מֵֽ֜עַתָּ֗ה וְעַד־עוֹלָֽם' } },
  { name: 'חהו', englishName: 'Haheuiah', number: 24, bible: { book: 'psalms', number: 33, verse: 18, text: 'הִנֵּ֚ה עֵ֣ין יְ֖הֹוָה אֶל־יְרֵאָ֑יו לַֽמְיַֽחֲלִ֥ים לְחַסְדּֽוֹ' } },
  { name: 'נתה', englishName: 'Nith-Haiah', number: 25, bible: { book: 'psalms', number: 9, verse: 2, text: 'אוֹדֶ֣ה יְ֖הֹוָה בְּכָל־לִבִּ֑י אֲ֜סַפְּרָ֗ה כָּל־נִפְלְאוֹתֶֽיךָ' } },
  { name: 'האא', englishName: 'Haaiah', number: 26, bible: { book: 'psalms', number: 119, verse: 145, text: 'קָרָ֣אתִי בְכָל־לֵ֖ב עֲנֵ֥נִי יְהֹוָ֗ה חֻקֶּ֥יךָ אֶצֹּֽרָה' } },
  { name: 'ירת', englishName: 'Yerathel', number: 27, bible: { book: 'psalms', number: 140, verse: 2, text: 'חַלְּצֵ֣נִי יְ֖הֹוָה מֵֽאָדָ֣ם רָ֑ע מֵאִ֖ישׁ חֲמָסִ֣ים תִּנְצְרֵֽנִי' } },
  { name: 'שאה', englishName: 'Seheiah', number: 28, bible: { book: 'psalms', number: 71, verse: 12, text: 'אֱלֹהִים אַל־תִּרְחַ֣ק מִמֶּ֑נִּי אֱ֜לֹהַ֗י לְעֶזְרָ֥תִי חֽוּשָׁה (כתיב חֽיּשָׁה) ' } },
  { name: 'ריי', englishName: 'Reiyel', number: 29, bible: { book: 'psalms', number: 54, verse: 6, text: 'הִנֵּ֣ה אֱ֖לֹהִים עֹזֵ֣ר לִ֑י אֲ֜דֹנָ֗י בְּֽסֹמְכֵ֥י נַפְשִֽׁי' } },
  { name: 'אום', englishName: 'Omael', number:  30, bible: { book: 'psalms', number: 71, verse: 5, text: 'כִּֽי־אַתָּ֥ה תִקְוָתִ֑י אֲדֹנָ֥י יֱ֜הֹוִ֗ה מִבְטַחִ֥י מִנְּעוּרָֽי' } },
  { name: 'לכב', englishName: 'Lecabel', number: 31, bible: { book: 'psalms', number: 71, verse: 16, text: 'אָב֗וֹא בִּ֖גְבֻרוֹת אֲדֹנָ֣י יֱהֹוִ֑ה אַזְכִּ֖יר צִדְקָֽתְךָ֣ לְבַדֶּֽךָ' } },
  { name: 'ושר', englishName: 'Vasariah', number: 32, bible: { book: 'psalms', number: 33, verse: 4, text: 'כִּֽי־יָשָׁ֥ר דְּבַר־יְהֹוָ֑ה וְכָל־מַֽ֜עֲשֵׂ֗הוּ בֶּֽאֱמוּנָֽה' } },
  { name: 'יחו', englishName: 'Yehuiah', number: 33, bible: { book: 'psalms', number: 94, verse: 11, text: 'יְהֹוָ֗ה יֹ֖דֵעַ מַחְשְׁב֣וֹת אָדָ֑ם כִּ֖י הֵ֣מָּה הָֽבֶל' } },
  { name: 'להח', englishName: 'Lehahiah', number: 34, bible: { book: 'psalms', number: 131, verse: 3, text: 'יַחֵ֣ל יִ֖שְׂרָאֵל אֶל־יְהֹוָ֑ה מֵֽ֜עַתָּ֗ה וְעַד־עוֹלָֽם' } },
  { name: 'כוק', englishName: 'Chavakhiah', number: 35, bible: { book: 'psalms', number: 116, verse: 1, text: 'אָהַבְתִּי כִּֽי־יִשְׁמַ֥ע | יְהֹוָ֑ה אֶת־ק֜וֹלִ֗י תַּֽחֲנוּנָֽי' } },
  { name: 'מנד', englishName: 'Menadel', number: 36, bible: { book: 'psalms', number: 26, verse: 8, text: 'יְהֹוָ֗ה אָ֖הַבְתִּי מְע֣וֹן בֵּיתֶ֑ךָ וּ֜מְק֗וֹם מִשְׁכַּ֥ן כְּבוֹדֶֽךָ' } },
  { name: 'אני', englishName: 'Aniel', number: 37, bible: { book: 'psalms', number: 80, verse: 20, text: 'יְהֹ֘וָ֚ה אֱלֹהִ֣ים צְבָא֣וֹת הֲשִׁיבֵ֑נוּ הָאֵ֥ר פָּ֜נֶ֗יךָ וְנִוָּשֵֽׁעָה' } },
  { name: 'חעם', englishName: 'Haamiah', number: 38, bible: { book: 'psalms', number: 91, verse: 9, text: 'כִּֽי־אַתָּ֣ה יְהֹוָ֣ה מַחְסִּ֑י עֶ֜לְי֗וֹן שַׂ֣מְתָּ מְעוֹנֶֽךָ' } },
  { name: 'רהע', englishName: 'Rehael', number: 39, bible: { book: 'psalms', number: 30, verse: 11, text: 'שְׁמַע־יְהֹוָ֥ה וְחָנֵּ֑נִי יְ֜הֹוָ֗ה הֱֽיֵ֤ה | עֹזֵ֬ר לִֽי' } },
  { name: 'ייז', englishName: 'Ieiazel', number: 40, bible: { book: 'psalms', number: 88, verse: 15, text: 'לָמָ֣ה יְ֖הֹוָה תִּזְנַ֣ח נַפְשִׁ֑י תַּסְתִּ֖יר פָּנֶ֣יךָ מִמֶּֽנִּי' } },
  { name: 'ההה', englishName: 'Hahahel', number: 41, bible: { book: 'psalms', number: 120, verse: 2, text: 'יְהֹוָ֗ה הַצִּ֣ילָה נַ֖פְשִׁי מִשְּׂפַת־שֶׁ֑קֶר מִלָּשׁ֥וֹן רְמִיָּֽה' } },
  { name: 'מיכ', englishName: 'Mikhael', number: 42, bible: { book: 'psalms', number: 121, verse: 7, text: 'יְֽהֹוָ֗ה יִשְׁמָרְךָ֥ מִכָּל־רָ֑ע יִ֜שְׁמֹ֗ר אֶת־נַפְשֶֽׁךָ' } },
  { name: 'וול', englishName: 'Veuliah', number: 43, bible: { book: 'psalms', number: 88, verse: 14, text: 'וַֽאֲנִ֚י | אֵלֶ֣יךָ יְהֹוָ֣ה שִׁוַּ֑עְתִּי וּ֜בַבֹּ֗קֶר תְּפִלָּתִ֥י תְקַדְּמֶֽךָּ' } },
  { name: 'ילה', englishName: 'Yelahiah', number: 44, bible: { book: 'psalms', number: 119, verse: 108, text: 'נִדְב֣וֹת פִּ֖י רְצֵה־נָ֣א יְהֹוָ֑ה וּמִשְׁפָּטֶ֥יךָ לַמְּדֵֽנִי' } },
  { name: 'סאל', englishName: 'Sealiah', number: 45, bible: { book: 'psalms', number: 94, verse: 18, text: 'אִם־אָ֖מַרְתִּי מָטָ֣ה רַגְלִ֑י חַסְדְּךָ֥ יְ֜הֹוָ֗ה יִסְעָדֵֽנִי' } },
  { name: 'ערי', englishName: 'Ariel', number: 46, bible: { book: 'psalms', number: 145, verse: 9, text: 'טֽוֹב־יְהֹוָ֥ה לַכֹּ֑ל וְ֜רַֽחֲמָ֗יו עַל־כָּל־מַֽעֲשָֽׂיו' } },
  { name: 'עשל', englishName: 'Asaliah', number: 47, bible: { book: 'psalms', number: 92, verse: 6, text: 'מַה־גָּֽדְל֣וּ מַֽעֲשֶׂ֣יךָ יְהֹוָ֑ה מְ֜אֹ֗ד עָֽמְק֥וּ מַחְשְׁבֹתֶֽיךָ' } },
  { name: 'מיה', englishName: 'Mihael', number: 48, bible: { book: 'psalms', number: 98, verse: 2, text: 'הוֹדִ֣יעַ יְ֖הֹוָה יְשֽׁוּעָת֑וֹ לְעֵינֵ֥י הַ֜גּוֹיִ֗ם גִּלָּ֥ה צִדְקָתֽוֹ' } },
  { name: 'והו', englishName: 'Vehuel', number: 49, bible: { book: 'psalms', number: 145, verse: 3, text: 'גָּ֘ד֥וֹל יְהֹוָ֣ה וּמְהֻלָּ֣ל מְאֹ֑ד וְ֜לִגְדֻלָּת֗וֹ אֵ֣ין חֵֽקֶר' } },
  { name: 'דני', englishName: 'Daniel', number: 50, bible: { book: 'psalms', number: 145, verse: 8, text: 'חַנּ֣וּן וְרַח֣וּם יְהֹוָ֑ה אֶ֥רֶךְ אַ֜פַּ֗יִם וּגְדָל־חָֽסֶד' } },
  { name: 'החש', englishName: 'Hahasiah', number: 51, bible: { book: 'psalms', number: 104, verse: 31, text: 'יְהִ֚י כְב֣וֹד יְהֹוָ֣ה לְעוֹלָ֑ם יִשְׂמַ֖ח יְהֹוָ֣ה בְּמַֽעֲשָֽׂיו' } },
  { name: 'עמם', englishName: 'Imamiah', number: 52, bible: { book: 'psalms', number: 7, verse: 18, text: 'אוֹדֶ֣ה יְהֹוָ֣ה כְּצִדְק֑וֹ וַֽ֜אֲזַמְּרָ֗ה שֵֽׁם־יְהֹוָ֥ה עֶלְיֽוֹן' } },
  { name: 'ננא', englishName: 'Nanael', number: 53, bible: { book: 'psalms', number: 119, verse: 75, text: 'יָדַ֣עְתִּי יְ֖הֹוָה כִּי־צֶ֣דֶק מִשְׁפָּטֶ֑יךָ וֶֽ֜אֱמוּנָ֗ה עִנִּיתָֽנִי' } },
  { name: 'נית', englishName: 'Nithael', number: 54, bible: { book: 'psalms', number: 103, verse: 19, text: 'יְֽהוָ֗ה בַּ֖שָּׁמַיִם הֵכִ֣ין כִּסְא֑וֹ וּ֜מַלְכוּת֗וֹ בַּכֹּ֥ל מָשָֽׁלָה' } },
  { name: 'מבה', englishName: 'Mebahiah', number: 55, bible: { book: 'psalms', number: 102, verse: 13, text: 'וְאַתָּ֣ה יְ֖הֹוָה לְעוֹלָ֣ם תֵּשֵׁ֑ב וְ֜זִכְרְךָ֗ לְדֹ֣ר וָדֹֽר' } },
  { name: 'פוי', englishName: 'Poyel', number: 56, bible: { book: 'psalms', number: 145, verse: 14, text: 'סוֹמֵ֣ךְ יְ֖הֹוָה לְכָל־הַנֹּֽפְלִ֑ים וְ֜זוֹקֵ֗ף לְכָל־הַכְּפוּפִֽים' } },
  { name: 'נמם', englishName: 'Nemamiah', number: 57, bible: { book: 'psalms', number: 115, verse: 11, text: 'יִרְאֵ֣י יְ֖הֹוָה בִּטְח֣וּ בַֽיהֹוָ֑ה עֶזְרָ֖ם וּמָֽגִנָּ֣ם הֽוּא' } },
  { name: 'ייל', englishName: 'Yeialel', number: 58, bible: { book: 'psalms', number: 6, verse: 4, text: 'וְנַפְשִׁי נִבְהֲלָ֣ה מְאֹ֑ד וְאַתָּ֥ה (כתיב וְאַתָּ֥) יְ֜הֹוָ֗ה עַד־מָתָֽי' } },
  { name: 'הרח', englishName: 'Harahel', number: 59, bible: { book: 'psalms', number: 113, verse: 3, text: 'מִמִּזְרַח־שֶׁ֥מֶשׁ עַד־מְבוֹא֑וֹ מְ֜הֻלָּ֗ל שֵׁ֣ם יְהֹוָֽה' } },
  { name: 'מצר', englishName: 'Mitzrael', number: 60, bible: { book: 'psalms', number: 145, verse: 17, text: 'צַדִּ֣יק יְ֖הֹוָה בְּכָל־דְּרָכָ֑יו וְ֜חָסִ֗יד בְּכָל־מַֽעֲשָֽׂיו' } },
  { name: 'ומב', englishName: 'Umabel', number: 61, bible: { book: 'psalms', number: 113, verse: 2, text: 'יְהִ֚י שֵׁ֣ם יְהֹוָ֣ה מְבֹרָ֑ךְ מֵֽ֜עַתָּ֗ה וְעַד־עוֹלָֽם' } },
  { name: 'יהה', englishName: 'Iah\'hel', number: 62, bible: { book: 'psalms', number: 119, verse: 159, text: 'רְאֵה כִּי־פִקּוּדֶ֣יךָ אָהָ֑בְתִּי יְ֜הֹוָ֗ה כְּחַסְדְּךָ֥ חַיֵּֽנִי' } },
  { name: 'ענו', englishName: 'Anauel', number: 63, bible: { book: 'psalms', number: 100, verse: 2, text: 'עִבְד֣וּ אֶת־יְהֹוָ֣ה בְּשִׂמְחָ֑ה בֹּ֥אוּ לְ֜פָנָ֗יו בִּרְנָנָֽה' } },
  { name: 'מחי', englishName: 'Mehiel', number: 64, bible: { book: 'psalms', number: 33, verse: 18, text: 'הִנֵּ֚ה עֵ֣ין יְ֖הֹוָה אֶל־יְרֵאָ֑יו לַֽמְיַֽחֲלִ֥ים לְחַסְדּֽוֹ' } },
  { name: 'דמב', englishName: 'Damabiah', number: 65, bible: { book: 'psalms', number: 90, verse: 13, text: 'שׁוּבָ֣ה יְ֖הֹוָה עַד־מָתָ֑י וְ֜הִנָּחֵ֗ם עַל־עֲבָדֶֽיךָ' } },
  { name: 'מנק', englishName: 'Manakel', number: 66, bible: { book: 'psalms', number: 38, verse: 22, text: 'אַל־תַּֽעַזְבֵ֥נִי יְהֹוָ֑ה אֱ֜לֹהַ֗י אַל־תִּרְחַ֥ק מִמֶּֽנִּי' } },
  { name: 'איע', englishName: 'Eyael', number: 67, bible: { book: 'psalms', number: 37, verse: 4, text: 'וְהִתְעַנַּ֥ג עַל־יְהֹוָ֑ה וְיִֽתֶּן־לְ֜ךָ֗ מִשְׁאֲלֹ֥ת לִבֶּֽךָ' } },
  { name: 'חבו', englishName: 'Habuhiah', number: 68, bible: { book: 'psalms', number: 106, verse: 1, text: 'הַֽלְלוּיָ֨הּ | הוֹד֣וּ לַֽיהֹוָ֣ה כִּי־ט֑וֹב כִּ֖י לְעוֹלָ֣ם חַסְדּֽוֹ' } },
  { name: 'ראה', englishName: 'Rochel', number: 69, bible: { book: 'psalms', number: 16, verse: 5, text: 'יְֽהֹוָ֗ה מְנָ֣ת חֶלְקִ֣י וְכוֹסִ֑י אַ֜תָּ֗ה תּוֹמִ֥יךְ גּוֹרָלִֽי' } },
  { name: 'יבמ', englishName: 'Jabamiah', number: 70, bible: { book: 'genesis', number: 1, verse: 1, text: 'בְּרֵאשִׁ֖ית בָּרָ֣א אֱלֹהִ֑ים אֵ֥ת הַשָּׁמַ֖יִם וְאֵ֥ת הָאָֽרֶץ' } },
  { name: 'היי', englishName: 'Haiaiel', number: 71, bible: { book: 'psalms', number: 109, verse: 30, text: 'א֘וֹדֶ֚ה יְהֹוָ֣ה מְאֹ֣ד בְּפִ֑י וּבְת֖וֹךְ רַבִּ֣ים אֲהַֽלְלֶֽנּוּ' } },
  { name: 'מום', englishName: 'Mumiah', number: 72, bible: { book: 'psalms', number: 116, verse: 7, text: 'שׁוּבִ֣י נַ֖פְשִׁי לִמְנוּחָ֑יְכִי כִּ֥י יְ֜הֹוָ֗ה גָּ֘מַ֥ל עָלָֽיְכִי' } },
].map(o => { o.bible.text += '׃'; return o }); // really ugly hack to solve a problem with how the editors are parsing Hebrew

export const tarotSuitNames = ['clubs', 'diamonds', 'hearts', 'spades'];
export const tarotSuitSymbols = ['♣', '♦', '♥', '♠'];

export const TAROT_SUIT_SYMBOL_NAMES = {
  '♣': { en: 'of Clubs', pt: 'de Bastões' },
  '♦': { en: 'of Diamonds', pt: 'de Ouros' },
  '♥': { en: 'of Hearts', pt: 'de Copas' },
  '♠': { en: 'of Spades', pt: 'de Espadas' },
};

// 'clubs (♣), diamonds (♦), hearts (♥), and spades (♠)'
const tarotCards = [
  'Q♣',
  '2♣',
  '3♣',
  '3♣',
  '4♣',
  'J♦',
  'J♦',
  '5♦',
  '6♦',
  '6♦',
  '7♦',
  'K♠',
  'K♠',
  '8♠',
  '9♠',
  '9♠',
  '10♠',
  'Q♥',
  'Q♥',
  '2♥',
  '3♥',
  '3♥',
  '4♥',
  'J♣',
  'J♣',
  '5♣',
  '6♣',
  '6♣',
  '7♣',
  'K♦',
  'K♦',
  '8♦',
  '9♦',
  '9♦',
  '10♦',
  'Q♠',
  'Q♠',
  '2♠',
  '3♠',
  '3♠',
  '4♠',
  'J♥',
  'J♥',
  '5♥',
  '6♥',
  '6♥',
  '7♥',
  'K♣',
  'K♣',
  '8♣',
  '9♣',
  '9♣',
  '10♣',
  'Q♦',
  'Q♦',
  '2♦',
  '3♦',
  '3♦',
  '4♦',
  'J♠',
  'J♠',
  '5♠',
  '6♠',
  '6♠',
  '7♠',
  'K♥',
  'K♥',
  '8♥',
  '9♥',
  '9♥',
  '10♥',
  'Q♣',
];

const decans: BodyKey[] = [
  'mars',
  'sun',
  'venus',
  'mercury',
  'moon',
  'saturn',
  'jupiter',
];

export const posToSignDMS = (pos: number) => {
  const coord = translateCoordinate(pos, []);
  const dms = degToDMSVals(coord.pos);

  return {
    ...coord,
    ...dms,
  };
};

export const signDMSToPos = (sign: string, d: number, m: number, s: number): number => {
  const signStart = signNames.findIndex(s => s === sign) * 30;
  return signStart + d + (m / 60) + (s / 3600);
};

export const signNames = [
  'aries',
  'taurus',
  'gemini',
  'cancer',
  'leo',
  'virgo',
  'libra',
  'scorpio',
  'sagittarius',
  'capricorn',
  'aquarius',
  'pisces',
];

export const signs = [
  '♈',
  '♉',
  '♊',
  '♋',
  '♌',
  '♍',
  '♎',
  '♏',
  '♐',
  '♑',
  '♒',
  '♓',
];

export const keys = [
  'C',
  'F',
  'Bb',
  'Eb',
  'Ab',
  'Db',
  'F#',
  'B',
  'E',
  'A',
  'D',
  'G',
];

export type ClassicPlanets = 'saturn' | 'jupiter' | 'mars' | 'sun' | 'venus' | 'mercury' | 'moon';

export const modes: Record<ClassicPlanets, string> = {
  saturn: 'mixolydian',
  jupiter: 'lydian',
  mars: 'phrygian',
  sun: 'dorian',
  venus: 'hypolydian',
  mercury: 'hypophrygian',
  moon: 'hypodorian',
};

// d eb f g ab b c

export const celestialBodies = [
  'sun',
  'moon',
  'mercury',
  'venus',
  'mars',
  'jupiter',
  'saturn',
  'uranus',
  'neptune',
  'pluto',
  'meanAscendingNode',
  'meanDescendingNode',
];

export const planetSephirahMap = {
  'sun': 'tipheret',
  'moon': 'yesod',
  'mercury': 'hod',
  'venus': 'netzach',
  'mars': 'gevurah',
  'jupiter': 'chesed',
  'saturn': 'binah',
  'uranus': 'chokhmah',
  'neptune': 'keter',
  'pluto': 'daath',
};


/*
export const celestialBodies: [
  'sun': '☉︎',
  'moon': '☾',
  'mercury': '☿',
  'venus': '♀',
  'mars': '♂',
  'jupiter': '♃',
  'saturn': '♄',
  'uranus': '♅',
  'neptune': '♆',
  'pluto': '♇',
  'meanAscendingNode': '☊',
  'meanDescendingNode': '☋',
};
*/
