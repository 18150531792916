export const MoonSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" preserveAspectRatio="xMidYMid meet" version="1.0" {...props}>
      <path
        id="path2"
        d="m 8.5,1 c -2.7617188,0 -5,2.2382812 -5,5 0,2.7617188 2.2382812,5 5,5 C 6.7148438,9.96875 5.6132812,8.0625 5.6132812,6 5.6132812,3.9375 6.7148438,2.03125 8.5,1 Z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
}