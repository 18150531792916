"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcAberration = exports.calcDeflection = exports.getP0 = exports.calcVelocityAberration = void 0;
const math_1 = require("../math");
const constants_1 = require("../constants");
const heliocentric_1 = require("./heliocentric");
const calcVelocityAberration = function (body, q, e, earthBody, observer) {
    const p0 = (0, exports.getP0)(q, e);
    let p = [0, 0, 0];
    let ptemp = [0, 0, 0]; // double
    let P;
    let Q;
    let E;
    let t = 1;
    let x;
    let y; // double
    E = 0.0;
    for (let i = 0; i < 3; i++) {
        E += e[i] * e[i];
    }
    E = Math.sqrt(E);
    for (let k = 0; k < 2; k++) {
        P = 0.0;
        Q = 0.0;
        for (let i = 0; i < 3; i++) {
            y = q[i];
            x = y - e[i];
            p[i] = x;
            Q += y * y;
            P += x * x;
        }
        P = Math.sqrt(P);
        Q = Math.sqrt(Q);
        /* Note the following blows up if object equals sun. */
        t = (P + 1.97e-8 * Math.log((E + P + Q) / (E - P + Q))) / 173.1446327;
        body = (0, heliocentric_1.calcHeliocentricPosition)(observer.date.julian - t, body, q, ptemp);
    }
    body.lightTime = 1440.0 * t;
    /* Final object-earth vector and the amount by which it changed.
     */
    for (let i = 0; i < 3; i++) {
        x = q[i] - e[i];
        p[i] = x;
        body.locals.dp[i] = x - p0[i];
    }
    body.aberration = (0, math_1.showcor)(p0, body.locals.dp);
    /* Calculate dRA/dt and dDec/dt.
     * The desired correction of apparent coordinates is relative
     * to the equinox of date, but the coordinates here are
     * for J2000.  This introduces a slight error.
     *
     * Estimate object-earth vector t days ago.  We have
     * p(?) = q(J-t) - e(J), and must adjust to
     * p(J-t)  =  q(J-t) - e(J-t)  =  q(J-t) - (e(J) - Vearth * t)
     *         =  p(?) + Vearth * t.
     */
    const velocityEarth = (0, heliocentric_1.calcVelocityEarth)(observer.date.julian, earthBody);
    for (let i = 0; i < 3; i++) {
        p[i] += velocityEarth[i] * t;
    }
    var d = (0, math_1.deltap)(p, p0); /* see dms */
    body.locals.dradt = d.dr;
    body.locals.ddecdt = d.dd;
    body.locals.dradt /= t;
    body.locals.ddecdt /= t;
};
exports.calcVelocityAberration = calcVelocityAberration;
const getP0 = (q, e) => {
    let p0 = [0, 0, 0];
    /* save initial q-e vector for display */
    for (let i = 0; i < 3; i++) {
        p0[i] = q[i] - e[i];
    }
    return p0;
};
exports.getP0 = getP0;
const calcDeflection = (p, q, e, body, result) => {
    var C; // double
    var i; // int
    C = 1.974e-8 / (body.locals.SE * (1.0 + body.locals.qe));
    for (i = 0; i < 3; i++) {
        body.locals.dp[i] = C * (body.locals.pq * e[i] / body.locals.SE - body.locals.ep * q[i] / body.locals.SO);
        p[i] += body.locals.dp[i];
    }
    const sunElongation = Math.acos(-body.locals.ep) / constants_1.DEG_TO_RADIANS;
    const lightDeflection = (0, math_1.showcor)(p, body.locals.dp);
    return { ...(result !== null && result !== void 0 ? result : {}), sunElongation, lightDeflection };
};
exports.calcDeflection = calcDeflection;
const calcAberration = (p, earthBody, observer, body, result) => {
    var A, B, C; // double
    var betai, pV; // double
    var x = [];
    var V = []; // double
    var i; // int
    /* Calculate the velocity of the earth (see vearth.c).
     */
    const velocityEarth = (0, heliocentric_1.calcVelocityEarth)(observer.date.julian, earthBody);
    betai = 0.0;
    pV = 0.0;
    for (i = 0; i < 3; i++) {
        A = velocityEarth[i] / constants_1.SPEED_LIGHT_AU_DAY;
        V[i] = A;
        betai += A * A;
        pV += p[i] * A;
    }
    /* Make the adjustment for aberration.
     */
    betai = Math.sqrt(1.0 - betai);
    C = 1.0 + pV;
    A = betai / C;
    B = (1.0 + pV / (1.0 + betai)) / C;
    for (i = 0; i < 3; i++) {
        C = A * p[i] + B * V[i];
        x[i] = C;
        body.locals.dp[i] = C - p[i];
    }
    result = (0, math_1.showcor)(p, body.locals.dp, result);
    for (i = 0; i < 3; i++) {
        p[i] = x[i];
    }
    return result;
};
exports.calcAberration = calcAberration;
