"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saturn = void 0;
exports.saturn = {
    key: 'saturn',
    type: 'heliocentric',
    epoch: 2446800.5, // 05.01.1987
    inclination: 2.4858,
    node: 113.555,
    perihelion: 337.969,
    semiAxis: 9.54050,
    dailyMotion: 0.0334510,
    eccentricity: 0.052786,
    anomaly: 159.6327,
    equinox: 2446800.,
    magnitude: -8.88,
    semiDiameter: 82.73,
    distance: 0.0, // computed
    longitude: 0.0, // computed
    perturbation: 0.0, // computed
    ptable: {
        maxargs: 9,
        max_harmonic: [0, 0, 1, 0, 8, 18, 9, 5, 0],
        max_power_of_t: 7,
        distance: 9.5575813548599999e+00,
        timescale: 3652500.0,
        trunclvl: 1.0,
        lon_tbl: [
            1788381.26240, 2460423.68044, 1370113.15868, 415406.99187,
            72040.39885, 12669.58806, 439960754.85333, 180256.80433,
            18.71177, -40.37092,
            66531.01889, -195702.70142, 57188.02694, -179110.60982,
            -19803.06520, -58084.15705, -9055.13344, -31146.10779,
            11245.43286, -3247.59575, 459.48670, 2912.82402,
            -4.06749, -13.53763,
            -30.55598, -4.51172,
            1.48832, 0.37139,
            597.35433, 1193.44545, -297.50957, 976.38608,
            -263.26842, 34.84354, -6.77785, -29.92106,
            -0.16325, -0.18346,
            -0.15364, -0.08227,
            0.20180, 0.02244,
            0.04672, -0.29867,
            -0.04143, -0.00760,
            -0.17046, -0.00778,
            0.04200, 0.23937, -0.00098, -0.05236,
            -0.02749, -0.01813,
            0.00637, 0.01256,
            -0.04506, 0.04448,
            -0.00105, 0.06224,
            0.01157, 0.17057, -0.03214, 0.18178,
            -0.22059, -0.01472,
            -0.24213, 0.04309, 0.03436, 0.44873,
            0.01350, -0.01931,
            -0.80618, -0.56864, 0.29223, -0.03101,
            0.04171, 0.02264,
            -0.01264, -0.01645,
            0.01774, 0.06374,
            -0.01925, -0.03552,
            0.10473, -0.04119,
            0.08045, 0.04635,
            -3.01112, -9.26158, 8.13745, 1.88838,
            -0.15184, 0.16898,
            -0.22091, 0.29070, -0.03259, 0.06938,
            -0.08499, -0.21688, 0.01848, -0.05594,
            0.50100, -0.00027, 0.13300, 0.12055,
            0.03039, 0.03854,
            -1.55287, 2.55618, -0.45497, -0.29895,
            -0.93268, 0.83518,
            -0.32785, 7.03878, -1.66649, 2.75564,
            -0.29459, 0.01050,
            0.08293, -0.03161,
            -0.12750, -0.04359,
            0.04217, 0.07480,
            -114.43467, 49.47867, -66.52340, -26.27841,
            15.48190, -13.06589, 3.28365, 5.02286,
            -0.17155, -0.07404,
            0.00924, -0.07407,
            -0.02922, 0.06184,
            108.04882, 86.09791, -155.12793, 208.10044,
            -311.72810, -268.92703, 74.57561, -420.03057,
            -0.07893, 0.09246,
            -0.66033, -0.39026, -0.13816, -0.08490,
            -36.79241, -78.88254, 71.88167, -68.05297,
            51.71616, 65.77970, -43.59328, 23.51076,
            -0.02029, -0.32943,
            -8.82754, 1.48646, -3.12794, 2.12866,
            -0.06926, 0.44979,
            0.00621, -0.51720,
            -3.82964, -1.48596, -0.11277, -3.21677,
            0.81705, -0.19487,
            -0.06195, 0.10005, -0.02208, 0.00108,
            0.00455, -0.03825,
            0.01217, -0.00599,
            -0.17479, -0.47290, 0.85469, 1.12548,
            -0.80648, -0.44134,
            -0.01559, -0.07061,
            0.01268, -0.01773,
            0.01308, -0.03461,
            -0.71114, 1.97680, -0.78306, -0.23052,
            0.94475, -0.10743,
            0.18252, -8.03174,
            0.00734, 0.04779,
            0.12334, -0.03513,
            0.01341, 0.02461,
            0.02047, -0.03454,
            0.02169, -0.01921,
            -1.12789, 0.09304, 0.14585, 0.36365,
            0.03702, 0.10661, -0.00464, -1.72706,
            -0.00769, -0.04635,
            -0.01157, 0.00099,
            10.92646, 1.96174, 2.91142, 4.74585,
            -0.29832, 0.75543,
            0.05411, 1.05850,
            0.38846, -0.16265,
            1.52209, 0.12185, 0.18650, 0.35535,
            -278.33587, -82.58648, -160.00093, -225.55776,
            35.17458, -77.56672, 10.61975, 3.33907,
            0.06090, 2.17429,
            -4.32981, -5.84246, 11.43116, 20.61395,
            -0.65772, 1.28796,
            1224.46687, -3113.15508, 3798.33409, -137.28735,
            -256.89302, 2227.35649, -779.78215, -260.37372,
            11.73617, -13.25050, -0.75248, -2.87527,
            -8.38102, 17.21321,
            -61784.69616, 39475.02257, -54086.68308, 54550.85490,
            -16403.69351, 29602.70098, 14672.06363, 16234.17489,
            15702.37109, -22086.30300, -22889.89844, -1245.88352,
            1.48864, 19.75000, 0.78646, 3.29343,
            -1058.13125, 4095.02368, -2793.78506, 1381.93282,
            -409.19381, -772.54270, 161.67509, -34.15910,
            -514.27437, 27.34222, -311.04046, 48.01030,
            -43.36486, 16.19535, -0.73816, -0.81422,
            287.32231, -110.44135, 200.43610, 37.98170,
            17.73719, 34.40023, -2.46337, 1.48125,
            0.09042, -0.11788,
            0.37284, 0.51725, 0.00597, 0.14590,
            -0.01536, 0.00980,
            0.00721, 0.02023,
            0.00027, 0.02451,
            -0.72448, -0.71371, 0.29322, 0.18359,
            0.72719, -0.37154, 0.14854, -0.02530,
            0.23052, 0.04258,
            4.82082, 0.01885, 3.11279, -0.63338,
            0.10559, -0.02146,
            -0.01672, 0.03412,
            0.00605, 0.06415,
            -0.89085, 1.51929, -0.36571, 0.39317,
            12.05250, -3.79392, 3.96557, -3.51272,
            -0.17953, 12.30669,
            -0.05083, -0.11442,
            0.02013, -0.02837,
            -0.02087, -0.01599,
            0.49190, 0.30360, 0.01316, 0.17649,
            0.21193, -0.09149, -0.07173, -0.05707,
            4.24196, -1.25155, 1.81336, 0.68887,
            -0.01675, 0.20772,
            -0.04117, -0.03531,
            -0.02690, -0.02766,
            37.54264, 10.95327, 8.05610, 30.58210,
            -12.68257, 1.72831, 0.13466, -3.27007,
            0.01864, -0.00595,
            0.03676, 0.14857, -0.07223, 0.06179,
            0.44878, -1.64901, -20.06001, 0.63384,
            -4.97849, 4.78627, 29.87370, 7.29899,
            0.00047, -0.00155,
            0.00314, 0.01425,
            -0.17842, -0.08461,
            -1.61020, -8.47710, 6.85048, -4.38196,
            1.05809, 2.68088,
            -0.01027, -0.00833,
            0.06834, -0.04205,
            0.03330, -0.01271,
            0.01301, -0.01358,
            0.03537, 0.03612, 0.02962, 0.62471,
            -0.30400, -0.64857,
            0.01773, 0.01890,
            0.01426, -0.00226,
            -0.50957, -0.01955, -0.09702, 1.09983,
            0.64387, -0.02755,
            0.26604, 0.30684, 0.06354, 0.05114,
            -0.00058, -0.04672,
            -0.00828, 0.00712,
            -0.00440, 0.00029,
            -0.01601, 0.03566,
            0.13398, -0.02666,
            -0.06752, -0.43044, 0.07172, -0.01999,
            -0.01761, -0.05357,
            0.06104, 0.29742, -0.08785, 0.05241,
            -6.57162, -4.20103, 0.03199, -6.46187,
            1.32846, -0.51137,
            0.06358, 0.37309,
            -1.46946, 2.34981,
            -0.18712, 0.11618,
            240.62965, -107.21962, 219.81977, 84.04246,
            -62.22931, 68.35902, -9.48460, -32.62906,
            5.57483, -1.82396, 1.00095, -0.39774,
            7.87054, 11.45449,
            -432.67155, 55064.72398, 12444.62359, 54215.28871,
            8486.03749, 12297.48243, -333.27968, 1147.93192,
            1403.73797, 990.40885, -3.84938, -722.43963,
            16.83276, 96.48787, 7.04834, 38.22208,
            0.63843, 2.61007,
            230.73221, 171.64166, 1.96751, 287.80846,
            -85.21762, 31.33649, -2.25739, -11.28441,
            0.04699, 0.06555,
            -0.08887, 1.70919, 0.09477, 0.26291,
            -0.15490, 0.16009,
            1.93274, 1.01953, 0.36380, 1.29582,
            -0.13911, 0.14169,
            -0.00491, -0.00030,
            -0.08908, -0.10216,
            -0.03265, -0.03889,
            0.40413, -1.12715, -0.94687, -0.04514,
            0.02487, -0.01048,
            0.39729, 2.82305, -0.61100, 1.11728,
            -0.13083, -0.04965,
            -0.00602, -0.02952,
            -6.13507, 13.73998, -15.70559, -1.28059,
            2.64422, -9.33798, 3.26470, 1.56984,
            -0.00572, 0.09992,
            -8.80458, -8.23890, -11.51628, 9.47904,
            11.31646, 4.29587,
            -2.41367, -0.05883, -0.80022, -1.02706,
            0.21461, -0.06864,
            0.01882, 0.01798,
            0.27614, -0.01007, 0.04362, 0.07560,
            0.05519, 0.23435,
            -0.09389, 0.01613,
            0.01298, 0.04691,
            -0.02665, -0.03582,
            0.60080, -4.28673, 1.87316, -1.05840,
            0.13248, 0.40887,
            -0.67657, 0.67732, 0.05522, 0.07812,
            -0.17707, -0.07510,
            0.24885, 10.63974, -7.40226, -2.33827,
            2.75463, -32.51518,
            0.05140, 0.01555,
            180.43808, 263.28252, 384.50646, -76.53434,
            -93.50706, -220.50123, -81.91610, 103.92061,
            30.90305, -2.89292,
            -0.06634, -0.37717, -0.01945, -0.05936,
            29.27877, -59.73705, 35.86569, -18.36556,
            3.88812, 4.82090, -0.70903, 0.06615,
            0.01558, -0.01854,
            0.16209, 0.12682, 0.02508, 0.02406,
            -0.03078, -0.01737, -0.00033, -0.00020,
            0.01023, 0.05972,
            -0.03373, -0.07289,
            -2.08162, -0.14717, -0.64233, -0.75397,
            0.11752, -0.09202,
            4.42981, -4.19241, 5.02542, 5.03467,
            -4.22983, 2.80794,
            3.03016, -2.74373, -1.11490, -2.72378,
            -0.63131, 0.74864,
            -0.00759, -0.00675,
            0.03615, -0.01806,
            -2.71920, -1.50954, 0.54479, -1.92088,
            0.66427, 0.32228,
            -2.55188, -0.65332, -2.73798, 2.10182,
            1.54407, 3.01357,
            38.76777, 23.54578, 27.29884, -14.93005,
            -7.50931, -5.66773, 0.30142, 1.52416,
            0.00634, 0.09697, -0.00748, 0.01433,
            0.02936, 0.53228, -0.03603, 0.06345,
            0.30816, -1.07925, 0.46709, -0.21568,
            0.01663, 0.10810,
            -0.42511, 0.35872, -0.19662, -6.74031,
            1.05776, 1.86205, 1.08919, 0.10483,
            -0.03368, -0.21535,
            0.07556, -0.27104, 0.05142, -0.03812,
            1.20189, -1.36782, 1.35764, 1.39387,
            -1.19124, 0.77347,
            -0.54760, -0.26295, -0.07473, 0.23043,
            2.82621, -0.23524, 0.47352, -0.81672,
            -0.08515, 0.04700,
            0.55355, -0.40138, 0.22255, 0.12236,
            -0.09110, 0.31982, 0.39404, -0.17898,
            -0.00056, 0.00014,
            -0.02012, 0.03102,
            0.43236, -0.10037, -0.00961, 0.07440,
            -0.07076, -1.97272,
            0.25555, -0.21832, -0.00837, -0.08393,
            0.01531, 0.00627,
            0.33193, 0.70765, -0.43556, 0.28542,
            -0.23190, -0.04293, -0.08062, 0.13427,
            0.23763, -0.17092, 0.09259, 0.05155,
            0.08065, -0.11943,
            -0.02174, -0.68899,
            -0.01875, -0.01746,
            0.13604, 0.29280, -0.17871, 0.11799,
            0.02003, 0.04065,
            0.01343, -0.06060,
            -0.01290, -0.26068,
            -0.09033, 0.02649,
            -0.00092, -0.03094,
            -0.00770, -0.10447,
            -0.04113, 0.01259,
            -0.00469, -0.04346,
            -0.00010, 0.06547
        ],
        lat_tbl: [
            -567865.62548, -796277.29029, -410804.00791, -91793.12562,
            -6268.13975, 398.64391, -710.67442, 175.29456,
            -0.87260, 0.18444,
            -1314.88121, 20709.97394, -1850.41481, 20670.34255,
            -896.96283, 6597.16433, -179.80702, 613.45468,
            17.37823, -13.62177, -0.36348, 12.34740,
            0.47532, 0.48189,
            0.27162, -0.20655,
            -0.23268, 0.05992,
            46.94511, 15.78836, 21.57439, 23.11342,
            -0.25862, 5.21410, -0.22612, -0.05822,
            -0.00439, -0.01641,
            -0.01108, -0.00608,
            0.00957, 0.00272,
            -0.00217, 0.00001,
            -0.00534, -0.00545,
            0.00277, -0.00843,
            0.00167, -0.00794, 0.00032, -0.00242,
            -0.00002, -0.00041,
            -0.00025, 0.00031,
            0.00062, -0.00060,
            0.00083, 0.00032,
            0.00527, -0.00211, 0.00054, 0.00004,
            -0.02769, -0.01777,
            0.00247, 0.00097, 0.00020, -0.00232,
            0.00044, -0.00035,
            -0.00072, 0.01341, 0.00325, -0.01159,
            0.00079, -0.00078,
            -0.00009, 0.00066,
            0.00222, 0.00002,
            0.00013, -0.00161,
            0.01374, -0.05305,
            0.00478, -0.00283,
            0.16033, 0.13859, 0.33288, -0.16932,
            -0.00316, 0.00625,
            -0.00309, 0.01687, 0.00001, 0.00486,
            0.00401, -0.01805, -0.00048, -0.00407,
            -0.01329, 0.01311, -0.00591, 0.00166,
            0.00830, 0.00665,
            -0.80207, 0.22994, -0.34687, 0.08460,
            -0.11499, -0.01449,
            -0.01574, 0.78813, -0.03063, 0.28872,
            -0.00337, 0.01801,
            -0.01703, -0.00929,
            -0.00738, 0.03938,
            0.05616, -0.00516,
            -3.09497, 30.13091, -3.14968, 17.62201,
            -0.73728, 2.46962, -0.11233, 0.03450,
            -0.07837, -0.01573,
            -0.01595, 0.00394,
            0.00174, 0.01470,
            6.83560, -2.37594, 4.95125, 3.24711,
            2.44781, 5.17159, 1.99820, -2.38419,
            0.00840, 0.03614,
            -0.00209, -0.30407, -0.02681, -0.06128,
            1.50134, 11.82856, 4.39644, 6.98850,
            -4.17679, 5.73436, -9.66087, 1.98221,
            -0.29755, 0.08019,
            -0.24766, -8.54956, -1.74494, -3.36794,
            -0.32661, -0.00722,
            0.14141, 0.01023,
            -1.21541, -2.58470, 0.38983, -1.70307,
            0.31209, -0.10345,
            0.02593, 0.02178, 0.00289, 0.00393,
            -0.00236, -0.00373,
            -0.00270, -0.00049,
            -0.06282, -0.00443, -0.02439, -0.02254,
            -0.02220, 0.03532,
            -0.00072, 0.00010,
            -0.00049, -0.00112,
            0.00086, 0.00112,
            0.10135, -0.10972, 0.08357, 0.00155,
            0.04363, -0.00201,
            -0.01996, -0.01341,
            -0.00039, -0.00042,
            -0.00294, 0.00070,
            0.00005, -0.00027,
            0.00070, -0.00076,
            0.00234, -0.00239,
            -0.08365, -0.08531, -0.03531, 0.15012,
            -0.01995, -0.01731, -0.00370, -0.00745,
            -0.00315, -0.00079,
            -0.00120, -0.00145,
            -0.99404, -1.31859, 0.03584, -0.83421,
            0.10720, -0.05768,
            0.06664, -0.09338,
            -0.01814, -0.00003,
            -0.05371, -0.06458, -0.00100, -0.01298,
            -7.08710, -23.13374, 4.18669, -19.94756,
            4.85584, -3.37187, 0.58851, 0.31363,
            0.01994, 0.27494,
            -1.37112, 2.61742, 0.52477, -0.46520,
            -0.13183, 0.26777,
            836.90400, -484.65861, 815.99098, 236.54649,
            -32.38814, 288.95705, -68.17178, -18.87875,
            -1.79782, -3.68662, -1.27310, -0.65697,
            -3.67530, 2.10471,
            -13758.97795, 4807.62301, -14582.14552, 9019.73021,
            -3202.60105, 4570.16895, 2078.68911, 2892.62326,
            -2399.35382, 3253.16198, -8182.38152, -3588.77680,
            -0.16505, 1.08603, 0.53388, 0.87152,
            61.53677, 538.43813, -407.32927, 322.27446,
            -148.71585, -179.37765, 54.07268, -34.12281,
            -14.76569, -17.95681, -10.82061, -6.39954,
            -2.10954, 0.67063, 0.22607, -0.43648,
            20.90476, -45.48667, 30.39436, -14.20077,
            5.17385, 5.12726, -0.66319, 0.55668,
            0.02269, -0.00016,
            0.07811, 0.00111, 0.01603, 0.01020,
            -0.00107, 0.00494,
            -0.00077, -0.00084,
            -0.00196, 0.00081,
            -0.03776, 0.01286, -0.00652, -0.01450,
            0.05942, -0.08612, 0.01093, -0.01644,
            0.02147, -0.00592,
            0.36350, -0.00201, 0.14419, -0.10070,
            -0.00491, -0.01771,
            -0.00053, -0.00033,
            0.00146, 0.00048,
            0.00582, 0.04423, -0.00549, 0.00983,
            0.27355, -0.38057, 0.24001, -0.05441,
            -0.07706, 0.14269,
            -0.00059, -0.00154,
            -0.00013, -0.00088,
            -0.00046, 0.00029,
            -0.00276, -0.00507, 0.00075, -0.00076,
            0.01806, 0.00862, -0.00510, -0.01364,
            -0.00029, -0.12664, 0.03899, -0.03562,
            0.00318, 0.00514,
            0.00057, 0.00201,
            0.00028, 0.00014,
            -0.47022, -0.74561, 0.40155, -0.16471,
            -0.18445, 0.34425, -0.07464, -0.13709,
            -0.01018, -0.00748,
            -0.01210, -0.04274, -0.00579, -0.00692,
            -11.09188, -1.67755, -6.62063, -13.84023,
            12.75563, -6.73501, 8.31662, 5.40196,
            0.00052, 0.00034,
            0.00128, 0.00085,
            -0.02202, -0.00599,
            -0.33458, -1.65852, 1.47003, -1.02434,
            0.87885, 1.15334,
            -0.00241, -0.00721,
            0.03154, 0.00612,
            0.00318, -0.02521,
            0.00042, 0.00213,
            -0.01094, 0.05417, -0.03989, -0.00567,
            0.00123, -0.00244,
            0.00108, 0.00242,
            -0.00138, -0.00099,
            0.04967, 0.01643, -0.00133, 0.02296,
            0.12207, 0.05584,
            0.00437, -0.04432, -0.00176, -0.00922,
            -0.00252, 0.00326,
            -0.00020, -0.00050,
            -0.00263, -0.00084,
            -0.01971, 0.00297,
            0.03076, 0.01736,
            -0.01331, 0.01121, -0.00675, 0.00340,
            -0.00256, 0.00327,
            -0.00946, 0.03377, -0.00770, 0.00337,
            0.61383, 0.71128, -0.02018, 0.62097,
            -0.07247, 0.04418,
            -0.02886, -0.03848,
            -0.44062, 0.03973,
            -0.00999, -0.04382,
            57.94459, 117.45112, -71.22893, 126.39415,
            -62.33152, -31.90754, 12.17738, -16.46809,
            -1.13298, 0.08962, -0.20532, 0.16320,
            -1.55110, -1.44757,
            -3102.08749, -7452.61957, -5009.53858, -7216.29165,
            -2476.87148, -1880.58197, -574.49433, 227.45615,
            144.50228, 379.15791, 225.36130, -443.47371,
            -8.51989, -3.75208, -4.25415, -1.59741,
            -0.43946, -0.06595,
            150.42986, 6.54937, 87.67736, 92.32332,
            -21.97187, 29.87097, -4.21636, -5.72955,
            -0.03879, -0.01071,
            -0.45985, 0.02679, -0.02448, 0.02397,
            -0.06551, -0.01154,
            1.97905, -0.82292, 1.10140, 0.30924,
            0.03389, 0.14230,
            0.00003, 0.00119,
            -0.01117, 0.00665,
            -0.00132, -0.00576,
            -0.08356, 0.08556, -0.26362, -0.12450,
            0.00509, 0.00165,
            0.02591, 0.16200, -0.03318, 0.06463,
            -0.00899, -0.00462,
            0.00102, 0.00004,
            -0.73102, 0.08299, -0.52957, -0.35744,
            0.14119, -0.24903, 0.20843, 0.14143,
            0.00031, -0.00234,
            -0.42643, -2.02084, 1.58848, -1.57963,
            0.68418, 2.07749,
            -0.45888, 0.19859, -0.30277, -0.22591,
            0.11607, -0.09705,
            0.00040, 0.00431,
            -0.02683, 0.03158, -0.01302, -0.00541,
            0.01742, -0.00006,
            -0.02231, -0.01128,
            -0.00800, 0.02055,
            -0.00346, 0.00151,
            0.56732, -0.68995, 0.27701, -0.16748,
            0.01002, 0.00043,
            0.26916, -0.57751, 0.15547, -0.15825,
            -0.02074, -0.07722,
            -8.23483, -4.02022, 0.69327, -5.91543,
            1.72440, 1.02090,
            0.00024, -0.00053,
            20.03959, 14.79136, 76.43531, -14.42019,
            -7.82608, -69.96121, -54.94229, 23.55140,
            26.60767, 14.68275,
            0.05118, -0.10401, -0.00075, -0.01942,
            -3.84266, -26.23442, 10.20395, -14.77139,
            3.40853, 2.07297, -0.53348, 0.40635,
            0.00716, -0.00189,
            0.12472, -0.02903, 0.02254, -0.00183,
            -0.00175, -0.01522, 0.00003, -0.00339,
            0.00383, -0.00168,
            0.01327, -0.03657,
            -0.08458, -0.00115, -0.03991, -0.02629,
            0.00243, -0.00505,
            0.33875, -0.16744, 0.05183, 0.01744,
            -0.24427, 0.15271,
            0.37550, -0.17378, 0.09198, -0.27966,
            -0.22160, 0.16426,
            0.00032, -0.00310,
            -0.00022, -0.00144,
            -0.06170, -0.01195, -0.00918, 0.02538,
            0.03602, 0.03414,
            -0.14998, -0.44351, 0.45512, -0.11766,
            0.35638, 0.27539,
            5.93405, 10.55777, 12.42596, -1.82530,
            -2.36124, -6.04176, -0.98609, 1.67652,
            -0.09271, 0.03448, -0.01951, 0.00108,
            0.33862, 0.21461, 0.02564, 0.06924,
            0.01126, -0.01168, -0.00829, -0.00740,
            0.00106, -0.00854,
            -0.08404, 0.02508, -0.02722, -0.06537,
            0.01662, 0.11454, 0.06747, 0.00742,
            -0.01975, -0.02597,
            -0.00097, -0.01154, 0.00164, -0.00274,
            0.02954, -0.05161, -0.02162, -0.02069,
            -0.06369, 0.03846,
            0.00219, -0.01634, -0.04518, 0.06696,
            1.21537, 0.99500, 0.68376, -0.28709,
            -0.11397, -0.06468,
            0.00607, -0.00744, 0.01531, 0.00975,
            -0.03983, 0.02405, 0.07563, 0.00356,
            -0.00018, -0.00009,
            0.00172, -0.00331,
            0.01565, -0.03466, -0.00230, 0.00142,
            -0.00788, -0.01019,
            0.01411, -0.01456, -0.00672, -0.00543,
            0.00059, -0.00011,
            -0.00661, -0.00496, -0.01986, 0.01271,
            -0.01323, -0.00764, 0.00041, 0.01145,
            0.00378, -0.00137, 0.00652, 0.00412,
            0.01946, -0.00573,
            -0.00326, -0.00257,
            -0.00225, 0.00090,
            -0.00292, -0.00317, -0.00719, 0.00468,
            0.00245, 0.00189,
            0.00565, -0.00330,
            -0.00168, -0.00047,
            -0.00256, 0.00220,
            0.00180, -0.00162,
            -0.00085, -0.00003,
            -0.00100, 0.00098,
            -0.00043, 0.00007,
            -0.00003, -0.00013
        ],
        rad_tbl: [
            -38127.94034, -48221.08524, -20986.93487, -3422.75861,
            -8.97362, 53.34259, -404.15708, -0.05434,
            0.46327, 0.16968,
            -387.16771, -146.07622, 103.77956, 19.11054,
            -40.21762, 996.16803, -702.22737, 246.36496,
            -63.89626, -304.82756, 78.23653, -2.58314,
            -0.11368, -0.06541,
            -0.34321, 0.33039,
            0.05652, -0.16493,
            67.44536, -29.43578, 50.85074, 18.68861,
            0.39742, 13.64587, -1.61284, 0.11482,
            0.01668, -0.01182,
            -0.00386, 0.01025,
            0.00234, -0.01530,
            -0.02569, -0.00799,
            -0.00429, -0.00217,
            -0.00672, 0.00650,
            0.01154, 0.00120, -0.00515, 0.00125,
            0.00236, -0.00216,
            -0.00098, 0.00009,
            -0.00460, -0.00518,
            0.00600, 0.00003,
            0.00834, 0.00095, 0.01967, 0.00637,
            -0.00558, -0.06911,
            -0.01344, -0.06589, -0.05425, -0.00607,
            -0.00247, -0.00266,
            0.08790, -0.08537, -0.00647, 0.04028,
            -0.00325, 0.00488,
            0.00111, -0.00044,
            -0.00731, 0.00127,
            -0.00417, 0.00303,
            0.05261, 0.01858,
            -0.00807, 0.01195,
            1.26352, -0.38591, -0.34825, 1.10733,
            -0.02815, -0.02148,
            -0.05083, -0.04377, -0.01206, -0.00586,
            0.03158, -0.01117, 0.00643, 0.00306,
            -0.01186, -0.05161, 0.01136, -0.00976,
            -0.00536, 0.01949,
            -1.41680, -0.81290, -0.09254, -0.24347,
            -0.14831, -0.34381,
            -2.44464, 0.41202, -0.99240, -0.33707,
            -0.01930, -0.08473,
            0.00830, 0.01165,
            -0.01604, -0.02439,
            0.00227, 0.04493,
            -42.75310, -22.65155, -9.93679, -18.36179,
            2.73773, 3.24126, -1.20698, 1.07731,
            0.00434, -0.10360,
            -0.02359, 0.00054,
            -0.02664, -0.00122,
            -19.79520, 33.11770, -53.56452, -35.41902,
            67.95039, -82.46551, 117.31843, 14.08609,
            0.06447, 0.03289,
            0.40365, -0.33397, 0.07079, -0.09504,
            -30.36873, 6.23538, -14.25988, -44.91408,
            38.53146, -16.31919, 6.99584, 22.47169,
            -0.13313, 0.28016,
            6.83715, -6.01384, 1.68531, -3.62443,
            -0.22469, -0.29718,
            0.25169, 0.13780,
            -3.64824, 1.22420, -2.48963, -1.12515,
            -0.01510, -0.56180,
            -0.03306, 0.01848, -0.00103, -0.00077,
            -0.01681, -0.00227,
            -0.00402, -0.00287,
            0.04965, -0.16190, -0.40025, 0.20734,
            0.15819, -0.25451,
            0.02467, -0.00495,
            0.00597, 0.00490,
            -0.01085, -0.00460,
            -0.71564, -0.26624, 0.03797, -0.28263,
            0.03510, 0.30014,
            2.79810, 0.07258,
            -0.01618, 0.00337,
            0.00876, 0.04438,
            0.00742, -0.00455,
            -0.01163, -0.00683,
            0.00950, 0.01275,
            -0.02124, -0.67527, -0.23635, 0.06298,
            -0.03844, 0.01010, 0.73588, -0.00271,
            0.01742, -0.00467,
            0.00017, -0.00505,
            -0.27482, 5.00521, -1.92099, 1.55295,
            -0.35919, -0.09314,
            -0.47002, 0.06826,
            0.07924, 0.16838,
            -0.04221, 0.71510, -0.16482, 0.08809,
            41.76829, -125.79427, 106.65271, -71.30642,
            36.18112, 17.36143, -1.63846, 5.02215,
            -1.08404, 0.00061,
            2.45567, -2.42818, -9.88756, 5.36587,
            -0.61253, -0.35003,
            1523.54790, 602.82184, 68.66902, 1878.26100,
            -1098.78095, -120.72600, 127.30918, -383.96064,
            -7.00838, -6.09942, -1.54187, 0.34883,
            -9.47561, -4.35408,
            -21541.63676, -32542.09807, -29720.82604, -28072.21231,
            -15755.56255, -8084.58657, -8148.87315, 7434.89857,
            11033.30133, 7827.94658, 610.18256, -11411.93624,
            -9.87426, 0.94865, -1.63656, 0.41275,
            1996.57150, 511.48468, 669.78228, 1363.67610,
            -379.72037, 198.84438, -16.63126, -79.37624,
            -2.30776, -246.07820, -16.85846, -148.18168,
            -6.89632, -20.49587, 0.39892, -0.34627,
            -57.81309, -136.96971, 15.25671, -96.61153,
            16.09785, -8.79091, 0.70515, 1.16197,
            0.05647, 0.04684,
            0.25032, -0.19951, 0.07282, -0.00696,
            0.00493, 0.00733,
            -0.01085, 0.00422,
            -0.01309, 0.00262,
            0.37616, -0.36203, -0.11154, 0.18213,
            0.15691, 0.29343, 0.00485, 0.06106,
            -0.01492, 0.09954,
            0.28486, 2.27190, 0.33102, 1.50696,
            -0.01926, 0.04901,
            0.01827, 0.00863,
            -0.03315, 0.00178,
            -0.77600, -0.48576, -0.21111, -0.19485,
            1.90295, 6.44856, 1.71638, 2.12980,
            -7.19585, -0.08043,
            0.07004, -0.02764,
            0.01604, 0.01158,
            0.00936, -0.01199,
            0.18396, -0.29234, 0.10422, -0.00720,
            0.05196, 0.10753, 0.02859, -0.03602,
            0.63828, 1.96280, -0.31919, 0.85859,
            -0.10218, -0.00673,
            0.01748, -0.02190,
            0.01266, -0.02729,
            -4.80220, 8.90557, -5.94059, 2.28577,
            -0.19687, -1.28666, 0.32398, 0.14879,
            -0.02619, -0.02056,
            -0.04872, -0.07011, -0.04082, -0.04740,
            0.60167, -2.20365, -0.27919, -0.45957,
            -1.31664, -2.22682, 176.89871, 13.03918,
            0.00568, 0.00560,
            0.01093, 0.00486,
            -0.00948, -0.31272,
            -11.87638, -3.68471, -1.74977, -9.60468,
            2.94988, -0.57118,
            0.00307, -0.01636,
            0.02624, 0.03032,
            -0.00464, -0.01338,
            0.00935, 0.00530,
            -0.11822, 0.03328, -0.41854, 0.04331,
            0.41340, -0.21657,
            -0.00865, 0.00849,
            -0.00374, -0.00899,
            0.01227, -0.23462, -0.71894, -0.04515,
            0.00047, 0.28112,
            -0.12788, 0.11698, -0.02030, 0.02759,
            0.02967, -0.00092,
            0.00454, 0.00565,
            -0.00026, 0.00164,
            -0.01405, -0.00862,
            0.01088, 0.05589,
            0.18248, -0.06931, -0.00011, 0.03713,
            0.01932, -0.00982,
            -0.13861, 0.09853, -0.03441, -0.02492,
            2.26163, -5.94453, 4.14361, -0.94105,
            0.39561, 0.75414,
            -0.17642, 0.03724,
            -1.32978, -0.56610,
            -0.03259, -0.06752,
            39.07495, 80.25429, -28.15558, 82.69851,
            -37.53894, -17.88963, 6.98299, -13.04691,
            -0.48675, -1.84530, -0.07985, -0.33004,
            -3.39292, 2.73153,
            -17268.46134, 1144.22336, -16658.48585, 5252.94094,
            -3461.47865, 2910.56452, -433.49442, -305.74268,
            -383.45023, 545.16136, 313.83376, 27.00533,
            -31.41075, 7.90570, -12.40592, 3.01833,
            -0.83334, 0.23404,
            59.26487, -112.74279, 113.29402, -15.37579,
            14.03282, 32.74482, -4.73299, 1.30224,
            -0.00866, 0.01232,
            -0.53797, 0.00238, -0.07979, 0.04443,
            -0.05617, -0.05396,
            0.10185, -1.05476, 0.43791, -0.32302,
            0.06465, 0.03815,
            0.00028, -0.00446,
            0.09289, -0.06389,
            0.01701, -0.01409,
            0.47101, 0.16158, 0.01036, -0.39836,
            0.00477, 0.01101,
            -2.06535, 0.33197, -0.82468, -0.41414,
            0.03209, -0.09348,
            0.00843, -0.00030,
            -9.49517, -3.82206, 0.66899, -10.28786,
            6.33435, 1.73684, -0.98164, 2.25164,
            -0.07577, -0.00277,
            1.02122, 0.75747, 1.79155, -0.77789,
            -2.56780, -2.07807,
            0.19528, 0.77118, -0.28083, 0.32130,
            -0.04350, -0.07428,
            -0.01161, 0.01387,
            0.02074, 0.19802, -0.03600, 0.04922,
            -0.19837, 0.02572,
            -0.00682, -0.04277,
            -0.01805, 0.00299,
            0.03283, -0.02099,
            3.57307, 1.17468, 0.65769, 1.88181,
            -0.39215, 0.08415,
            -0.53635, -0.19087, -0.12456, 0.02176,
            0.01182, -0.07941,
            -2.43731, 2.44464, 1.03961, -1.81936,
            30.33140, 0.92645,
            0.00508, -0.01771,
            -81.06338, 66.43957, 33.16729, 131.44697,
            76.63344, -34.34324, -35.33012, -28.04413,
            -1.47440, 13.09015,
            0.13253, -0.01629, 0.02187, -0.00963,
            -21.47470, -9.44332, -7.21711, -12.59472,
            1.76195, -1.63911, 0.09060, 0.28656,
            0.00635, 0.00536,
            0.03470, -0.06493, 0.00666, -0.01084,
            0.01116, -0.01612, -0.00102, 0.00208,
            -0.05568, 0.00628,
            0.02665, -0.01032,
            0.21261, -1.90651, 0.72728, -0.57788,
            0.08662, 0.10918,
            3.39133, 3.97302, -4.63381, 4.26670,
            -2.50873, -3.76064,
            1.28114, 1.81919, 1.48064, -0.37578,
            -0.26209, -0.47187,
            0.00282, -0.00499,
            0.01749, 0.03222,
            1.60521, -1.79705, 1.61453, 0.68886,
            -0.29909, 0.55025,
            -0.07894, 0.19880, -0.15635, 0.46159,
            2.09769, 1.52742,
            -7.60312, 11.34886, 4.35640, 8.61048,
            2.15001, -2.15303, -0.61587, -0.11950,
            -0.03289, -0.00520, -0.00501, -0.00445,
            0.15294, -0.05277, 0.02455, 0.00408,
            1.19601, 0.43479, 0.20422, 0.57125,
            -0.12790, 0.01318,
            -0.15275, -0.43856, 6.99144, -0.08794,
            -1.69865, 0.82589, -0.20235, 0.97040,
            0.20903, 0.00675,
            0.26943, 0.08281, 0.03686, 0.05311,
            1.28468, 1.21735, -1.38174, 1.29570,
            -0.75899, -1.17168,
            0.44696, -0.32341, -0.06378, -0.27573,
            -0.06406, 0.87186, 0.21069, 0.19724,
            0.00119, -0.04147,
            0.39279, 0.51437, -0.11035, 0.21450,
            -0.04309, 0.02359, 0.20490, 0.14210,
            0.00007, -0.00017,
            -0.03529, -0.02644,
            0.10710, 0.44476, -0.02632, -0.01817,
            2.11335, -0.04432,
            0.18206, 0.27335, 0.08867, 0.00313,
            -0.00692, 0.01595,
            -0.72957, 0.32080, -0.29291, -0.44764,
            0.12767, -0.05778, 0.04797, -0.00223,
            0.17661, 0.22427, -0.04914, 0.09114,
            0.12236, 0.00708,
            0.74315, -0.01346,
            0.02245, -0.02555,
            -0.30446, 0.13947, -0.12340, -0.18498,
            -0.04099, 0.02103,
            0.06337, -0.01224,
            0.28181, -0.01019,
            -0.02794, -0.09412,
            0.03272, -0.01095,
            0.11247, -0.00650,
            -0.01319, -0.04296,
            0.04653, -0.00423,
            0.06535, 0.00014
        ],
        arg_tbl: [
            0, 7,
            3, 2, 5, -6, 6, 3, 7, 0,
            2, 2, 5, -5, 6, 5,
            3, 1, 6, -4, 7, 2, 8, 0,
            2, 1, 6, -3, 7, 0,
            3, 1, 6, -2, 7, -2, 8, 0,
            2, 4, 5, -10, 6, 3,
            3, 1, 5, -1, 6, -4, 7, 0,
            3, 2, 5, -4, 6, -3, 7, 0,
            3, 2, 6, -8, 7, 4, 8, 0,
            3, 3, 5, -10, 6, 7, 7, 0,
            2, 6, 5, -15, 6, 0,
            2, 2, 6, -6, 7, 0,
            3, 1, 5, -4, 6, 4, 7, 1,
            3, 1, 5, -2, 6, -1, 7, 0,
            3, 2, 5, -5, 6, 1, 8, 0,
            3, 3, 5, -8, 6, 2, 7, 0,
            3, 1, 5, -3, 6, 2, 8, 0,
            3, 1, 5, -3, 6, 1, 7, 1,
            1, 1, 8, 0,
            3, 1, 5, -3, 6, 2, 7, 1,
            3, 1, 5, -2, 6, -2, 7, 0,
            2, 2, 6, -5, 7, 1,
            3, 2, 6, -6, 7, 2, 8, 0,
            3, 2, 6, -7, 7, 4, 8, 0,
            3, 2, 5, -4, 6, -2, 7, 0,
            3, 1, 5, -1, 6, -5, 7, 0,
            3, 2, 6, -7, 7, 5, 8, 0,
            3, 1, 6, -1, 7, -2, 8, 0,
            2, 1, 6, -2, 7, 1,
            3, 1, 6, -3, 7, 2, 8, 0,
            3, 1, 6, -4, 7, 4, 8, 1,
            3, 2, 5, -5, 6, 2, 8, 1,
            3, 2, 5, -6, 6, 2, 7, 1,
            2, 2, 7, -2, 8, 0,
            1, 1, 7, 2,
            2, 5, 5, -12, 6, 2,
            3, 2, 6, -5, 7, 1, 8, 0,
            3, 1, 5, -1, 6, -3, 7, 0,
            3, 7, 5, -18, 6, 3, 7, 0,
            2, 3, 5, -7, 6, 3,
            3, 1, 6, 1, 7, -5, 8, 0,
            3, 1, 5, -4, 6, 3, 7, 0,
            3, 5, 5, -13, 6, 3, 7, 0,
            2, 1, 5, -2, 6, 3,
            3, 3, 5, -9, 6, 3, 7, 0,
            3, 3, 5, -8, 6, 3, 7, 1,
            2, 1, 5, -3, 6, 3,
            3, 5, 5, -14, 6, 3, 7, 0,
            3, 1, 5, -3, 6, 3, 7, 2,
            2, 3, 6, -7, 7, 0,
            2, 3, 5, -8, 6, 2,
            3, 2, 5, -3, 6, -4, 7, 1,
            3, 2, 5, -8, 6, 7, 7, 0,
            2, 5, 5, -13, 6, 0,
            2, 2, 6, -4, 7, 2,
            3, 2, 6, -5, 7, 2, 8, 0,
            3, 2, 5, -4, 6, -1, 7, 0,
            3, 2, 5, -7, 6, 4, 7, 0,
            2, 1, 6, -2, 8, 2,
            2, 1, 6, -1, 7, 0,
            3, 1, 6, -2, 7, 2, 8, 0,
            3, 2, 5, -5, 6, 2, 7, 0,
            3, 2, 5, -6, 6, 2, 8, 0,
            3, 2, 5, -6, 6, 1, 7, 0,
            2, 3, 7, -2, 8, 0,
            1, 2, 7, 1,
            2, 1, 6, -1, 8, 1,
            3, 1, 5, -2, 6, 1, 7, 0,
            3, 1, 5, -2, 6, 2, 8, 0,
            2, 3, 6, -6, 7, 2,
            2, 6, 5, -14, 6, 0,
            3, 3, 6, -7, 7, 2, 8, 0,
            3, 2, 5, -3, 6, -3, 7, 1,
            2, 4, 5, -9, 6, 3,
            3, 2, 6, -2, 7, -2, 8, 0,
            2, 2, 6, -3, 7, 1,
            3, 2, 6, -4, 7, 2, 8, 0,
            2, 2, 5, -4, 6, 3,
            3, 2, 5, -7, 6, 3, 7, 1,
            3, 1, 6, 1, 7, -2, 8, 0,
            1, 1, 6, 5,
            3, 2, 5, -5, 6, 3, 7, 1,
            2, 2, 5, -6, 6, 3,
            1, 3, 7, 3,
            2, 4, 5, -11, 6, 3,
            2, 1, 5, -4, 7, 0,
            3, 2, 5, -5, 6, -3, 7, 1,
            2, 6, 5, -16, 6, 0,
            3, 3, 5, -7, 6, 2, 7, 0,
            3, 3, 6, -4, 7, -2, 8, 0,
            2, 3, 6, -5, 7, 1,
            3, 3, 6, -6, 7, 2, 8, 1,
            3, 3, 6, -7, 7, 4, 8, 0,
            3, 2, 5, -3, 6, -2, 7, 2,
            3, 2, 5, -8, 6, 5, 7, 0,
            2, 2, 6, -4, 8, 0,
            3, 2, 6, -1, 7, -2, 8, 1,
            2, 2, 6, -2, 7, 2,
            3, 2, 6, -3, 7, 2, 8, 0,
            3, 2, 5, -4, 6, 1, 7, 0,
            3, 2, 5, -4, 6, 2, 8, 0,
            3, 2, 5, -7, 6, 2, 7, 1,
            2, 1, 6, 1, 7, 1,
            2, 5, 5, -11, 6, 2,
            3, 1, 5, -2, 7, -2, 8, 0,
            2, 1, 5, -3, 7, 0,
            2, 3, 5, -6, 6, 3,
            3, 2, 6, 1, 7, -5, 8, 0,
            2, 2, 6, -3, 8, 1,
            2, 1, 5, -1, 6, 3,
            3, 2, 5, -7, 6, 3, 8, 0,
            3, 3, 5, -7, 6, 3, 7, 0,
            3, 2, 5, -1, 6, -7, 7, 0,
            2, 1, 5, -4, 6, 2,
            3, 1, 5, -2, 6, 3, 7, 0,
            2, 4, 6, -7, 7, 0,
            2, 3, 5, -9, 6, 0,
            3, 2, 5, -2, 6, -4, 7, 0,
            2, 3, 6, -4, 7, 2,
            3, 2, 5, -3, 6, -1, 7, 0,
            3, 2, 5, -8, 6, 4, 7, 0,
            2, 2, 6, -2, 8, 1,
            2, 2, 6, -1, 7, 0,
            3, 2, 6, -2, 7, 2, 8, 1,
            3, 2, 5, -4, 6, 2, 7, 0,
            3, 2, 5, -7, 6, 2, 8, 0,
            3, 2, 5, -7, 6, 1, 7, 0,
            2, 1, 6, 2, 7, 0,
            2, 2, 6, -1, 8, 0,
            2, 4, 6, -6, 7, 1,
            2, 6, 5, -13, 6, 0,
            3, 2, 5, -2, 6, -3, 7, 1,
            2, 4, 5, -8, 6, 2,
            3, 3, 6, -2, 7, -2, 8, 0,
            2, 3, 6, -3, 7, 0,
            3, 3, 6, -4, 7, 2, 8, 0,
            2, 2, 5, -3, 6, 3,
            3, 2, 5, -8, 6, 3, 7, 1,
            3, 2, 6, 1, 7, -2, 8, 0,
            1, 2, 6, 5,
            3, 2, 5, -4, 6, 3, 7, 2,
            2, 2, 5, -7, 6, 3,
            3, 1, 6, 4, 7, -2, 8, 0,
            2, 1, 6, 3, 7, 1,
            3, 1, 6, 2, 7, 2, 8, 0,
            2, 4, 5, -12, 6, 2,
            2, 5, 6, -8, 7, 0,
            2, 4, 6, -5, 7, 0,
            3, 2, 5, -2, 6, -2, 7, 0,
            2, 3, 6, -2, 7, 1,
            3, 3, 6, -3, 7, 2, 8, 0,
            2, 5, 5, -10, 6, 2,
            3, 1, 5, 1, 6, -3, 7, 0,
            2, 3, 5, -5, 6, 3,
            2, 3, 6, -3, 8, 0,
            1, 1, 5, 2,
            2, 1, 5, -5, 6, 2,
            2, 5, 6, -7, 7, 0,
            2, 4, 6, -4, 7, 2,
            2, 3, 6, -2, 8, 0,
            2, 3, 6, -1, 7, 0,
            2, 5, 6, -6, 7, 0,
            2, 4, 5, -7, 6, 2,
            2, 4, 6, -3, 7, 2,
            2, 2, 5, -2, 6, 2,
            3, 2, 6, -9, 7, 3, 8, 0,
            1, 3, 6, 4,
            3, 2, 5, -3, 6, 3, 7, 1,
            2, 2, 5, -8, 6, 3,
            3, 2, 6, 4, 7, -2, 8, 0,
            2, 4, 5, -13, 6, 1,
            2, 6, 6, -8, 7, 1,
            2, 5, 6, -5, 7, 0,
            2, 4, 6, -2, 7, 0,
            2, 5, 5, -9, 6, 2,
            2, 3, 5, -4, 6, 2,
            2, 1, 5, 1, 6, 2,
            2, 6, 5, -11, 6, 0,
            3, 6, 6, -7, 7, 2, 8, 0,
            2, 4, 5, -6, 6, 2,
            2, 2, 5, -1, 6, 2,
            1, 4, 6, 3,
            3, 2, 5, -2, 6, 3, 7, 1,
            2, 2, 5, -9, 6, 1,
            2, 5, 5, -8, 6, 2,
            2, 3, 5, -3, 6, 1,
            2, 1, 5, 2, 6, 2,
            2, 6, 5, -10, 6, 1,
            2, 4, 5, -5, 6, 2,
            1, 2, 5, 1,
            1, 5, 6, 2,
            2, 5, 5, -7, 6, 1,
            2, 3, 5, -2, 6, 1,
            3, 1, 5, 2, 6, 3, 7, 0,
            2, 6, 5, -9, 6, 0,
            2, 4, 5, -4, 6, 2,
            2, 2, 5, 1, 6, 1,
            2, 7, 5, -11, 6, 0,
            2, 5, 5, -6, 6, 1,
            2, 3, 5, -1, 6, 1,
            2, 6, 5, -8, 6, 1,
            2, 4, 5, -3, 6, 0,
            2, 5, 5, -5, 6, 0,
            1, 3, 5, 0,
            2, 6, 5, -7, 6, 1,
            2, 7, 5, -9, 6, 0,
            2, 5, 5, -4, 6, 0,
            2, 6, 5, -6, 6, 0,
            2, 7, 5, -8, 6, 0,
            2, 6, 5, -5, 6, 0,
            2, 7, 5, -7, 6, 0,
            2, 8, 5, -9, 6, 0,
            2, 8, 5, -8, 6, 0,
            2, 1, 3, -1, 6, 0,
            -1
        ],
    },
};
