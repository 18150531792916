import { Chart as ChartType } from '@danielpedroso/astrum-calc/dist/types';
import { SIGNS } from '@danielpedroso/astrum-calc/dist/constants';
import { hebrewOnly } from '@danielpedroso/hebrew';
import { Sign } from './sign';
import { CelestialObject } from './object';
import { useMemo } from 'react';
import { SquareRatio, Container, Svg } from './styled';
import { Rose } from './rose';

interface Props {
  chart?: ChartType;
  name: string;
  mode: 'edit' | 'view';
  doubleLettersEndInKaf?: boolean;
  svgRef?: React.Ref<SVGSVGElement> | undefined;
}

export const SigilChart: React.FC<Props> = ({ chart, name, mode, svgRef, doubleLettersEndInKaf = true }) => {
  const planets = useMemo(() => {
    if (!chart) return [];

    const objs = Object.values(chart.planets)
      .map(({ key, ...rest }) => ({ key: key as string, ...rest, depth: 1, isRetrograde: false })); // retrogrades are not taken into account when building the sigil
    
    for (let i = 0; i < objs.length; i++) {
      const curr = objs[i];
      curr.depth = objs
        .slice(0, i)
        .filter(o => Math.abs(o.absolute - curr.absolute) < 10.0)
        .length;
    }

    return objs;
  }, [chart]);

  const rotation = useMemo(() => {
    if (!chart?.houseCusps?.[0]) return 0;

    return mode === 'edit' ? 0 : chart.houseCusps[0];
  }, [chart, mode]);

  const hebrewName = useMemo(() => hebrewOnly(name), [name]);

  return (
    <SquareRatio>
      <Container>
        <Svg
          id="sigil-chart"
          viewBox="0 0 800 800"
          preserveAspectRatio="xMidYMid meet"
          style={{ transformBox: 'fill-box', transformOrigin: 'center', transform: chart ? `rotate(${rotation}deg)` : undefined, backgroundColor: 'white' }}
          xmlns="http://www.w3.org/2000/svg"
          ref={svgRef}
        >
          {/* External circle */}
          <circle r="48%" cx="50%" cy="50%" stroke="black" fill="transparent" />

          <Rose
            internalRadiusMultiplier={.05}
            parentSize={800}
            layerSize={.11}
            name={hebrewName}
            rotation={rotation}
            mode={mode}
            doubleLettersEndInKaf={doubleLettersEndInKaf}
          />

          {/* Planets */}
          {planets?.map((planet) => (
            <CelestialObject
              key={planet.key}
              objKey={planet.key}
              object={planet}
              externalRadiusMultiplier={.43}
              name={planet.key}
              parentSize={800}
              pos={planet.absolute}
              depth={planet.depth}
              rotation={rotation}
              colour="black"
              fontSize={32}
              hideDegrees
              condensed
            />
          ))}

          {/* Signs */}
          {SIGNS.map((name, i) => (
            <Sign
              key={name}
              externalRadiusMultiplier={.48}
              internalRadiusMultiplier={.4}
              cusp={i * 30}
              name={name}
              parentSize={800}
              rotation={rotation}
              fontSize={32}
              colour="black"
              noSeparator
            />
          ))}
        </Svg>
      </Container>
    </SquareRatio>
  );
};
