"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.earth = void 0;
exports.earth = {
    key: 'earth',
    type: 'heliocentric',
    anomaly: 1.1791,
    dailyMotion: 0.985611,
    distance: 0.0, // computed
    eccentricity: 0.016713,
    epoch: 2446800.5, // 05.01.1987
    equinox: 2446800.,
    inclination: 0.0,
    longitude: 0.0, // computed
    magnitude: -3.86,
    node: 0.0,
    perihelion: 102.884,
    perturbation: 0.0, // computed
    semiAxis: 0.999999,
    semiDiameter: 0.0,
    ptable: {
        maxargs: 12,
        max_harmonic: [2, 11, 14, 19, 6, 10, 2, 2, 0, 1, 1, 4, 0, 0, 0, 0, 0, 0],
        max_power_of_t: 3,
        distance: 1.0001398729597080e+00,
        timescale: 3.6525000000000000e+06,
        trunclvl: 1.0000000000000000e-04,
        lon_tbl: [
            -242809, -178223, -6154, -6547,
            15526, -79460, 66185, -19531,
            -12754, 4389, 3153, -1151,
            768, 1750,
            -248, 657, -80, 0,
            -4, -29,
            -3020, 301, -360, 412,
            -1463, 2266,
            -41, 30,
            -39868, -14275, -25052, 1583,
            15695, 10018,
            -113, -122,
            -243, 18, -33, 31,
            -134, -171, 243, -115,
            18, 148,
            -120, -129, 19, -220,
            -30, 19,
            8, 23,
            -162, -124, 189, -315,
            73, 77,
            32006, -11295, 11595, 5629,
            -838, 1728,
            0, 4,
            38, 15,
            142, -228, 92, 32,
            -2274, -1500, -2277, 3143,
            3204, 127,
            -20, -11,
            5186, 1054, 996, 1769,
            -231, 163,
            -88, -19,
            -2, -145,
            -27, 48,
            -8, 421,
            -7, 148,
            -16, -2,
            -3964, 4259, -11192, -8385,
            11513, -13415,
            103, -43,
            -289, -79, -29, 163,
            -117, 559, -190, -15,
            7108, 5345, 12933, -7709,
            3485, -26023,
            11, -5,
            311, 78, 22, 76,
            2846, -3922, 2329, 43,
            34, 442,
            3, -245,
            -5, -3,
            -17, 5,
            318, 15963, 2520, 7115,
            2548, -9836,
            -7063, 1950, -4471, -8326,
            4964, -3101,
            563, -80, -1444, -472,
            8, -22,
            1558, -88, 235, 359,
            293, -16, 144, 209,
            -13, -7,
            812, -744, 150, -740,
            -2569, -956, 69, -2475,
            1009, -55,
            -1707, -2525, 1071, -1761,
            550, 279,
            -14, 36,
            -10442, 3344, -6759, -21551,
            24737, -434,
            504, -385, 191, 96,
            -2760, -1068, 85, -2617,
            1062, -43,
            192, -16, 30, 42,
            -2446, 588, -1522, -2933,
            1746, -1070,
            511, -1401, 139, 729,
            -12, 29,
            -2618, -2076, 2079, -3711,
            -21, -2727,
            -80, -19,
            113, 2420, 325, 1058,
            379, -1478,
            296, -251, -265, -409,
            -10, 20,
            15, -15,
            11, 143, -83, 19,
            266, -17, 40, 59,
            19, -105, 5, 48331,
            21, -16,
            -97, -318, 158, -171,
            456, -351, 168, 85,
            12, -2,
            20, -15,
            15, 2,
            385, -1125, 521, -23,
            -815, -2088, 1644, -1329,
            7, 14,
            -582, 234, -67, -467,
            -167, -51,
            -684, -2302, 1315, -797,
            6, -70,
            -118, -406, 67, -63,
            -4848, 3713, -8483, -8776,
            13049, -9404,
            -23, 34,
            -12, 1,
            -24, -10,
            -21, 0,
            -1, 24,
            -3, 28,
            -3032, -2494, 2498, -4342,
            -6538, 1899, -4414, -13249,
            15540, -292,
            -228, 176, -40, -161,
            -20, -36,
            -800, -172, -36, -208,
            -249, -374, -1410, -72118,
            -745, 213, -23, 196,
            -14, -2,
            -239, -341, 1015, -291,
            33, -94, 90, -20431,
            4, -39,
            75, 216, -23, 41,
            116, 24, 5, 26,
            -45, -4178,
            -9, -23,
            12, 18,
            68, -2,
            36, -19,
            42, -8,
            6, -106,
            4, -38,
            -73, 259, 107, -293,
            -12, -44, 37, 13,
            73, -46, 17, 8,
            5832, 1989, -1404, 4469,
            -1619, -743,
            -1796, -2206, 461, -291,
            153, 1104,
            19195, 652503, 5587, -5252787,
            47, -17340051, -32, 68926621,
            1054, -230, -1601, 356,
            -562, -998,
            124, -446, -171, 66,
            26, 60,
            -7, 0,
            -88, -43, 65, -400,
            4, 183,
            -1014, 2043, -1076, 126,
            -41, -205,
            -127, -85, -15, 68,
            0, 0,
            -320, 75, -42, 285,
            -303, 771, 616, 400,
            -470, 48, -76, -103,
            -190, -11,
            -139, -5, -48, -87,
            -22, -362, -271, 1233,
            -392, 353, -154, -71,
            -109, 112,
            17, 8,
            1, -17,
            -170, 623, -279, 21,
            139, -151, -107, -55199,
            588, -188, 397, 674,
            -406, 269,
            166, -207, 585, 333,
            -386, 754,
            29, -65,
            35, 10,
            63, 1291,
            62, 8,
            239, 1323, -1434, 53,
            19, -1,
            34, 82,
            -15, -16,
            265, -338, -729, -207,
            3, 17,
            697, 399, 274, 760,
            -12, 2,
            -48, -9,
            3, 64,
            147, 36, 9, 46,
            77, 144, -76, 65,
            2329, 1763, 987, 5506,
            66, -123, -41, -24,
            -12, 1,
            -19, 94,
            19, 8,
            -1, -18,
            142, 77, -78, 187,
            6, 18,
            607, 163, 17, 158,
            27, -208, 154, 27317,
            587, -143, 22, -153,
            5, -34,
            75, 330,
            98, -128, -67, -6542,
            -115, -236, 217, -12,
            10, -6,
            -250, 653, 1611, -209,
            4, 1072,
            -129, 216, 402, 81,
            117, 11,
            0, 20,
            24, -28,
            245, 437, -16, 59,
            527952, -74895, 169682, 177186,
            -376, -362869, -60, 719658,
            -151, -382, -22, -43,
            5, -5,
            14, 5,
            -9, 13,
            83, 296, -369, -1,
            -14, -6,
            42, 8,
            -31, 7,
            -354, 634, 1132, 243,
            -38, 42,
            -14, 68,
            -6, 31,
            -36, -13,
            7, -2104,
            16, 67,
            9, -4,
            174, 144, 58, 438,
            -15, 5,
            -16, 19,
            -135, 1642,
            -140, -11,
            -4, 27,
            253, -382, -651, -221,
            11, 1,
            -12, -6,
            136, 23,
            -1, 43,
            3, 38,
            -26, -5,
            17864, -4244, 5704, 7754,
            -36, -7891, -3, 10418,
            2, -844,
            -1, 126,
            -7, 32,
            -67, -5,
            39, 10,
            5, 52,
            -13, 159,
            -49, -21,
            1, -394,
            7, -15,
            -4, -245, 1, 172,
            -36, -3,
            13, 5,
            0, 1,
            -1, 0, 0, -202,
            -2, 19,
            -20, -2,
            5, 3,
            0, -110,
            -12, -1,
            0, -62,
            0, -36,
            0, -22,
            -13, 3
        ],
        lat_tbl: [
            -428091, -488399, 746850, 6,
            210, -93, 32, 1,
            -365, 332, -105, 76,
            -7, 2,
            -8, 14, -1, 2,
            0, 0,
            -65, 12, -17, 7,
            -1, 1,
            0, 0,
            -15, 65, -4, 26,
            -2, 0,
            0, 0,
            0, 2, 0, 0,
            2, 2, 0, 0,
            0, 0,
            -1, -3, 0, 0,
            0, 0,
            0, 0,
            -2, 0, 0, 0,
            -1, 0,
            -30, 28, -6, 10,
            0, 0,
            0, 0,
            0, 0,
            2, 0, 0, 0,
            -16, 20, -6, -41,
            -9, -3,
            0, 0,
            -6, 2, 2, 0,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            -96, 33, -12, 228,
            -23, -21,
            0, 0,
            -12, -2, -4, 4,
            -1, 0, 1, 0,
            -329, -22, -34, -726,
            -147, -21,
            0, 0,
            -2, 4, -1, 0,
            2, -7, 0, 1,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            36, 88, -162, -19,
            -11, 21,
            31, 37, -31, 53,
            -5, -15,
            -3, -11, 9, 3,
            0, 0,
            -2, 0, 1, 0,
            2, -1, 0, 0,
            0, 0,
            -162, -102, -37, 30,
            19, 23, -18, 9,
            1, -6,
            -6, 22, -2, 3,
            1, -2,
            0, -1,
            26, -25, 66, 52,
            -641, -153,
            -13, -9, 2, -3,
            -29, 8, -6, -2,
            0, -6,
            2, -4, 1, 0,
            -26, -11, -1, -10,
            -6, -13,
            66, -1337, -879, -207,
            1, -1,
            8, -30, -24, -18,
            -16, 1,
            9, 1,
            -24, -8, 9, -17,
            -13, 75,
            19, -8, -29, 24,
            0, 0,
            -1, 1,
            -25, 36, -7, -22,
            0, -3, 1, -1,
            187, -46, -6, 74,
            5, -10,
            -5, -4, -16, 10,
            -5, -5, 2, -4,
            5, -2,
            -2, 1,
            -1, 0,
            -16, -12, 1, -13,
            -17, -111, -186, 73,
            -1, -2,
            -277, -77, -27, 106,
            16, 5,
            -12, -15, -13, -30,
            -1, 1,
            0, 36, -10, 4,
            607, 262, 533, -1530,
            -1630, 304,
            8, -6,
            1, 1,
            0, -1,
            5, -2,
            0, -1,
            -1, -4,
            -44, -22, -64, -46,
            537, 430, 268, -1553,
            -2040, -486,
            -3, -23, 20, 41,
            -1, 2,
            -21, -4, -1, -3,
            -84, 50, -177, 26,
            5, -12, 2, -4,
            7, 1,
            -115, -305, -310, 138,
            -186, 246, -96, 17,
            0, 0,
            4, -2, 1, 1,
            -3, 2, -1, 0,
            -15, 68,
            0, 2,
            -3, 0,
            -5, 0,
            -1, 1,
            -5, 6,
            0, 0,
            0, 0,
            -235, -98, -2, 2,
            9, -40, -1, -2,
            -33, -9, -5, -4,
            5662, -3849, 1941, -124,
            210, 160,
            -24721, -72945, 4099, -21914,
            1345, -555,
            23637393, -5516830, 17737677, 43330654,
            -44668315, 14540723, -824, -2086,
            -4423, -41801, 5562, -11664,
            960, -125,
            2001, -149, 587, -350,
            23, -52,
            -3, 3,
            -248, -148, -40, 86,
            2, 0,
            21, -82, 11, 8,
            -8, 0,
            -30, -33, -22, 46,
            0, -191,
            -168, -135, 27, -85,
            14, 232, 217, 59,
            5, 12, -5, 2,
            -24, -26,
            -52, 13, -3, 18,
            26, 45, 32, -169,
            14, -6, -3, 4,
            -5, 2,
            6, 2,
            -2, 3,
            20, -15, 0, 10,
            -486, -8, 4, -114,
            102, -188, 23, -67,
            6, 12,
            -43, -1, -32, 2,
            15, 9,
            16, -36,
            -6, -2,
            14, -5,
            17, -15,
            -28, 307, 289, 69,
            2, -7,
            3, -1,
            -1, 1,
            -16, -811, 287, -68,
            0, 0,
            0, -1, 16, -7,
            0, 0,
            0, 2,
            0, 0,
            0, -1, 1, 0,
            -3, -4, 2, 3,
            -29, 34, 59, -15,
            -3, -3, -1, 0,
            -2, -3,
            3, -19,
            0, 0,
            0, 0,
            -15, 1, 5, 2,
            0, 0,
            -1, -5, 0, -1,
            -120, 84, 7, -30,
            -7, -3, -1, 0,
            0, -1,
            9, -6,
            -186, -11, 13, -57,
            1, 4, 1, -1,
            0, 0,
            -5, 796, 46, 5,
            -1, -6,
            -10, 228, 5, -6,
            1, -5,
            0, 0,
            -6, -2,
            148, 137, 10, 28,
            430546, -279834, 488902, 664558,
            -746515, 243112, -39, -37,
            -13, -174, 6, -25,
            2, -3,
            -4, -2,
            0, 4,
            -5, 70, 82, 20,
            0, 1,
            1, 1,
            0, 1,
            -27, 430, 226, -53,
            1, 1,
            0, 1,
            1, -7,
            2, 1,
            -3, -8,
            1, 0,
            -1, 12,
            -2, -5, 4, 0,
            0, 1,
            1, 1,
            1, 9,
            33, 4,
            0, 0,
            0, -321, 4, 1,
            0, 0,
            1, 0,
            106, -22,
            0, 0,
            4, 0,
            0, 2,
            7006, -9443, 12833, 11137,
            -14037, 4575, -2, 0,
            -1, -6,
            1, 1,
            4, 6,
            16, 2,
            55, -10,
            1, 0,
            0, 1,
            0, 2,
            0, -4,
            -2, 0,
            -351, 24, 0, 0,
            8, 1,
            30, -5,
            -12, 10,
            -4, 1, -1, -2,
            0, 0,
            4, 0,
            17, -3,
            0, -2,
            2, 0,
            0, -1,
            0, -1,
            0, 0,
            0, 0
        ],
        rad_tbl: [
            14575, -26192, -144864, 2,
            -22, 15, -8, -21,
            -148, -104, -14, -75,
            15, 2,
            -5, -3, -1, 0,
            0, 0,
            0, 21, -2, 7,
            -5, -3,
            0, 0,
            83, -94, 9, -67,
            -29, 50,
            1, -1,
            3, 2, 0, 0,
            4, 3, 1, 1,
            -1, -1,
            0, -1, 2, -1,
            0, 1,
            0, 0,
            -2, 3, -5, -2,
            -1, 1,
            197, 511, -82, 189,
            -28, -12,
            0, 0,
            0, -1,
            6, -1, 0, 1,
            30, -30, -37, -25,
            6, 21,
            0, 0,
            16, -139, 43, -28,
            4, 6,
            0, 3,
            4, 0,
            1, 1,
            -13, 0,
            -4, 0,
            0, 1,
            150, 135, -291, 436,
            -560, -343,
            1, 3,
            8, -15, -13, -5,
            -17, -3, 1, -6,
            -314, 428, 606, 758,
            1230, -411,
            0, -1,
            11, -14, 4, 1,
            221, 157, 1, 132,
            -25, 3,
            12, 0,
            0, 0,
            0, -1,
            1487, -108, 707, -79,
            -950, -190,
            177, 582, -676, 399,
            -281, -396,
            0, 52, 39, -130,
            2, 1,
            12, 148, -34, 23,
            1, 27, -20, 13,
            1, -1,
            198, -34, -21, -80,
            -99, 332, -307, 9,
            -15, -125,
            330, -231, 236, 139,
            -36, 74,
            7, 3,
            -588, -1722, 3623, -1245,
            187, 4366,
            -72, -75, 11, -33,
            174, -467, 444, 9,
            11, 180,
            -6, -39, 8, -7,
            -126, -500, 599, -317,
            224, 355,
            -590, -39, 134, -379,
            -7, -3,
            494, -628, 893, 490,
            712, -7,
            -7, 35,
            -720, 50, -321, 72,
            443, 106,
            74, 82, 112, -84,
            -6, -3,
            5, 4,
            58, 7, -2, 38,
            6, 92, -20, 14,
            33, 13, -11189, -2,
            -11, -8,
            106, -35, 58, 52,
            132, 170, -32, 63,
            -2, -6,
            6, 7,
            -1, 6,
            452, 155, 9, 209,
            788, -318, 511, 616,
            -5, 3,
            142, 303, -280, 32,
            21, -69,
            984, -291, 340, 562,
            30, 2,
            171, -51, 27, 28,
            -1570, -2053, 3702, -3593,
            4012, 5467,
            -14, -9,
            -1, -6,
            4, -11,
            0, -9,
            -11, 0,
            15, 2,
            1133, -1366, 1961, 1134,
            -867, -3010, 6041, -2049,
            142, 7138,
            -79, -103, 73, -18,
            17, -9,
            79, -372, 97, -17,
            182, -118, 33577, -675,
            -99, -347, -91, -11,
            1, -7,
            158, -111, 136, 474,
            50, 16, 9739, 51,
            19, 2,
            -105, 36, -20, -11,
            -12, 56, -13, 2,
            2030, -22,
            11, -4,
            9, -6,
            1, 33,
            10, 18,
            4, 21,
            53, 3,
            19, 2,
            130, 37, -147, -54,
            -22, 6, 7, -19,
            22, 36, -4, 8,
            -949, 2911, -2221, -697,
            371, -808,
            1203, -1117, 191, 189,
            -549, 77,
            -321201, 19788, 2622593, 5990,
            8667033, 114, -34455835, 86,
            -92, -493, 179, 807,
            -499, 281,
            225, 51, -34, -88,
            -30, 13,
            0, -3,
            20, -43, 201, 33,
            -93, 2,
            -1034, -518, -63, -545,
            104, -20,
            43, -64, -34, -7,
            0, 0,
            -61, -159, -143, -8,
            -392, -157, -204, 309,
            -24, -248, 55, -40,
            -6, 91,
            -16, 57, -41, 18,
            197, -20, -668, -150,
            -192, -216, 39, -84,
            -62, -59,
            -4, 8,
            -7, -1,
            -352, -100, -10, -158,
            61, 55, 32493, -49,
            107, 344, -395, 227,
            -154, -238,
            123, 104, -205, 348,
            -449, -236,
            -54, -19,
            -6, 21,
            -790, 27,
            -5, 30,
            -846, 154, -26, -920,
            0, 12,
            -54, 21,
            11, -10,
            137, 132, 109, -337,
            -11, 2,
            -272, 467, -511, 179,
            -1, -8,
            7, -32,
            -44, 2,
            -26, 101, -32, 6,
            -98, 48, -42, -53,
            -1222, 1601, -3775, 656,
            83, 46, 16, -28,
            0, 7,
            -66, -14,
            -6, 13,
            12, 0,
            -58, 91, -123, -58,
            -12, 4,
            -114, 423, -111, 12,
            112, 27, -19072, 71,
            100, 410, 107, 15,
            24, 3,
            -214, 30,
            49, 44, 5017, -27,
            167, -80, 8, 153,
            4, 7,
            -219, -35, 244, 694,
            -762, 2,
            -84, -49, -28, 158,
            -4, 56,
            -14, 0,
            9, 12,
            7, 18, 2, -7,
            -15426, 91, 25800, -15,
            144767, -53, -287824, -24,
            19, -9, 6, 7,
            0, 0,
            -3, 8,
            -5, -3,
            -232, 53, -1, -271,
            4, -12,
            -8, 30,
            -8, -25,
            -253, -150, -105, 470,
            -37, -29,
            -59, -6,
            -24, -5,
            9, -18,
            1784, 3,
            -54, 13,
            -12, 7,
            -116, 144, -353, 52,
            -4, -12,
            -17, -14,
            -1340, -64,
            10, -116,
            -24, -2,
            190, 131, 130, -307,
            -1, 9,
            5, -7,
            -10, 56,
            -33, 0,
            -14, 3,
            2, -12,
            -635, -160, 64, -44,
            2712, -3, -3606, -1,
            774, 1,
            133, -1,
            -19, 0,
            5, -59,
            -5, 14,
            -45, 5,
            -140, -8,
            15, -28,
            379, 1,
            6, 3,
            55, 0, -54, 0,
            3, -33,
            -3, 4,
            0, -4,
            0, -1, 200, 0,
            -17, -1,
            2, -20,
            -2, 0,
            111, 0,
            1, -12,
            64, 0,
            38, 0,
            23, 0,
            3, 13
        ],
        arg_tbl: [
            0, 3,
            3, 4, 3, -8, 4, 3, 5, 1,
            2, 2, 5, -5, 6, 2,
            4, 4, 3, -8, 4, 5, 5, -5, 6, 1,
            3, 2, 2, 1, 3, -8, 4, 0,
            3, 3, 2, -7, 3, 4, 4, 2,
            3, 7, 3, -13, 4, -1, 5, 0,
            2, 8, 2, -13, 3, 2,
            3, 1, 3, -2, 4, 2, 6, 0,
            3, 1, 2, -8, 3, 12, 4, 1,
            3, 6, 2, -10, 3, 3, 5, 1,
            1, 1, 7, 0,
            2, 1, 5, -2, 6, 1,
            2, 1, 5, -3, 6, 0,
            3, 1, 3, -2, 4, 1, 5, 0,
            3, 3, 3, -6, 4, 2, 5, 1,
            3, 1, 1, -5, 2, 4, 3, 0,
            2, 8, 3, -15, 4, 2,
            3, 4, 3, -7, 4, -3, 5, 0,
            3, 2, 2, -7, 3, 7, 4, 0,
            2, 2, 5, -4, 6, 1,
            1, 1, 6, 2,
            2, 2, 5, -6, 6, 0,
            2, 9, 3, -17, 4, 2,
            3, 3, 2, -5, 3, 1, 5, 0,
            3, 2, 3, -4, 4, 2, 5, 0,
            3, 2, 3, -4, 4, 1, 5, 0,
            3, 3, 2, -5, 3, 2, 5, 0,
            2, 1, 5, -1, 6, 0,
            3, 3, 2, -6, 3, 2, 4, 0,
            2, 1, 3, -2, 4, 2,
            2, 2, 5, -3, 6, 0,
            1, 2, 6, 1,
            2, 3, 5, -5, 6, 1,
            1, 1, 5, 2,
            3, 4, 3, -8, 4, 2, 5, 0,
            2, 1, 5, -5, 6, 1,
            2, 7, 3, -13, 4, 2,
            2, 2, 5, -2, 6, 0,
            2, 10, 3, -19, 4, 0,
            2, 3, 5, -4, 6, 0,
            2, 3, 2, -5, 3, 2,
            2, 2, 3, -4, 4, 2,
            2, 5, 2, -8, 3, 1,
            2, 3, 5, -3, 6, 0,
            2, 6, 3, -11, 4, 1,
            2, 1, 1, -4, 3, 1,
            2, 4, 5, -5, 6, 0,
            1, 2, 5, 1,
            2, 3, 3, -6, 4, 2,
            2, 5, 3, -9, 4, 2,
            2, 6, 2, -10, 3, 0,
            2, 2, 2, -3, 3, 2,
            2, 4, 3, -8, 4, 1,
            2, 4, 3, -7, 4, 2,
            2, 5, 3, -10, 4, 1,
            2, 3, 3, -5, 4, 2,
            2, 1, 2, -2, 3, 1,
            2, 7, 2, -11, 3, 0,
            2, 2, 3, -3, 4, 1,
            2, 1, 3, -1, 4, 0,
            2, 4, 2, -7, 3, 0,
            2, 4, 2, -6, 3, 2,
            1, 1, 4, 1,
            2, 8, 3, -14, 4, 0,
            2, 1, 3, -5, 5, 0,
            2, 1, 3, -3, 4, 1,
            2, 7, 3, -12, 4, 1,
            2, 1, 2, -1, 3, 1,
            2, 2, 3, -5, 4, 0,
            2, 1, 3, -4, 5, 1,
            2, 6, 3, -10, 4, 1,
            2, 3, 3, -7, 4, 0,
            3, 1, 3, -4, 5, 2, 6, 0,
            3, 1, 3, -1, 5, -5, 6, 0,
            2, 5, 3, -8, 4, 1,
            2, 1, 3, -3, 5, 1,
            3, 1, 3, -5, 5, 5, 6, 0,
            2, 2, 2, -4, 3, 1,
            2, 6, 2, -9, 3, 0,
            2, 4, 3, -6, 4, 1,
            3, 1, 3, -3, 5, 2, 6, 0,
            2, 1, 3, -5, 6, 1,
            2, 1, 3, -2, 5, 2,
            3, 1, 3, -4, 5, 5, 6, 0,
            3, 1, 3, -1, 5, -2, 6, 0,
            3, 1, 3, -3, 5, 3, 6, 0,
            2, 1, 3, -4, 6, 0,
            3, 1, 3, -2, 5, 1, 6, 0,
            2, 5, 2, -9, 3, 0,
            2, 3, 3, -4, 4, 1,
            2, 3, 2, -4, 3, 2,
            2, 1, 3, -3, 6, 1,
            3, 1, 3, -2, 5, 2, 6, 0,
            3, 1, 3, 1, 5, -5, 6, 1,
            2, 1, 3, -1, 5, 1,
            3, 1, 3, -3, 5, 5, 6, 1,
            3, 1, 3, 2, 5, -7, 6, 0,
            2, 1, 3, -2, 6, 1,
            2, 2, 3, -2, 4, 1,
            3, 3, 2, -4, 3, 1, 5, 0,
            2, 10, 3, -17, 4, 1,
            3, 1, 3, 2, 5, -6, 6, 1,
            2, 1, 3, -1, 6, 0,
            3, 1, 3, -2, 5, 4, 6, 0,
            2, 7, 3, -15, 4, 0,
            2, 1, 3, -2, 7, 0,
            3, 1, 3, 1, 5, -3, 6, 0,
            2, 1, 3, -2, 8, 0,
            2, 1, 3, -1, 7, 0,
            2, 1, 3, -1, 8, 0,
            2, 8, 2, -14, 3, 1,
            3, 3, 2, -8, 3, 4, 4, 1,
            3, 1, 3, 4, 5, -10, 6, 1,
            3, 1, 3, 2, 5, -5, 6, 2,
            3, 5, 3, -8, 4, 3, 5, 2,
            1, 1, 12, 3,
            3, 3, 3, -8, 4, 3, 5, 2,
            3, 1, 3, -2, 5, 5, 6, 2,
            3, 3, 2, -6, 3, 4, 4, 0,
            2, 8, 2, -12, 3, 1,
            3, 1, 3, 1, 5, -2, 6, 0,
            2, 9, 3, -15, 4, 2,
            2, 1, 3, 1, 6, 1,
            2, 1, 10, -1, 11, 0,
            1, 2, 4, 1,
            2, 1, 3, 1, 5, 1,
            2, 8, 3, -13, 4, 1,
            2, 3, 2, -6, 3, 0,
            2, 1, 3, -4, 4, 1,
            2, 5, 2, -7, 3, 1,
            2, 7, 3, -11, 4, 1,
            2, 1, 1, -3, 3, 0,
            2, 1, 3, 2, 5, 0,
            2, 2, 3, -6, 4, 0,
            2, 6, 3, -9, 4, 1,
            2, 2, 2, -2, 3, 1,
            2, 5, 3, -7, 4, 2,
            2, 4, 3, -5, 4, 2,
            2, 1, 2, -3, 3, 0,
            2, 7, 2, -10, 3, 0,
            2, 3, 3, -3, 4, 0,
            2, 2, 3, -1, 4, 0,
            2, 4, 2, -5, 3, 1,
            2, 1, 3, 1, 4, 0,
            2, 2, 3, -5, 5, 0,
            2, 8, 3, -12, 4, 0,
            1, 1, 2, 1,
            3, 2, 3, -5, 5, 2, 6, 0,
            2, 2, 3, -4, 5, 1,
            3, 2, 3, -6, 5, 5, 6, 0,
            2, 7, 3, -10, 4, 0,
            3, 2, 3, -4, 5, 2, 6, 0,
            3, 2, 3, -1, 5, -5, 6, 1,
            2, 6, 3, -8, 4, 1,
            2, 2, 3, -3, 5, 1,
            3, 2, 3, -5, 5, 5, 6, 1,
            2, 2, 2, -5, 3, 0,
            2, 6, 2, -8, 3, 0,
            3, 2, 3, -4, 5, 3, 6, 0,
            3, 2, 3, -3, 5, 1, 6, 0,
            2, 5, 3, -6, 4, 1,
            3, 2, 3, -3, 5, 2, 6, 0,
            2, 2, 3, -5, 6, 1,
            2, 2, 3, -2, 5, 1,
            3, 2, 3, -4, 5, 5, 6, 1,
            2, 2, 3, -4, 6, 0,
            2, 4, 3, -4, 4, 0,
            2, 3, 2, -3, 3, 1,
            2, 2, 3, -3, 6, 1,
            3, 2, 3, -2, 5, 2, 6, 0,
            2, 2, 3, -1, 5, 1,
            2, 2, 3, -2, 6, 0,
            2, 3, 3, -2, 4, 1,
            2, 2, 3, -1, 6, 0,
            2, 2, 3, -2, 7, 0,
            3, 2, 3, 2, 5, -5, 6, 0,
            3, 6, 3, -8, 4, 3, 5, 1,
            1, 2, 12, 3,
            3, 2, 3, -8, 4, 3, 5, 1,
            3, 2, 3, -2, 5, 5, 6, 0,
            2, 8, 2, -11, 3, 0,
            2, 2, 3, 1, 5, 0,
            2, 5, 2, -6, 3, 1,
            2, 8, 3, -11, 4, 0,
            2, 1, 1, -2, 3, 0,
            2, 7, 3, -9, 4, 0,
            2, 2, 2, -1, 3, 1,
            2, 6, 3, -7, 4, 0,
            2, 5, 3, -5, 4, 0,
            2, 7, 2, -9, 3, 0,
            2, 4, 3, -3, 4, 0,
            2, 4, 2, -4, 3, 0,
            2, 3, 3, -5, 5, 0,
            2, 1, 2, 1, 3, 0,
            2, 3, 3, -4, 5, 1,
            2, 8, 3, -10, 4, 0,
            2, 7, 3, -8, 4, 0,
            2, 3, 3, -3, 5, 0,
            2, 6, 2, -7, 3, 0,
            2, 6, 3, -6, 4, 0,
            2, 3, 3, -2, 5, 1,
            2, 3, 3, -4, 6, 0,
            2, 5, 3, -4, 4, 0,
            2, 3, 2, -2, 3, 0,
            2, 3, 3, -3, 6, 0,
            2, 3, 3, -1, 5, 0,
            2, 3, 3, -2, 6, 0,
            1, 3, 12, 3,
            2, 5, 2, -5, 3, 0,
            2, 1, 1, -1, 3, 0,
            1, 2, 2, 0,
            2, 7, 2, -8, 3, 0,
            2, 4, 2, -3, 3, 0,
            2, 4, 3, -5, 5, 0,
            2, 4, 3, -4, 5, 0,
            2, 4, 3, -3, 5, 0,
            2, 6, 2, -6, 3, 0,
            2, 4, 3, -2, 5, 0,
            1, 4, 12, 1,
            2, 8, 2, -9, 3, 0,
            2, 5, 2, -4, 3, 0,
            1, 1, 1, 0,
            2, 7, 2, -7, 3, 1,
            2, 5, 3, -5, 5, 0,
            2, 9, 2, -10, 3, 0,
            2, 6, 2, -5, 3, 0,
            2, 8, 2, -8, 3, 0,
            2, 10, 2, -11, 3, 0,
            2, 9, 2, -9, 3, 0,
            2, 10, 2, -10, 3, 0,
            2, 11, 2, -11, 3, 0,
            2, 2, 1, -1, 3, 0,
            -1
        ],
    },
};
