import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { BreathGuide } from './breath';
import { CrossGuide } from './cross';
import { PentagramGuide } from "./pentagram";

const ritualStages = [
  'breath',
  'cross',
  'pentagram',
  'hexagram',
  'cross',
];

export const LBRPGuide: React.FC = () => {
  const [stage, setStage] = useState(0);

  const handleNextClicked = useCallback(() => {
    setStage(s => {
      const ns = (s + 1) % ritualStages.length
      console.log('Moving to', ns);
      return ns;
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column" paddingX={2}>
      {ritualStages[stage] === 'breath' && <BreathGuide onNext={handleNextClicked} />}
      {ritualStages[stage] === 'cross' && <CrossGuide onNext={handleNextClicked} />}
      {ritualStages[stage] === 'pentagram' && <PentagramGuide onNext={handleNextClicked} />}
      <p>Stage: {ritualStages[stage]}</p>
    </Box>
  );
}