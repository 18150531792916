export const DescNodeSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        d="m 8.828427,3.6533286 c 1.560607,1.5606068 1.560607,4.0962473 3e-7,5.6568541 -1.5606068,1.5606063 -4.0962476,1.5606063 -5.6568543,0 -1.5606067,-1.5606068 -1.5606067,-4.0962475 0,-5.6568543"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="m 3.171573,3.6533284 c -0.4888978,0.4888979 -1.278869,0.488898 -1.7677669,0 -0.48889811,-0.4888981 -0.4888979,-1.2788691 0,-1.7677669 0.4888978,-0.4888979 1.2788688,-0.4888981 1.7677669,0 0.488898,0.4888979 0.4888979,1.2788691 0,1.7677669 z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="m 10.596194,3.6533286 c -0.488898,0.4888979 -1.278869,0.488898 -1.767767,0 -0.488898,-0.488898 -0.4888978,-1.278869 0,-1.7677669 0.4888979,-0.4888979 1.278869,-0.4888981 1.767767,0 0.488898,0.4888979 0.488898,1.2788691 0,1.7677669 z m 0,0"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
}