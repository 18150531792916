import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { buildChart } from '@danielpedroso/astrum-calc';
import { SephirotChart } from '@danielpedroso/chart/dist/sephirot';
import { PathType } from '@danielpedroso/chart/dist/utils';
import { Grid, MenuItem, TextField } from '@mui/material';
import { chartAtom, chartStateAtom, locationAtom, zmanimAtom } from './state';
import { useTranslation } from 'react-i18next';
import { SelectLocation } from '../../core/location/select';
import { DateTimeInput } from '../../core/datetime';
import { switchTimezones } from '../../core/datetime/utils';
import { Location as LocationType, locationsAtom } from '../../core/location/state';

///* Dan
//const bd = moment('1990-06-08T18:00:00.000-03:00');
//const lat = -15.2735;
//const lon = -49.64455;
//*/

/* Mai
const bd = moment('1990-07-21 16:55:00.000-03:00');
const lat = -27.216093;
const lon = -49.643502;
*/

export const SephirotPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [chartState, setChartState] = useAtom(chartStateAtom);
  const [chart, setChart] = useAtom(chartAtom);
  const [zmanim] = useAtom(zmanimAtom);
  const [location] = useAtom(locationAtom);
  const [locations] = useAtom(locationsAtom);

  const handleSubtract = useCallback((dur: moment.unitOfTime.DurationConstructor) => () => {
    setChartState((s) => ({
      ...s,
      isoDate: moment(s.isoDate).subtract(1, dur).toISOString(),
    }));
  }, [setChartState]);

  const handleAdd = useCallback((dur: moment.unitOfTime.DurationConstructor) => () => {
    setChartState((s) => ({
      ...s,
      isoDate: moment(s.isoDate).add(1, dur).toISOString(),
    }));
  }, [setChartState]);

  const handleChangeDate = useCallback((d: string) => {
    if (!d) return;

    setChartState((s) => ({
      ...s,
      isoDate: d,
    }));
  }, [setChartState]);

  const handleLocationChanged = useCallback((locationId: string | undefined, loc?: LocationType) => {
    if (!locationId || !loc) return;

    setChartState((s) => {
      const oldLocation = locations.find(l => l.id === s.locationId);
      if (!oldLocation) return s;

      const updated = switchTimezones(s.isoDate, oldLocation.tz, loc.tz);

      return {
        ...s,
        locationId,
        isoDate: updated,
      };
    });
  }, [locations, setChartState]);

  const handleChangeTreeType: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback((e) => {
    if (!e.target.value) return;

    setChartState((s) => ({
      ...s,
      treeType: e.target.value as PathType,
    }));
  }, [setChartState]);

  useEffect(() => {
    if (!location) return;
    if (!chartState?.isoDate) return;

    (async () => {
      setLoading(true);
      try {
        await buildChart({
          date: chartState.isoDate,
          lat: location.lat,
          lon: location.lon,
          height: location.height,
          highPrecision: false,
        }).then((chart) => {
          setChart(chart);
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [chartState, location, setChart]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h5">{t('chartPage.dates.title')}</Typography>
            <Box display="flex" flexDirection="row">
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('hour')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; h</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('day')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; d</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('month')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; m</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('year')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; y</Button>
              <DateTimeInput tz={location?.tz ?? moment.tz.guess()} value={chartState.isoDate} onChange={handleChangeDate} />
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('year')} sx={{ display: { xs: 'none', md: 'block' } }}>y &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('month')} sx={{ display: { xs: 'none', md: 'block' } }}>m &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('day')} sx={{ display: { xs: 'none', md: 'block' } }}>d &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('hour')} sx={{ display: { xs: 'none', md: 'block' } }}>h &gt;</Button>
            </Box>
          </Box>
        </Paper>
        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }}} maxWidth="100vw">
          <Grid
            item
            md={8}
            lg={9}
            pt={2}
            display="flex"
            flexDirection="column"
            maxWidth="100vw"
            sx={{
              opacity: loading ? 0.5 : 1,
              position: 'relative',
            }}
          >
            {loading && <Typography variant="h3" width="100%" textAlign="center" sx={{ position: 'absolute', top: '300px' }}>{t('chartPage.loading')}</Typography>}
            <SephirotChart chart={chart} treeType={chartState.treeType || 'lurianic'} />
          </Grid>
          <Grid item md={4} lg={3} maxWidth="100vw">
            <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('chartPage.location.title')}</Typography>
                <SelectLocation label={t('chartPage.location.title')} value={chartState.locationId} onChange={handleLocationChanged} />
                <Typography variant="h5">{t('sephirotPage.treeType')}</Typography>
                <TextField
                  select
                  value={chartState.treeType || 'lurianic'}
                  onChange={handleChangeTreeType}
                >
                  <MenuItem value="lurianic">{t('sephirotPage.lurianic')}</MenuItem>
                  <MenuItem value="golden-dawn">{t('sephirotPage.golden-dawn')}</MenuItem>
                </TextField>
                <Typography variant="h5" gutterBottom>זמנים</Typography>
                <Box pb={1}>
                  <Typography>{zmanim?.sunrise} :הנץ החמה</Typography>
                  <Typography>{zmanim?.latestShema} :סוף זמן קריאת שמע</Typography>
                  <Typography>{zmanim?.latestAmida} :סוף זמן תפילה</Typography>
                  <Typography>{zmanim?.halachicMidday} :חצות היום</Typography>
                  <Typography>{zmanim?.earliestMincha} :מנחה גדולה</Typography>
                  <Typography>{zmanim?.idealMincha} :מנחה קטנה</Typography>
                  <Typography>{zmanim?.plagMincha} :פלג המנחה</Typography>
                  {zmanim?.candleLighting && (
                    <Typography>{zmanim?.candleLighting} :נרות שבת</Typography>
                  )}
                  <Typography>{zmanim?.sunset} :שקיעה</Typography>
                  <Typography>{zmanim?.tzeit} :צאת הכוכבים</Typography>
                  {zmanim?.shabbatEnds && (
                    <Typography>{zmanim?.shabbatEnds} :יציאת שבת</Typography>
                  )}
                  <Typography>{zmanim?.proportionalHour} :שעה זמנית</Typography>
                </Box>

              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
