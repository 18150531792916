import { Chart as ChartType } from '@danielpedroso/astrum-calc/dist/types';
import { SIGNS } from '@danielpedroso/astrum-calc/dist/constants';
import { hebrewOnly } from '@danielpedroso/hebrew';
import { Sign } from './sign';
import { CelestialObject } from './object';
import { useMemo } from 'react';
import { SquareRatio, Container, Svg } from './styled';
import { Rose } from './rose';

interface Props {
  name: string;
  mode: 'edit' | 'view';
  doubleLettersEndInKaf?: boolean;
  svgRef?: React.Ref<SVGSVGElement> | undefined;
}

export const SigilOnly: React.FC<Props> = ({ name, mode, svgRef, doubleLettersEndInKaf = true }) => {
  const hebrewName = useMemo(() => hebrewOnly(name), [name]);

  return (
    <SquareRatio>
      <Container>
        <Svg
          id="sigil"
          viewBox="0 0 800 800"
          preserveAspectRatio="xMidYMid meet"
          style={{ transformBox: 'fill-box', transformOrigin: 'center', backgroundColor: 'white' }}
          xmlns="http://www.w3.org/2000/svg"
          ref={svgRef}
        >
          <Rose
            internalRadiusMultiplier={.05}
            parentSize={800}
            layerSize={.11}
            name={hebrewName}
            mode={mode}
            doubleLettersEndInKaf={doubleLettersEndInKaf}
          />
        </Svg>
      </Container>
    </SquareRatio>
  );
};
