import React, { useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { translateCoordinate } from '@danielpedroso/astrum-calc';
import { objectSymbols } from '@danielpedroso/celestial-objects';
import { zodiacSignSymbols } from '@danielpedroso/zodiac-signs';
import { degToDMS, namesOfGod, getNameOfGodIdx, getCoordinates, getDecan, getLineCoordinates, getTarotCard, keys } from '../utils'
import { useTranslation } from 'react-i18next';
import { numbers } from './numbers';
import { labels } from './labels';
import { HouseTooltip } from './tooltip';
import { EXTRA_INFO_CUSPS, EXTRA_INFO_CUSP_LABEL } from './utils';

interface Props {
  parentSize: number;
  externalRadiusMultiplier: number;
  internalRadiusMultiplier: number;
  extraInfoRadiusMultiplier: number;
  cusp: number;
  name: string;
  namePos?: 'internal' | 'external';
  rotation: number;
  fontSize?: number;
  highPrecision?: boolean;
}

export const House: React.FC<Props> = ({
  parentSize,
  cusp,
  name,
  namePos = 'internal',
  externalRadiusMultiplier,
  internalRadiusMultiplier,
  extraInfoRadiusMultiplier,
  rotation,
  fontSize = 24,
  highPrecision = false,
}) => {
  const { t } = useTranslation();
  const [hovering, setHovering] = useState(false);
  const hasInfo = EXTRA_INFO_CUSPS.includes(name);

  const coords = useMemo(() => {
    const width = parentSize;
    const r = width / 2;
    const externalRadius = (width * (hasInfo ? extraInfoRadiusMultiplier : externalRadiusMultiplier));
    const internalRadius = width * internalRadiusMultiplier;
    const textPos = namePos === 'internal' ? (internalRadius + fontSize) : (externalRadius - fontSize);
    const deg = (cusp + 180) % 360;

    const start = getLineCoordinates(deg, internalRadius, externalRadius, r);
    const text = getCoordinates(deg + 15, textPos, r);
    const text2 = hasInfo ? getCoordinates(deg, externalRadius + fontSize, r) : undefined;

    return {
      start,
      name: {
        g: {},
        text: {
          x: text.x - 10,
          y: text.y - 10,
        },
      },
      text2: hasInfo ? { x: text2?.x as number - 15, y: text2?.y as number - 15 } : undefined,
    };
  }, [parentSize, hasInfo, extraInfoRadiusMultiplier, externalRadiusMultiplier, internalRadiusMultiplier, namePos, fontSize, cusp]);

  const coord = translateCoordinate(cusp, []);
  const decan = getDecan(cusp);
  const DecanSymbol = objectSymbols[decan];
  if (!DecanSymbol) throw new Error(`Unknown object: ${decan}`);

  const ZodiacSymbol = zodiacSignSymbols[coord.sign];
  if (!ZodiacSymbol) throw new Error(`Unknown zodiac sign: ${coord.sign}`);

  const Number = numbers[name + ''];
  const Label = hasInfo ? labels[name + ''] : undefined;

  return (
    <Tooltip
      title={
        <HouseTooltip
          coord={coord}
          cusp={cusp}
          label={EXTRA_INFO_CUSP_LABEL[name] ?? t('houses.title', { name })}
          highPrecision={highPrecision}
        />
      }
      followCursor
    >
      <g onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} pointerEvents="bounding-box">
        <line {...coords.start} stroke={hovering ? 'grey' : 'black'} strokeWidth={hasInfo ? '4' : undefined} />
        <g transform={`rotate(-${rotation} ${coords.name.text.x + 10} ${coords.name.text.y + 10})`}>
          <Number {...coords.name.text} opacity={0.3} />
        </g>
        {hasInfo && Label && coords.text2 && (
          <g transform={`rotate(-${rotation} ${coords.text2.x + 15} ${coords.text2.y + 15})`}>
            <Label {...coords.text2} />
          </g>
        )}
      </g>
    </Tooltip>
  );
}