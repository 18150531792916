export const Shin: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_107_5)">
        <path d="M37.6641 39.1172C36.3828 39.8359 34.8047 40.4297 32.9297 40.8984C31.0703 41.3516 28.75 41.6875 25.9688 41.9062C23.2031 42.125 19.8203 42.2344 15.8203 42.2344L10.3359 12.9141H16.9688L19.5234 26.9297L21.2344 26.6016C22.2969 26.3984 23.0781 26.0547 23.5781 25.5703C24.0938 25.0703 24.4844 24.3281 24.75 23.3438C24.875 22.8594 24.9922 22.1875 25.1016 21.3281C25.2266 20.4531 25.3438 19.3906 25.4531 18.1406L25.8047 12.9141H32.2734L31.5703 20.9297C31.4766 22.0234 31.3438 23.0078 31.1719 23.8828C31 24.7578 30.7812 25.5156 30.5156 26.1562C30 27.4375 29.2734 28.4375 28.3359 29.1562C27.4141 29.875 25.9453 30.4766 23.9297 30.9609L20.4141 31.7812L21.375 36.5859H21.9375C23.8906 36.5859 25.6172 36.5078 27.1172 36.3516C28.6328 36.1797 29.9219 35.9219 30.9844 35.5781C32.4062 35.1406 33.5781 34.5312 34.5 33.75C35.4219 32.9531 36.1484 31.8984 36.6797 30.5859C37.2109 29.2578 37.5859 27.5781 37.8047 25.5469L39.2812 12.9141H45.8203L44.3203 25.3828C44.0078 28.0703 43.5703 30.2969 43.0078 32.0625C42.4609 33.8281 41.75 35.2656 40.875 36.375C40.0156 37.4688 38.9453 38.3828 37.6641 39.1172Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_107_5">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
