export const Qof: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_121_12)">
        <path d="M37.7578 22.5703C37.7578 25.5391 37.1562 28.3125 35.9531 30.8906C34.75 33.4688 32.9844 35.7812 30.6562 37.8281C28.3438 39.875 25.5078 41.5859 22.1484 42.9609V36.2344C25.0859 34.8594 27.3516 33.0312 28.9453 30.75C30.5547 28.4531 31.3594 25.9766 31.3594 23.3203C31.3594 21.5703 30.9219 20.3438 30.0469 19.6406C29.1719 18.9375 27.6406 18.5859 25.4531 18.5859H11.8828V12.9141H26.3672C30.3359 12.9141 33.2266 13.6719 35.0391 15.1875C36.8516 16.6875 37.7578 19.1484 37.7578 22.5703ZM19.4062 24.1406V51.0234H13.0781V24.1406H19.4062Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_121_12">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
