import React, { useMemo } from 'react';
import { Chart as ChartType } from '@danielpedroso/astrum-calc/dist/types';
import { sephiroth, getPaths, PathType } from './utils';
import { SquareRatio, Container, Svg } from './styled';
import { Sephirah } from './sephirah';
import { Path } from './path';

interface Props {
  chart?: ChartType;
  treeType?: PathType;
}

export const SephirotChart: React.FC<Props> = ({ chart, treeType = 'lurianic' }) => {
  const paths = useMemo(() => {
    return getPaths(treeType);
  }, [treeType]);

  return (
    <SquareRatio>
      <Container>
        <Svg
          id="sephirot-chart"
          viewBox="0 0 1000 1000"
          preserveAspectRatio="xMidYMid meet"
          fontFamily='"Times New Roman", Times, serif' 
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Gradients */}
          <defs>
          {
            chart && sephiroth.map((sephirah, i) => (
              <linearGradient
                key={`gradient-${i}`}
                id={`gradient-${i}`}
                x1={0}
                x2={0}
                y1={0}
                y2={1}
              >
                {sephirah.getSephirahColour(chart!).map((c, ci) => (
                  <React.Fragment key={`colour-${i}-${ci}`}>
                    <stop offset={`${((ci) / 4) * 100}%`} stopColor={c} />
                    <stop offset={`${((ci + 1) / 4) * 100}%`} stopColor={c} />
                  </React.Fragment>
                ))}
              </linearGradient>
            ))
          }
          </defs>

          {/* Paths */}
          {paths.map((path, i) => (
            <Path
              key={`path-${i}`}
              from={path.from}
              to={path.to}
              name={path.name}
              namePos={path.namePos}
            />
          ))}

          {chart && sephiroth.map((sephira, i) => (
            <Sephirah
              key={`sephira-${sephira.number}`}
              x={sephira.x}
              y={sephira.y}
              sephiraName={sephira.name}
              sephiraEnglishName={sephira.englishName}
              colour={`url(#gradient-${i})`}
              nameOfGod={sephira.getNameOfGod(chart!)}
              opacity={sephira.opacity}
            />
          ))}
        </Svg>
      </Container>
    </SquareRatio>
   );
};
