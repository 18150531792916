export const JupiterSymbol: React.FC<any> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        d="m 2.25,1 c 1.3398438,0.773437 2.1640625,2.203125 2.1640625,3.75 0,1.546875 -0.8242187,2.9765625 -2.1640625,3.75 h 7.5"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
      <path
        d="m 7.25,6 v 5"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
}