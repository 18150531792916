import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  topBorder?: boolean;
  bottomBorder?: boolean;
}

export const Toolbar: React.FC<Props> = ({ title, children, topBorder, bottomBorder }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!isMobile) return null;
 
  return (
    <Box
      borderBottom={bottomBorder ? `1px solid rgba(0, 0, 0, 0.3)` : undefined}
      borderTop={topBorder ? `1px solid rgba(0, 0, 0, 0.3)` : undefined}
      px={2}
      maxWidth="100%"
      overflow="hidden"
      flexShrink={0}
    >
      <Typography variant="h6">{title}</Typography>
      <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between" sx={{ overflowX: 'auto', overflowY: 'hidden', py: 2 }}>
        {children}
      </Box>
    </Box>
  );
}