"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSolarInfo = exports.mToHM = exports.hmToHHMM = void 0;
const constants_1 = require("./constants");
const hmToHHMM = (h, m) => {
    const hs = h < 10 ? `0${h}` : h.toString();
    const ms = m < 10 ? `0${m}` : m.toString();
    return `${hs}:${ms}`;
};
exports.hmToHHMM = hmToHHMM;
const mToHM = (minutes) => {
    const h = Math.trunc(minutes / 60);
    const m = Math.trunc(minutes % 60);
    return (0, exports.hmToHHMM)(h, m);
};
exports.mToHM = mToHM;
const to1900Date = (inDate) => {
    const returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
    return Math.trunc(returnDateTime);
};
const fractionalDayToMinutes = (fDay) => {
    return fDay * 24 * 60;
};
const degrees = (rad) => rad * constants_1.RADIANS_TO_DEG;
const radians = (deg) => deg * constants_1.DEG_TO_RADIANS;
const getSolarInfo = (date, lat, lon, height = 0.0, posBelowHorizon = 0.833) => {
    const localTime = 1 / 24;
    const localDate = to1900Date(date.toDate());
    const localOffset = date.utcOffset() / 60;
    const julian = localDate + 2415018.5 + localTime - localOffset / 24;
    const julianCentury = (julian - 2451545) / 36525;
    const sunGeomMeanLong = (280.46646 +
        julianCentury *
            (36000.76983 + julianCentury * 0.0003032)) % 360;
    const sunGeomMeanAnom = 357.52911 + julianCentury * (35999.05029 - 0.0001537 * julianCentury);
    const earthOrbitEccent = 0.016708634 - julianCentury * (0.000042037 + 0.0000001267 * julianCentury);
    const meanObliquityOfEcliptic = 23 + (26 + ((21.448 - julianCentury * (46.815 + julianCentury * (0.00059 - julianCentury * 0.001813)))) / 60) / 60;
    const obliqCorrection = meanObliquityOfEcliptic + 0.00256 * Math.cos(radians(125.04 - 1934.136 * julianCentury));
    const fractionalYear = Math.tan(radians(meanObliquityOfEcliptic / 2)) * Math.tan(radians(meanObliquityOfEcliptic / 2));
    const sunEqOfCtr = Math.sin(radians(sunGeomMeanAnom)) *
        (1.914602 - julianCentury * (0.004817 + 0.000014 * julianCentury)) +
        Math.sin(radians(2 * sunGeomMeanAnom)) *
            (0.019993 - 0.000101 * julianCentury) +
        Math.sin(radians(3 * sunGeomMeanAnom)) *
            0.000289;
    const sunTrueLong = sunGeomMeanLong + sunEqOfCtr;
    const sunAppLong = sunTrueLong - 0.00569 - 0.00478 * Math.sin(radians(125.04 - 1934.136 * julianCentury));
    const sunDec = degrees(Math.asin(Math.sin(radians(obliqCorrection)) * Math.sin(radians(sunAppLong))));
    const eqtime = 4 * degrees(fractionalYear * Math.sin(2 * radians(sunGeomMeanLong)) -
        2 * earthOrbitEccent * Math.sin(radians(sunGeomMeanAnom)) +
        4 * earthOrbitEccent * fractionalYear * Math.sin(radians(sunGeomMeanAnom)) *
            Math.cos(2 * radians(sunGeomMeanLong)) -
        0.5 * fractionalYear * fractionalYear *
            Math.sin(4 * radians(sunGeomMeanLong)) -
        1.25 * earthOrbitEccent * earthOrbitEccent *
            Math.sin(2 * radians(sunGeomMeanAnom)));
    const h0 = 90.0 + posBelowHorizon;
    const sunriseHA = degrees(Math.acos(Math.cos(radians(h0)) /
        (Math.cos(radians(lat)) * Math.cos(radians(sunDec))) -
        Math.tan(radians(lat)) *
            Math.tan(radians(sunDec))));
    const solarNoon = (720 - 4 * lon - eqtime + localOffset * 60) / 1440;
    const sunriseTime = solarNoon - sunriseHA * 4 / 1440;
    const sunsetTime = solarNoon + sunriseHA * 4 / 1440;
    return {
        date,
        sun: {
            rise: (0, exports.mToHM)(fractionalDayToMinutes(sunriseTime)),
            noon: (0, exports.mToHM)(fractionalDayToMinutes(solarNoon)),
            set: (0, exports.mToHM)(fractionalDayToMinutes(sunsetTime)),
        },
    };
};
exports.getSolarInfo = getSolarInfo;
