"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZmanim = void 0;
const sun_times_1 = require("./sun-times");
const getZmanim = (date, lat, lon, height = 0.0) => {
    var _a, _b;
    const info = (0, sun_times_1.getSolarInfo)(date, lat, lon, height);
    const [hStart, mStart] = info.sun.rise.split(':').map(s => Number.parseInt(s));
    const [hEnd, mEnd] = info.sun.set.split(':').map(s => Number.parseInt(s));
    const minStart = hStart * 60 + mStart;
    const minEnd = hEnd * 60 + mEnd;
    const perHr = (minEnd - minStart) / 12;
    const isShabbat = date.day() === 6;
    const isErevShabbat = date.day() === 5;
    const shabbatEnds = isShabbat ? (0, sun_times_1.getSolarInfo)(date, lat, lon, height, 8.5) : undefined;
    return {
        date,
        sunrise: info.sun.rise,
        latestShema: (0, sun_times_1.mToHM)(minStart + (perHr * 3) - 1),
        latestAmida: (0, sun_times_1.mToHM)(minStart + (perHr * 4) - 1),
        noon: info.sun.noon,
        halachicMidday: (0, sun_times_1.mToHM)(minStart + (perHr * 6)),
        earliestMincha: (0, sun_times_1.mToHM)(minStart + (perHr * 6.5) - 1),
        idealMincha: (0, sun_times_1.mToHM)(minStart + (perHr * 9.5) - 1),
        plagMincha: (0, sun_times_1.mToHM)(minStart + (perHr * 10.75) - 1),
        candleLighting: isErevShabbat ? (0, sun_times_1.mToHM)(minEnd - 18) : '',
        shabbatEnds: (_b = (_a = shabbatEnds === null || shabbatEnds === void 0 ? void 0 : shabbatEnds.sun) === null || _a === void 0 ? void 0 : _a.set) !== null && _b !== void 0 ? _b : '',
        sunset: info.sun.set,
        tzeit: (0, sun_times_1.mToHM)(minEnd + 24),
        proportionalHour: (0, sun_times_1.mToHM)(perHr),
    };
};
exports.getZmanim = getZmanim;
