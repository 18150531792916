"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcGeocentricPosition = void 0;
const math_1 = require("../math");
const lonlat_1 = require("./lonlat");
const precess_1 = require("./precess");
const aberration_1 = require("./aberration");
const constants_1 = require("../constants");
const nutation_1 = require("./nutation");
const calcGeocentricPosition = (body, q, e, earthBody, observer, calcHeliocentricLongitudesOnly = false) => {
    var _a;
    let p = [0, 0, 0];
    let temp = [0, 0, 0];
    let polar = [0, 0, 0]; // double
    let a;
    let b;
    let r = 0.0;
    let s;
    let x; // double
    let i; // int
    /* Save the geometric coordinates at TDT
     */
    for (i = 0; i < 3; i++) {
        temp[i] = q[i];
    }
    if (!calcHeliocentricLongitudesOnly) {
        /* Display ecliptic longitude and latitude, precessed to equinox
        of date.  */
        body.equinoxEclipticLonLat = (0, lonlat_1.calcLonLat)(q, observer.date, polar, 1);
    }
    /* Adjust for light time (planetary aberration)
     */
    (0, aberration_1.calcVelocityAberration)(body, q, e, earthBody, observer); // NOTE mutates body
    /* Find Euclidean vectors between earth, object, and the sun
     */
    for (i = 0; i < 3; i++) {
        p[i] = q[i] - e[i];
    }
    body = (0, math_1.angles)(p, q, e, body); // NOTE mutates body
    if (!calcHeliocentricLongitudesOnly) {
        a = 0.0;
        for (i = 0; i < 3; i++) {
            b = temp[i] - e[i];
            a += b * b;
        }
        a = Math.sqrt(a);
        if (!body.position) {
            body.position = { polar: [0, 0, 0], rect: [0, 0, 0] };
        }
        body.position.trueGeocentricDistance = a; /* was EO */
        body.position.equatorialDiameter = 2.0 * ((_a = body.semiDiameter) !== null && _a !== void 0 ? _a : 0) / body.locals.EO;
        /* Calculate radius.
         */
        r = 0.0;
        x = 0.0;
        for (i = 0; i < 3; i++) {
            x = p[i];
            r += x * x;
        }
        r = Math.sqrt(r);
        /* Calculate visual magnitude.
         * "Visual" refers to the spectrum of visible light.
         * Phase = 0.5(1+pq) = geometric fraction of disc illuminated.
         * where pq = cos( sun-object-earth angle )
         * The magnitude is
         *    V(1,0) + 2.5 log10( SE^2 SO^2 / Phase)
         * where V(1,0) = elemnt->mag is the magnitude at 1au from
         * both earth and sun and 100% illumination.
         */
        a = 0.5 * (1.0 + body.locals.pq);
        /* Fudge the phase for light leakage in magnitude estimation.
         * Note this phase term estimate does not reflect reality well.
         * Calculated magnitudes of Mercury and Venus are inaccurate.
         */
        b = 0.5 * (1.01 + 0.99 * body.locals.pq);
        s = body.magnitude + 2.1715 * Math.log(body.locals.EO * body.locals.SO) - 1.085 * Math.log(b);
        body.position.approxVisual = {
            magnitude: s,
            phase: a
        };
    } // END calcHeliocentricLongitudesOnly
    /* Find unit vector from earth in direction of object
     */
    for (i = 0; i < 3; i++) {
        p[i] /= body.locals.EO;
        temp[i] = p[i];
    }
    /* Report astrometric position
     */
    if (!body.position) {
        body.position = { polar: [0, 0, 0], rect: [0, 0, 0] };
    }
    if (!calcHeliocentricLongitudesOnly) {
        body.position.astrometricJ2000 = (0, math_1.showrd)(p, polar);
        /* Also in 1950 coordinates */
        temp = (0, precess_1.calcPrecess)(temp, constants_1.BESSELIAN_1950, -1);
        body.position.astrometricB1950 = (0, math_1.showrd)(temp, polar);
    } // END calcHeliocentricLongitudesOnly
    /* Correct position for light deflection */
    body.position.deflection = (0, aberration_1.calcDeflection)(p, q, e, body); // relativity NOTE - mutates p
    /* Correct for annual aberration */
    body.position.aberration = (0, aberration_1.calcAberration)(p, earthBody, observer, body); // NOTE - mutates p
    /* Precession of the equinox and ecliptic
     * from J2000.0 to ephemeris date
     */
    p = (0, precess_1.calcPrecess)(p, observer.date.julian, -1); // NOTE - mutates p
    /* Ajust for nutation
     * at current ecliptic.
     */
    body.position.nutation = (0, nutation_1.calcNutation)(observer.date, p); // NOTE mutates p
    /* Display the final apparent R.A. and Dec.
    * for equinox of date.
    */
    body.position.apparent = (0, math_1.showrd)(p, polar);
    /* Geocentric ecliptic longitude and latitude.  */
    for (i = 0; i < 3; i++) {
        p[i] *= body.locals.EO;
    }
    body.position.apparentGeocentric = (0, lonlat_1.calcLonLat)(p, observer.date, temp, 0);
    body.position.apparentLongitude = body.position.apparentGeocentric[0] * constants_1.RADIANS_TO_DEG;
    if (!calcHeliocentricLongitudesOnly) {
        body.position.apparentLongitudeString =
            body.position.apparentGeocentric[3].degree + '\u00B0' +
                body.position.apparentGeocentric[3].minutes + '\'' +
                Math.floor(body.position.apparentGeocentric[3].seconds) + '"';
        body.position.apparentLongitude30String =
            (body.position.apparentGeocentric[3].degree % 30) + '\u00B0' +
                body.position.apparentGeocentric[3].minutes + '\'' +
                Math.floor(body.position.apparentGeocentric[3].seconds) + '"';
        body.position.geocentricDistance = r;
        /* Go do topocentric reductions. */
        polar[2] = body.locals.EO;
    }
    return body;
};
exports.calcGeocentricPosition = calcGeocentricPosition;
