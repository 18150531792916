"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcBodyPosition = exports.calcMoonPos = exports.calcSunPos = exports.calcEarthPos = void 0;
const body_1 = require("../bodies/body");
const constants_1 = require("../constants");
const math_1 = require("../math");
const geocentric_1 = require("./geocentric");
const heliocentric_1 = require("./heliocentric");
const lonlat_1 = require("./lonlat");
const moon_1 = require("./moon");
const nutation_1 = require("./nutation");
const precess_1 = require("./precess");
const calcEarthPos = (julianDate) => {
    let earth = (0, body_1.createBodyInstance)('earth');
    return (0, heliocentric_1.calcHeliocentricPosition)(julianDate, earth);
};
exports.calcEarthPos = calcEarthPos;
const calcSunPos = (julianDate, earth, observer) => {
    var _a, _b, _c, _d;
    if (!earth.position || !earth.position.rect)
        throw new Error('Earth position not calculated');
    const body = (0, body_1.createBodyInstance)('sun');
    let t = 0;
    let x = 0;
    let y = 0;
    let ecr = [0, 0, 0];
    let rec = [0, 0, 0];
    let pol = [0, 0, 0];
    let i; // int
    let d;
    /* Display ecliptic longitude and latitude.
     */
    for (i = 0; i < 3; i++) {
        ecr[i] = -earth.position.rect[i]; //-rearth[i];
    }
    let r = earth.position.polar[2]; //eapolar [2];
    body.position = (_a = body.position) !== null && _a !== void 0 ? _a : {};
    if (!body.position)
        throw new Error('TypeScript is stupid');
    body.position.equinoxEclipticLonLat = (0, lonlat_1.calcLonLat)(ecr, observer.date, pol, 1);
    /* Philosophical note: the light time correction really affects
     * only the Sun's barymetric position; aberration is due to
     * the speed of the Earth.  In Newtonian terms the aberration
     * is the same if the Earth is standing still and the Sun moving
     * or vice versa.  Thus the following is actually wrong, but it
     * differs from relativity only in about the 8th decimal.
     * It should be done the same way as the corresponding planetary
     * correction, however.
     */
    pol[2] = r;
    let earthTDT = (0, exports.calcEarthPos)(julianDate); // clone to prevent mutation
    for (i = 0; i < 2; i++) {
        t = pol[2] / 173.1446327;
        /* Find the earth at time TDT - t */
        earthTDT = (0, heliocentric_1.calcHeliocentricPosition)(observer.date.julian - t, earthTDT, ecr, pol);
    }
    r = pol[2];
    for (i = 0; i < 3; i++) {
        x = -ecr[i];
        y = -((_d = (_c = (_b = earthTDT.position) === null || _b === void 0 ? void 0 : _b.rect) === null || _c === void 0 ? void 0 : _c[i]) !== null && _d !== void 0 ? _d : 0); //-rearth[i];
        ecr[i] = x; /* position t days ago */
        rec[i] = y; /* position now */
        pol[i] = y - x; /* change in position */
    }
    body.position = { ...body.position, ...{
            lightTime: 1440.0 * t,
            aberration: (0, math_1.showcor)(ecr, pol)
        } };
    /* Estimate rate of change of RA and Dec
     * for use by altaz().
     */
    d = (0, math_1.deltap)(ecr, rec); /* see dms.c */
    body.locals.dradt = d.dr;
    body.locals.ddecdt = d.dd;
    body.locals.dradt /= t;
    body.locals.ddecdt /= t;
    /* There is no light deflection effect.
     * AA page B39.
     */
    /* precess to equinox of date
     */
    ecr = (0, precess_1.calcPrecess)(ecr, observer.date.julian, -1);
    for (i = 0; i < 3; i++) {
        rec[i] = ecr[i];
    }
    /* Nutation.
     */
    let epsilonObject = (0, math_1.calcEpsilon)(observer.date.julian);
    let nutationObject = (0, nutation_1.getObject)(observer.date);
    (0, nutation_1.calcNutation)(observer.date, ecr); // NOTE nutation mutates the nutation object AND returns a result.
    /* Display the final apparent R.A. and Dec.
     * for equinox of date.
     */
    body.position.apparent = (0, math_1.showrd)(ecr, pol);
    /* Show it in ecliptic coordinates */
    y = epsilonObject.coseps * rec[1] + epsilonObject.sineps * rec[2];
    y = (0, math_1.zatan2)(rec[0], y) + nutationObject.nutl;
    body.position.apparentLongitude = constants_1.RADIANS_TO_DEG * y;
    var dmsLongitude = (0, math_1.dms)(y);
    body.position.apparentLongitudeString =
        dmsLongitude.degree + '\u00B0' +
            dmsLongitude.minutes + '\'' +
            Math.floor(dmsLongitude.seconds) + '"';
    body.position.apparentLongitude30String =
        (dmsLongitude.degree % 30) + '\u00B0' +
            dmsLongitude.minutes + '\'' +
            Math.floor(dmsLongitude.seconds) + '"';
    body.position.geocentricDistance = -1;
    return body;
};
exports.calcSunPos = calcSunPos;
const calcMoonPos = (earth, observer) => {
    let body = (0, body_1.createBodyInstance)('moon');
    return (0, moon_1.calcMoonPosition)(body, earth, observer);
};
exports.calcMoonPos = calcMoonPos;
const calcBodyPosition = (body, julianDate, earth, observer) => {
    if (!earth.position || !earth.position.rect)
        throw new Error(`Failed to calculate heliocentric position for earth`);
    if (body.key === 'earth')
        return (0, heliocentric_1.calcHeliocentricPosition)(julianDate, body);
    if (body.key === 'sun')
        return (0, exports.calcSunPos)(julianDate, earth, observer);
    if (body.key === 'moon')
        return (0, exports.calcMoonPos)(earth, observer);
    if (!body.semiAxis && (body.perihelionDistance && body.eccentricity)) {
        body.semiAxis = body.perihelionDistance / (1 - body.eccentricity);
    }
    body = (0, heliocentric_1.calcHeliocentricPosition)(julianDate, body);
    if (!body.position || !body.position.rect)
        throw new Error(`Failed to calculate heliocentric position for ${body.key}`);
    body = (0, geocentric_1.calcGeocentricPosition)(body, body.position.rect, earth.position.rect, earth, observer, false);
    return body;
};
exports.calcBodyPosition = calcBodyPosition;
