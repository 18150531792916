export const Dalet: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_114_6)">
        <path
          d="M34.0391 18.5859V42H27.7109V18.5859H11.9609V12.9141H37.6484V18.5859H34.0391Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_114_6">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
