import { atom } from 'jotai';
import { AspectData } from '../state';
import { addLastZeroIfNeeded, addZeroPointIfNeeded, calcPointCoord } from './service';
import { ChartConfig, ObjectTransits, PointsMemo, TransitPoint } from './types';

export const chartSizeAtom = atom({
  width: 1000,
  height: 500,
  margin: {
    top: 10,
    bottom: 50,
    left: 50,
    right: 50,
  },
});

export const chartDataAtom = atom<AspectData[] | undefined>(undefined);

export const xElementsAtom = atom<number>((get) => {
  const data = get(chartDataAtom);
  return data?.[0]?.data?.length ?? 0;
});

export const chartConfigAtom = atom<ChartConfig | undefined>((get) => {
  const size = get(chartSizeAtom);
  const xElements = get(xElementsAtom);

  return {
    margin: size.margin,
    xRange: size.width - size.margin.left - size.margin.right,
    yRange: size.height - size.margin.top - size.margin.bottom,
    xElements,
    yElements: 5,
  };
});

export const aspectTransitsAtom = atom<ObjectTransits[]>((get) => {
  const data = get(chartDataAtom);
  const chartConfig = get(chartConfigAtom) as ChartConfig;

  return data?.reduce((acc, curr) => {
    const transits: TransitPoint[][] = [];
    let currTransit: TransitPoint[] = [];
    let wasInTransit = false;

    for (let i = 0; i < curr.data.length; i++) {
      const d = curr.data[i];
      const isInTransit = !!curr.data[i].aspect;

      if (!wasInTransit && isInTransit) {
        currTransit = [];
        addZeroPointIfNeeded(curr, i, currTransit, chartConfig);
        transits.push(currTransit);
      }
      if (isInTransit) {
        currTransit.push({
          data: d,
          index: i,
          coord: calcPointCoord({
            ...chartConfig,
            x: i,
            y: 5 - (d.aspect?.degree ?? 5)
          }),
        });
      }
      if (wasInTransit && (!isInTransit || i === curr.data.length - 1)) {
        addLastZeroIfNeeded(curr, isInTransit ? i : i - 1, currTransit, chartConfig);
      }

      wasInTransit = isInTransit;
    }

    return acc.concat({ key: curr.key, colour: curr.colour ?? 'black', transits });
  }, [] as ObjectTransits[]) ?? [];
});

export const pointsMemoAtom = atom<PointsMemo>(
  (get) => {
    const transits = get(aspectTransitsAtom);

    const allPoints = transits.flatMap(k => k.transits.flatMap(p => p)).sort((a, b) => a.coord.x - b.coord.x);
    const memo = allPoints.reduce((acc, curr) => {
      const x = curr.coord.x;
      const items = acc[x] || [];
      return {
        ...acc,
        [x]: items.concat(curr).sort((a, b) => a.coord.y - b.coord.y),
      };
    }, {} as PointsMemo['memo'])
    const keys = Object.keys(memo).map(x => Number.parseFloat(x)).sort((a, b) => a - b);

    return { keys, memo };
  }
);

export const selectedPointAtom = atom<TransitPoint | undefined>(undefined);
