import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { objectSymbols } from "@danielpedroso/celestial-objects";
import { zodiacSignSymbols } from "@danielpedroso/zodiac-signs";
import { Pos } from "@danielpedroso/astrum-calc/dist/types";
import { degToDMS, getDecan, getNameOfGodIdx, getTarotCard, keys, namesOfGod } from "../utils";

interface Props {
  coord: Pos;
  cusp: number;
  label: React.ReactNode;
  highPrecision?: boolean;
}

export const HouseTooltip: React.FC<Props> = ({ coord, cusp, label, highPrecision }) => {
  const { t } = useTranslation();

  const decan = getDecan(cusp);
  const DecanSymbol = objectSymbols[decan];
  if (!DecanSymbol) throw new Error(`Unknown object: ${decan}`);

  const ZodiacSymbol = zodiacSignSymbols[coord.sign];
  if (!ZodiacSymbol) throw new Error(`Unknown zodiac sign: ${coord.sign}`);

  const tarotCard = getTarotCard(coord.absolute);
  const musicalKey = keys[Math.trunc(coord.absolute / 30) % 12];
  const deg = degToDMS(coord.pos, highPrecision);
  const nameOfGod = namesOfGod[getNameOfGodIdx(coord.absolute)];


  return (
    <>
      <Typography variant="h6">{label ?? t('houses.title', { name })}</Typography>
      <Typography variant="subtitle2" display="flex" flexDirection="row" gap={1} alignItems="center">
        <span>{deg}</span>
        <ZodiacSymbol stroke="currentColor" fill="currentColor" strokeWidth="1px" width="12px" height="12px" />
        <span>({t(`signs.${coord.sign}`)})</span>
        <span>{coord.intermediary ? t('signs.intermediary') : ''}</span>
      </Typography>
      <Typography variant="body2" display="flex" flexDirection="row" gap={0.5} alignItems="center">
        {`${t('decans.capital')}:`}
        <DecanSymbol stroke="currentColor" fill="currentColor" strokeWidth="1px" width="12px" height="12px" />
        <span>({t(`celestialBodies.${decan}`)})</span>
      </Typography>
      <Typography variant="body2">{t('other.musicalKey')}: {musicalKey}</Typography>
      <Typography variant="body2">Tarot: {tarotCard} - {t(`tarot.${tarotCard}`)}</Typography>
      <Typography variant="body2">{nameOfGod.name} - {nameOfGod.englishName} ({nameOfGod.number})</Typography>
      <Typography variant="body2">{t(`biblical.verses.${nameOfGod.bible.book}.${nameOfGod.bible.number}.${nameOfGod.bible.verse}`)}</Typography>
      <Typography variant="body2">({t(`biblical.${nameOfGod.bible.book}`)} {nameOfGod.bible.number}:{nameOfGod.bible.verse})</Typography>
      <Typography
        variant="h5"
        fontFamily='"Times New Roman", Times, serif' 
      >
        {nameOfGod.bible.text}
      </Typography>
    </>
  );
};
