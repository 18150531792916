import { SetStateAction } from 'react';
import { v4 } from 'uuid';
import moment from 'moment-timezone';
import { atom } from 'jotai';
import { Aspect, BodyKey, Chart, ChartObjectKey, ChartObjectPos, Ephemeris } from '../../lib/types';
import { ChartInput, calculateAspects } from '@danielpedroso/astrum-calc';
import { locationsAtom } from '../../core/location/state';

const worker = new Worker(new URL('../../lib/webworker', import.meta.url));

interface ResponseCallback {
  id: string;
  fn: string;
  resolve: (arg0: any) => void;
}

const awaiting: ResponseCallback[] = [];

worker.onmessage = ({ data: { id, fn, result } }) => {
  awaiting.find(r => r.fn === fn && r.id === id)?.resolve(result);
};

const callWorker = <T>(callFn: string, args: {}) => new Promise<T>((resolve, reject) => {
  const id = v4();

  awaiting.push({
    id,
    fn: callFn,
    resolve,
  })
  worker.postMessage({
    id,
    fn: callFn,
    payload: args,
  });
});


const buildChart = (input: ChartInput) =>
  callWorker<Chart>('buildChart', { input });
const calculateEphemerisTable = (from: ChartInput, toDate: string) =>
  callWorker<Ephemeris[]>('calculateEphemerisTable', { from, toDate });

interface ChartState {
  chartData: {
    isoDate: string;
    locationId: string;
  },
  locationId: string;
  year: number;
}

const initialState: ChartState = {
  chartData: {
    isoDate: new Date().toISOString(),
    locationId: 'BNE',
  },
  locationId: 'BNE',
  year: new Date().getUTCFullYear(),
};
const initialStr = JSON.stringify(initialState);
const STORAGE_KEY = 'ASTRUM_REVOLUTION';

const strStateAtom = atom(localStorage.getItem(STORAGE_KEY) ?? initialStr);

export const chartStateAtom = atom(
  (get) => JSON.parse(get(strStateAtom)) as ChartState,
  (get, set, newState: SetStateAction<ChartState>) => {
    let newV: ChartState;
    if (typeof newState === 'function') {
      newV = newState(get(chartStateAtom));
    } else {
      newV = newState;
    }

    const newVal = JSON.stringify(newV ?? initialState);
    set(strStateAtom, newVal);
    localStorage.setItem(STORAGE_KEY, newVal);
  }
);

export const chartLocationAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const locations = get(locationsAtom);
  const locationId = chartState.chartData.locationId ?? 'BNE';
  return locations.find((l) => l.id === locationId) ?? locations[0];
});

export const transitLocationAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const locations = get(locationsAtom);
  const locationId = chartState.locationId ?? 'BNE';
  return locations.find((l) => l.id === locationId) ?? locations[0];
});

export const chartAtom = atom<Promise<Chart>>(
  async (get) => {
    const state = get(chartStateAtom);
    const chartLocation = get(chartLocationAtom);

    const chart = await buildChart({
      date: state.chartData.isoDate,
      lat: chartLocation.lat,
      lon: chartLocation.lon,
    });
    return chart;
  },
);

export const ephemerisTableAtom = atom<Promise<Ephemeris[]>>(
  async (get) => {
    const state = get(chartStateAtom);
    const transitLocation = get(transitLocationAtom);

    const ephemerisStartDate = moment
      .tz(state.chartData.isoDate, transitLocation.tz)
      .year(state.year)
      .hours(0)
      .minutes(0)
      .seconds(0);

    const ephemerisEndDate = moment
      .tz(ephemerisStartDate, transitLocation.tz)
      .add(1, 'year')
      .subtract(1, 'day');

    const table = await calculateEphemerisTable(
      {
        date: ephemerisStartDate.toISOString(),
        lat: transitLocation.lat,
        lon: transitLocation.lon,
      },
      ephemerisEndDate.toISOString()
    );
    return table;
  }
);

export interface AspectData {
  key: ChartObjectKey;
  colour?: string;
  data: {
    date: moment.Moment;
    position: ChartObjectPos;
    aspect?: Aspect;
  }[]
}

const keys = [
  'sun',
  'mercury',
  'venus',
  'mars',
  'jupiter',
  'saturn',
  'uranus',
  'neptune',
  'pluto',
];

const colours = [
  '#f4e22b',
  '#ea8834',
  '#009b52',
  '#d72f33',
  '#2a347f',
  '#000000',
  '#9ca19f',
  '#dadada',
  '#8f908a',
];

export const transitTargetAtom = atom<BodyKey>('sun');

export const aspectsAtom = atom<Promise<AspectData[]>>(
  async (get) => {
    const chart = await get(chartAtom);
    const ephemeris = await get(ephemerisTableAtom);
    const transitTaget = get(transitTargetAtom);

    const aspects = keys.map((p, index) => ({
      key: p,
      colour: colours[index],
      data: ephemeris.map((eph) => {
        const ephemeris = eph.table.find(e => e.key === p) as ChartObjectPos;
        const aspect = calculateAspects(
          { ...chart.planets[transitTaget], type: 'planet' },
          ephemeris,
          new Set<string>(),
          true
        )[0];

        return {
          date: moment(eph.date),
          position: ephemeris,
          aspect,
        };
      }),
    } as AspectData));
    return aspects;
  },
);
