export const Ayin: React.FC<any> = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_8)">
        <path
          d="M10.9609 36.8672C15.0078 36.7891 18.1406 36.3906 20.3594 35.6719L12.0625 12.9141H18.9297L25.8438 32.8359C27.0312 31.8828 27.9766 30.5859 28.6797 28.9453C29.3984 27.2891 29.9219 25.1562 30.25 22.5469C30.5781 19.9219 30.7422 16.7109 30.7422 12.9141H37.2578C37.2578 20.3984 36.4297 26.2266 34.7734 30.3984C33.6641 33.2109 32.1094 35.5234 30.1094 37.3359C28.1094 39.1328 25.5469 40.4922 22.4219 41.4141C19.2969 42.3359 15.4766 42.8984 10.9609 43.1016V36.8672Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_120_8">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
