import React from "react";
import { AspectType } from "@danielpedroso/astrum-calc/dist/types";
import { ConjunctionSymbol } from "./conjunction";
import { OppositionSymbol } from "./opposition";
import { QuincunxSymbol } from "./quincunx";
import { SemisextileSymbol } from "./semisextile";
import { SextileSymbol } from "./sextile";
import { SquareSymbol } from "./square";
import { TrineSymbol } from "./trine";

export const aspectSymbols: Record<AspectType, React.FC<any>> = {
  'conjunction': ConjunctionSymbol,
  'semisextile': SemisextileSymbol,
  'sextile': SextileSymbol,
  'square': SquareSymbol,
  'trine': TrineSymbol,
  'quincunx': QuincunxSymbol,
  'opposition': OppositionSymbol,
};