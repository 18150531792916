import React from "react";
import { ACSymbol } from "./ac";
import { AscNodeSymbol } from "./asc-node";
import { DescNodeSymbol } from "./desc-node";
import { JupiterSymbol } from "./jupiter";
import { MarsSymbol } from "./mars";
import { MCSymbol } from "./mc";
import { MercurySymbol } from "./mercury";
import { MoonSymbol } from "./moon";
import { NeptuneSymbol } from "./neptune";
import { PlutoSymbol } from "./pluto";
import { SaturnSymbol } from "./saturn";
import { SunSymbol } from "./sun";
import { UranusSymbol } from "./uranus";
import { VenusSymbol } from "./venus";

export const objectSymbols: Record<string, React.ComponentType<any>> = {
  'sun': SunSymbol,
  'moon': MoonSymbol,
  'mercury': MercurySymbol,
  'venus': VenusSymbol,
  'mars': MarsSymbol,
  'jupiter': JupiterSymbol,
  'saturn': SaturnSymbol,
  'uranus': UranusSymbol,
  'neptune': NeptuneSymbol,
  'pluto': PlutoSymbol,
  'meanAscendingNode': AscNodeSymbol,
  'meanDescendingNode': DescNodeSymbol,
  '1': ACSymbol,
  '10': MCSymbol,
};