"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moon = void 0;
exports.moon = {
    key: 'moon',
    type: 'luna',
    ptables: {
        lat: {
            maxargs: 14,
            max_harmonic: [0, 26, 29, 8, 3, 5, 0, 0, 0, 6, 5, 3, 5, 1, 0, 0, 0, 0],
            max_power_of_t: 3,
            distance: 2.5735686895300000e-03,
            timescale: 3.6525000000000000e+06,
            trunclvl: 1.0000000000000000e-04,
            lon_tbl: [
                -3, -4,
                4, -1856, 0, 8043,
                -9, -1082,
                -1, -310,
                -1, -522,
                -330, -1449, -853, 4656,
                -66, 7,
                -1, 9996928,
                -66, 6,
                23, 183,
                0, 173,
                0, -56,
                0, 50,
                0, -785,
                1, 51,
                0, -60,
                1, 11843, 0, -50754,
                0, 1834, 1, -7910,
                0, -48060,
                1, 56,
                0, 13141, -1, -56318,
                0, 2541,
                -1, -649,
                -133, 778,
                -46, 8,
                1, 1665737,
                -47, 7,
                0, 65,
                0, 45,
                0, -138,
                0, -1005,
                0, -2911,
                0, -47,
                0, 96,
                0, -394,
                2, 76,
                2, -17302, 0, 74337,
                0, -101,
                0, 58,
                0, -171,
                0, -77,
                0, -1283, 0, 2686,
                0, -55,
                0, 99,
                0, 55,
                0, 397,
                0, 540,
                0, 626,
                -1, -5188, 0, 10857,
                0, -216,
                -2, -123,
                0, 6337,
                2, 224,
                -152, -23472, -29, -74336, 0, 295775,
                -20, 149,
                -2, 84,
                9, 304,
                0, -3051,
                -70, -6,
                -57, 34,
                0, -638,
                0, -201,
                -73, 9,
                0, -100,
                -101, -8,
                0, -57,
                0, -207,
                -3, 80,
                -45, 45,
                -5, 102,
                -59, -23,
                52, 201,
                -48, 233, -220, 71,
                4, 2810, 0, 6236541,
                -61, 218, -216, 67,
                51, 201,
                -59, -23,
                -144, -837, -457, 3029,
                -45, 42,
                -15, 73,
                -6, -169,
                0, 135,
                -64, -7,
                0, -16245,
                0, -81,
                -74, -10,
                0, 702, 0, -3013,
                0, -5889,
                1, 141,
                58, 9598, 12, 30443, 1, -120946,
                -1, -84,
                -2, 11246, -1, -48391,
                0, 1393,
                0, 200,
                -136, -17,
                0, 558,
                -64, -8,
                0, -71,
                0, 317577,
                -28, 183,
                1, 219,
                0, 421,
                0, -133,
                501, -139,
                3, 354,
                -101, -13,
                74, 7,
                144, -84,
                59, -2,
                1, 64,
                -2931, 12559, -4641, 2638, -303, -2058,
                -13, -100, -123, -79,
                -19214, 6084, 1494, 26993, 15213, -82219,
                42, 52, 48, -101,
                -53, -4,
                4, 47,
                58, -131,
                46, 14,
                -21, -6,
                -1311, -8791, 10198, -4185, 2815, 5640,
                167, 422, -229, 83,
                3140, 39, 1221, 120, 96, -30,
                -1, 184612405,
                187, 416, -226, 81,
                -1985, -10083, 9983, -4464, 2807, 5643,
                -21, -9,
                113, -367,
                120, 580, -667, 27,
                8, 66,
                -56, -6,
                337, 95,
                -87, 3303,
                -1, 65,
                68, -374,
                0, -574,
                15, -94,
                0, -53,
                0, -1303,
                0, -236,
                283, 36,
                -1, -54,
                269, -35,
                0, -83,
                0, -52,
                0, 730, 0, -3129,
                0, 813,
                0, -4299,
                1, 59,
                -6, 5130, 1, 16239, -1, -64603,
                0, -80,
                91, 12,
                0, -561,
                133, -17,
                0, 250,
                -12, 71,
                0, 155664,
                82, -11,
                0, 106,
                0, -604,
                0, 21862,
                55, -7,
                0, -1514, 0, 6501,
                0, 906,
                0, -68,
                0, 241,
                0, 366,
                0, 70,
                0, -1382, 0, 5957,
                0, 113,
                0, -51,
                0, -55,
                0, 731,
                0, -264,
                0, 65788,
                1, -1504, 0, 3147,
                0, 217,
                0, -4105, 0, 17658,
                1, 69,
                0, -3518,
                0, -1767,
                -43, -7044, -10, -22304, 0, 88685,
                3, 91,
                0, -485,
                0, -57,
                -1, 333548,
                -24, 172,
                11, 544, 1, -1132,
                0, 353,
                0, -188,
                0, 53,
                0, 77,
                158, -887,
                35, 131,
                -54, 13,
                0, 1994821,
                -53, 14,
                36, 125,
                2, 56,
                0, -243,
                0, -364,
                -2, 1916, 0, -8227,
                0, 15700, -1, -67308,
                1, 66,
                0, -53686,
                1, 3058, 1, -13177,
                0, -72,
                0, -72,
                0, 61,
                0, 15812,
                0, 165,
                8, -96,
                318, 1341, 803, -4252,
                24, 193,
                1137, -226, 310, 622,
                -56, 30,
                -3, 10101666,
                -56, 30,
                1096, -225, 300, 600,
                -31, 409,
                -1, -507,
                0, -287,
                0, -1869, 0, 8026,
                1, 544, -1, -1133,
                0, 27984,
                0, -62,
                0, -249,
                0, 187,
                0, -1096,
                1, 53,
                2, 12388, 0, -53107,
                0, -322,
                0, -94,
                0, 15157,
                0, -582,
                0, 3291,
                0, 565,
                0, 106,
                0, 112,
                0, 306,
                0, 809,
                0, 130,
                0, -961, 0, 4149,
                0, 174,
                0, -105,
                0, 2196,
                0, 59,
                0, 36737,
                -1, -1832, 0, 3835,
                0, -139,
                0, 24138,
                0, 1325,
                1, 64,
                0, -361,
                0, -1162,
                -44, -6320, -10, -20003, 0, 79588,
                2, 80,
                0, -2059,
                0, -304,
                0, 21460,
                0, -166,
                0, -87,
                89, -493,
                32, 114,
                34, 510, 1, 1172616,
                31, 113,
                -1, 57,
                0, 214,
                0, -656,
                0, -646,
                0, 1850, 0, -7931,
                0, -6674,
                0, 2944, 0, -12641,
                0, 916,
                45, -255,
                16, 60,
                -1, 619116,
                16, 57,
                0, -58,
                0, 1045,
                0, -156,
                -15, 88,
                0, -62964,
                0, -126,
                0, 1490, 0, -6387,
                0, 119,
                0, 1338,
                0, -56,
                0, 204,
                0, 153,
                0, 940,
                0, 251,
                0, 312,
                0, 584,
                0, -786, 0, 3388,
                0, -52,
                0, 4733,
                0, 618,
                0, 29982,
                0, 101,
                0, -174,
                0, -2637, 0, 11345,
                0, -284,
                0, -524,
                0, -121,
                0, 1464,
                11, -60,
                -1, 151205,
                0, 139,
                0, -2448,
                0, -51,
                0, -768,
                0, -638,
                0, 552, 0, -2370,
                0, 70,
                0, 64,
                0, 57,
                0, 39840,
                0, 104,
                0, -10194,
                0, -635,
                0, 69,
                0, 113,
                0, 67,
                0, 96,
                0, 367,
                0, 134,
                0, 596,
                0, 63,
                0, 1622,
                0, 483,
                0, 72,
                0, 11917,
                0, -63,
                0, 1273,
                0, -66,
                0, -262,
                0, -97,
                0, 103,
                0, 15196,
                0, -1445,
                0, -66,
                0, -55,
                0, -323,
                0, 2632,
                0, -1179,
                0, 59,
                0, -56,
                0, 78,
                0, 65,
                0, 422,
                0, 309,
                0, 2125,
                0, -66,
                0, 124,
                0, -57,
                0, 1379,
                0, -304,
                0, 177,
                0, -118,
                0, 146,
                0, 283,
                0, 119
            ],
            lat_tbl: [],
            rad_tbl: [],
            arg_tbl: [
                0, 1,
                3, 1, 10, 1, 12, -1, 11, 1,
                4, 2, 10, 2, 12, -1, 13, -1, 11, 0,
                5, 2, 10, -1, 13, -1, 11, 3, 2, -3, 3, 0,
                5, 2, 10, -1, 13, -1, 11, 2, 3, -2, 5, 0,
                2, -1, 13, 1, 14, 1,
                5, -1, 13, 1, 11, 4, 3, -8, 4, 3, 5, 0,
                2, 1, 13, -1, 11, 0,
                5, 1, 13, -1, 11, 4, 3, -8, 4, 3, 5, 0,
                5, 2, 10, -1, 13, -1, 11, 2, 3, -3, 5, 0,
                4, 1, 10, 1, 12, -2, 13, 1, 11, 0,
                4, 1, 13, -1, 11, 1, 2, -1, 3, 0,
                5, 2, 10, -1, 13, -1, 11, 2, 2, -2, 3, 0,
                3, 1, 10, -2, 13, 1, 11, 0,
                4, 1, 13, -1, 11, 1, 3, -1, 5, 0,
                4, -1, 13, 1, 11, 1, 2, -1, 3, 0,
                3, 1, 12, 1, 13, -1, 11, 1,
                4, 2, 10, 1, 12, -1, 13, -1, 11, 1,
                2, 1, 10, -1, 11, 0,
                4, -1, 13, 1, 11, 1, 3, -1, 5, 0,
                3, 1, 12, -1, 13, 1, 11, 1,
                3, 2, 10, -3, 13, 1, 11, 0,
                3, 2, 12, 1, 13, -1, 11, 0,
                3, -2, 10, 1, 13, 1, 14, 0,
                6, -2, 10, 1, 13, 1, 11, 4, 3, -8, 4, 3, 5, 0,
                3, 2, 10, -1, 13, -1, 11, 0,
                6, 2, 10, -1, 13, -1, 11, 4, 3, -8, 4, 3, 5, 0,
                4, -1, 13, 1, 11, 2, 3, -2, 5, 0,
                4, -1, 13, 1, 11, 3, 2, -3, 3, 0,
                3, 1, 10, -1, 12, -1, 11, 0,
                3, 2, 12, -1, 13, 1, 11, 0,
                3, 2, 10, 1, 13, -3, 11, 0,
                5, -2, 10, 1, 13, 1, 11, 1, 2, -1, 3, 0,
                4, 2, 10, -1, 12, -3, 13, 1, 11, 0,
                3, 3, 10, -2, 13, -1, 11, 0,
                5, -2, 10, 1, 13, 1, 11, 1, 3, -1, 5, 0,
                4, 2, 10, -1, 12, -1, 13, -1, 11, 1,
                2, 3, 10, -3, 11, 0,
                5, -2, 10, 1, 13, 1, 11, 2, 2, -2, 3, 0,
                4, 2, 10, -1, 12, 1, 13, -3, 11, 0,
                3, 4, 10, -3, 13, -1, 11, 0,
                4, 2, 10, -2, 12, -1, 13, -1, 11, 1,
                3, 4, 10, -1, 13, -3, 11, 0,
                4, 2, 10, -3, 12, -1, 13, -1, 11, 0,
                3, 4, 10, -1, 12, -3, 11, 0,
                3, 2, 10, -3, 12, -1, 11, 0,
                4, 4, 10, -1, 12, -2, 13, -1, 11, 0,
                2, 4, 10, -3, 11, 0,
                3, 2, 10, -2, 12, -1, 11, 1,
                4, 3, 10, -1, 12, -1, 13, -1, 11, 0,
                4, -2, 10, 1, 11, 2, 3, -2, 5, 0,
                3, 4, 10, -2, 13, -1, 11, 0,
                4, -2, 10, 1, 11, 2, 2, -2, 3, 0,
                3, 2, 10, -1, 12, -1, 11, 2,
                3, -2, 10, 1, 12, 1, 14, 0,
                4, -2, 10, 1, 11, 2, 3, -2, 4, 0,
                4, -2, 10, 1, 11, 1, 3, -1, 5, 0,
                3, 3, 10, -1, 13, -1, 11, 0,
                4, -2, 10, 1, 11, 3, 2, -4, 3, 0,
                4, -2, 10, 1, 11, 1, 3, -2, 5, 0,
                4, 2, 10, -1, 12, -2, 13, 1, 11, 0,
                4, -2, 10, 1, 11, 1, 2, -1, 3, 0,
                2, -1, 10, 1, 2, 0,
                3, 2, 10, 2, 13, -3, 11, 0,
                4, -2, 10, 1, 11, 2, 2, -3, 3, 0,
                3, 2, 12, -2, 13, 1, 11, 0,
                4, 1, 10, -1, 12, 1, 13, -1, 11, 0,
                3, -2, 10, 1, 11, 1, 5, 0,
                4, 2, 10, -1, 11, 1, 3, -2, 4, 0,
                3, 2, 10, -2, 11, 1, 14, 0,
                4, -2, 10, 1, 11, 8, 2, -13, 3, 0,
                5, -2, 10, -1, 13, 1, 11, 18, 2, -16, 3, 0,
                5, 2, 10, -1, 11, 4, 3, -8, 4, 3, 5, 1,
                2, 2, 10, -1, 11, 1,
                5, -2, 10, 1, 11, 4, 3, -8, 4, 3, 5, 1,
                5, 2, 10, -1, 13, -1, 11, 18, 2, -16, 3, 0,
                4, 2, 10, -1, 11, 8, 2, -13, 3, 0,
                2, -2, 10, 1, 14, 1,
                4, -2, 10, 1, 11, 1, 3, -2, 4, 0,
                3, 2, 10, -1, 11, 1, 5, 0,
                2, 2, 12, -1, 11, 0,
                4, 3, 10, 1, 12, -1, 13, -1, 11, 0,
                4, 2, 10, -1, 11, 2, 2, -3, 3, 0,
                3, 2, 10, -2, 13, 1, 11, 0,
                4, 2, 10, -1, 11, 1, 2, -1, 3, 0,
                3, 1, 10, 1, 2, -2, 3, 0,
                3, 1, 12, -2, 13, 1, 11, 1,
                3, 1, 10, 1, 13, -1, 11, 0,
                4, 2, 10, -1, 11, 1, 3, -1, 5, 0,
                3, 2, 10, 1, 12, -1, 11, 2,
                3, -2, 10, -1, 12, 1, 14, 0,
                2, 1, 12, -1, 11, 1,
                3, 1, 10, -1, 13, 1, 11, 0,
                4, 2, 10, -1, 11, 2, 2, -2, 3, 0,
                3, 1, 10, 2, 2, -3, 3, 0,
                4, 2, 10, 1, 12, -2, 13, 1, 11, 0,
                3, -1, 10, 1, 2, -2, 3, 0,
                3, -1, 11, 1, 2, -1, 3, 0,
                2, 2, 13, -1, 11, 0,
                2, -2, 13, 1, 14, 0,
                4, 2, 10, -1, 11, 2, 3, -2, 5, 0,
                4, 2, 10, -1, 11, 3, 2, -3, 3, 0,
                4, 2, 10, 2, 12, -2, 13, -1, 11, 0,
                3, 1, 10, 1, 3, -2, 5, 0,
                4, 1, 10, 1, 12, 1, 13, -1, 11, 0,
                3, 1, 10, 3, 2, -4, 3, 0,
                3, 1, 10, 1, 3, -1, 5, 0,
                3, 1, 10, 1, 3, -2, 6, 0,
                3, 1, 10, 2, 3, -2, 4, 0,
                4, 1, 10, 1, 12, -1, 13, -1, 11, 0,
                3, 2, 10, 2, 12, -1, 11, 2,
                4, 1, 10, 1, 3, 2, 5, -5, 6, 1,
                1, 1, 14, 2,
                3, 1, 10, 8, 2, -12, 3, 1,
                5, -2, 10, 1, 13, -1, 11, 20, 2, -21, 3, 0,
                5, 2, 10, -2, 13, 1, 11, 2, 3, -3, 5, 0,
                3, 1, 10, 1, 3, 1, 6, 0,
                4, -1, 13, -1, 11, 26, 2, -29, 3, 0,
                3, -1, 11, 8, 2, -13, 3, 0,
                4, -1, 13, -1, 11, 18, 2, -16, 3, 2,
                4, -1, 13, 1, 11, 10, 2, -3, 3, 1,
                1, 1, 11, 3,
                4, -1, 13, -1, 11, 10, 2, -3, 3, 1,
                4, -1, 13, 1, 11, 18, 2, -16, 3, 2,
                3, 1, 11, 8, 2, -13, 3, 0,
                2, 1, 10, 2, 4, 0,
                4, 2, 10, -1, 11, 5, 2, -6, 3, 1,
                5, 2, 10, -2, 13, -1, 11, 2, 3, -3, 5, 0,
                5, -2, 10, 1, 13, 1, 11, 20, 2, -21, 3, 0,
                3, 1, 10, 1, 3, 1, 5, 0,
                2, -2, 11, 1, 14, 0,
                5, 2, 10, -2, 13, 1, 11, 2, 3, -2, 5, 0,
                3, 1, 10, 5, 2, -7, 3, 0,
                4, 1, 10, 1, 12, -1, 13, 1, 11, 0,
                3, 1, 10, 2, 2, -2, 3, 0,
                4, 2, 10, 2, 12, -2, 13, 1, 11, 0,
                2, 2, 13, -3, 11, 0,
                4, 2, 10, -1, 11, 4, 2, -4, 3, 0,
                3, 1, 10, 4, 2, -5, 3, 0,
                3, 1, 10, -3, 13, 1, 11, 0,
                2, 1, 10, 1, 2, 0,
                3, 1, 11, 1, 2, -1, 3, 0,
                4, 2, 10, -1, 11, 3, 3, -3, 5, 0,
                3, 1, 12, 2, 13, -1, 11, 1,
                4, 2, 10, 1, 12, -2, 13, -1, 11, 0,
                3, 1, 10, -1, 13, -1, 11, 0,
                3, 1, 11, 1, 3, -1, 5, 0,
                2, 1, 12, 1, 11, 2,
                4, 2, 10, -1, 11, 5, 2, -5, 3, 0,
                3, 1, 10, 5, 2, -6, 3, 0,
                3, 2, 10, 1, 12, -3, 11, 0,
                3, 1, 10, 2, 2, -1, 3, 0,
                3, 2, 10, -4, 13, 1, 11, 0,
                3, -2, 10, 2, 13, 1, 14, 0,
                3, 2, 10, -2, 13, -1, 11, 0,
                3, 1, 10, 3, 2, -2, 3, 0,
                4, 1, 10, -1, 12, -1, 13, -1, 11, 0,
                2, 2, 12, 1, 11, 0,
                2, 2, 10, -3, 11, 0,
                3, 1, 10, 4, 2, -3, 3, 0,
                4, 2, 10, -1, 12, -2, 13, -1, 11, 1,
                3, 2, 10, -1, 12, -3, 11, 0,
                3, 4, 10, -4, 13, -1, 11, 0,
                4, 2, 10, -2, 12, -2, 13, -1, 11, 0,
                4, 4, 10, -2, 12, -1, 13, -1, 11, 0,
                3, 6, 10, -3, 13, -1, 11, 0,
                4, 4, 10, -1, 12, -1, 13, -1, 11, 1,
                4, 2, 10, -3, 12, -1, 13, 1, 11, 0,
                3, 5, 10, -2, 13, -1, 11, 0,
                3, 4, 10, 1, 13, -3, 11, 0,
                4, 2, 10, -2, 12, 1, 13, -1, 11, 0,
                3, 3, 10, -1, 12, -1, 11, 0,
                3, 4, 10, -1, 13, -1, 11, 0,
                4, 2, 10, -2, 12, -1, 13, 1, 11, 1,
                3, 4, 10, -3, 13, 1, 11, 0,
                4, 2, 10, -1, 12, 1, 13, -1, 11, 1,
                5, -2, 10, 1, 13, -1, 11, 2, 2, -2, 3, 0,
                2, 3, 10, -1, 11, 0,
                4, 4, 10, 1, 12, -1, 13, -1, 11, 0,
                4, 2, 10, -1, 12, -1, 13, 1, 11, 2,
                5, -2, 10, 1, 13, -1, 11, 1, 3, -1, 5, 0,
                3, 3, 10, -2, 13, 1, 11, 0,
                5, -2, 10, 1, 13, -1, 11, 1, 2, -1, 3, 0,
                3, 2, 10, 1, 13, -1, 11, 0,
                3, -2, 10, -1, 13, 1, 14, 0,
                3, 2, 12, -1, 13, -1, 11, 1,
                3, 3, 10, 1, 12, -1, 11, 0,
                3, 1, 10, -1, 12, 1, 11, 0,
                4, -1, 13, -1, 11, 3, 2, -3, 3, 0,
                4, -1, 13, -1, 11, 2, 3, -2, 5, 0,
                3, 2, 10, -1, 13, 1, 14, 0,
                4, -2, 10, -1, 11, 18, 2, -16, 3, 0,
                6, 2, 10, -1, 13, 1, 11, 4, 3, -8, 4, 3, 5, 0,
                3, 2, 10, -1, 13, 1, 11, 0,
                6, -2, 10, 1, 13, -1, 11, 4, 3, -8, 4, 3, 5, 0,
                5, 2, 10, -2, 13, 1, 11, 18, 2, -16, 3, 0,
                4, -2, 10, 1, 13, -2, 11, 1, 14, 0,
                3, 1, 12, -3, 13, 1, 11, 0,
                3, 1, 10, 2, 13, -1, 11, 0,
                4, 2, 10, 1, 12, 1, 13, -1, 11, 1,
                3, 1, 12, -1, 13, -1, 11, 1,
                4, -1, 13, -1, 11, 1, 3, -1, 5, 0,
                2, 1, 10, 1, 11, 0,
                4, 2, 10, 1, 12, -1, 13, 1, 11, 1,
                3, 1, 12, 1, 13, -3, 11, 0,
                4, -1, 13, -1, 11, 1, 2, -1, 3, 0,
                5, 2, 10, -1, 13, 1, 11, 2, 2, -2, 3, 0,
                2, 3, 13, -1, 11, 0,
                4, 1, 10, 1, 12, -2, 13, -1, 11, 0,
                4, 2, 10, 2, 12, 1, 13, -1, 11, 0,
                2, 1, 13, 1, 14, 1,
                5, 2, 10, -1, 13, 1, 11, 2, 3, -3, 5, 0,
                4, -2, 13, -1, 11, 18, 2, -16, 3, 1,
                5, 1, 13, 1, 11, 4, 3, -8, 4, 3, 5, 0,
                2, 1, 13, 1, 11, 0,
                5, -1, 13, -1, 11, 4, 3, -8, 4, 3, 5, 0,
                3, 1, 11, 18, 2, -16, 3, 1,
                3, -1, 13, -2, 11, 1, 14, 0,
                5, 2, 10, -1, 13, 1, 11, 2, 3, -2, 5, 0,
                5, 2, 10, -1, 13, 1, 11, 3, 2, -3, 3, 0,
                3, 1, 10, 1, 12, 1, 11, 1,
                4, 2, 10, 2, 12, -1, 13, 1, 11, 1,
                2, 1, 13, -3, 11, 0,
                4, 1, 13, 1, 11, 1, 2, -1, 3, 0,
                3, 1, 12, 3, 13, -1, 11, 0,
                4, 2, 10, 1, 12, -3, 13, -1, 11, 0,
                3, 1, 10, -2, 13, -1, 11, 0,
                4, 1, 13, 1, 11, 1, 3, -1, 5, 0,
                3, 1, 12, 1, 13, 1, 11, 1,
                2, 1, 10, -3, 11, 0,
                3, 1, 12, -1, 13, 3, 11, 0,
                3, 2, 10, -3, 13, -1, 11, 0,
                3, 2, 12, 1, 13, 1, 11, 0,
                3, 2, 10, -1, 13, -3, 11, 0,
                4, 2, 10, -1, 12, -3, 13, -1, 11, 0,
                4, 2, 10, -1, 12, -1, 13, -3, 11, 0,
                4, 6, 10, -1, 12, -2, 13, -1, 11, 0,
                3, 4, 10, -2, 12, -1, 11, 0,
                3, 6, 10, -2, 13, -1, 11, 0,
                4, 4, 10, -2, 12, -2, 13, 1, 11, 0,
                3, 4, 10, -1, 12, -1, 11, 1,
                3, 2, 10, -3, 12, 1, 11, 0,
                3, 5, 10, -1, 13, -1, 11, 0,
                4, 4, 10, -1, 12, -2, 13, 1, 11, 0,
                4, 2, 10, -2, 12, 2, 13, -1, 11, 0,
                2, 4, 10, -1, 11, 0,
                3, 2, 10, -2, 12, 1, 11, 1,
                4, 3, 10, -1, 12, -1, 13, 1, 11, 0,
                3, 4, 10, -2, 13, 1, 11, 0,
                4, 2, 10, -1, 12, 2, 13, -1, 11, 0,
                4, -2, 10, -1, 11, 2, 2, -2, 3, 0,
                3, 3, 10, 1, 13, -1, 11, 0,
                3, 4, 10, 1, 12, -1, 11, 0,
                3, 2, 10, -1, 12, 1, 11, 2,
                4, -2, 10, -1, 11, 1, 3, -1, 5, 0,
                3, 3, 10, -1, 13, 1, 11, 0,
                4, 4, 10, 1, 12, -2, 13, 1, 11, 0,
                3, 2, 10, 2, 13, -1, 11, 0,
                3, 2, 12, -2, 13, -1, 11, 0,
                4, 1, 10, -1, 12, 1, 13, 1, 11, 0,
                2, 2, 10, 1, 14, 0,
                5, -2, 10, -1, 13, -1, 11, 18, 2, -16, 3, 0,
                2, 2, 10, 1, 11, 1,
                5, 2, 10, -1, 13, 1, 11, 18, 2, -16, 3, 0,
                3, -2, 10, -2, 11, 1, 14, 0,
                4, 3, 10, 1, 12, -1, 13, 1, 11, 0,
                3, 2, 10, -2, 13, 3, 11, 0,
                4, 2, 10, 1, 12, 2, 13, -1, 11, 0,
                3, 1, 12, -2, 13, -1, 11, 1,
                3, 1, 10, 1, 13, 1, 11, 0,
                3, 2, 10, 1, 12, 1, 11, 1,
                2, 4, 13, -1, 11, 0,
                2, 2, 13, 1, 14, 0,
                4, -3, 13, -1, 11, 18, 2, -16, 3, 0,
                2, 2, 13, 1, 11, 0,
                4, 1, 13, 1, 11, 18, 2, -16, 3, 0,
                4, 2, 10, 1, 11, 2, 3, -2, 5, 0,
                4, 1, 10, 1, 12, 1, 13, 1, 11, 0,
                3, 2, 10, 2, 12, 1, 11, 0,
                2, 2, 11, 1, 14, 0,
                1, 3, 11, 0,
                3, 1, 10, -3, 13, -1, 11, 0,
                3, 1, 12, 2, 13, 1, 11, 1,
                2, 1, 12, 3, 11, 0,
                3, 2, 10, -4, 13, -1, 11, 0,
                3, 2, 12, 2, 13, 1, 11, 0,
                3, 2, 10, -2, 13, -3, 11, 0,
                4, 6, 10, -1, 12, -1, 13, -1, 11, 0,
                3, 6, 10, -1, 13, -1, 11, 0,
                4, 4, 10, -2, 12, -1, 13, 1, 11, 0,
                3, 6, 10, -3, 13, 1, 11, 0,
                4, 4, 10, -1, 12, 1, 13, -1, 11, 0,
                4, 4, 10, -1, 12, -1, 13, 1, 11, 1,
                3, 5, 10, -2, 13, 1, 11, 0,
                3, 4, 10, 1, 13, -1, 11, 0,
                4, 2, 10, -2, 12, 1, 13, 1, 11, 0,
                3, 4, 10, -1, 13, 1, 11, 0,
                4, 2, 10, -1, 12, 3, 13, -1, 11, 0,
                4, 4, 10, 1, 12, 1, 13, -1, 11, 0,
                4, 2, 10, -1, 12, 1, 13, 1, 11, 1,
                2, 3, 10, 1, 11, 0,
                4, 4, 10, 1, 12, -1, 13, 1, 11, 0,
                4, 2, 10, -1, 12, -1, 13, 3, 11, 0,
                3, 2, 10, 3, 13, -1, 11, 0,
                3, 2, 10, 1, 13, 1, 14, 0,
                3, 2, 10, 1, 13, 1, 11, 0,
                3, 3, 10, 1, 12, 1, 11, 0,
                3, 2, 10, -1, 13, 3, 11, 0,
                4, 2, 10, 1, 12, 3, 13, -1, 11, 0,
                3, 1, 12, -3, 13, -1, 11, 0,
                3, 1, 10, 2, 13, 1, 11, 0,
                4, 2, 10, 1, 12, 1, 13, 1, 11, 1,
                3, 1, 12, -1, 13, -3, 11, 0,
                2, 1, 10, 3, 11, 0,
                2, 5, 13, -1, 11, 0,
                2, 3, 13, 1, 11, 0,
                4, 1, 10, 1, 12, 2, 13, 1, 11, 0,
                2, 1, 13, 3, 11, 0,
                3, 1, 12, 3, 13, 1, 11, 0,
                3, 1, 12, 1, 13, 3, 11, 0,
                3, 2, 10, -5, 13, -1, 11, 0,
                3, 6, 10, -1, 12, -1, 11, 0,
                4, 6, 10, -1, 12, -2, 13, 1, 11, 0,
                2, 6, 10, -1, 11, 0,
                3, 4, 10, -2, 12, 1, 11, 0,
                3, 6, 10, -2, 13, 1, 11, 0,
                4, 4, 10, -1, 12, 2, 13, -1, 11, 0,
                3, 4, 10, -1, 12, 1, 11, 0,
                3, 4, 10, 2, 13, -1, 11, 0,
                4, 2, 10, -2, 12, 2, 13, 1, 11, 0,
                2, 4, 10, 1, 11, 0,
                3, 4, 10, -2, 13, 3, 11, 0,
                4, 2, 10, -1, 12, 2, 13, 1, 11, 0,
                3, 3, 10, 1, 13, 1, 11, 0,
                3, 4, 10, 1, 12, 1, 11, 0,
                3, 2, 10, -1, 12, 3, 11, 0,
                3, 2, 10, 4, 13, -1, 11, 0,
                3, 2, 10, 2, 13, 1, 11, 0,
                2, 2, 10, 3, 11, 0,
                3, 1, 12, -4, 13, -1, 11, 0,
                3, 1, 10, 3, 13, 1, 11, 0,
                4, 2, 10, 1, 12, 2, 13, 1, 11, 0,
                2, 4, 13, 1, 11, 0,
                2, 2, 13, 3, 11, 0,
                1, 5, 11, 0,
                3, 1, 12, 4, 13, 1, 11, 0,
                4, 6, 10, -1, 12, -1, 13, 1, 11, 0,
                3, 6, 10, 1, 13, -1, 11, 0,
                3, 6, 10, -1, 13, 1, 11, 0,
                4, 4, 10, -1, 12, 1, 13, 1, 11, 0,
                3, 4, 10, 1, 13, 1, 11, 0,
                3, 4, 10, -1, 13, 3, 11, 0,
                4, 2, 10, -1, 12, 3, 13, 1, 11, 0,
                4, 4, 10, 1, 12, 1, 13, 1, 11, 0,
                3, 2, 10, 3, 13, 1, 11, 0,
                3, 2, 10, 1, 13, 3, 11, 0,
                2, 5, 13, 1, 11, 0,
                2, 3, 13, 3, 11, 0,
                2, 6, 10, 1, 11, 0,
                3, 4, 10, 2, 13, 1, 11, 0,
                3, 2, 10, 4, 13, 1, 11, 0,
                -1
            ]
        },
        lr: {
            maxargs: 14,
            max_harmonic: [3, 26, 29, 23, 5, 10, 0, 0, 0, 8, 4, 4, 6, 2, 0, 0, 0, 0],
            max_power_of_t: 3,
            distance: 2.5735686895300000e-03,
            timescale: 3.6525000000000000e+06,
            trunclvl: 1.0000000000000000e-04,
            lon_tbl: [175667, 66453, 5249, -42,
                20057, 403, -2360, 6148,
                -7644, 24646, -1273, 9127,
                -1395, 1958,
                232, -289,
                -97, 553, 69, 130,
                -80, 6,
                129, -868, 26, -89,
                1042, 1172, 194, -112,
                -47433, -241666, 224626, -103752,
                63419, 127606,
                2294, -691, -1827, -1254,
                -1, -119,
                1057, 324,
                505, -195, 254, -641,
                -36, 1008, -1082, -3,
                -87, 122,
                161, 11,
                2, -106,
                29, -123,
                -32, 41,
                -524, -35,
                133, -595,
                225, 837, -108, -191,
                -2294, 841, -340, -394,
                -351, -1039, 238, -108,
                -66, 21,
                1405, 869, 520, 2776,
                -174, 71,
                425, 652, -1260, -80,
                249, 77,
                -192, -17,
                -97, 134,
                -7, -54,
                -802, -7436, -2824, 70869,
                -35, 2481,
                1865, 1749, -2166, 2415,
                33, -183,
                -835, 283,
                27, -45,
                56, 235,
                2, 718,
                -1206, 275, -87, -158,
                -7, -2534, 0, 10774,
                1, -324,
                -208, 821,
                281, 1340, -797, 440,
                224, 72,
                -65, -5,
                -7, -44,
                -48, 66,
                -151, -40,
                -41, -45,
                76, -108,
                -18, 1202, 0, -2501,
                1438, -595, 900, 3040,
                -3435, -5,
                -100, -26,
                0, -13714,
                -183, 68,
                453, -83,
                -228, 325,
                97, 13,
                2, 105,
                -61, 257,
                0, 57,
                88, -11,
                -1, -8220,
                0, 275,
                -43, -10,
                -199, 105,
                1, -5849, 2, 24887,
                -128, 48,
                712, 970, -1407, 845,
                -266, 378,
                311, 1526, -1751, 27,
                0, -185858,
                133, 6383,
                -108, 25,
                -7, 1944,
                5, 390,
                -11, 31,
                277, -384, 158, 72,
                -81, -41, -13, -111,
                -2332, -65804, -698, 505812,
                34, 1676716, -72, -6664384,
                154, -57, 52, 95,
                -4, -5,
                -7, 37,
                -63, -32,
                4, 3349, 1, -14370,
                16, -83,
                0, -401,
                13, 3013,
                48, -20,
                0, 250,
                51, -79,
                -7, -146,
                148, 9,
                0, -64,
                -17, -59,
                -67, -492,
                -2, 2116601,
                -12, -1848,
                8, -436,
                -6, 324, 0, -1363,
                -163, 9,
                0, -74,
                63, 8167, -29, 37587,
                -22, -74501,
                -71, 497,
                -1, 551747,
                -87, -22,
                0, -51,
                -1, -463,
                0, -444,
                3, 89,
                15, -84,
                -36, -6829, -5, -21663,
                0, 86058,
                0, -298,
                -2, 751, -2, -1015,
                0, 69,
                1, -4989, 0, 21458,
                0, -330,
                0, -7,
                0, -226,
                0, -1407, 0, 2942,
                0, 66,
                0, 667,
                0, -155,
                0, 105,
                0, -107,
                0, -74,
                0, -52,
                0, 91,
                0, 59,
                0, 235,
                -1, -1819, 0, 2470,
                71, 13,
                0, 1026,
                14, -54,
                0, -174,
                -121, -19,
                0, -200,
                0, 3008,
                -16, -8043, -10, -37136,
                -3, 73724,
                -157, -5,
                0, -854,
                8, 147,
                -13, -893,
                0, 11869,
                -23, -172,
                89, 14,
                -1, 872, 0, -3744,
                11, 1606,
                0, -559,
                -1, -2530,
                0, 454,
                0, -193,
                -60, -10,
                -82, -13,
                -75, 6,
                36, 81,
                354, -162836, 148, -516569,
                4, 2054441,
                4, -94,
                39, 38,
                61, -30,
                2, 121,
                -11, 590,
                62, 2108,
                0, -12242,
                -476, -42,
                -84, 113,
                -394, 236,
                0, 276,
                -49, 31,
                0, 86,
                1, -1313,
                1, 69,
                -60, 88,
                -46, 18,
                0, -63818,
                14, -93,
                113, 547, -618, 17,
                -7, 12290, -1, -25679,
                0, 92,
                -115, 50,
                -48, 233,
                4, 1311, 1, -5567,
                3, 1251,
                29, 548,
                -244, 257,
                -2, 1825,
                42, 637,
                -46, 68,
                -62, 8,
                3, 110,
                445, -100, -316, -202,
                2925, -621, 763, 1495,
                -169, -184, 20, -76,
                -475, -138, 8, -141,
                -197, 1351, -1284, 422,
                -129, 1879, -102, 8382,
                -9, 45864958,
                -215, 1350, -1285, 422,
                -481, -136, 8, -140,
                40, -53,
                2622, -543, 700, 1406,
                402, -95, -318, -194,
                122, 13,
                -30, 147,
                -121, -902,
                61, -23,
                -63, 7,
                69, 479,
                -224, 228,
                -7, 500,
                0, -429,
                -42, 193,
                -92, 37,
                67, 5,
                -350, -31,
                0, 67,
                -55, -5,
                0, 47,
                -36, 53,
                5, 561,
                0, -126,
                0, 871,
                -52, 4,
                -201, 116922, -22, 371352,
                -12, -1473285,
                0, 87,
                -164, 84,
                -3, 422,
                30, 1434,
                -26, 38,
                2, -1249943,
                -404, -34,
                -57, 79,
                5, 509,
                1, 131,
                -344, 168,
                112, 22540, 30, 71218,
                18, -283983,
                0, -851,
                0, -1538,
                0, 1360,
                -12, 51,
                -48, 68,
                88, -20,
                1, 63,
                0, -568,
                303, 25,
                0, -122,
                87, 586, -606, -14,
                0, -100,
                -85, 8,
                -165, 54,
                -45, 140,
                0, -54,
                4, -831, 1, 3495,
                31, 116,
                -46, -11,
                -371, 190,
                -507, 399,
                -2, 57,
                -60, 36,
                -198, -1174, -613, 4988,
                -87, -4,
                141, 560, -276, 187,
                1876, 1379, 778, 4386,
                24, -15,
                167, -774,
                -71, -9,
                -62, 90,
                98, 580, -663, -7,
                34, -112,
                57, 15,
                -355, -214,
                -3240, -13605, 12229, -5723,
                3496, 7063,
                33, -51,
                1908, 1160, -226, 715,
                964, 1170, -1264, 623,
                14071, 5280, 5614, 3026,
                488, 1576, -2, 226395859,
                824, 1106, -1287, 617,
                1917, 1156, -214, 718,
                90, -97,
                12078, -2366, 3282, 6668,
                -219, 9179, 593, 2015,
                -282, -186,
                57, 25,
                31, -102,
                -77, -4,
                -268, -341, -7, -45,
                -3, 74,
                15, -615,
                -88, -7,
                234, -353,
                1, -119,
                -163, -1159, -601, 4969,
                22, -58,
                -17, -11434,
                17, 54,
                348, 348, -460, 434,
                -371, 175,
                -11, -204,
                4, -6440,
                -5, -53,
                -4, -14388, -37, -45231,
                -7, 179562,
                -44, 136,
                -160, 49,
                -101, 81,
                -1, -188,
                0, 2,
                -4, 12124, -11, -25217,
                71, 543, -557, -14,
                -75, 526,
                0, 395274,
                -233, -16,
                93, -20,
                -43, 61,
                0, -1275,
                0, -824,
                1, -415, 0, 1762,
                -261, 131,
                -45, 64,
                -297, -25,
                0, -17533,
                -6, -56,
                21, 1100,
                1, 327,
                1, 66,
                23, -217,
                -83, -7,
                83, 86847, 49, 275754,
                -4, -1093857,
                -46, 2,
                0, -24,
                0, -419,
                0, -5833,
                1, 506,
                0, -827,
                -1, -377,
                -11, -78,
                0, 131945,
                -2, -334,
                1, -75,
                0, -72,
                0, -213,
                -6, 5564, -2, -11618,
                0, 1790,
                0, -131,
                0, 6,
                0, -76,
                0, -130,
                0, -1115, 0, 4783,
                0, -195,
                0, -627,
                0, -55,
                0, -83,
                0, 163,
                0, -54,
                0, 82,
                0, 149,
                0, -754, 0, 1578,
                0, 138,
                0, 68,
                2, -2506, 0, 3399,
                0, -125,
                86, 16,
                0, -6350, 0, 27316,
                18, -63,
                0, -169,
                -1, 46,
                -136, -21,
                0, -239,
                -30, -8788, -15, -40549,
                -4, 80514,
                -46, -8,
                -168, -6,
                -1, 536, 0, -2314,
                9, 148,
                -13, -842,
                -1, 307713,
                -23, -175,
                95, 15,
                0, -297,
                11, 1341,
                0, -106,
                0, 5,
                -4, 68,
                -114, 10,
                32, 75,
                159, -130487, 98, -413967,
                2, 1647339,
                -4, -85,
                100, -46,
                2, 95,
                -11, 461,
                51, 1647,
                0, -32090,
                -375, -33,
                -65, 86,
                -300, 180,
                0, 836, 0, -3576,
                0, -222,
                0, -993,
                -41, 60,
                0, -4537,
                -431, -34,
                2, 927, 0, -1931,
                -79, 33,
                -31, 144,
                -1, 284, 0, -1207,
                0, 88,
                -11, 315,
                -178, 177,
                -1, 144,
                -58, 986,
                11, 86,
                -228, -110,
                2636, -494, 718, 1474,
                28, -35,
                -24, 782, -797, 277,
                2142, -1231, 856, 1853,
                74, 10797, 0, 23699298,
                -21, 786, -796, 277,
                27, -34,
                2615, -494, 712, 1461,
                -226, -109,
                -11, 663,
                0, -123,
                -169, 157,
                -54, 266,
                0, -76,
                1, -634, 0, 2738,
                -25, 106,
                -63, 24,
                0, -372,
                -221, -24,
                0, -5356,
                0, -219,
                0, 91,
                -28, 7684, -6, 24391,
                -1, -96795,
                -77, 43,
                2, 95,
                -47, -3,
                0, -84530,
                2, 310,
                1, 88,
                111, 19331, 32, 61306,
                4, -243595,
                0, 770,
                0, -103,
                0, 160,
                0, 356,
                0, 236,
                -41, 354,
                39, 303,
                12, -56,
                873, -143, 238, 482,
                -28, 35,
                -93, 31,
                -3, 7690211,
                -91, 33,
                -34, 43,
                824, -130, 226, 450,
                -39, 341,
                -1, -687,
                0, -303,
                11, -2935, 1, 12618,
                121, 924, 9, -1836,
                -268, -1144, -678, 3685,
                -69, -261,
                0, -4115951,
                -69, -261,
                5, -151,
                0, -88,
                0, 91,
                0, 187,
                0, -1281,
                1, 77,
                1, 6059, 3, 19238,
                0, -76305,
                0, -90,
                0, -238,
                0, -962, 0, 4133,
                0, 96,
                0, 9483,
                0, 85,
                0, -688,
                0, -5607,
                0, 55,
                0, -752,
                0, 71,
                0, 303,
                0, -288,
                0, 57,
                0, 45,
                0, 189,
                0, 401,
                0, -1474, 0, 3087,
                0, -71,
                0, 2925,
                0, -75,
                0, 359,
                0, 55,
                1, -10155, 0, 43735,
                0, -572,
                0, -49,
                0, -660,
                0, -3591, 0, 7516,
                0, 668,
                -1, -53,
                -2, 384259,
                0, -163,
                0, -93,
                1, 112,
                -95, -11528, -22, -36505,
                -1, 145308,
                5, 145,
                0, 4047,
                1, 1483, 0, -6352,
                0, 991, 0, -4262,
                0, -93,
                0, -334,
                0, -160,
                0, -153,
                -10, 127,
                51, 185,
                -77, 18,
                56, 1217, 6, 1919574,
                -74, 17,
                50, 180,
                -5, 93,
                0, -104,
                0, -58,
                -3, -353, -1, 1499,
                0, -229,
                -15, 86,
                0, -93657,
                0, 1561, 0, -6693,
                0, -5839,
                1, 6791, 0, -29143,
                1, -701, 0, 3015,
                0, 2543,
                0, 693,
                -1, 361233,
                0, -50,
                0, 946,
                -1, -140,
                -70, 407,
                0, -450995,
                0, -368,
                0, 54,
                0, -802,
                0, -96,
                0, 1274, 0, -5459,
                0, -614, 0, 2633,
                0, 685,
                0, -915,
                0, -85,
                0, 88,
                0, 106,
                0, 928,
                0, -726, 0, 1523,
                0, 5715,
                0, -4338, 0, 18706,
                0, -135,
                0, -132,
                0, -158,
                0, -98,
                0, 680,
                -1, 138968,
                0, -192,
                0, -1698,
                0, -2734, 0, 11769,
                0, 4,
                0, 673, 0, -2891,
                0, 889, 0, -3821,
                0, 121,
                -1, 143783,
                0, 231,
                -9, 51,
                0, -57413,
                0, -483,
                0, -407,
                0, 676, 0, -2902,
                0, 531,
                0, 445,
                0, 672,
                0, 19336,
                0, 70,
                0, -39976,
                0, -68,
                0, 4203,
                0, -406,
                0, 446,
                0, -108,
                0, 79,
                0, 84,
                0, 734,
                0, 255,
                0, 3944,
                0, -655, 0, 2825,
                0, -109,
                0, -234,
                0, 57,
                0, 19773,
                0, -2013,
                0, 958,
                0, -521,
                0, -757,
                0, 10594,
                0, -9901,
                0, 199,
                0, -275,
                0, 64,
                0, 54,
                0, 165,
                0, 1110,
                0, -3286,
                0, 909,
                0, 54,
                0, 87,
                0, 258,
                0, 1261,
                0, -51,
                0, 336,
                0, -114,
                0, 2185,
                0, -850,
                0, 75,
                0, -69,
                0, -103,
                0, 776,
                0, -1238,
                0, 137,
                0, 67,
                0, -260,
                0, 130,
                0, 49,
                0, 228,
                0, 215,
                0, -178,
                0, 57,
                0, -133
            ],
            lat_tbl: [],
            rad_tbl: [
                -5422, -2120, 1077, 772,
                39, 75, 3, 10,
                -468, -326, -113, -78,
                -4, -2,
                1, 3,
                29, 24, 4, 2,
                1, 0,
                -9, 7, -2, 0,
                -32, -13, -3, -3,
                233, 126, 89, 77,
                -33, 16,
                3, -3, 0, -1,
                2, 0,
                0, 1,
                4, 9, 1, 1,
                16, -1, 0, 18,
                3, 2,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, -1,
                -22, -5,
                10, 3, 1, 1,
                -15, 7, -2, 1,
                -8, -11, -1, -2,
                -1, 1,
                46, -58, 126, -23,
                4, 8,
                35, 8, 10, -17,
                0, 0,
                0, 0,
                -10, -7,
                0, 0,
                -23, 3, 151, 10,
                -327, 0,
                4, -5, 6, 5,
                1, 0,
                -1, -3,
                0, 0,
                0, 1,
                -185, 0,
                -3, -24, -5, -2,
                -1062, 3, 4560, 0,
                -3, 0,
                4, 1,
                8, -1, 2, 4,
                0, 1,
                0, -1,
                0, 0,
                -1, 0,
                0, 1,
                0, 0,
                -1, -1,
                277, 3, -583, 1,
                -1, 4, -32, 7,
                0, -34,
                1, -1,
                -23685, 0,
                -1, -2,
                -1, -7,
                -5, -4,
                0, 2,
                -2, 0,
                -5, -1,
                35, 0,
                0, 2,
                202, 0,
                180, 0,
                0, -1,
                -3, -6,
                -193, 0, 770, -1,
                -2, -4,
                -32, 23, -28, -46,
                -13, -9,
                -54, 10, -1, -61,
                -44895, 0,
                -230, 5,
                -1, -4,
                -71, 0,
                -15, 0,
                1, 0,
                15, 11, -3, 6,
                2, -3, 4, -1,
                2576, -138, -19881, -47,
                -65906, -1, 261925, -4,
                -2, -7, 4, -2,
                0, 0,
                -1, 0,
                1, -3,
                172, -2, -727, 0,
                4, 1,
                324, 0,
                -139, 1,
                1, 3,
                -276, 0,
                5, 3,
                9, 0,
                -1, 10,
                -37, 0,
                5, -1,
                76, -10,
                1318810, 1,
                12, -1,
                -38, 1,
                -141, 0, 611, 0,
                0, -11,
                4, 0,
                -627, 2, -2882, -3,
                5711, -2,
                -48, -7,
                55294, 0,
                2, -7,
                31, 0,
                34, 0,
                -259, 0,
                -55, 2,
                6, 3,
                -4273, 20, -13554, 3,
                53878, 0,
                -46, 0,
                -85, 0, 114, 0,
                -45, 0,
                -818, 0, 3520, 0,
                34, 0,
                -157, 0,
                29, 0,
                -878, 0, 1838, 0,
                -428, 0,
                161, 0,
                24, 0,
                65, 0,
                19, 0,
                15, 0,
                12, 0,
                -26, 0,
                -14, 0,
                -149, 0,
                584, 0, -793, 0,
                4, -23,
                -238, 0,
                -18, -5,
                45, 0,
                -7, 42,
                79, 0,
                -1723, 0,
                2895, -6, 13362, -4,
                -26525, -1,
                -2, 57,
                291, 0,
                52, -3,
                -327, 5,
                -2755, 0,
                -63, 9,
                5, -33,
                -261, -1, 1122, 0,
                621, -4,
                -227, 0,
                1077, 0,
                -167, 0,
                85, 0,
                -4, 23,
                -5, 32,
                3, 30,
                -32, 14,
                64607, 141, 204958, 59,
                -815115, 2,
                -37, -1,
                15, -15,
                12, 24,
                48, -1,
                235, 4,
                843, -25,
                4621, 0,
                -17, 191,
                45, 34,
                95, 159,
                -132, 0,
                13, 20,
                32, 0,
                -540, 0,
                29, 0,
                37, 25,
                8, 19,
                22127, 0,
                -35, -5,
                232, -48, 7, 262,
                5428, 3, -11342, 1,
                -45, 0,
                -21, -49,
                -100, -21,
                -626, 1, 2665, 0,
                532, -2,
                235, -12,
                -111, -105,
                774, 1,
                -283, 17,
                29, 20,
                3, 27,
                47, -2,
                -43, -192, -87, 136,
                -269, -1264, 646, -330,
                -79, 73, -33, -9,
                60, -205, 61, 4,
                -584, -85, -182, -555,
                -780, -57, -3488, -45,
                -19818328, -4,
                583, 93, 182, 555,
                -59, 208, -60, -4,
                23, 17,
                235, 1133, -608, 302,
                41, 174, 84, -137,
                6, -53,
                63, 13,
                -392, 52,
                -10, -27,
                -3, -27,
                199, -31,
                99, 97,
                -218, -3,
                209, 0,
                84, 18,
                16, 40,
                2, -30,
                14, -154,
                30, 0,
                -2, 24,
                -108, 0,
                -24, -16,
                262, -2,
                55, 0,
                -304, 0,
                2, 25,
                55112, 95, 175036, 11,
                -694477, 5,
                41, 0,
                -38, -76,
                199, 1,
                679, -14,
                -17, -12,
                582619, 1,
                -16, 191,
                38, 27,
                -234, 2,
                -60, 0,
                80, 163,
                -10296, 48, -32526, 13,
                129703, 8,
                -1366, 0,
                -741, 0,
                -646, 0,
                25, 6,
                33, 23,
                10, 43,
                -31, 0,
                -6, 0,
                -12, 147,
                59, 0,
                287, -42, -7, 297,
                -59, 0,
                -4, -42,
                -27, -81,
                -69, -22,
                27, 0,
                -423, -2, 1779, -1,
                -57, 15,
                5, -23,
                94, 182,
                -197, -250,
                24, 1,
                -18, -30,
                581, -98, -2473, -303,
                -2, 43,
                -277, 70, -92, -136,
                -681, 925, -2165, 384,
                -8, -12,
                382, 82,
                -4, 35,
                -45, -31,
                -286, 48, 3, -328,
                -55, -17,
                8, -28,
                -106, 175,
                -6735, 1601, -2832, -6052,
                3495, -1730,
                -25, -17,
                -574, 944, -354, -112,
                -579, 476, -308, -625,
                -2411, 7074, -1529, 2828,
                -1335, 247, -112000844, -1,
                545, -409, 305, 637,
                572, -950, 356, 106,
                48, 44,
                1170, 5974, -3298, 1624,
                -4538, -106, -996, 294,
                92, -139,
                -12, 28,
                50, 16,
                2, -38,
                169, -133, 22, -3,
                38, 1,
                305, 7,
                4, -44,
                175, 116,
                59, 1,
                -573, 81, 2453, 297,
                29, 11,
                5674, -8,
                -27, 9,
                173, -173, 215, 228,
                -87, -184,
                102, -5,
                3206, 2,
                -53, 2,
                7159, -7, 22505, -19,
                -89344, -3,
                67, 22,
                24, 79,
                -40, -50,
                94, 0,
                186, 0,
                -6063, 0, 12612, -5,
                -271, 35, 7, -278,
                -479, -74,
                426754, 0,
                8, -116,
                -10, -47,
                -31, -22,
                645, 0,
                426, 0,
                -213, 0, 903, 0,
                -67, -133,
                -33, -23,
                13, -152,
                -9316, 0,
                29, -3,
                -564, 11,
                -167, 0,
                -34, 0,
                114, 12,
                4, -44,
                -44561, 42, -141493, 25,
                561256, -2,
                -1, -24,
                -261, 0,
                211, 0,
                -4263, 0,
                -262, 1,
                1842, 0,
                202, 0,
                41, -6,
                77165, 0,
                176, -1,
                39, 1,
                -24, 0,
                118, 0,
                -2991, -4, 6245, -1,
                46886, 0,
                -75, 0,
                -100, 0,
                40, 0,
                75, 0,
                -618, 0, 2652, 0,
                112, 0,
                1780, 0,
                30, 0,
                49, 0,
                86, 0,
                33, 0,
                -30, 0,
                -95, 0,
                277, 0, -580, 0,
                -35, 0,
                -319, 0,
                1622, 1, -2201, 0,
                79, 0,
                10, -57,
                2363, 0, -10162, 0,
                -41, -12,
                62, 0,
                30, 1,
                -14, 89,
                -2721, 0,
                5780, -19, 26674, -10,
                -52964, -2,
                -5, 30,
                -4, 111,
                -317, -1, 1369, 0,
                93, -6,
                -564, 9,
                -115913, 0,
                -113, 15,
                10, -62,
                99, 0,
                891, -7,
                36, 0,
                108, 0,
                -42, -2,
                7, 75,
                -50, 21,
                86822, 104, 275441, 65,
                -1096109, 1,
                -56, 3,
                31, 66,
                63, -1,
                307, 7,
                1097, -34,
                17453, 0,
                -22, 250,
                57, 43,
                120, 200,
                -297, 0, 1269, 0,
                166, 0,
                -662, 0,
                40, 28,
                1521, 0,
                -23, 288,
                351, -2, -729, 0,
                -22, -52,
                -96, -21,
                -139, -1, 589, 0,
                35, 0,
                210, 7,
                -118, -119,
                62, 0,
                -583, -26,
                -42, 5,
                -73, 152,
                -330, -1759, 983, -479,
                -23, -19,
                -522, -15, -185, -533,
                739, 1559, -1300, 614,
                -7332, 52, -15836758, 0,
                524, 16, 185, 532,
                23, 18,
                330, 1751, -978, 476,
                73, -151,
                519, 18,
                38, 0,
                105, 113,
                -178, -37,
                26, 0,
                262, 1, -1139, 0,
                71, 17,
                16, 42,
                151, 0,
                16, -148,
                4147, 0,
                149, 0,
                -30, 0,
                2980, 9, 9454, 2,
                -37519, 0,
                -28, -49,
                37, -1,
                2, -31,
                33870, 0,
                -208, 1,
                -59, 1,
                -13105, 68, -41564, 21,
                165148, 3,
                -1022, 0,
                -40, 0,
                -132, 0,
                -228, 0,
                95, 0,
                -138, -16,
                -126, 16,
                24, 5,
                -57, -346, 191, -94,
                -14, -11,
                -12, -37,
                -3053364, -1,
                13, 36,
                17, 13,
                51, 327, -179, 90,
                138, 16,
                233, 0,
                62, 0,
                1164, 0, -5000, 0,
                -407, 117, 770, 9,
                -4, 1, 21, 2,
                1, 0,
                -16869, 0,
                -1, 0,
                1, 0,
                35, 0,
                -78, 0,
                78, 0,
                -533, 0,
                -31, 1,
                -2448, -6, -7768, -1,
                30812, 0,
                37, 0,
                -227, 0,
                197, 0, -846, 0,
                -77, 0,
                4171, 0,
                -67, 0,
                287, 0,
                2532, 0,
                -19, 0,
                -40, 0,
                -56, 0,
                128, 0,
                83, 0,
                -45, 0,
                -36, 0,
                -92, 0,
                -134, 0,
                714, 0, -1495, 0,
                32, 0,
                -981, 0,
                15, 0,
                -166, 0,
                -59, 0,
                4923, 0, -21203, 0,
                246, 0,
                15, 0,
                104, 0,
                1683, 0, -3523, 0,
                -865, 0,
                -25, 1,
                -186329, -1,
                10, 0,
                50, 0,
                53, 0,
                5455, -45, 17271, -10,
                -68747, 0,
                69, -2,
                -7604, 0,
                -724, 1, 3101, 0,
                -46, 0, 200, 0,
                -44, 0,
                97, 0,
                -53, 0,
                62, 0,
                -54, -4,
                88, -24,
                -9, -36,
                -581, 27, -914711, 3,
                8, 35,
                -86, 24,
                51, 3,
                48, 0,
                26, 0,
                133, 1, -577, 0,
                105, 0,
                -3, -1,
                3194, 0,
                528, 0, -2263, 0,
                2028, 0,
                -3266, 1, 14016, 0,
                10, 0, -41, 0,
                -100, 0,
                -32, 0,
                -124348, 0,
                16, 0,
                -325, 0,
                50, -1,
                1, 0,
                -553, 0,
                0, 0,
                0, 0,
                2, 0,
                -34, 0,
                -444, 0, 1902, 0,
                9, 0, -37, 0,
                254, 0,
                156, 0,
                -2, 0,
                -35, 0,
                -48, 0,
                -368, 0,
                327, 0, -686, 0,
                -2263, 0,
                1952, 0, -8418, 0,
                -13, 0,
                52, 0,
                9, 0,
                21, 0,
                -261, 0,
                -62404, 0,
                0, 0,
                79, 0,
                1056, 0, -4547, 0,
                -351, 0,
                -305, 0, 1310, 0,
                -1, 0, 6, 0,
                0, 0,
                -55953, 0,
                -80, 0,
                0, 0,
                168, 0,
                -147, 0,
                127, 0,
                -265, 0, 1138, 0,
                -1, 0,
                -9, 0,
                -8, 0,
                -5984, 0,
                -22, 0,
                -5, 0,
                0, 0,
                0, 0,
                127, 0,
                -2, 0,
                10, 0,
                -31, 0,
                -29, 0,
                -286, 0,
                -98, 0,
                -1535, 0,
                252, 0, -1087, 0,
                43, 0,
                4, 0,
                -19, 0,
                -7620, 0,
                29, 0,
                -322, 0,
                203, 0,
                0, 0,
                -3587, 0,
                10, 0,
                0, 0,
                94, 0,
                0, 0,
                -1, 0,
                -1, 0,
                -315, 0,
                1, 0,
                0, 0,
                0, 0,
                -30, 0,
                -94, 0,
                -460, 0,
                1, 0,
                -114, 0,
                0, 0,
                -746, 0,
                4, 0,
                -23, 0,
                24, 0,
                0, 0,
                -237, 0,
                1, 0,
                0, 0,
                -18, 0,
                0, 0,
                0, 0,
                -16, 0,
                -76, 0,
                -67, 0,
                0, 0,
                -16, 0,
                0, 0
            ],
            arg_tbl: [
                0, 3,
                3, 4, 3, -8, 4, 3, 5, 1,
                2, 2, 5, -5, 6, 2,
                5, -1, 10, 2, 13, -1, 11, 3, 3, -7, 4, 0,
                3, 1, 13, -1, 11, 2, 5, 1,
                2, 4, 5, -10, 6, 0,
                4, 2, 10, -2, 13, 14, 3, -23, 4, 1,
                3, 3, 2, -7, 3, 4, 4, 1,
                3, -1, 13, 18, 2, -16, 3, 2,
                2, 8, 2, -13, 3, 1,
                5, 2, 10, -2, 13, 2, 3, -3, 5, 1, 6, 0,
                3, -1, 13, 26, 2, -29, 3, 0,
                3, 1, 10, -1, 11, 2, 4, 1,
                4, 1, 10, -1, 13, 3, 2, -4, 3, 1,
                4, 1, 10, -1, 13, 3, 3, -4, 4, 0,
                3, -1, 10, 15, 2, -12, 3, 0,
                4, 2, 10, -3, 13, 24, 2, -24, 3, 0,
                3, -1, 10, 23, 2, -25, 3, 0,
                4, 1, 10, -1, 11, 1, 3, 1, 6, 0,
                4, 2, 10, -2, 11, 5, 2, -6, 3, 0,
                4, 2, 10, -2, 13, 6, 2, -8, 3, 0,
                4, -2, 10, 1, 13, 12, 2, -8, 3, 1,
                5, -1, 10, 1, 13, -1, 11, 20, 2, -20, 3, 1,
                4, -2, 10, 1, 13, 3, 1, -1, 3, 1,
                5, 2, 10, -2, 13, 2, 3, -5, 5, 5, 6, 0,
                4, 2, 10, -2, 13, 2, 3, -3, 5, 1,
                4, 2, 10, -2, 13, 6, 3, -8, 4, 0,
                4, -2, 10, 1, 13, 20, 2, -21, 3, 1,
                4, 1, 10, -1, 11, 1, 3, 1, 5, 0,
                1, 1, 6, 0,
                4, 2, 10, -2, 13, 5, 3, -6, 4, 0,
                3, 3, 2, -5, 3, 2, 5, 0,
                2, -1, 11, 1, 14, 1,
                4, 2, 10, -2, 13, 2, 3, -2, 5, 0,
                2, 1, 3, -2, 4, 1,
                4, 1, 10, -1, 11, 5, 2, -7, 3, 0,
                1, 1, 5, 0,
                2, 7, 3, -13, 4, 0,
                4, -2, 10, 1, 13, 15, 2, -13, 3, 0,
                4, 2, 10, -2, 13, 3, 2, -3, 3, 0,
                2, -2, 11, 2, 14, 1,
                3, 1, 10, 1, 12, -1, 13, 1,
                3, -1, 13, 21, 2, -21, 3, 0,
                2, 3, 2, -5, 3, 0,
                2, 2, 3, -4, 4, 1,
                2, 5, 2, -8, 3, 0,
                3, -1, 13, 23, 2, -24, 3, 0,
                2, 6, 3, -11, 4, 0,
                1, 2, 5, 0,
                2, 3, 3, -6, 4, 0,
                2, 5, 3, -9, 4, 0,
                4, 1, 10, -1, 11, 1, 3, -2, 5, 0,
                3, 2, 10, 2, 12, -2, 13, 1,
                2, 2, 2, -3, 3, 2,
                2, 4, 3, -7, 4, 0,
                2, 2, 13, -2, 11, 0,
                2, 3, 3, -5, 4, 0,
                2, 1, 2, -2, 3, 0,
                2, 2, 3, -3, 4, 0,
                4, 1, 10, -1, 11, 4, 2, -5, 3, 0,
                2, 1, 3, -1, 4, 0,
                2, 4, 2, -6, 3, 0,
                4, 2, 10, -2, 13, 2, 2, -2, 3, 0,
                3, 1, 10, -1, 11, 1, 2, 0,
                2, 1, 2, -1, 3, 0,
                3, 1, 12, 2, 13, -2, 11, 0,
                2, 5, 3, -8, 4, 0,
                2, 1, 3, -3, 5, 0,
                3, 2, 10, 1, 12, -2, 13, 1,
                2, 4, 3, -6, 4, 0,
                2, 1, 3, -2, 5, 1,
                2, 3, 3, -4, 4, 0,
                2, 3, 2, -4, 3, 1,
                2, 1, 10, -1, 13, 0,
                2, 1, 3, -1, 5, 0,
                2, 1, 3, -2, 6, 0,
                2, 2, 3, -2, 4, 0,
                2, 1, 3, -1, 6, 0,
                2, 8, 2, -14, 3, 0,
                3, 1, 3, 2, 5, -5, 6, 1,
                3, 5, 3, -8, 4, 3, 5, 1,
                1, 1, 12, 3,
                3, 3, 3, -8, 4, 3, 5, 1,
                3, 1, 3, -2, 5, 5, 6, 0,
                2, 8, 2, -12, 3, 0,
                2, 1, 3, 1, 5, 0,
                3, 2, 10, 1, 12, -2, 11, 1,
                2, 5, 2, -7, 3, 0,
                3, 1, 10, 1, 13, -2, 11, 0,
                2, 2, 2, -2, 3, 0,
                2, 5, 3, -7, 4, 0,
                3, 1, 12, -2, 13, 2, 11, 0,
                2, 4, 3, -5, 4, 0,
                2, 3, 3, -3, 4, 0,
                1, 1, 2, 0,
                3, 3, 10, 1, 12, -3, 13, 0,
                2, 2, 3, -4, 5, 0,
                2, 2, 3, -3, 5, 0,
                2, 2, 10, -2, 13, 0,
                2, 2, 3, -2, 5, 0,
                2, 3, 2, -3, 3, 0,
                3, 1, 10, -1, 12, -1, 13, 1,
                2, 2, 3, -1, 5, 0,
                2, 2, 3, -2, 6, 0,
                1, 2, 12, 2,
                3, -2, 10, 1, 11, 1, 14, 0,
                2, 2, 10, -2, 11, 0,
                2, 2, 2, -1, 3, 0,
                4, -2, 10, 2, 13, 1, 2, -1, 3, 0,
                2, 4, 2, -4, 3, 0,
                2, 3, 10, -3, 13, 0,
                4, -2, 10, 2, 13, 1, 3, -1, 5, 0,
                2, 3, 3, -3, 5, 0,
                3, 2, 10, -1, 12, -2, 13, 2,
                3, 3, 10, -1, 13, -2, 11, 0,
                1, 3, 12, 1,
                4, -2, 10, 2, 13, 2, 2, -2, 3, 0,
                3, 2, 10, -1, 12, -2, 11, 1,
                2, 5, 2, -5, 3, 0,
                2, 4, 10, -4, 13, 0,
                2, 6, 2, -6, 3, 0,
                3, 2, 10, -2, 12, -2, 13, 1,
                3, 4, 10, -2, 13, -2, 11, 0,
                3, 2, 10, -2, 12, -2, 11, 0,
                2, 7, 2, -7, 3, 0,
                3, 2, 10, -3, 12, -2, 13, 0,
                2, 8, 2, -8, 3, 0,
                2, 9, 2, -9, 3, 0,
                2, 10, 2, -10, 3, 0,
                3, 2, 10, -4, 12, -1, 13, 0,
                3, 4, 10, -2, 12, -3, 13, 0,
                4, 4, 10, -1, 12, -1, 13, -2, 11, 0,
                3, 2, 10, -3, 12, -1, 13, 1,
                4, -2, 10, 1, 13, 3, 3, -2, 5, 0,
                3, 4, 10, -1, 12, -3, 13, 0,
                4, -2, 10, 1, 13, 3, 3, -3, 5, 0,
                4, 2, 10, -2, 12, 1, 13, -2, 11, 0,
                4, -2, 10, 1, 13, 2, 2, -1, 3, 0,
                3, 3, 10, -1, 12, -2, 11, 0,
                3, 4, 10, -1, 13, -2, 11, 0,
                3, 2, 10, -2, 12, -1, 13, 2,
                4, -2, 10, 1, 13, 2, 3, -1, 5, 0,
                3, 3, 10, -1, 12, -2, 13, 0,
                4, -2, 10, 1, 13, 3, 2, -3, 3, 0,
                4, -2, 10, 1, 13, 2, 3, -2, 5, 0,
                2, 4, 10, -3, 13, 0,
                4, -2, 10, 1, 13, 2, 3, -3, 5, 0,
                3, -2, 10, 1, 13, 1, 2, 0,
                4, 2, 10, -1, 12, 1, 13, -2, 11, 1,
                4, -2, 10, 1, 13, 2, 2, -2, 3, 0,
                2, 3, 12, -1, 13, 0,
                2, 3, 10, -2, 11, 0,
                2, 1, 10, -2, 12, 0,
                4, 4, 10, 1, 12, -1, 13, -2, 11, 0,
                3, -1, 13, 3, 2, -2, 3, 0,
                3, -1, 13, 3, 3, -2, 5, 0,
                3, -2, 10, 18, 2, -15, 3, 0,
                5, 2, 10, -1, 13, 3, 3, -8, 4, 3, 5, 0,
                3, 2, 10, -1, 12, -1, 13, 2,
                5, -2, 10, 1, 13, 5, 3, -8, 4, 3, 5, 0,
                5, -2, 10, 1, 13, 1, 3, 2, 5, -5, 6, 0,
                4, 2, 10, -2, 13, 18, 2, -17, 3, 0,
                4, -2, 10, 1, 13, 1, 3, -1, 6, 0,
                4, -2, 10, 1, 13, 2, 3, -2, 4, 0,
                4, -2, 10, 1, 13, 1, 3, -1, 5, 0,
                2, 3, 10, -2, 13, 0,
                4, -2, 10, 1, 13, 3, 2, -4, 3, 0,
                4, -2, 10, 1, 13, 3, 3, -4, 4, 0,
                4, -2, 10, 1, 13, 1, 3, -2, 5, 0,
                3, 4, 10, 1, 12, -3, 13, 0,
                4, -2, 10, 1, 13, 1, 3, -3, 5, 0,
                3, -1, 13, 4, 2, -4, 3, 0,
                4, -2, 10, 1, 13, 1, 2, -1, 3, 0,
                4, -2, 10, 1, 13, 1, 3, -1, 4, 0,
                4, -2, 10, 1, 13, 2, 3, -3, 4, 0,
                4, -2, 10, 1, 13, 3, 3, -5, 4, 0,
                3, 2, 10, 1, 13, -2, 11, 0,
                4, -2, 10, -1, 13, 1, 11, 1, 14, 0,
                4, -2, 10, 1, 13, 2, 2, -3, 3, 1,
                2, 2, 12, -1, 13, 1,
                3, 3, 10, 1, 12, -2, 11, 0,
                4, 2, 10, -1, 13, 2, 3, -4, 4, 0,
                4, 2, 10, -1, 13, 3, 2, -5, 3, 0,
                2, 1, 10, -1, 12, 1,
                3, -1, 13, 3, 2, -3, 3, 0,
                3, -2, 10, 1, 13, 1, 5, 0,
                4, 2, 10, -1, 13, 1, 3, -2, 4, 0,
                3, -1, 13, 2, 3, -2, 5, 0,
                4, 2, 10, -1, 13, -1, 11, 1, 14, 0,
                3, -1, 13, 5, 3, -6, 4, 0,
                3, -2, 10, 1, 13, 1, 6, 0,
                3, -1, 10, 1, 3, -1, 5, 0,
                4, -2, 10, 1, 13, 8, 2, -13, 3, 1,
                3, -2, 10, 18, 2, -16, 3, 1,
                5, -2, 10, 1, 13, 3, 2, -7, 3, 4, 4, 1,
                4, 2, 10, -1, 13, 2, 5, -5, 6, 1,
                5, 2, 10, -1, 13, 4, 3, -8, 4, 3, 5, 1,
                2, 2, 10, -1, 13, 2,
                5, -2, 10, 1, 13, 4, 3, -8, 4, 3, 5, 1,
                4, -2, 10, 1, 13, 2, 5, -5, 6, 1,
                5, 2, 10, -1, 13, 3, 2, -7, 3, 4, 4, 0,
                4, 2, 10, -2, 13, 18, 2, -16, 3, 1,
                4, 2, 10, -1, 13, 8, 2, -13, 3, 1,
                3, -1, 10, 3, 2, -4, 3, 0,
                3, -1, 13, 6, 2, -8, 3, 0,
                3, -1, 13, 2, 3, -3, 5, 0,
                3, -1, 13, 6, 3, -8, 4, 0,
                3, 2, 10, -1, 13, 1, 6, 0,
                4, -2, 10, 1, 13, -1, 11, 1, 14, 0,
                4, -2, 10, 1, 13, 1, 3, -2, 4, 0,
                3, 2, 10, -1, 13, 1, 5, 0,
                3, 3, 10, 1, 12, -2, 13, 0,
                4, -2, 10, 1, 13, 3, 2, -5, 3, 0,
                4, -2, 10, 1, 13, 2, 3, -4, 4, 0,
                2, -1, 13, 1, 2, 0,
                4, 2, 10, -1, 13, 2, 2, -3, 3, 0,
                3, -1, 10, 1, 2, -1, 3, 0,
                3, -1, 13, 4, 2, -5, 3, 0,
                3, 2, 10, -3, 13, 2, 11, 0,
                4, 2, 10, -1, 13, 2, 3, -3, 4, 0,
                3, -1, 13, 2, 2, -2, 3, 0,
                4, 2, 10, -1, 13, 1, 2, -1, 3, 0,
                4, 2, 10, 1, 12, 1, 13, -2, 11, 0,
                3, -2, 13, 18, 2, -15, 3, 0,
                2, 1, 12, -1, 13, 2,
                3, -1, 13, 1, 3, -1, 6, 0,
                4, 2, 10, -1, 13, 1, 3, -2, 5, 0,
                3, -1, 13, 2, 3, -2, 4, 0,
                3, -1, 13, 1, 3, -1, 5, 0,
                4, 2, 10, -1, 13, 3, 3, -4, 4, 0,
                1, 1, 10, 0,
                3, -1, 13, 3, 2, -4, 3, 0,
                3, -1, 13, 3, 3, -4, 4, 0,
                4, 2, 10, -1, 13, 1, 3, -1, 5, 0,
                4, 2, 10, -1, 13, 2, 3, -2, 4, 0,
                3, -1, 13, 1, 3, -2, 5, 0,
                3, 2, 10, 1, 12, -1, 13, 2,
                3, 1, 12, 1, 13, -2, 11, 0,
                3, -1, 13, 1, 2, -1, 3, 0,
                4, 2, 10, -1, 13, 2, 2, -2, 3, 0,
                3, -1, 13, 4, 2, -6, 3, 0,
                3, -1, 13, 2, 3, -3, 4, 0,
                3, 1, 13, 1, 2, -2, 3, 0,
                4, 2, 10, -1, 13, 3, 3, -3, 4, 0,
                2, 3, 13, -2, 11, 0,
                4, 2, 10, -1, 13, 4, 2, -5, 3, 0,
                3, 1, 10, 1, 2, -1, 3, 0,
                3, -1, 13, 2, 2, -3, 3, 1,
                3, 2, 10, 2, 12, -3, 13, 0,
                3, 2, 10, -1, 13, 1, 2, 0,
                3, 1, 13, 2, 3, -4, 4, 0,
                3, 1, 13, 3, 2, -5, 3, 0,
                2, 21, 2, -21, 3, 0,
                3, 1, 10, 1, 12, -2, 13, 1,
                4, 2, 10, -1, 13, 2, 3, -4, 5, 0,
                4, 2, 10, -1, 13, 7, 3, -10, 4, 0,
                2, -1, 13, 1, 5, 0,
                3, 1, 13, 1, 3, -2, 4, 0,
                4, 2, 10, -3, 13, 2, 3, -2, 5, 0,
                3, 1, 10, 1, 3, -2, 5, 0,
                3, 1, 13, -1, 11, 1, 14, 1,
                2, -1, 13, 1, 6, 0,
                4, 2, 10, -1, 13, 6, 3, -8, 4, 1,
                4, 2, 10, -1, 13, 2, 3, -3, 5, 1,
                3, -1, 13, 8, 3, -15, 4, 0,
                4, 2, 10, -1, 13, 6, 2, -8, 3, 0,
                5, 2, 10, -1, 13, -2, 11, 5, 2, -6, 3, 0,
                3, 1, 10, 3, 3, -4, 4, 0,
                3, 1, 10, 3, 2, -4, 3, 1,
                4, 1, 10, -1, 13, -1, 11, 2, 4, 0,
                3, -2, 13, 26, 2, -29, 3, 0,
                3, -1, 13, 8, 2, -13, 3, 0,
                3, -2, 13, 18, 2, -16, 3, 2,
                4, -1, 13, 3, 2, -7, 3, 4, 4, 0,
                3, 1, 13, 2, 5, -5, 6, 1,
                4, 1, 13, 4, 3, -8, 4, 3, 5, 1,
                1, 1, 13, 3,
                4, -1, 13, 4, 3, -8, 4, 3, 5, 1,
                3, -1, 13, 2, 5, -5, 6, 1,
                4, 1, 13, 3, 2, -7, 3, 4, 4, 0,
                2, 18, 2, -16, 3, 1,
                3, 1, 13, 8, 2, -13, 3, 2,
                2, 26, 2, -29, 3, 0,
                4, 1, 10, 1, 13, -1, 11, 2, 4, 0,
                5, 2, 10, 1, 13, -2, 11, 5, 2, -6, 3, 0,
                3, 1, 13, 8, 3, -15, 4, 1,
                4, 2, 10, -3, 13, 2, 3, -3, 5, 0,
                3, 1, 10, 1, 3, -1, 5, 0,
                2, 1, 13, 1, 6, 0,
                4, 2, 10, -1, 13, 5, 3, -6, 4, 0,
                3, 1, 10, 2, 3, -2, 4, 0,
                3, -1, 13, -1, 11, 1, 14, 1,
                4, 2, 10, -1, 13, 2, 3, -5, 6, 0,
                4, 2, 10, -1, 13, 2, 3, -2, 5, 0,
                5, 2, 10, -1, 13, 2, 3, -4, 5, 5, 6, 0,
                3, -1, 13, 1, 3, -2, 4, 1,
                2, 1, 13, 1, 5, 0,
                4, 2, 10, -1, 13, 4, 3, -4, 4, 0,
                4, 2, 10, -1, 13, 3, 2, -3, 3, 0,
                4, 2, 10, 2, 12, -1, 13, -2, 11, 0,
                2, 1, 10, 1, 12, 2,
                3, -1, 13, 3, 2, -5, 3, 0,
                3, -1, 13, 2, 3, -4, 4, 0,
                4, 2, 10, -1, 13, 2, 3, -1, 5, 0,
                4, 2, 10, -1, 13, 2, 3, -2, 6, 0,
                3, 1, 10, 1, 12, -2, 11, 0,
                3, 2, 10, 2, 12, -1, 13, 1,
                3, 1, 13, 2, 2, -3, 3, 1,
                3, -1, 13, 1, 11, 1, 14, 0,
                2, 1, 13, -2, 11, 0,
                4, 2, 10, -1, 13, 5, 2, -6, 3, 0,
                3, -1, 13, 1, 2, -2, 3, 0,
                3, 1, 13, 2, 3, -3, 4, 0,
                3, 1, 13, 1, 2, -1, 3, 0,
                4, 2, 10, -1, 13, 4, 2, -4, 3, 0,
                3, 2, 10, 1, 12, -3, 13, 1,
                3, 1, 13, 1, 3, -2, 5, 0,
                3, 1, 13, 3, 3, -4, 4, 0,
                3, 1, 13, 3, 2, -4, 3, 0,
                2, 1, 10, -2, 13, 0,
                4, 2, 10, -1, 13, 3, 3, -4, 5, 0,
                3, 1, 13, 1, 3, -1, 5, 0,
                3, 1, 13, 2, 3, -2, 4, 0,
                3, 1, 13, 1, 3, -1, 6, 0,
                4, 2, 10, -1, 13, 3, 3, -3, 5, 0,
                4, 2, 10, -1, 13, 6, 2, -7, 3, 0,
                2, 1, 12, 1, 13, 2,
                4, 2, 10, -1, 13, 3, 3, -2, 5, 0,
                4, 2, 10, 1, 12, -1, 13, -2, 11, 0,
                2, 1, 10, 2, 12, 0,
                2, 1, 10, -2, 11, 0,
                3, 1, 13, 2, 2, -2, 3, 0,
                3, 1, 12, -1, 13, 2, 11, 0,
                4, 2, 10, -1, 13, 5, 2, -5, 3, 0,
                3, 1, 13, 2, 3, -3, 5, 0,
                2, 2, 10, -3, 13, 0,
                3, 1, 13, 2, 3, -2, 5, 0,
                3, 1, 13, 3, 2, -3, 3, 0,
                3, 1, 10, -1, 12, -2, 13, 0,
                4, 2, 10, -1, 13, 6, 2, -6, 3, 0,
                2, 2, 12, 1, 13, 1,
                3, 2, 10, -1, 13, -2, 11, 0,
                3, 1, 10, -1, 12, -2, 11, 0,
                3, 2, 10, 1, 13, -4, 11, 0,
                3, 1, 13, 4, 2, -4, 3, 0,
                4, 2, 10, -1, 13, 7, 2, -7, 3, 0,
                3, 2, 10, -1, 12, -3, 13, 1,
                2, 3, 12, 1, 13, 0,
                4, 2, 10, -1, 12, -1, 13, -2, 11, 0,
                3, 1, 13, 5, 2, -5, 3, 0,
                4, 2, 10, -1, 13, 8, 2, -8, 3, 0,
                3, 2, 10, -2, 12, -3, 13, 0,
                4, 2, 10, -1, 13, 9, 2, -9, 3, 0,
                3, 4, 10, -3, 12, -2, 13, 0,
                2, 2, 10, -4, 12, 0,
                3, 4, 10, -2, 12, -2, 13, 1,
                2, 6, 10, -4, 13, 0,
                3, 4, 10, -1, 12, -2, 11, 0,
                2, 2, 10, -3, 12, 1,
                3, 3, 10, -2, 12, -1, 13, 0,
                3, -2, 10, 3, 3, -2, 5, 0,
                3, 4, 10, -1, 12, -2, 13, 1,
                3, -2, 10, 3, 3, -3, 5, 0,
                2, 5, 10, -3, 13, 0,
                3, -2, 10, 4, 2, -4, 3, 0,
                3, -2, 10, 2, 2, -1, 3, 0,
                2, 4, 10, -2, 11, 0,
                2, 2, 10, -2, 12, 2,
                3, -2, 10, 3, 3, -2, 4, 0,
                3, -2, 10, 2, 3, -1, 5, 0,
                3, 3, 10, -1, 12, -1, 13, 1,
                3, -2, 10, 3, 2, -3, 3, 0,
                3, -2, 10, 2, 3, -2, 5, 0,
                2, 4, 10, -2, 13, 0,
                3, -2, 10, 2, 3, -3, 5, 0,
                2, -2, 10, 1, 2, 0,
                4, 2, 10, -1, 12, 2, 13, -2, 11, 0,
                3, -2, 10, 2, 2, -2, 3, 0,
                3, 3, 10, 1, 13, -2, 11, 0,
                3, 4, 10, 1, 12, -2, 11, 0,
                4, 2, 10, -1, 12, -1, 11, 1, 14, 0,
                4, -2, 10, -1, 13, 18, 2, -15, 3, 0,
                4, 2, 10, 3, 3, -8, 4, 3, 5, 0,
                2, 2, 10, -1, 12, 2,
                4, -2, 10, 5, 3, -8, 4, 3, 5, 0,
                4, 2, 10, -1, 13, 18, 2, -17, 3, 0,
                3, -2, 10, 1, 3, -1, 6, 0,
                3, -2, 10, 2, 3, -2, 4, 0,
                3, -2, 10, 1, 3, -1, 5, 0,
                2, 3, 10, -1, 13, 0,
                3, -2, 10, 3, 2, -4, 3, 0,
                3, -2, 10, 3, 3, -4, 4, 0,
                3, -2, 10, 1, 3, -2, 5, 0,
                3, 4, 10, 1, 12, -2, 13, 1,
                4, 2, 10, -1, 12, -2, 13, 2, 11, 0,
                3, -2, 10, 1, 2, -1, 3, 0,
                3, -2, 10, 2, 3, -3, 4, 0,
                3, 2, 10, 2, 13, -2, 11, 0,
                3, -2, 10, 2, 2, -3, 3, 0,
                2, 2, 12, -2, 13, 1,
                3, 2, 10, 2, 3, -4, 4, 0,
                3, 2, 10, 3, 2, -5, 3, 0,
                3, 1, 10, -1, 12, 1, 13, 1,
                3, -2, 13, 3, 2, -3, 3, 0,
                2, -2, 10, 1, 5, 0,
                3, 2, 10, 1, 3, -2, 4, 0,
                3, -2, 13, 2, 3, -2, 5, 0,
                3, 2, 10, -1, 11, 1, 14, 0,
                4, 4, 10, -2, 13, 2, 3, -3, 5, 0,
                3, -2, 10, 8, 2, -13, 3, 0,
                4, -2, 10, -1, 13, 18, 2, -16, 3, 1,
                4, -2, 10, 3, 2, -7, 3, 4, 4, 0,
                4, 2, 10, 4, 3, -8, 4, 3, 5, 1,
                1, 2, 10, 3,
                4, -2, 10, 4, 3, -8, 4, 3, 5, 1,
                4, 2, 10, 3, 2, -7, 3, 4, 4, 0,
                4, 2, 10, -1, 13, 18, 2, -16, 3, 1,
                3, 2, 10, 8, 2, -13, 3, 0,
                3, -2, 10, -1, 11, 1, 14, 0,
                4, 4, 10, -2, 13, 2, 3, -2, 5, 0,
                3, -2, 10, 1, 3, -2, 4, 0,
                2, 2, 10, 1, 5, 0,
                4, 4, 10, -2, 13, 3, 2, -3, 3, 0,
                3, 3, 10, 1, 12, -1, 13, 1,
                3, -2, 10, 3, 2, -5, 3, 0,
                3, -2, 10, 2, 3, -4, 4, 0,
                3, 4, 10, 2, 12, -2, 13, 0,
                3, 2, 10, 2, 2, -3, 3, 0,
                3, 2, 10, -2, 13, 2, 11, 0,
                3, 2, 10, 1, 2, -1, 3, 0,
                4, 2, 10, 1, 12, 2, 13, -2, 11, 0,
                2, 1, 12, -2, 13, 2,
                3, 2, 10, 1, 3, -2, 5, 0,
                3, -2, 13, 1, 3, -1, 5, 0,
                3, 2, 10, 3, 2, -4, 3, 0,
                2, 1, 10, 1, 13, 0,
                3, 2, 10, 1, 3, -1, 5, 0,
                3, 2, 10, 2, 3, -2, 4, 0,
                2, 2, 10, 1, 12, 2,
                2, 1, 12, -2, 11, 0,
                3, -2, 13, 1, 2, -1, 3, 0,
                3, 1, 10, -1, 13, 2, 11, 0,
                3, 2, 10, 2, 2, -2, 3, 0,
                3, 1, 10, 1, 12, -3, 13, 0,
                3, 2, 13, -1, 11, 1, 14, 0,
                3, 2, 10, 2, 3, -3, 5, 0,
                3, 2, 10, 6, 2, -8, 3, 0,
                3, -3, 13, 18, 2, -16, 3, 1,
                3, 2, 13, 2, 5, -5, 6, 0,
                4, 2, 13, 4, 3, -8, 4, 3, 5, 0,
                1, 2, 13, 0,
                4, -2, 13, 4, 3, -8, 4, 3, 5, 0,
                3, -2, 13, 2, 5, -5, 6, 0,
                3, 1, 13, 18, 2, -16, 3, 1,
                3, -2, 13, -1, 11, 1, 14, 0,
                3, 2, 10, 2, 3, -2, 5, 0,
                3, 2, 10, 3, 2, -3, 3, 0,
                3, 1, 10, 1, 12, 1, 13, 1,
                2, 2, 10, 2, 12, 1,
                2, 1, 11, 1, 14, 1,
                4, -1, 13, -2, 11, 18, 2, -16, 3, 0,
                1, 2, 11, 0,
                4, -1, 13, 2, 11, 18, 2, -16, 3, 0,
                2, -3, 11, 1, 14, 0,
                3, 2, 13, 1, 2, -1, 3, 0,
                3, 2, 10, 4, 2, -4, 3, 0,
                3, 2, 10, 1, 12, -4, 13, 0,
                2, 1, 10, -3, 13, 0,
                3, 2, 13, 1, 3, -1, 5, 0,
                2, 1, 12, 2, 13, 2,
                3, 1, 10, 2, 12, 1, 13, 0,
                3, 1, 10, -1, 13, -2, 11, 0,
                2, 1, 12, 2, 11, 1,
                3, 2, 10, 5, 2, -5, 3, 0,
                2, 2, 10, -4, 13, 0,
                3, 2, 10, 6, 2, -6, 3, 0,
                2, 2, 12, 2, 13, 0,
                3, 2, 10, -2, 13, -2, 11, 0,
                2, 2, 12, 2, 11, 0,
                2, 2, 10, -4, 11, 0,
                3, 2, 10, 7, 2, -7, 3, 0,
                3, 2, 10, -1, 12, -4, 13, 0,
                4, 2, 10, -1, 12, -2, 13, -2, 11, 0,
                3, 2, 10, 8, 2, -8, 3, 0,
                3, 2, 10, 9, 2, -9, 3, 0,
                3, 4, 10, -3, 12, -1, 13, 0,
                3, 6, 10, -1, 12, -3, 13, 0,
                3, 4, 10, -2, 12, -1, 13, 1,
                3, 5, 10, -1, 12, -2, 13, 0,
                2, 6, 10, -3, 13, 0,
                4, 4, 10, -1, 12, 1, 13, -2, 11, 0,
                3, 2, 10, -3, 12, 1, 13, 0,
                2, 3, 10, -2, 12, 0,
                3, 4, 10, -1, 12, -1, 13, 1,
                2, 5, 10, -2, 13, 0,
                3, 6, 10, 1, 12, -3, 13, 0,
                3, 4, 10, 1, 13, -2, 11, 0,
                3, 2, 10, -2, 12, 1, 13, 1,
                2, 3, 10, -1, 12, 0,
                4, -2, 10, -1, 13, 2, 3, -2, 5, 0,
                2, 4, 10, -1, 13, 0,
                4, 2, 10, -2, 12, -1, 13, 2, 11, 0,
                3, 4, 10, -3, 13, 2, 11, 0,
                4, -2, 10, -1, 13, 2, 2, -2, 3, 0,
                3, 2, 10, -1, 12, 1, 13, 2,
                4, -2, 10, -1, 13, 1, 3, -1, 5, 0,
                1, 3, 10, 0,
                3, 4, 10, 1, 12, -1, 13, 1,
                4, 2, 10, -1, 12, -1, 13, 2, 11, 1,
                4, -2, 10, -1, 13, 1, 2, -1, 3, 0,
                3, 2, 10, 3, 13, -2, 11, 0,
                2, 2, 12, -3, 13, 0,
                3, 1, 10, -1, 12, 2, 13, 0,
                4, 2, 10, 1, 13, -1, 11, 1, 14, 0,
                4, -2, 10, -2, 13, 18, 2, -16, 3, 0,
                5, 2, 10, 1, 13, 4, 3, -8, 4, 3, 5, 0,
                2, 2, 10, 1, 13, 1,
                5, -2, 10, -1, 13, 4, 3, -8, 4, 3, 5, 0,
                3, 2, 10, 18, 2, -16, 3, 0,
                4, -2, 10, -1, 13, -1, 11, 1, 14, 0,
                4, 4, 10, -1, 13, 2, 3, -2, 5, 0,
                4, 4, 10, -1, 13, 3, 2, -3, 3, 0,
                2, 3, 10, 1, 12, 1,
                3, 4, 10, 2, 12, -1, 13, 0,
                4, 2, 10, -1, 13, 1, 11, 1, 14, 0,
                3, 2, 10, -1, 13, 2, 11, 0,
                2, 1, 12, -3, 13, 1,
                2, 1, 10, 2, 13, 0,
                3, 2, 10, 1, 12, 1, 13, 1,
                3, 1, 12, -1, 13, -2, 11, 1,
                2, 1, 10, 2, 11, 0,
                4, 2, 10, 1, 12, -1, 13, 2, 11, 0,
                1, 3, 13, 0,
                4, 2, 10, 1, 13, 2, 3, -2, 5, 0,
                3, 1, 10, 1, 12, 2, 13, 0,
                3, 2, 10, 2, 12, 1, 13, 0,
                3, 1, 13, 1, 11, 1, 14, 0,
                2, 1, 13, 2, 11, 0,
                3, 1, 10, 1, 12, 2, 11, 0,
                4, 2, 10, 2, 12, -1, 13, 2, 11, 0,
                2, 1, 13, -4, 11, 0,
                2, 1, 10, -4, 13, 0,
                2, 1, 12, 3, 13, 1,
                3, 1, 12, 1, 13, 2, 11, 1,
                2, 2, 10, -5, 13, 0,
                3, 2, 10, -3, 13, -2, 11, 0,
                3, 2, 10, -1, 13, -4, 11, 0,
                3, 6, 10, -2, 12, -2, 13, 0,
                2, 4, 10, -3, 12, 0,
                3, 6, 10, -1, 12, -2, 13, 0,
                2, 4, 10, -2, 12, 1,
                2, 6, 10, -2, 13, 0,
                2, 4, 10, -1, 12, 1,
                2, 5, 10, -1, 13, 0,
                3, 6, 10, 1, 12, -2, 13, 0,
                4, 4, 10, -1, 12, -2, 13, 2, 11, 0,
                3, 4, 10, 2, 13, -2, 11, 0,
                3, 2, 10, -2, 12, 2, 13, 0,
                1, 4, 10, 0,
                3, 2, 10, -2, 12, 2, 11, 0,
                3, 4, 10, -2, 13, 2, 11, 0,
                3, 2, 10, -1, 12, 2, 13, 1,
                2, 3, 10, 1, 13, 0,
                2, 4, 10, 1, 12, 1,
                3, 2, 10, -1, 12, 2, 11, 1,
                3, 3, 10, -1, 13, 2, 11, 0,
                2, 2, 10, 2, 13, 0,
                3, 3, 10, 1, 12, 1, 13, 0,
                3, 2, 10, 1, 11, 1, 14, 0,
                2, 2, 10, 2, 11, 0,
                2, 1, 12, -4, 13, 0,
                2, 1, 10, 3, 13, 0,
                3, 2, 10, 1, 12, 2, 13, 1,
                3, 1, 12, -2, 13, -2, 11, 0,
                3, 1, 10, 1, 13, 2, 11, 0,
                3, 2, 10, 1, 12, 2, 11, 0,
                1, 4, 13, 0,
                3, 1, 10, 1, 12, 3, 13, 0,
                2, 2, 13, 2, 11, 0,
                4, 1, 10, 1, 12, 1, 13, 2, 11, 0,
                1, 4, 11, 0,
                2, 1, 12, 4, 13, 0,
                3, 1, 12, 2, 13, 2, 11, 0,
                3, 2, 10, -4, 13, -2, 11, 0,
                3, 6, 10, -2, 12, -1, 13, 0,
                2, 8, 10, -3, 13, 0,
                3, 6, 10, -1, 12, -1, 13, 0,
                3, 4, 10, -2, 12, 1, 13, 0,
                2, 6, 10, -1, 13, 0,
                3, 4, 10, -1, 12, 1, 13, 1,
                3, 6, 10, 1, 12, -1, 13, 0,
                4, 4, 10, -1, 12, -1, 13, 2, 11, 0,
                3, 2, 10, -2, 12, 3, 13, 0,
                2, 4, 10, 1, 13, 0,
                3, 4, 10, -1, 13, 2, 11, 0,
                3, 2, 10, -1, 12, 3, 13, 0,
                3, 4, 10, 1, 12, 1, 13, 0,
                4, 2, 10, -1, 12, 1, 13, 2, 11, 0,
                2, 2, 10, 3, 13, 0,
                3, 2, 10, 1, 13, 2, 11, 0,
                3, 2, 10, -1, 13, 4, 11, 0,
                3, 2, 10, 1, 12, 3, 13, 0,
                3, 1, 12, -3, 13, -2, 11, 0,
                3, 1, 10, 2, 13, 2, 11, 0,
                4, 2, 10, 1, 12, 1, 13, 2, 11, 0,
                1, 5, 13, 0,
                2, 3, 13, 2, 11, 0,
                2, 1, 13, 4, 11, 0,
                3, 1, 12, 3, 13, 2, 11, 0,
                2, 8, 10, -2, 13, 0,
                2, 6, 10, -1, 12, 0,
                1, 6, 10, 0,
                3, 6, 10, -2, 13, 2, 11, 0,
                3, 4, 10, -1, 12, 2, 13, 0,
                3, 4, 10, -1, 12, 2, 11, 0,
                2, 4, 10, 2, 13, 0,
                2, 4, 10, 2, 11, 0,
                3, 2, 10, -1, 12, 4, 13, 0,
                3, 4, 10, 1, 12, 2, 13, 0,
                4, 2, 10, -1, 12, 2, 13, 2, 11, 0,
                2, 2, 10, 4, 13, 0,
                3, 2, 10, 2, 13, 2, 11, 0,
                2, 2, 10, 4, 11, 0,
                1, 6, 13, 0,
                2, 4, 13, 2, 11, 0,
                2, 2, 13, 4, 11, 0,
                3, 6, 10, -1, 12, 1, 13, 0,
                2, 6, 10, 1, 13, 0,
                2, 4, 10, 3, 13, 0,
                3, 4, 10, 1, 13, 2, 11, 0,
                2, 2, 10, 5, 13, 0,
                3, 2, 10, 3, 13, 2, 11, 0,
                -1
            ],
        },
    },
};
