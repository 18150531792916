import { AquariusSymbol } from "./aquarius";
import { AriesSymbol } from "./aries";
import { CancerSymbol } from "./cancer";
import { CapricornSymbol } from "./capricorn";
import { GeminiSymbol } from "./gemini";
import { LeoSymbol } from "./leo";
import { LibraSymbol } from "./libra";
import { PiscesSymbol } from "./pisces";
import { SagittariusSymbol } from "./sagittarius";
import { ScorpioSymbol } from "./scorpio";
import { TaurusSymbol } from "./taurus";
import { VirgoSymbol } from "./virgo";

export const zodiacSignSymbols: Record<string, React.ComponentType<any>> = {
  'aries': AriesSymbol,
  'taurus': TaurusSymbol,
  'gemini': GeminiSymbol,
  'cancer': CancerSymbol,
  'leo': LeoSymbol,
  'virgo': VirgoSymbol,
  'libra': LibraSymbol,
  'scorpio': ScorpioSymbol,
  'sagittarius': SagittariusSymbol,
  'capricorn': CapricornSymbol,
  'aquarius': AquariusSymbol,
  'pisces': PiscesSymbol,
};

export * from './utils';